<template>

    <section id="searchAgain" class="  m-0 mb-0 pb-0" style="background-color:#FF0000; padding-top:58px;">
        <div class="align-items-center"  style="background-color:#FF0000;  margin: auto;">

                                    <carousel-slider></carousel-slider>

        </div>
                        
        <div class="ceka container shape-container d-flex align-items-center">

            

            <div style="margin:auto; z-index:1; border:1px solid #B4B4B4;" class="container row mt-3 p-3 bg-white formPesanan">
                            <div class="col-lg-12" style="position: relative;">

                                        <div class="text-left mb-4 semi-bold poppins fz-22" style="color:#0770CC; float-left" > {{ $t('message.top_titlediv1') }} </div>

                                         <div class="row">
                                            <div class="col-lg-12 col">
                                                <div class="row">
                                                    <div class="col-lg-5 col-12 p-0 m-0">
                                                        <div class="row p-0 m-0">
                                                            <div class="col-lg-5 ppp ppr col-12 text-left">
                                                                <div class="form-group">
                                                                    <label class="text-color-hitam1 montser regular fz-12">{{ $t('message.top_form_dari') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                                                    
                                                                    <multiselect style="width:100%; border-radius: 8px; border-color:#c4c4c4 !important;" class="border p-1" v-model="v_source"  select-label="Pilih" deselect-label=""  track-by="name" label="name" :placeholder="$t('message.top_form_placeholder_dari')" :options="options" :searchable="true" :allow-empty="false" group-values="location" group-label="location_category_name" >
                                                                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                                                    </multiselect>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 tukar pl-2 pr-0" style="text-align:center;">
                                                                <img @click="ubahDatago" style="cursor: pointer;" src="img/imgjrg/sswap.png" width="30" />
                                                            </div>
                                                            <div class="col-lg-5 ppp col-12 text-left ">
                                                                    <div class="form-group">
                                                                        <label class="text-color-hitam1 montser regular fz-12"> {{ $t('message.top_form_ke') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>

                                                                        <multiselect class="border p-1" style="border-radius: 8px; border-color:#c4c4c4 !important;" v-model="v_destination" select-label="Pilih" deselect-label=""  track-by="name" label="name" :placeholder="$t('message.top_form_placeholder_ke')" :options="options" :searchable="true" :allow-empty="false" group-values="location" group-label="location_category_name" >
                                                                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                                                        </multiselect>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                     <div class="col-lg-5 col-12 text-left ">
                                                            <div class="row">
                                                                <div class="col-lg-6 col-12 text-left pr-0">
                                                                    <div class="form-group">
                                                                        <label class="text-color-hitam1 montser regular fz-12">{{ $t('message.top_form_berangkat') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span> </label>
                                                                        <datetime  style="width:100%;" format="ddd, DD MMM YYYY" :disabled-date="stopDateYesterday" :lang="lang" v-model="date_go" type="date" input-class="form-control p-1"></datetime>
                                                                    </div>
                                                                </div>
                                                            
                                                                <div class="col-lg-6 col-12 text-left pr-0">
                                                                    <div class="form-group">
                                                                        <label class="text-color-hitam1 montser regular fz-12">
                                                                            <input class="mt-1" type="checkbox" id="checkbox" v-model="v_check_home" />
                                                                                {{ $t('message.top_form_pulang') }} 
                                                                        </label>
                                                                        <datetime  :disabled="!v_check_home" style="width:100%;"  :disabled-date="stopDateYesterday"  format="ddd, DD MMM YYYY" :lang="lang" v-model="date_home" type="date" input-class="form-control"></datetime>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                     </div>
                                        
                                                  



                                                    <div class="col-lg-2 col text-left">
                                                            <div class="form-group">
                                                                <label class="text-color-hitam1 montser regular fz-12">{{ $t('message.top_form_penumpang') }}<span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                                                <multiselect select-label="" style="border-radius: 8px; border-color:#c4c4c4 !important;" class="border p-1" v-model="v_seat" deselect-label="" track-by="name" label="name" :placeholder="$t('message.top_form_placeholder_dewasa')" :options="$t('message.top_options_seat')" :searchable="false" :allow-empty="false">
                                                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                                                </multiselect>
                                                                <small class="avenirLight roman" style="font-size:10px;">{{ $t('message.usia_des') }}</small>
                                                            </div>
                                                    </div>
                                                 </div>
                                            </div>
                                         </div>
                                         <div class="row">
                                             <div class="col-lg-8 float-left pt-3">
                                                 <span class="montser regular fz-18">{{ $t('message.top_form_deskripsi') }}
                                                     
                                                 </span>
                                             </div>
                                             <div class="col-lg-4 mr-0 mt-2">
                                                    <div class="offset-lg-6 col-lg-6 pr-0">

                                                        <button :style="'border-radius: 0px; background-color:' + ( disabled_btn_cari || loading_api ? '#8898aa' : '#FF0000' ) + '; cursor: ' + ( disabled_btn_cari || loading_api ? 'no-drop' : 'pointer' )" @click="searchTravelNow" class="col-12 btn btn-sm mb-3 text-white pl-3 pr-3" :disabled="( disabled_btn_cari || loading_api )">

                                                         <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                                        <span v-if="!loading_api" class=" avenirHeavy medium fz-18"><i class="fa fa-search"></i>  {{ $t('message.top_form_cari') }} </span>
                                                        
                                                        </button>
                                                        
                                                    </div>
                                             </div>
                                         </div>
                              
                            </div>
                         </div>
        </div>




    </section>
</template>
<script>

import DatePicker from 'vue2-datepicker';
import '../../assets/cssVendor/vue-datePicker.css';
import '../../assets/cssVendor/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect' 
import swal from 'sweetalert';
import axios from "axios";
import Config from "../../model/Config"
import { Helper } from '../../helper.js';
import { Master } from '../../model/Master.js'
import { Validate } from '../../model/Validate.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import CarouselSlider from "../Carousel"

export default {
    mixins:[Helper,Master,Validate],
    components: {
        datetime: DatePicker,
        multiselect: Multiselect,
        PulseLoader,
        CarouselSlider
    },
    data() {
        return {
            loading_api:false,
            slide: 0,
            sliding: null,
            v_check_home:false,
            isOkIsi:false,

            is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            runTest_setDataResultBus:null,
            runTest_setDataPesan:null,

            disabled_btn_cari:true,
            ubahDatago_flag:true,
            flag_check_home:false,

            data_url:Config,
            date_go: null,
            date_home: null,
            v_source: null,
            v_destination: null,
            v_seat: { name: '1 Dewasa', value: 1 },
            v_seat_baby: null,
            v_weight:null,
            v_dimension:null,
            options_dimension: [
                { name: '< 20x20x20', language: 'JavaScript' },
                { name: '< 50x50x50', language: 'Ruby' },
                { name: '< 100x100x100', language: 'Elixir' },
                { name: '> 100x100x100', language: 'Elixir' }
            ],
           
            options:  [
                {
                    language: 'Surabaya',
                    libs: [
                        { name: 'Sidoarjo', category: 'Front-end' },
                        { name: 'Surabaya Bungur', category: 'Backend' }
                    ]
                },
                {
                    language: 'Jakarta',
                    libs: [
                        { name: 'Tanah Abang', category: 'Backend' },
                        { name: 'Monas', category: 'Backend' }
                    ]
                },
                {
                    language: 'Jogja',
                    libs: [
                        { name: 'Malioboro', category: 'Backend' },
                        { name: 'Candi Prambanan', category: 'Backend' }
                    ]
                }
            ],
            lang: {
                formatLocale: {
                    // MMM
                    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
                    // dds
                    weekdaysMin: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                    weekdaysShort: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                },
            },
            disabledDates: {
                to: new Date(Date.now() - 8640000)
            }
        };
    },
    created(){

    

        this.getLocation();
        

    },
   
    updated(){

        if( this.v_check_home == true & this.flag_check_home == false ){
            this.date_home = this.date_go
            this.flag_check_home = true
        }
        
         if( this.v_check_home == false & this.flag_check_home == true ){
             this.date_home = null
             this.flag_check_home = false
        }

        try {



             if( this.isKosong( this.v_source.id ) 
            || this.isKosong( this.v_destination.id ) 
            || this.isKosong( this.v_seat.value)
            || this.isKosong(this.date_go ) 
            || ( this.v_check_home == true & this.isKosong(this.date_home )) ){
                this.disabled_btn_cari = true
            }else{
                this.disabled_btn_cari = false
            }
            
        } catch (error) {
            error
        }

       

    },
    methods: {

        ubahDatago(){

            let ubah = null;

            if(this.ubahDatago_flag){
                this.ubahDatago_flag = false
                ubah = this.v_source
                this.v_source = this.v_destination
                this.v_destination = ubah

            }else{
                this.ubahDatago_flag = true

                ubah = this.v_destination
                this.v_destination = this.v_source
                this.v_source = ubah

            }

        },

        stopDateYesterday(date){
            var dateN = new Date();
            // add a day
            dateN.setDate(dateN.getDate() - 1);

            return date < dateN
        },

        async getLocation(){

            await axios.post( this.data_url.getPSILocation, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                        this.runTestResult = true

                        if( !this.is_runTest ){ console.log(res.data.resultdata); }
                        this.options = res.data.resultdata;
                }
            }).catch( ( error ) => {
                console.log(error)

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });
        },
        

        async searchTravelNow(){
                
            try {

                if(this.v_source.id == this.v_destination.id ){
                     swal({
                            title: "Maaf, Data Tidak Valid",
                            text: "Kota Asal dan Tujuan Tidak Boleh Sama",
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false
                }


                this.loading_api = true
                let formData = new URLSearchParams(); 
                

                if(!this.is_runTest){

                        formData.append('date_berangkat', this.dateParse( this.date_go ) );

                        if( this.v_check_home == true ){
                            formData.append('date_pulang',  this.dateParse( this.date_home ) );
                            formData.append('is_pp', "Y" );
                        }
                        
                        formData.append('location_from_id', this.v_source.id ); 
                        formData.append('location_to_id', this.v_destination.id ); 
                        formData.append('total_penumpang', this.v_seat.value );

                }else{
                        formData = this.runTestParameter;
                }

                await axios.post( this.data_url.getPSIAvailableJadwal + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                    if( res.data.codestatus == "S" ){

                        if( res.data.resultdata[0].jadwal_keberangkatan.length < 1 ){
                          
                               swal({
                                    title: "Jadwal Berangkat",
                                    text: "Mohon Maaf, Jadwal Tidak Tersedia",
                                    icon: '/img/imgjrg/antri.png'
                                });

                             return false
                        }

                        if( this.v_check_home == true ){
                            if( res.data.resultdata[0].jadwal_kepulangan.length < 1 ){
                                 swal({
                                    title: "Jadwal Kepulangan",
                                    text: "Mohon Maaf, Jadwal Tidak Tersedia",
                                    icon: '/img/imgjrg/antri.png'
                                });
                                 return false
                            }
                        }
                        
                        this.runTestResult = true;
                        this.runTest_setDataResultBus = res.data.resultdata[0];

                        let data_home = "";
                        try {
                            data_home = this.dateParse( this.date_home )
                        } catch (error) {
                            data_home = ""
                        }

                        this.$store.commit('setDataPesan', [ this.dateParse( this.date_go ), data_home , this.v_source, this.v_destination, this.v_seat, this.v_check_home ] );
                        
                        this.$store.commit('setDataResultBus', res.data.resultdata[0]);
                        
                        this.$parent.activeLevelOne();

                    }
                }).catch( ( error ) => {

                    if(this.searchWord(error,"Network Error")){
                        swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                    }

                    if(this.is_runTest == false){ console.log(error)  }
                    try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }

                });
                
            } catch (error) {
                console.log(error)
            }
           this.loading_api = false


             
        },
        async searchPacketNow(){

            this.$parent.activeLevelOnePacket();

            this.$store.commit('setDataOrderPacket', 'dataOrderPacket');


        }
    }
};
</script>
<style>

.mx-input-wrapper > * {
  text-align: center;
  font-weight: bold;
  font-size:16px !important;
  color:#2c2c2c !important;
}

input[name="date"] {
  text-align: center;
  font-weight: bold;
}

.mx-input-wrapper > input {
    border-color: #c4c4c4 !important;
    border-radius: 8px;
    padding: 10px;
    height:52px;
} 

@media (min-width: 320px) { 

    .ppp{
        padding-left: 20px ;
    }

    .tukar{
         padding-top:10px;
    }

    .ceka{
        padding-bottom: 20px;
    }
}

@media (min-width: 576px) { 

    

    .ceka{
        padding-bottom: 20px;
    }
}

@media (min-width: 768px) { 
    

    .ceka{
        padding-bottom: 20px;
    }
}

@media (min-width: 992px) { 
    .formPesanan{
        position: absolute; 
        border-radius:20px; 
        top:300px; 
        margin-left: auto;
        margin-right: auto;
    }

    .ppp{
        padding-left: 0px !important;
    }

    .ppr{
        padding-right: 0px !important;
    }

   
    .ceka{
        padding-bottom: 0px;
    }
}

@media (min-width: 1200px) { 
    .formPesanan{
        position: absolute; 
        border-radius:20px; 
        top:350px; 
        /* left:10%;
        right:0; */
        margin-left: auto;
        margin-right: auto;
    }

     .tukar{
        padding-top:40px;
    }
}

@media (min-width: 1400px) { 
    .formPesanan{
        position: absolute; 
        border-radius:20px; 
        top:480px; 
        left:auto;
        right:auto;
        margin-left: auto;
        margin-right: auto;
    }

  
}

@media (min-width: 1600px) { 
    .formPesanan{
        position: absolute; 
        border-radius:20px; 
        top:580px; 
        left:auto;
        right:auto;
        margin-left: auto;
        margin-right: auto;
    }

    
}

</style>
