<template>
    <div>
        
        <div class="col-lg-12">

            <p class="border-bottom"><span class="avenirORoman fz-16"> {{ dataBank.name }}</span> <img class="float-right" :src="'img/imgjrg/' + payment.imageName" /></p>
            

            <p class="border-bottom"><span class="avenirORoman fz-16">{{ $t('paymentMethodRetail.txt_3') }}</span> <br/><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ convertToRupiah(totalHarga()) }}</span></p>

            <p><span class="avenirORoman fz-16">
                Pembayaran untuk ShopeePay menggunakan QR CODE, Silahkan scan QR-Code dibawah ini untuk melakukan pembayaran.
                </span>
            </p>
            <p class="text-center">
                <vue-qrcode v-if="!loading_api" :value="qrcode" :options="{ width: 300 }"></vue-qrcode>
                <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
            </p>

            <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('paymentMethodRetail.txt_4') }}</span></p>
            <p class="avenirMedium fz-14 border-bottom">
               <ol>
                   <li v-for="(value, index) in dataBank.info_penting_pembayaran" :key="index" >{{ value.description }}</li>
               </ol>
            </p>

            <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('paymentMethodRetail.txt_5') }}</span></p>
           <b-card no-body class="mb-1 border-0" v-for="(value, index) in dataBank_detail" :key="index">
                <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="bg-white avenirHeavy fz-14" variant=""> {{ value.name }} </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body class="avenirORoman fz-14">
                    <b-card-text v-for="(valuex, index) in value.detail" :key="index">{{ valuex.no }}.  {{ valuex.description }}</b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

        </div>

        <button class="btn btn-outline-danger float-left hov" @click="changeMethod"> {{ $t('paymentMethodRetail.txt_7') }}</button>
            <a href="#top" v-smooth-scroll> 
                <button class="btn btn-danger float-right bgDefaultMerah" @click="finish"> {{ $t('paymentMethodRetail.txt_8') }} </button>
            </a>

        <div class="clearfix"></div>


       

    </div>
</template>
<script>

import axios from "axios";
import { Helper } from '../../../helper.js';
import { Master } from '../../../model/Master.js';
import { Validate } from '../../../model/Validate.js';
import Config from "../../../model/Config"
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import swal from 'sweetalert';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
    mixins:[Validate,Master,Helper],
    components:{
        PulseLoader,
        VueQrcode
    },
    data() {
        return {

             is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            runTestHeader:null,
            runTest_setDataPayment:null,

            qrcode:null,
            data_url:Config,
            dataBank_detail:[],
            payment:{
                methodName:null,
                virtualName:null,
                imageName:null,
                nomorVA:null,
            },
            result_setpyment:{
                desktop_web_checkout_url:"",
            },
        }
    },
    props:{
        id:String,
        delMethodPayment:Function,
        dataBank:Object,
        dataPayment:Object,
        orderRekap:Object,
        is_ticket:Boolean,

         is_payment_ticket: {
            type: Boolean,
            default: true
        },

         is_runTestProp:{
            type: Boolean,
            default: false
        },
        data_available_travel:{
            type: Object,
            default: null
        }
    },
    async created(){
        this.getName();

        console.log(this.dataBank)
        console.log(this.dataPayment)

        this.qrcode = "00020101021126570011ID.DANA.WWW011893600915310892391202091089239120303UMI51440014ID.CO.QRIS.WWW0215ID10210617450920303UMI5204504553033605802ID5907Daganah6011Kab. Gresik61056111163042E25"

        this.getInfoPay();

        try {
            this.dataBank_detail = this.dataBank.cara_pembayaran
        } catch (error) {
            this.dataBank_detail = []
        }
      
    },
    methods:{
        async getInfoPay(){

                this.loading_api = true

                let formData = new URLSearchParams(); 

                 if( !this.is_runTestProp ){

                    formData.append('id', this.dataPayment.id);
                    formData.append('psi_m_payment_method_id', this.dataBank.id);

                }else{
                    formData = this.runTestParameter
                }

                let headerSet = null
                if( this.is_runTestProp ){
                    console.log("" + formData );
                    console.log(JSON.stringify( this.runTestHeader ));
                    headerSet = this.runTestHeader
                }else{
                     if(this.is_ticket){
                        headerSet = this.dataHeaderWithTrx_Token()
                    }else{
                        headerSet = this.dataHeaderWithTrx_Token(2)
                    }
                }   

                
                await axios.post( this.data_url.setPSIPaymentEWallet + "?" + formData, null, headerSet ).then(res => {            
                    console.log(res.data)

                    if( res.data.codestatus == "S" ){

                        this.runTestResult = true;

                        this.qrcode = res.data.resultdata[0].qr_checkout_string
                    }else{
                         swal({
                            title: "Ada Kesalahan",
                            text: "Maaf, Silahkan pilih kembali metode pembayaran",
                            icon: '/img/imgjrg/error.png'
                        });
                        this.$emit('delMethodPaymentByApi');
                    }

                }).catch( ( error ) => {

                      try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                    if(this.searchWord(error,"Network Error")){
                           swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                        this.$emit('delMethodPaymentByApi');
                        return false
                    }

                    if(this.searchWord(error,"401")){
                                 swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                                localStorage.removeItem('login');
                                this.$router.push('/redirect')
                            }

                    
                            
                    this.$emit('delMethodPaymentByApi');
                    console.log(error)
                });

                this.loading_api = false

        },
        copyCodeVirtual(){
             navigator.clipboard.writeText(  this.dataBank.va_number );
        },
        finish(){
            this.$emit('finish');    
        },
        changeMethod(){
            this.$emit('delMethodPayment');
        },

         totalHarga(){

            if( this.is_ticket )
            {

                try {
                    if( this.orderRekap.data_trx2.length > 0 ){
                    return this.orderRekap.data_trx2[0].total_amount
                    }
                    
                } catch (error) {
                    return this.orderRekap.data_trx[0].total_amount
                }
            }else{
                 try {
                    return this.orderRekap.data_packet.total_fix
                    } catch (error) {
                        return 0 ;
                }
            }

        },

        getName(){
            switch (this.dataBank.name) {
                case "ShopeePay":
                    this.payment.methodName = "ShopeePay"
                    this.payment.imageName = 'mp_shopee.png';
                break;                  
            }
        }
    }
}
</script>