<template>
    <footer style=" background:#ff0000; color:#FFFFFF; ">
        <div class="pt-5 pb-5">
        <div class="container"  >
            <div class="row">
                <div class="col-lg-3 mb-2">
                    <p class="font-weight-bold avenirHeavy fz-18">{{ $t('footer.text_5') }}</p>

                    <div class="row">
                        <div class="col-lg-12 col-6">
                            <div class="row">
                                <div class="col-lg-3 pt-2"><img width="40" src="img/imgjrg/wa.svg" /></div>
                                <div class="col-lg-9 avenirHeavy fz-14">
                                <p> <a style="color:white;" :href="'https://wa.me/' + this.dataKontak.wa" ><span class="fz-14" >{{ this.dataKontak.wa_name }} </span> <br/><span class="fz-14" > {{ this.dataKontak.wa }} 
                                    <!-- <br/> {{ this.dataKontak.wa1 }}   -->
                                    </span></a> </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-6">
                            <div class="row">
                                <div class="col-lg-3 pt-2"><img  width="40" src="img/imgjrg/email.svg" /></div>
                                <div class="col-lg-9 avenirHeavy fz-14">
                                <p> <a style="color:white;" :href="'mailto:' + this.dataKontak.email" > <span class="fz-14">{{ this.dataKontak.email_name }}</span> <br/><span class="fz-14" > {{ this.dataKontak.email }}  </span> </a> </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-6">
                            <div class="row">
                                <div class="col-lg-3 pt-2"><img  width="40" src="img/imgjrg/cc.svg" /></div>
                                <div class="col-lg-9 avenirHeavy fz-14">
                                <p>  <a style="color:white;" :href="'tel:' + this.dataKontak.telp + ':'" >  <span class="fz-14">{{ this.dataKontak.telp_name }}</span> <br/><span class="fz-14" >  {{ this.dataKontak.telp }}  </span> </a> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3" v-if="isContentMenu">
                    <p class="font-weight-bold avenirHeavy fz-18">{{ $t('footer.text_4') }}</p>
                    <p @click="goHowOrder" class="avenirMedium fz-14 pointer" v-if="isContentMenuArray[0]" >{{  this.contentMenu[0].name }}</p>
                    <p @click="goHowPacket" class="avenirMedium fz-14 pointer" v-if="isContentMenuArray[1]">{{  this.contentMenu[1].name }}</p>
                    <p @click="goContact" class="avenirMedium fz-14 pointer" v-if="isContentMenuArray[2]">{{  this.contentMenu[2].name }}</p>
                    <p @click="goAboutMe" class="avenirMedium fz-14 pointer" v-if="isContentMenuArray[3]">{{  this.contentMenu[3].name }}</p>
                    <p @click="goTerms" class="avenirMedium fz-14 pointer" v-if="isContentMenuArray[4]">{{  this.contentMenu[4].name }}</p>
                </div>

                <div class="col-lg-3 mb-2"  v-if="isContentMenu">
                    <p class="font-weight-bold avenirHeavy fz-18">{{ $t('footer.text_1') }}</p>
                    <router-link to="/" class="text-white"><p class="avenirMedium fz-14">{{ $t('footer.text_2') }}</p></router-link>
                    <p class="avenirMedium fz-14 pointer" @click="pesanBanyak" v-if="isContentMenuArray[5]">{{ this.contentMenu[5].name }}</p>
                    <router-link to="/kirimpaket" class="text-white"><p class="avenirMedium fz-14">{{ $t('footer.text_3') }}</p></router-link>
                    <p></p>
                </div>

                <div class="col-lg-3" >
                    <div class="row">
                        <div class="col-lg-12 font-weight-bold avenirHeavy fz-18">{{ this.url_follow }}</div>
                        <div class="col-lg-12">
                            <div class="row mt-2 text-left">
                                  <div class=" col-lg-2  col-2">
                                     <a :href="url_fb" target="_blank"> <i class="fa fa-facebook-square fa-2x" style="color:#ffffff;" aria-hidden="true"></i></a>
                                  </div>
                                  <div class="col-lg-2 col-2 enter">
                                    <a :href="url_twitter" target="_blank"><i class="fa fa-twitter fa-2x" style="color:#ffffff;" aria-hidden="true"></i></a>
                                  </div>
                                  <div class="col-lg-2 col-2 ">
                                     <a :href="url_instagram" target="_blank"><i class="fa fa-instagram fa-2x" style="color:#ffffff;" aria-hidden="true"></i></a>
                                  </div>
                                  <div class="col-lg-2 col-2">
                                     <a :href="url_youtube" target="_blank"> <i class="fa fa-youtube fa-2x" style="color:#ffffff;" aria-hidden="true"></i></a>
                                  </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-3" v-if="isContentMenu">
                            <div style="width:200px;">
                                <img src="img/imgjrg/jrg_putih.svg" height="100" width="200" />
                                <p class="avenirLight fz-20 font-weight-bold text-center" >{{ contentMenu[contentMenu.length - 1].description }}</p>
                                <p class="avenirLight fz-15 font-weight-bold text-left" >{{ $t('footer.text_6') }}</p>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>
    </footer>
</template>
<script>

import axios from "axios";
import Config from '../model/Config'
import { Helper } from '../helper';
import { Master } from '../model/Master.js';
import { Validate } from '../model/Validate';

import swal from 'sweetalert';  
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";


export default {
  name: 'app-footer',
  mixins:[Validate,Master,Helper],   
//   components: {
//     PulseLoader
//   },
  data() {
    return {
      data_url:Config,
      dataKontak:{
          wa_name:null,
          wa1_name:null,
          email_name:null,
          telp_name:null,

          wa:null,
          wa1:null,
          email:null,
          telp:null,
      },

        is_runTest:false,
        runTestParameter:"",
        runTestResult:false,
        url_fb:'',
        url_twitter:'',
        url_instagram:'',
        url_follow:'',
        lang:"id",
        url_youtube:'',
        contentMenu:[],
        isContentMenu:false,
        isContentMenuArray:[false,false,false,false,false,false],

        year: new Date().getFullYear()
    }
  },



  async created(){
    this.getDataCallCenter();
    this.getDataExtraMenu();
  },

  updated(){
      if( this.$i18n.locale != this.lang ){
        this.lang = this.$i18n.locale;
        this.getDataExtraMenu();
      }
  },

  methods:{

      pesanBanyak(){
          this.$router.push('/pesanbanyak');
      },

      goTerms(){
          this.$router.push('/terms');
      },

      goHowPacket(){
          this.$router.push('/howpacket');
      },

      goContact(){
          this.$router.push('/contact');
      },

      goHowOrder(){
         
        this.$router.push('/howorder');
       
         
      },

      goAboutMe(){
          this.$router.push('/aboutme');
      },

     async getDataCallCenter(){
            this.loading_api = true
            await axios.post( this.data_url.getPSICallCenter, null, this.dataHeaderNonAuth ).then(async res => { 
                    if( res.data.codestatus == "S" ){

                        this.runTestResult = true


                        try { this.dataKontak.wa =  res.data.resultdata[0].description[0].desc } catch (error) { this.dataKontak.wa = "" } 
                        try { this.dataKontak.wa_name =  res.data.resultdata[0].name } catch (error) { this.dataKontak.wa_name = "" } 

                        try { this.dataKontak.email =  res.data.resultdata[1].description[0].desc  } catch (error) {  this.dataKontak.email ="" } 
                        try { this.dataKontak.email_name =  res.data.resultdata[1].name  } catch (error) {  this.dataKontak.email_name = "" } 
                       
                        try { this.dataKontak.telp =  res.data.resultdata[2].description[0].desc  } catch (error) { this.dataKontak.telp = "" } 
                        try { this.dataKontak.telp_name =  res.data.resultdata[2].name  } catch (error) { this.dataKontak.telp_name = "" } 

                        try { this.url_follow =  res.data.resultdata[3].name  } catch (error) {  this.url_follow = "" } 
                        try { this.url_fb =  res.data.resultdata[3].description[0].desc  } catch (error) { this.url_fb = "" } 
                        try { this.url_twitter =  res.data.resultdata[3].description[1].desc  } catch (error) { this.url_twitter = "" } 
                        try { this.url_instagram =  res.data.resultdata[3].description[2].desc  } catch (error) { this.url_instagram = "" } 
                        try { this.url_youtube =  res.data.resultdata[3].description[3].desc  } catch (error) {  this.url_youtube = "" } 
                    }
            }).catch( ( error ) => {
                console.log(error)
                if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });
            this.loading_api = false
      },

       async getDataExtraMenu(){

          this.loading_api = true
          this.isContentMenu = false

           let formData = new URLSearchParams(); 
        //    alert( this.$t('locale_lang'))
           formData.append('lang',  this.$t('locale_lang') )

          await axios.post( this.data_url.getPSIMenuExtraInfo + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                  
                    for (let index = 0; index <  res.data.resultdata.length; index++) {

                        try {
                             this.contentMenu[res.data.resultdata[index].sequence] = res.data.resultdata[index]
                             this.isContentMenuArray[res.data.resultdata[index].sequence] = true
                        } catch (error) {
                            this.contentMenu[res.data.resultdata[index].sequence] = ""
                        }
                    }

                    if( res.data.resultdata.length > 0 ){
                        this.isContentMenu = true
                    }else{
                        this.isContentMenu = false
                    }
                    

                }

          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false


      },

  }
};
</script>
<style>

    .linkwhite{
        color:white !important;
    }
    .textInline
    {
        display:inline-block;
    }
    .pointer{
        cursor: pointer;
    }

</style>
