<template>
  <div>

      <div class="col-lg-12">

            <p class="avenirHeavy fz-20">Kartu Saya</p>

          <div class="row">
             <div class="col-lg-3 m-2 p-3 text-center  bg-white"  style="border-radius:20px; border:1px solid #B4B4B4;">
                 <img src="img/imgjrg/tambah.png" height="20" /><br/><br/>
                 <p class="avenirMedium fz-13">Tambah Kartu Kredit/Debit</p>
             </div>
             <div class="col-lg-3 m-2 p-3 text-center  bg-white"  style="border-radius:20px; border:1px solid #B4B4B4;">
                 <img src="img/imgjrg/tambah.png" height="20" /><br/><br/>
                 <p class="avenirMedium fz-13">Tambah Kartu Kredit/Debit</p>
             </div>
             <div class="col-lg-3 m-2 p-3 text-center  bg-white"  style="border-radius:20px; border:1px solid #B4B4B4;">
                 <img src="img/imgjrg/tambah.png" height="20" /><br/><br/>
                 <p class="avenirMedium fz-13">Tambah Kartu Kredit/Debit</p>
             </div>
          </div>
      </div>
     
  </div>
</template>
<script>


export default {
  name: "components",
  components: {
    
  },
  data() {
        return {
            
        }
  },
  methods: {
    
  }
};
</script>
<style>


.numberCircle {
    width: 70px;
    height: 70px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    font-size: 32px;
    border: 2px solid #FFE3E2;
}


</style>
