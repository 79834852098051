<template>
  <div class="pt-0">
 
    <choose-payment-method :is_payment_ticket="false" v-if="level_payment" ref="paymentMethodRef"></choose-payment-method>
      
    <section id="InputePassenger" class="section-components pb-5 pt-5 bg-white" ref="spTop" v-if="!level_payment">
         <carousel-slider></carousel-slider>


        <div class="container mt-4" >
          <div class="row justify-content-center section" v-show="templateHalaman == 0">
            <div class="col-lg-6 mb-4">
              <div class="offset-lg-1 col-lg-11 avenirHeavy fz-25 border-bottom text-center font-weight-bold" style="color:#ff0000" >
                  {{ $t('sendPacket.text_1') }}
              </div>
            </div>
           
            
         
            <div class="offset-lg-1 col-lg-11 avenirHeavy fz-22 border-bottom mb-4 pb-2" >
               {{ $t('sendPacket.text_2') }}
            </div>
            <div class="col-lg-5 offset-lg-1 pt-2" >
                    <div class="form-group">
                        <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_3') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                        <input type="text" style="border-color:#c4c4c4;" class="form-control hitamc2Child" v-model="input.nama_pengirim" >
                    </div>
                    <div class="form-group">
                          <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_4') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                            <multiselect style="width:100%; border:1px solid #c4c4c4;" v-model="v_pengirim_provinsi_computed"  select-label="Pilih" deselect-label=""  track-by="name" label="name" placeholder="" :options="options_provinsi" :searchable="true" :allow-empty="false" >
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                          </multiselect>
                            <!-- <input type="text" class="form-control" v-model="input.provinsi_pengirim" placeholder="Provinsi"> -->
                      </div>
                    <div class="form-group">
                          <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_5') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                           <pulse-loader :color="'#c4c4c4'" :loading="loading_api_kota_pengirim"></pulse-loader>
                            <multiselect v-show="!loading_api_kota_pengirim" style="width:100%;  border:1px solid #c4c4c4;" v-model="v_kota_pengirim_computed"  select-label="Pilih" deselect-label=""  placeholder="" track-by="name" label="name" :options="options_kota_pengirim" :searchable="true" :allow-empty="false" >
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                          </multiselect>
                           <!-- <input type="text" class="form-control" v-model="input.kota_pengirim" placeholder="Kota / Kabupaten"> -->
                      </div>
                    <div class="form-group">
                          <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_6') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                           <pulse-loader :color="'#c4c4c4'" :loading="loading_api_kecamatan"></pulse-loader>
                            <multiselect  v-show="!loading_api_kecamatan" style="width:100%; border:1px solid #c4c4c4;"  v-model="v_kecamatan_pengirim_computed"  select-label="Pilih" deselect-label=""  placeholder="" track-by="name" label="name"  :options="options_kecamatan_pengirim" :searchable="true" :allow-empty="false" >
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                          </multiselect>
                             <!-- <input style="border:1px solid #c4c4c4;" type="text" class="form-control" v-model="input.kecamatan_pengiriman" placeholder="Kecamatan"> -->
                      </div>
              </div>        


              <div class="col-lg-5 offset-lg-1 pt-2" >
                      <div class="form-group">
                          <label class="avenirORoman fz-16 abuabu"> Email <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                          <input type="text" style="border:1px solid #c4c4c4;" v-model="input.email_pengiriman" class="form-control hitamc2Child" >
                      
                      </div>
                      <div class="form-group">
                          <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_7') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                          <input type="number" min="0" style="border:1px solid #c4c4c4;" v-model="input.handphone_pengiriman" class="form-control hitamc2Child" >
                      </div>

                       <div class="form-group">
                          <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_8') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                          <b-textarea class="hitamc2Child" style="border:1px solid #c4c4c4;" v-model="input.alamat_pengiriman"></b-textarea>
                        </div>
                
                      <div class="form-group">
                            <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_9') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                            <!-- <input type="number" style="border:1px solid #c4c4c4;" v-model="input.kode_pos_pengiriman" class="form-control" placeholder="Kode Pos"> -->
                            <multiselect style="width:100%; border:1px solid #c4c4c4;"  v-model="input.kode_pos_pengiriman"  select-label="Pilih" deselect-label=""  track-by="name" label="name"  placeholder="" :options="options_kode_pos_pengiriman" :searchable="true" :allow-empty="false" >
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                          </multiselect>

                      </div>

                     <div class="form-group" >
                          <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_10') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                          <multiselect style="width:100%; border:1px solid #c4c4c4;" v-model="input.konter_pengirim"  select-label="Pilih" deselect-label=""  track-by="name" label="name"  placeholder="" :options="options_konter_pengirim" :searchable="true" :allow-empty="false" >
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                          </multiselect>
                      </div>

                      <div class="col-lg-12 offset-lg-1 pt-2 mt-3" >
                            <div class="alert alert-warning" role="alert" v-if="data_validate.length > 0">
                                <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                                <ol>
                                    <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                                </ol>                            
                            </div>
                      </div>

                      <div class="col-lg-12 offset-lg-1 mt-5 offset-6 col-6" style="text-align:right;padding-right:40px;">
                          <button :disabled="loading_api" @click="lanjuth2" class="avenirMedium fz-18 text-center btn btn-danger text-white" style="border-radius:25px;">
                              <span v-if="!loading_api_lanjut_bayar"> {{ $t('sendPacket.text_11') }} </span>
                          </button>
                      </div>

              </div>

            

            </div>

          <div class="row justify-content-center mt-2" v-show="templateHalaman == 1" >

              <div class="col-lg-5 offset-lg-1 col-6">
               <button :class="'avenirMedium fz-18 text-center  btn ' + ( input.penerima_antar == false ? 'btn-outline-danger' : 'btn-danger' )" style="border-radius:25px; width:100%; white-space: normal;" @click="input.penerima_antar = true">
                   {{ $t('sendPacket.text_12') }}
              </button>
            </div>

              <div class="col-lg-5 offset-lg-1 col-6">
                <button :class="'avenirMedium fz-18 text-center  btn ' + ( input.penerima_antar == true ? 'btn-outline-danger' : 'btn-danger' )" style="border-radius:25px;  width:100%; white-space: normal;"  @click="input.penerima_antar = false">
                   {{ $t('sendPacket.text_13') }}
                </button>
              </div>


              <div class="offset-lg-1 col-lg-11 mt-3 pb-2 mb-3 avenirHeavy fz-22 border-bottom"  >
                 {{ $t('sendPacket.text_14') }}
              </div>
              <div class="col-lg-5 offset-lg-1 pt-2" >
                  <div class="form-group">
                      <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_15') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                      <input type="text" style="border:1px solid #c4c4c4;" v-model="input.nama_penerima" class="form-control hitamc2Child" >
                  </div>
                  <div class="form-group">
                            <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_16') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                              <multiselect style="width:100%;  border:1px solid #c4c4c4;" v-model="v_provinsi_penerima_computed"  select-label="Pilih" deselect-label=""  track-by="name" label="name"  placeholder="" :options="options_provinsi" :searchable="true" :allow-empty="false" >
                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                            </multiselect>
                             <!-- <input type="text" class="form-control" v-model="input.provinsi_penerima" placeholder="Provinsi Penerima"> -->
                        </div>
                      <div class="form-group">
                            <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_17') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                               <pulse-loader :color="'#c4c4c4'" :loading="loading_api_kota"></pulse-loader>
                              <multiselect v-show="!loading_api_kota" style="width:100%;  border:1px solid #c4c4c4;" v-model="v_kota_penerima_computed"  select-label="Pilih" deselect-label=""  placeholder="" track-by="name" label="name"  :options="options_kota" :searchable="true" :allow-empty="false" >
                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                            </multiselect>
                            <!-- <input type="text" class="form-control" v-model="input.kota_penerima" placeholder="Kab / Kota Penerima"> -->
                        </div>
                      <div class="form-group">
                            <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_18') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                            <pulse-loader :color="'#c4c4c4'" :loading="loading_api_kecamatan"></pulse-loader>
                            <multiselect  placeholder="" v-show="!loading_api_kecamatan" style="width:100%;  border:1px solid #c4c4c4;" v-model="v_kecamatan_penerima_computed"  select-label="Pilih" deselect-label=""  track-by="name" label="name" :options="options_kecamatan_penerima" :searchable="true" :allow-empty="false" >
                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                            </multiselect>
                        </div>
              </div>
              <div class="col-lg-5 offset-lg-1 pt-2">
                  <div class="form-group">
                          <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_19') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                          <input type="number" style="border:1px solid #c4c4c4;" v-model="input.handphone_penerima" class="form-control hitamc2Child" >
                  </div>

                    <div class="form-group">
                      <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_20') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                      <b-textarea class="hitamc2Child" style="border:1px solid #c4c4c4;"  v-model="input.alamat_penerima"></b-textarea>
                    </div>
            
                  <div class="form-group">
                        <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_21') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                          <multiselect style="width:100%; border:1px solid #c4c4c4;" v-model="input.kode_pos_penerima"  select-label="Pilih" deselect-label=""  track-by="name" label="name"  :options="options_kode_pos_penerima" :searchable="true" :allow-empty="false" >
                          <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                      </multiselect>
                  </div>

                  <div class="form-group"  v-if=" ! input.penerima_antar">
                      <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_22') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                        <multiselect style="width:100%; border:1px solid #c4c4c4;"  v-model="input.konter_penerima"  select-label="Pilih" deselect-label=""  track-by="name" label="name" :options="options_konter_penerima" :searchable="true" :allow-empty="false" >
                          <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                      </multiselect>
                  </div>
              </div>

              <div class="col-lg-12 offset-lg-1 pt-2 mt-3" >
                    <div class="alert alert-warning" role="alert" v-if="data_validate.length > 0">
                        <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                        <ol>
                            <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                        </ol>                            
                    </div>
              </div>

               <div class="offset-lg-1 col-lg-5 mt-5 col-6">
                  <button :disabled="loading_api" @click="kembalih1" class="hov avenirMedium fz-18 text-center btn btn-outline-danger" style="border-radius:25px;"> {{ $t('sendPacket.text_23') }} 
                  </button>
                </div>
                 <div class="col-lg-6 mt-5 text-right col-6">
                  <button :disabled="loading_api" @click="lanjuth3" class="avenirMedium fz-18 text-center btn btn-danger text-white" style="border-radius:25px;"> {{ $t('sendPacket.text_24') }}
                  </button>
                </div>

             


            </div>



            <div class="row justify-content-center mt-5" v-show="templateHalaman == 2" >
              <div class="offset-lg-1 col-lg-11 avenirHeavy fz-22 border-bottom mt-3 mb-3"  >
                 {{ $t('sendPacket.text_25') }}
              </div>
              <div class="col-lg-5 offset-lg-1 pt-2" >
                  <div class="form-group">
                      <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_26') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                      <select style="border:1px solid #c4c4c4;" v-model="jenis_paket_computed" class="form-control hitamc2Child">
                        <option value="paket"> {{ $t('sendPacket.text_27') }}</option>
                        <option value="dokumen"> {{ $t('sendPacket.text_28') }}</option>
                      </select>
                  </div>
              </div>

              <div class="col-lg-5 offset-lg-1 pt-2" >
              </div>

              <div class="col-lg-11 offset-lg-1 ">
                 <div class="form-group" :key="refreshUkuran">
                        <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_29') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                        <div class="clearfix"></div>
                        <div  v-for="(value,index) in dataPaketSize" @click="pilihPaket(value)" :class="'pointer p-3 float-left col-lg-4 mr-2 mt-2 ' + ( value.id == dataPilihPaket ? 'dipilihPaket' : '' ) " style="position:relative; border-radius:10px; border:1px solid #8D8D8D;" :key="index">
                            <img id="g1paket" style="display:none;" src="img/imgjrg/boxactive.svg" height="100" class="float-left mr-3" />
                            <img id="g2paket" src="img/imgjrg/box.svg" height="100" class="float-left mr-3" />
                            <p id="namePaket" class="avenirHeavy fz-16">{{ value.name }} </p>
                            <img width="25" height="25" id="g3paket" style="display:none; position:absolute; right:4px; top:10px;" src="img/imgjrg/centangmerah.png" class="float-left mr-3" />
                            <p class="avenirORoman fz-14">{{ value.berat_min }} - {{ value.berat_max }} kg <br/> {{ value.panjang }} x  {{ value.lebar }} x  {{ value.tinggi }} {{  value.uom_volume_name }}</p>
                        </div>
                  </div>
              </div>

              <div class="col-lg-5 offset-lg-1 pt-2 mt-3" >
                  <div class="form-group">
                      <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_30') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                       <b-textarea class="hitamc2Child" style="border:1px solid #c4c4c4;" v-model="input.isi_paket"></b-textarea>
                  </div>
              </div>
              <div class="col-lg-5 offset-lg-1 pt-2 mt-3" >
                  <div class="form-group">
                      <label class="avenirORoman fz-16 abuabu"> {{ $t('sendPacket.text_31') }}</label>
                       <b-textarea class="hitamc2Child" style="border:1px solid #c4c4c4;" v-model="input.catatan" ></b-textarea>
                  </div>
              </div>

              <div class="col-lg-12 offset-lg-1 pt-2 mt-3" >
                     <div class="alert alert-warning" role="alert" v-if="data_validate.length > 0">
                        <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                        <ol>
                            <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                        </ol>                            
                    </div>
              </div>

            <div class="col-lg-5 offset-lg-1 col-6">
                <button @click="kembalih2" class="avenirMedium fz-18 text-center btn btn-outline-danger hov" style="border-radius:25px;"> {{ $t('sendPacket.text_32') }} 
                </button>
            </div>

            <div class="col-lg-5 offset-lg-1 text-right col-6" >

                   <button :disabled="loading_api" @click="cekHargaPacket()" class="avenirMedium fz-18 text-center btn btn-danger text-white" style="border-radius:25px;white-space: normal;">
                    <span v-if="!loading_api_lanjut_bayar"> {{ $t('sendPacket.text_33') }} </span>
                    <pulse-loader :color="'#ffffff'" :loading="loading_api_lanjut_bayar"></pulse-loader>
                </button>

            </div>

            </div>

            


            <div class="row justify-content-center mt-5" v-show="templateHalaman == 3" >
              <div class="offset-lg-1 col-lg-11 avenirHeavy fz-22 border-bottom mt-3 mb-3" style="color:#0770CC;" >
                 {{ $t('sendPacket.text_34') }}
              </div>
              <div class="col-lg-5 offset-lg-1 pt-2 avenirORoman fz-16" >
                  <p v-for="(value,index) in data_harga_paket" :key="index" > {{ $t('sendPacket.text_37') }} <span class="float-right">Rp {{ toMoney(value.total_fix) }}</span> </p>
               
                 
                  <button :disabled="loading_api" @click="templateHalaman = 2, scrollTop()" class="avenirMedium fz-18 text-center btn btn-outline-danger col-lg-5 float-left"     style="border-radius:25px;"> {{ $t('sendPacket.text_36') }} 
                    </button>

                  <button :disabled="loading_api" @click="setPacket" class="avenirMedium fz-18 text-center btn btn-danger text-white col-lg-6 float-right" style="border-radius:25px; width:100%;">
                       <span v-if="!loading_api_lanjut_bayar">  {{ $t('sendPacket.text_35') }} </span>
                      <pulse-loader :color="'#ffffff'" :loading="loading_api_lanjut_bayar"></pulse-loader>
                  </button>

              </div>
              <div class="col-lg-5 offset-lg-1 pt-2" >
              </div>
            </div>



          </div>
    </section>
  </div>
</template>
<script>
import '../assets/cssVendor/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect'
import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import CarouselSlider from "./Carousel"
import choosePaymentMethod from "./components/travel/choosePaymentMethod";
import swal from 'sweetalert';



// For more options see below


export default {
  mixins:[Helper,Master,Validate],
  name: "components",
  components: {
    CarouselSlider,
    // Badge,
    multiselect: Multiselect,
    PulseLoader,
    choosePaymentMethod
  },
  data() {
        return {

            is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            runTestHeader:null,

            data_url:Config,
            level_payment:false,
            refreshUkuran:0,
            loading_api_kota:false,
            loading_api_kecamatan:false,
            loading_api_kota_pengirim:false,
            loading_api_lanjut_bayar:false,
            data_harga_paket:[],
            data_validate :[],
            templateHalaman:0,

            input:{
              konter_penerima:null,
              nama_pengirim:null,
              provinsi_pengirim:null,
              kota_pengirim:null,
              kecamatan_pengiriman:null,
              alamat_pengiriman:null,
              kode_pos_pengiriman:null,
              handphone_pengiriman:null,
              email_pengiriman:null,

              penerima_antar:false,
              nama_penerima:null,
              provinsi_penerima:null,
              kota_penerima:null,
              kecamatan_penerima:null,
              alamat_penerima:null,
              kode_pos_penerima:null,
              handphone_penerima:null,

              isi_paket:null,
              catatan:null,

              jenis_paket:"paket",

              konter_pengirim:null,


            },

            options_provinsi:  [
                { name: 'Jawa Timur', id: 'Front-end' },
                { name: 'Jawa Tengah', id: 'Backend' }
            ],

            options_kota:  [
                { name: 'Loading', id: '' },
            ],

            options_kota_pengirim:  [
                { name: 'Loading', id: '' },
            ],

            options_kecamatan_penerima:  [],
            options_kecamatan_pengirim:  [],

            options_konter_penerima:[],
            options_konter_pengirim:[],
            options_kode_pos_pengiriman:[],
            options_kode_pos_penerima:[],
            dataPaketSize:[],
            dataPaketSizeTampungan:[],
            dataPilihPaket:"",


        }
  },
  created(){

    this.getPaketSize();
    this.getProvinsi();

    

  },
  computed:{

    jenis_paket_computed:{
      get(){
        return this.input.jenis_paket
      },
      async set(v){

        this.input.jenis_paket = v;
        this.dataPilihPaket = "";

        if(v == "paket"){
          this.dataPaketSize = []
          for (let index = 0; index < this.dataPaketSizeTampungan.length; index++) {

            if( this.dataPaketSizeTampungan[index].type == 'paket' ){
               this.dataPaketSize.push( this.dataPaketSizeTampungan[index] )      
            }
                  
          }

        }else{
          this.dataPaketSize = []
          for (let index = 0; index < this.dataPaketSizeTampungan.length; index++) {

            if( this.dataPaketSizeTampungan[index].type == 'dokumen' ){
               this.dataPaketSize.push( this.dataPaketSizeTampungan[index] )      
            }
                  
          }
        }


        this.refreshUkuran += 1;

      }
    },

    v_provinsi_penerima_computed:{
           get(){
            return this.input.provinsi_penerima
          },
          async set(v){
            this.input.provinsi_penerima = v
            this.loading_api_kota = true

            let formData = new URLSearchParams(); 
            formData.append('propinsi_id', v.id);

            await axios.post( this.data_url.getPSIKota + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                  if( res.data.codestatus == "S" ){
                    this.options_konter_penerima = [];

                    this.input.konter_penerima = null;
                    this.input.kota_penerima = null;
                    this.input.kecamatan_penerima = null;

                    this.options_kota = res.data.resultdata
                    console.log("a");
                  }
            }).catch( ( error ) => {
                console.log(error)

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });

            this.loading_api_kota = false
            
          }
    },



    v_pengirim_provinsi_computed:{
           get(){
            return this.input.provinsi_pengirim
          },
          async set(v){
            this.input.provinsi_pengirim = v
            this.loading_api_kota_pengirim = true

            let formData = new URLSearchParams(); 
            formData.append('propinsi_id', v.id);

            await axios.post( this.data_url.getPSIKota + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                  if( res.data.codestatus == "S" ){

                    this.options_konter_pengirim = [];
                    this.input.konter_pengirim = null;
                    this.input.kota_pengirim = null;
                    this.input.kecamatan_pengiriman = null;

                    this.options_kota_pengirim = res.data.resultdata
                  
                     
                  }
            }).catch( ( error ) => {
                console.log(error)

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });

            this.loading_api_kota_pengirim = false
            
          }
    },



    v_kota_penerima_computed:{
           get(){
            return this.input.kota_penerima
          },
          async set(v){
            this.input.kota_penerima = v

            
            this.loading_api_kecamatan = true

            let formData = new URLSearchParams(); 
            formData.append('kota_kabupaten', v.id);

            await axios.post( this.data_url.getPSICounter  + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                       this.input.konter_penerima = null;
                       this.options_konter_penerima = res.data.resultdata;
                }
            }).catch( ( error ) => {
                console.log(error)

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });


            let formData1 = new URLSearchParams(); 
            formData1.append('kota_id', v.id);

            await axios.post( this.data_url.getPSIKecamatan  + "?" + formData1, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.options_kode_pos_penerima = [];
                    this.input.kode_pos_penerima = null;

                      this.input.kecamatan_penerima = null;
                      this.options_kecamatan_penerima = res.data.resultdata;
                      
                }
            }).catch( ( error ) => {
                console.log(error)

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });
            
             this.loading_api_kecamatan = false
            
          }
    },

    v_kecamatan_penerima_computed:{
           get(){
            return this.input.kecamatan_penerima
          },
          async set(v){
            this.input.kecamatan_penerima = v

            this.loading_api_konter = true

            let formData2 = new URLSearchParams(); 
            formData2.append('kecamatan_id', v.id);

            await axios.post( this.data_url.getPSIKodepos  + "?" + formData2, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                      this.input.kode_pos_pengiriman = null;
                      this.options_kode_pos_penerima = res.data.resultdata;
                }
            }).catch( ( error ) => {
                console.log(error)

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });

             this.loading_api_konter = false
            
          }
    },

    v_kota_pengirim_computed:{
           get(){
            return this.input.kota_pengirim
          },
          async set(v){
            this.input.kota_pengirim = v
             this.loading_api_kecamatan = true

            let formData = new URLSearchParams(); 
            formData.append('kota_kabupaten', v.id);

            await axios.post( this.data_url.getPSICounter  + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.input.konter_pengirim = null;
                    this.options_konter_pengirim = res.data.resultdata
                    
                }
            }).catch( ( error ) => {
                console.log(error)

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });


            let formData1 = new URLSearchParams(); 
            formData1.append('kota_id', v.id);

            await axios.post( this.data_url.getPSIKecamatan  + "?" + formData1, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                      this.input.kecamatan_pengiriman = null;
                      this.options_kecamatan_pengirim = res.data.resultdata;

                      this.options_kode_pos_pengiriman = [];
                      this.input.kode_pos_pengiriman = null;
                      
                }
            }).catch( ( error ) => {
                console.log(error)

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });
       
             this.loading_api_kecamatan = false
          }
    },

    v_kecamatan_pengirim_computed:{
           get(){
            return this.input.kecamatan_pengiriman
          },
          async set(v){
            this.input.kecamatan_pengiriman = v
            this.loading_api_konter = true      

            let formData2 = new URLSearchParams(); 
            formData2.append('kecamatan_id', v.id);

            await axios.post( this.data_url.getPSIKodepos  + "?" + formData2, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                      this.input.kode_pos_pengiriman = null;
                      this.options_kode_pos_pengiriman = res.data.resultdata;
                      
                }
            }).catch( ( error ) => {
                console.log(error)

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });


            this.loading_api_konter = false
          }
    },
  },
  methods: {

      pilihPaket(value){

        this.dataPilihPaket = value.id
        this.refreshUkuran += 1;

      },

      async lanjuth2(){

        this.data_validate = [];

          if(String( this.input.nama_pengirim) == "null" || String( this.input.nama_pengirim) == ""){
              this.data_validate.push( this.$t('val.v1'));
          }

          try {
             if(String( this.input.provinsi_pengirim.id) == "null" || String( this.input.provinsi_pengirim.id) == ""){
                this.data_validate.push( this.$t('val.v2') );
            }
          } catch (error) {
                this.data_validate.push(  this.$t('val.v3') );
          }
         
           try {
          if(String( this.input.kota_pengirim.id) == "null" || String( this.input.kota_pengirim.id) == ""){
              this.data_validate.push( this.$t('val.v4') );
          }
           } catch (error) {
                this.data_validate.push( this.$t('val.v5') );
          }

          if(String( this.input.kecamatan_pengiriman) == "null" || String( this.input.kecamatan_pengiriman) == ""){
              this.data_validate.push( this.$t('val.v6') );
          }

          if(String( this.input.alamat_pengiriman) == "null" || String( this.input.alamat_pengiriman) == ""){
              this.data_validate.push( this.$t('val.v7') );
          }

          if(String( this.input.kode_pos_pengiriman) == "null" || String( this.input.kode_pos_pengiriman) == ""){
              this.data_validate.push( this.$t('val.v8') );
          }

           try {
          if(String( this.input.konter_pengirim.id) == "null" || String( this.input.konter_pengirim.id) == ""){
              this.data_validate.push( this.$t('val.v9') );
          }
           } catch (error) {
                  this.data_validate.push( this.$t('val.v10') );
          }

           if(String( this.input.handphone_pengiriman) == "null" || String( this.input.handphone_pengiriman) == ""){
              this.data_validate.push( this.$t('val.v11') );
          }else{
            if( !this.phoneIsValid( this.input.handphone_pengiriman ) ){
              this.data_validate.push( this.$t('val.v12') );
            }
          }

    

          if( this.input.email_pengiriman == null ||  this.input.email_pengiriman == "" ){
              this.data_validate.push( this.$t('val.v13') )
          }

          if( this.validateEmail(this.input.email_pengiriman) == false ){
              this.data_validate.push(this.$t('val.v14') )
          }

           if( this.data_validate.length > 0 ){
              return false
          }

        this.templateHalaman = 1;
        this.scrollTop() 

      },

      async kembalih1(){
        this.data_validate = [];

        this.templateHalaman = 0;
        this.scrollTop()
      },

      async lanjuth3(){

            this.data_validate = [];

          if( this.input.penerima_antar == false ){
              try {
                if(String( this.input.konter_penerima.id) == "null" || String( this.input.konter_penerima.id) == ""){
                    this.data_validate.push(this.$t('val.v15') );
                }
              } catch (error) {
                      this.data_validate.push(this.$t('val.v16') );
              }
          }else{
              this.input.konter_penerima = null;
          }

          if(String( this.input.nama_penerima) == "null" || String( this.input.nama_penerima) == ""){
              this.data_validate.push(this.$t('val.v17') );
          }

    try{
          if(String( this.input.provinsi_penerima.id) == "null" || String( this.input.provinsi_penerima.id) == ""){
              this.data_validate.push(this.$t('val.v18') );
          }
          } catch (error) {
                    this.data_validate.push(this.$t('val.v19') );
          }

        try{
            if(String( this.input.kota_penerima.id) == "null" || String( this.input.kota_penerima.id) == ""){
                this.data_validate.push(this.$t('val.v20') );
            }
        } catch (error) {
                  this.data_validate.push(this.$t('val.v21'));
        }
          

          if(String( this.input.kecamatan_penerima) == "null" || String( this.input.kecamatan_penerima) == ""){
              this.data_validate.push(this.$t('val.v22') );
          }

          if(String( this.input.alamat_penerima) == "null" || String( this.input.alamat_penerima) == ""){
              this.data_validate.push(this.$t('val.v23') );
          }

          if(String( this.input.kode_pos_penerima) == "null" || String( this.input.kode_pos_penerima) == ""){
              this.data_validate.push(this.$t('val.v24'));
          }

          if(String( this.input.handphone_penerima) == "null" || String( this.input.handphone_penerima) == ""){
              this.data_validate.push(this.$t('val.v25'));
          }else{
            if( !this.phoneIsValid( this.input.handphone_penerima ) ){
              this.data_validate.push(this.$t('val.v26'));
            }
          }

        if( this.data_validate.length > 0 ){
            return false
        }


        this.templateHalaman = 2; 
        this.scrollTop()
      },


      async kembalih2(){
        this.data_validate = [];

        this.templateHalaman = 1;
        this.scrollTop()
      },

      async getPaketSize(){

          this.loading_api = true

          await axios.post( this.data_url.getPSIPaketSize, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                      this.dataPaketSizeTampungan = res.data.resultdata;

                      this.dataPaketSize = []
                      for (let index = 0; index < this.dataPaketSizeTampungan.length; index++) {

                        if( this.dataPaketSizeTampungan[index].type == 'paket' ){
                          this.dataPaketSize.push( this.dataPaketSizeTampungan[index] )      
                        }
                              
                      }
                }
          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

            this.loading_api = false

      },

      async getProvinsi(){

          this.loading_api = true

           let formData = new URLSearchParams(); 
          formData.append('country_code', 'ID');

          await axios.post( this.data_url.getCountryState + "?" + formData , null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true
                    this.options_provinsi = res.data.resultdata
                }
          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

            this.loading_api = false

      },

     async getKecamatan(){

          this.loading_api = true

          await axios.post( this.data_url.getPSILocation, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                       console.log("a")
                }
          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

            this.loading_api = false

      },

    
   
     async getPrice(){

          this.loading_api = true

          await axios.post( this.data_url.getPSILocation, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                       console.log("a")
                }
          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

            this.loading_api = false

      },

     async setPacket(){

          this.loading_api_lanjut_bayar = true

          let formData = new URLSearchParams(); 
          formData.append('nama_pengirim', String( this.input.nama_pengirim));
          formData.append('propinsi_pengirim_id', String( this.input.provinsi_pengirim.id));
          formData.append('kota_pengirim_id', String(this.input.kota_pengirim.id));
          formData.append('kecamatan_pengirim_id', String( this.input.kecamatan_pengiriman.id));
          formData.append('alamat_pengirim', String( this.input.alamat_pengiriman));
          formData.append('kodepos_pengirim',  String(this.input.kode_pos_pengiriman));
          try { formData.append('counter_pengirim_id', String( this.input.konter_pengirim.id)); } catch (error) { error }
          formData.append('phone_pengirim', String( this.input.handphone_pengiriman));
          formData.append('email_pengirim', String( this.input.email_pengiriman));
          // formData.append('deskripsi_pengirim',  this.);
          formData.append('nama_penerima', String(this.input.nama_penerima));
          formData.append('propinsi_penerima_id', String( this.input.provinsi_penerima.id));
          formData.append('kota_penerima_id', String( this.input.kota_penerima.id));
          formData.append('kecamatan_penerima_id',String( this.input.kecamatan_penerima.id));
          formData.append('alamat_penerima', String( this.input.alamat_penerima));
          formData.append('kodepos_penerima', String( this.input.kode_pos_penerima));
          try { formData.append('counter_penerima_id',String(  this.input.konter_penerima.id )); } catch (error) { error }
          formData.append('phone_penerima',  String(this.input.handphone_penerima));
          formData.append('psi_m_paket_size_id',  this.dataPilihPaket);
          formData.append('paket_type',  this.input.jenis_paket);
          formData.append('isi_paket',  this.input.isi_paket);
          formData.append('keterangan_paket',  this.input.catatan);
          formData.append('kirim_ke',  this.input.penerima_antar ? "counter" : "alamat" );

          await axios.post( this.data_url.setPSIPaket + "?" + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){
                  console.log(res.data.resultdata)
                  this.$store.commit('setDataPacket', res.data.resultdata[0]);

                  this.$store.commit('setDataTrx', null);
                  this.$store.commit('setDataTrxTWo', null);
                  this.$store.commit('setDataPassenger', null);
                  this.level_payment = true;

                  this.scrollTop();
                       
                }else{
                   swal({
                            title: this.$t('swal.informasi'),
                            text:  res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }
          }).catch( ( error ) => {
              console.log(error)

                try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

              if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

            this.loading_api_lanjut_bayar = false

      },

     async cekHargaPacket(){

       
            this.data_validate = [];

        

          if(this.dataPilihPaket == null || String( this.dataPilihPaket) == "null" || String( this.dataPilihPaket) == ""){
              this.data_validate.push( this.$t('val.v27') );
          }

          if(String( this.input.jenis_paket) == "null" || String( this.input.jenis_paket) == ""){
              this.data_validate.push(this.$t('val.v28') );
          }

           if(String( this.input.isi_paket) == "null" || String( this.input.isi_paket) == ""){
              this.data_validate.push(this.$t('val.v29') );
          }
  

          if( this.data_validate.length > 0 ){
              return false
          }


          this.loading_api_lanjut_bayar = true

          let formData = new URLSearchParams(); 
          formData.append('nama_pengirim', String( this.input.nama_pengirim));
          formData.append('propinsi_pengirim_id', String( this.input.provinsi_pengirim.id));
          formData.append('kota_pengirim_id', String(this.input.kota_pengirim.id));
          formData.append('kecamatan_pengirim_id', String( this.input.kecamatan_pengiriman.id));
          formData.append('alamat_pengirim', String( this.input.alamat_pengiriman));
          formData.append('kodepos_pengirim',  String(this.input.kode_pos_pengiriman));
          try { formData.append('counter_pengirim_id', String( this.input.konter_pengirim.id)); } catch (error) { error }
          formData.append('phone_pengirim', String( this.input.handphone_pengiriman));
          // formData.append('deskripsi_pengirim',  this.);
          formData.append('nama_penerima', String(this.input.nama_penerima));
          formData.append('propinsi_penerima_id', String( this.input.provinsi_penerima.id));
          formData.append('kota_penerima_id', String( this.input.kota_penerima.id));
          formData.append('kecamatan_penerima',String( this.input.kecamatan_penerima.id));
          formData.append('alamat_penerima', String( this.input.alamat_penerima));
          formData.append('kodepos_penerima', String( this.input.kode_pos_penerima.id));
          try { formData.append('counter_penerima_id',String(  this.input.konter_penerima.id )); } catch (error) { error }
          formData.append('phone_penerima',  String(this.input.handphone_penerima));
          // formData.append('deskripsi_penerima',  this.);
          // formData.append('longitude_penerima',  this.);
          // formData.append('latitude_penerima',  this.);
          formData.append('psi_m_paket_size_id',  this.dataPilihPaket);
          formData.append('paket_type',  this.input.jenis_paket);
          formData.append('isi_paket',  this.input.isi_paket);
          formData.append('keterangan_paket',  this.input.catatan);
          formData.append('kirim_ke',  this.input.penerima_antar ? "counter"  : "alamat" );

          await axios.post( this.data_url.getPSIHargaPaket + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                  console.log(res.data.resultdata)
                  this.data_harga_paket = res.data.resultdata;
                  this.templateHalaman = 3;

                  this.scrollTop();
                }else{
                   swal({
                            title: this.$t('swal.informasi'),
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }
          }).catch( ( error ) => {
              console.log(error)

              if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

            this.loading_api_lanjut_bayar = false

      },

      scrollTop(){
         window.scrollTo(0,0);
      },

      showResiTracking(){
         this.$refs['bv_modal_cek_resi'].show();
      }
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}


.pointer{
  cursor: pointer;
}



</style>
