<template>
    <section id="InputePassenger" class="section-components pb-5 pt-5 bg-white">
          <carousel-slider></carousel-slider>



        <div class="container mt-4">
         
          <div class="col-lg-12 border shadow text-center pt-3" style="border-radius:10px;">
              <P class="avenirHeavy fz-20" style="color:#0770CC;">   {{ $t('cekResi.text_1') }} </P>
              <P  class="avenirMedium fz-16"> {{ $t('cekResi.text_2') }} </P>


              <div class="justify-content-center pb-5">
                 <div class="row"> 
                    <div class="col-lg-6 offset-lg-3 mb-2">
                          <input type="text" class="form-control text-center" placeholder="Masukkan No Resi" v-model="dataResi" />
                    </div>
                 </div>

                <div class="row"> 
                  <div class="col-lg-6 offset-lg-3">
                    <div class="row">
                      <div class="col-lg-12" v-if="!login.status">
                        <vue-recaptcha class="float-right" @verify="onVerify" sitekey="6Ld9IAgbAAAAAHuEXTHcXUmDy4t_29GiTlqhxidF" :loadRecaptchaScript="true"></vue-recaptcha>
                      </div>
                    </div>

                    

                    <div class="row">
                        <div class="col-lg-12">

                          <div class="alert alert-warning text-left mt-2 mb-2" role="alert" v-if="data_validate.length > 0">
                            <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                              <ol>
                                  <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                              </ol>                            
                          </div>


                          <button class="btn btn-danger m-0 float-right" :disabled="loading_api" @click="lacakpaket">
                              <span v-if="!loading_api"> {{ $t('cekResi.text_3') }} </span>
                              <pulse-loader :color="'#ffffff'" v-if="loading_api"></pulse-loader>
                          </button>
                          
                        </div>
                      </div>
                  </div>
                </div>
              </div>

          </div>


          <div class="col-lg-12 mt-5 shadow rounded p-2"  v-if="dataTracking.length > 0">

            <!-- <div class="col-lg-12 text-center text-danger mt-5" style="border-radius:20px;">
              <P class="avenirHeavy fz-20" > {{ $t('cekResi.text_4') }} </P>
            </div> -->

            <div class="row justify-content-center text-center mt-3">
              <div class="col-lg-3">
                <span class="font-weight-bold"> {{ $t('cekResi.text_5') }}</span><br/> {{ dataPaket.nama_pengirim }}
              </div>
              <div class="col-lg-3">
                <span class="font-weight-bold"> {{ $t('cekResi.text_6') }}</span> <br/> {{ dataPaket.nama_penerima }}
              </div>
              <div class="col-lg-2">
                <span class="font-weight-bold"> {{ $t('cekResi.text_7') }}</span> <br/>  {{ dataPaket.tipe_paket }}
              </div>
              <div class="col-lg-2">
                <span class="font-weight-bold"> {{ $t('cekResi.text_8') }}</span> <br/>  {{ dataPaket.isi_paket }}
              </div>
              <div class="col-lg-2">
                <span class="font-weight-bold"> {{ $t('cekResi.text_9') }}</span> <br/>  {{ dataPaket.psi_m_paket_size_name }}
              </div>
            </div>

          <div class="col-lg-10 offset-lg-1 mt-5 border rounded" v-if="url_img != ''">
            <div class="row p-3">
               <div class="col-md-12 mb-3 avenirMedium fz-16">
                  Bukti Pengiriman
              </div>

              <div class="col-md-6">
                Paket telah diterima dengan kondisi baik Dan Paket telah diterima. Bukti pengiriman
              </div>
              <div class="col-lg-6">
                <img :src="url_img" style="width:100%;"/>
              </div>
            </div>
          </div>


          <div class="row" style="margin-top:100px;">
              <div class="col-lg-12">


                  <div class="row" v-for="(v, i) in dataTrackingHeader" :key="i">
                      <div class="col-lg-5 text-center mb-4 avenirMedium">
                          {{ dayNameFullId(v) }}
                          
                      </div>
                      <div class="col-lg-6 pb-5">  
                        
                        <div class="col-lg-12" v-if="i == 0">
                          <div style="width:50px; height:100%; float:left;">
                            <span class="dot1"><i class="fa fa-check" aria-hidden="true"></i></span>
                          </div>
                          <div class="avenirLight" style="float:left; padding-left:50px; width:90%; height:70px;">
                            <span class="colorTLDate"> {{ splitTitikDua(splitSpace(dataTracking[0].tanggal,1),0) }}:{{ splitTitikDua(splitSpace(dataTracking[0].tanggal,1),1) }} </span> <br/>
                            {{ dataTracking[0].description }} <br/>
                          </div>
                          <div class="clearfix"></div>
                        </div>

                        <div class="col-lg-12" v-if="i > 0">

                          <div style="width:50px; height:100%; float:left;">
                            <span class="dotNonActive"><i class="fa fa-check" aria-hidden="true"></i></span>
                          </div>

                          <div class="avenirLight" style="float:left; padding-left:50px; width:90%; height:70px;">
                              <span class="colorTLDate">{{ splitTitikDua(splitSpace(dataTracking[dataTrackingNo[i]].tanggal,1),0) }}:{{ splitTitikDua(splitSpace(dataTracking[dataTrackingNo[i]].tanggal,1),1) }}</span><br/>
                            {{ dataTracking[dataTrackingNo[i]].description }} <br/>
                          </div>

                          <div class="clearfix"></div> 
                          
                        </div>
                    
                        <div  class="col-lg-12" v-for="(value,index) in dataTracking" :key="index">
                           <div v-if="v == splitSpace(value.tanggal,0) & value.is_head == false">
                            <div style="width:50px; height:100%; float:left; margin-top:-6px; position: relative;">
                              <div class="vericalline"></div>
                              <div class="dotNonActive"><i class="fa fa-check" aria-hidden="true"></i></div>
                            </div>
                            <div class="avenirLight widthLoopTL" style="float:left; margin-top:26px; height:70px; word-wrap: break-word;">
                               <span class="colorTLDate"> {{ splitTitikDua(splitSpace(value.tanggal,1),0) }}:{{ splitTitikDua(splitSpace(value.tanggal,1),1) }}  </span><br/>
                              {{ value.description }}<br/>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div> 
                        
                      </div>
                  </div>
              </div>
          </div>
          </div>


         

        </div>
    </section>
</template>
<script>
import '../assets/cssVendor/vue-multiselect.min.css';
import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import swal from 'sweetalert';
import CarouselSlider from "./Carousel"
import VueRecaptcha from 'vue-recaptcha';

export default {
  mixins:[Helper,Master,Validate],
  name: "components",
  components: {
    PulseLoader,
    CarouselSlider,
    VueRecaptcha
  },
  data() {
        return {

           login:{
            status:false,
            data:null
          },

          url_img:"",
          dataPaket:"",
           is_runTest:false,
          runTestResult:false,
          runTestParameter:"",
          runTestHeader:null,
          token_re:null,
          date_akhir_cek_double:"",
          data_validate:[],

            data_url:Config,
            // dataResi:"E43F411BFF0C4746",
            dataResi:"",
            dataTracking:[],
            dataTrackingNo:[],
            dataTrackingHeader:[],
        }
  },
  created(){
    if( localStorage.getItem("login") == null){
        this.login.status = false
        this.login.data = null
    }else{
        this.login.status = true
    }
  },
  methods: {

    dataCekDoubleLacak(a,b){  
          
      if( a == b & this.date_akhir_cek_double == a){
        console.log(a + " = " + b + " & " + this.date_akhir_cek_double + " == " + a  + " = true")
        return true
      }
      
      if( a == b ){
          console.log(a + " = " + b + " = false")
        this.date_akhir_cek_double = a
        return false
      }


    },

    aa(v,i){
          alert(v + " " + i)
          return "masuk"
        },


      onVerify(response){
        console.log('Verify: ' + response)
        this.token_re = response;
      },

      async lacakpaket(){

        this.data_validate = []

        if( this.dataResi == null ||  this.dataResi == "" ){
               this.data_validate.push("Nomor Resi Harus Di Isikan")
        }

        if(!this.login.status){

          if( this.token_re == null ||  this.token_re == "" ){
                this.data_validate.push("Captcha Harus Di Isikan")
          }

        }

        if( this.data_validate.length > 0 ){
            return false
        }

          this.loading_api = true

          this.dataTrackingHeader = null 
          this.dataTrackingNo = null

          this.dataTrackingHeader = [];
          this.dataTrackingNo = []

          let formData = new URLSearchParams(); 

          if(!this.is_runTest){

              formData.append('nomor_resi', this.dataResi);

              if(!this.login.status){
                formData.append('recaptcha_token', this.token_re);
              }
           }else{
              formData = this.runTestParameter;
          }

          await axios.post( this.data_url.getTrackingPaket + "?" + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){

                  if( res.data.resultdata.length > 0 ){

                      this.runTestResult = true
                      this.dataPaket = res.data.resultdata[0];
                      try {
                        this.url_img = res.data.resultdata[0].images[1].url_attach
                      } catch (error) {
                        console.log(error)
                      }
                      

                      this.dataTracking = res.data.resultdata[0].tracking
                      this.dataTracking.sort( (a,b) => (a.id < b.id) ? 1 : -1 )

                      let head1 = ""
                      let a = ""
                      for (let aaa = 0; aaa < this.dataTracking.length; aaa++) {
                        a = this.dataTracking[aaa].tanggal
                        a = a.split(" ")
                        if( head1 != a[0] ){
                          head1 =  a[0]
                          this.dataTracking[aaa].is_head = true
                        }else{
                           this.dataTracking[aaa].is_head = false
                        } 
                      }

                      console.log(this.dataTracking);

                      let dateTrac = ""
                      for (let index = 0; index < this.dataTracking.length; index++) {
                        let dataFlotSplit = this.splitSpace(this.dataTracking[index].tanggal)
                        if( dataFlotSplit[0] != dateTrac ){
                            dateTrac = dataFlotSplit[0];
                            this.dataTrackingNo.push(index)
                            this.dataTrackingHeader.push(dateTrac);
                        }
                     
                      }

                         console.log(this.dataTrackingHeader);

                  }else{
                        swal({
                            title: this.$t('swal.informasi'),
                            text: "Maaf, Data Cek Resi Tidak Ada",
                            icon: '/img/imgjrg/error.png'
                        });
                  }

                }

              

          }).catch( ( error ) => {

                try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

               

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
          
      },
      
      showResiTracking(){
         this.$refs['bv_modal_cek_resi'].show();
      }
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}




</style>
