export default class Config {
    static url_utama = "https://app.jrgindonesia.co.id";

    // API Daftar 
    static send_otp = Config.url_utama + '/api/ws/authentication/v1/sendOTP';
    static validateOTP = Config.url_utama + '/api/ws/authentication/v1/validateOTP';
    static createNewUser = Config.url_utama + '/api/ws/authentication/v1/createNewUser';
    static login = Config.url_utama + '/api/ws/authentication/v1/login';
    static get_provider_oauth = Config.url_utama + '/api/ws/authentication/v1/get_provider_oauth';
    static login_oauth = Config.url_utama + '/api/ws/authentication/v1/login_oauth';
    static resetpassword = Config.url_utama + '/api/ws/authentication/v1/resetpassword';

    static getPSIJadwal = Config.url_utama + '/api/ws/transaction/v1/getPSIJadwal';
    static getPSILocation = Config.url_utama + '/api/ws/master/v1/getPSILocation';
    static getPSIAvailableJadwal = Config.url_utama + '/api/ws/transaction/v1/getPSIAvailableJadwal';
    static setNewPSIOrder = Config.url_utama + '/api/ws/transaction/v1/setNewPSIOrder';
    static setNewPSIOrderPulang = Config.url_utama + '/api/ws/transaction/v1/setNewPSIOrderPulang';
    
    static getPSITipeKendaraan = Config.url_utama + '/api/ws/master/v1/getPSITipeKendaraan';
    static setPSIOrderFullJSONType = Config.url_utama + '/api/ws/transaction/v1/setPSIOrderFullJSONType';
    static getPSIAvailableKursi = Config.url_utama + '/api/ws/transaction/v1/getPSIAvailableKursi';

    static selectPSIKursi = Config.url_utama + '/api/ws/transaction/v1/selectPSIKursi';
    static changePSIKursi = Config.url_utama + '/api/ws/transaction/v1/changePSIKursi';
    static unselectPSIKursi = Config.url_utama + '/api/ws/transaction/v1/unselectPSIKursi';
    static savePSIKursiJSONType = Config.url_utama + '/api/ws/transaction/v1/savePSIKursiJSONType';
    
    static confirmPSIOrder = Config.url_utama + '/api/ws/transaction/v1/confirmPSIOrder';
    static cancelPSIOrder = Config.url_utama + '/api/ws/transaction/v1/cancelPSIOrder';

    static getPSIPaymentMethod = Config.url_utama + '/api/ws/master/v1/getPSIPaymentMethod';
    static sendPaymentInfo = Config.url_utama + '/api/ws/transaction/v1/sendPaymentInfo';

    static getUserInfo = Config.url_utama + '/api/ws/master/v1/getUserInfo';
    static setUserInfo = Config.url_utama + '/api/ws/master/v1/setUserInfo';

    static cekPesananDetail = Config.url_utama + '/api/ws/transaction/v1/cekPesananDetail';
    static cekPesananList = Config.url_utama + '/api/ws/transaction/v1/cekPesananList';

    static getPenumpang = Config.url_utama + '/api/ws/master/v1/getPenumpang';
    static setPenumpang = Config.url_utama + '/api/ws/master/v1/setPenumpang';
    static deletePenumpang = Config.url_utama + '/api/ws/master/v1/deletePenumpang';

    static getPSIPaymentInvoice = Config.url_utama + '/api/ws/transaction/v1/getPSIPaymentInvoice';
    static setPSIPaymentInvoice = Config.url_utama + '/api/ws/transaction/v1/setPSIPaymentInvoice';

    static getInfoPembayaran = Config.url_utama + '/api/ws/transaction/v1/getInfoPembayaran';
    static getPSICallCenter = Config.url_utama + '/api/ws/master/v1/getPSICallCenter';
    static setPSIPaymentEWallet = Config.url_utama + '/api/ws/transaction/v1/setPSIPaymentEWallet';

    static getPSIPaketSize = Config.url_utama + '/api/ws/master/v1/getPSIPaketSize';
    static getPSICounter = Config.url_utama + '/api/ws/master/v1/getPSICounter';
    static setPSIPaket = Config.url_utama + '/api/ws/transaction/v1/setPSIPaket';
    static getPSIBodyExtraInfo = Config.url_utama + '/api/ws/master/v1/getPSIBodyExtraInfo';

    static getTrackingPaket = Config.url_utama + '/api/ws/transaction/v1/getTrackingPaket';
    static cekPaketHarga = Config.url_utama + '/api/ws/transaction/v1/cekPaketHarga';
    static getPSIKota = Config.url_utama + '/api/ws/master/v1/getPSIKota';
    static getImgSlider = Config.url_utama + '/api/ws/master/v1/getImgSlider';
    static setPSIPaymentQRIS = Config.url_utama + '/api/ws/transaction/v1/setPSIPaymentQRIS';
    static setPSIPaymentCOD = Config.url_utama + '/api/ws/transaction/v1/setPSIPaymentCOD';
    static validateCancelPayment = Config.url_utama + '/api/ws/transaction/v1/validateCancelPayment';
    static getImgPopup = Config.url_utama + '/api/ws/master/v1/getImgPopup';
    
    static getPSIExtraInfo = Config.url_utama + '/api/ws/master/v1/getPSIExtraInfo';
    static getPSIMenuExtraInfo = Config.url_utama + '/api/ws/master/v1/getPSIMenuExtraInfo';
    static getCountryState = Config.url_utama + '/api/ws/master/v1/getCountryState';
    static getPSIKota = Config.url_utama + '/api/ws/master/v1/getPSIKota';
    static getPSIHargaPaket = Config.url_utama + '/api/ws/transaction/v1/getPSIHargaPaket';

    static cekPaketDetail = Config.url_utama + '/api/ws/transaction/v1/cekPaketDetail';
    static cekPaketList = Config.url_utama + '/api/ws/transaction/v1/cekPaketList';
    static getPSIKecamatan = Config.url_utama + '/api/ws/master/v1/getPSIKecamatan';

    static getPSIListPaketforKurir = Config.url_utama + '/api/ws/transaction/v1/getPSIListPaketforKurir';
    static getPSIListPaketforDriver = Config.url_utama + '/api/ws/transaction/v1/getPSIListPaketforDriver';

    static setPaketReturn = Config.url_utama + '/api/ws/transaction/v1/setPaketReturn';
    static setPaketReceivedMultipartFormType = Config.url_utama + '/api/ws/transaction/v1/setPaketReceivedMultipartFormType';
    static getPSIKodepos = Config.url_utama + '/api/ws/master/v1/getPSIKodepos';
    static getETicket = Config.url_utama + '/api/ws/transaction/v1/getETicket';
                                                      
}   

