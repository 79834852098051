<template>
    <section id="InputePassenger" class="section-components pb-5 pt-5 bg-white">
          <carousel-slider></carousel-slider>

        <center>
        <pulse-loader :color="'#FF0000'" :loading="descManifest == null"></pulse-loader>
        </center>
        <div class="container col-lg-8 mt-4"  v-if="descManifest != null">

          <div class="col-lg-12 border shadow text-center pt-3  mb-4" style="border-radius:10px;">

              <div class="col-lg-12 rounded fz-26 avenirHeavy p-3" style="border:1px solid #FF0000; color:#FF0000;" >
               {{ $t('terimaPaket.text_1') }} 
              </div>

               <div class="col-lg-12 mt-2 mb-4">
                <DatePicker v-if="!loading_api_base" format="ddd, DD MMM YYYY"  :lang="lang" v-model="date_manifest_computed"></DatePicker>
                <pulse-loader :color="'#FF0000'" :loading="loading_api_base"></pulse-loader>
              </div>

              <div class="alert alert-warning" role="alert" v-show="descManifest.nomor_manifest == ''">
                {{ $t('terimaPaket.text_2') }}
              </div>

               <table class="fz-16 avenirMedium text-left mt-3 mb-5"  v-show="descManifest.nomor_manifest != ''">
                 <!-- <tr>
                  <td width="150">Tanggal</td>
                  <td class="dppd2">: 
                    <DatePicker v-if="!loading_api_base" format="DD-MM-YYYY" v-model="date_manifest_computed"></DatePicker>
                    <pulse-loader :color="'#ffffff'" :loading="loading_api_base"></pulse-loader>
                  </td>
                </tr> -->
                <tr>
                  <td> {{ $t('terimaPaket.text_3') }}</td>
                  <td>: {{ descManifest.nomor_manifest }} </td>
                </tr>

                <tr>
                  <td> {{ $t('terimaPaket.text_4') }}</td>
                  <td>:  {{ descManifest.kurir_name }}</td>
                </tr>

                <tr>
                  <td> {{ $t('terimaPaket.text_5') }}</td>
                  <td>: {{ descManifest.tanggal_kirim }}
                    <!-- <DatePicker v-if="!loading_api_base" format="DD-MM-YYYY" v-model="date_manifest_computed"></DatePicker>
                    <pulse-loader :color="'#ffffff'" :loading="loading_api_base"></pulse-loader> -->
                  </td>
                </tr>
               
                <tr>
                  <td class="pr-5"> {{ $t('terimaPaket.text_6') }}</td>
                  <td>: {{ descManifest.jumlah_manifest }}</td>
                </tr>
              </table>
              
              <!-- <P class="avenirHeavy fz-20" style="color:#0770CC;">  Konfirmasi Pesanan Diterima </P> -->
              <P  class="avenirMedium fz-16"  v-show="descManifest.nomor_manifest != ''">  {{ $t('terimaPaket.text_7') }} </P>


              <div class="justify-content-center pb-5"  v-show="descManifest.nomor_manifest != ''">
                 <div class="row"> 

                       <div class="col-lg-12 alert alert-warning mb-2" role="alert" v-if="data_validate.length > 0">
                          <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                          <ol>
                              <li class="float-left" v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                          </ol>                            
                      </div>

                    <div class="col-lg-7 col-md-7 col-sm-7 offset-sm-2 offset-md-2 offset-lg-2 mb-2">
                      <div class="form-group">
                            <multiselect style="width:100%;" class="border-bottom" v-model="v_paket"  select-label="Pilih" deselect-label=""  track-by="psi_t_paket_nomor" label="psi_t_paket_nomor" placeholder="Pilih Paket" :options="options" :searchable="true" :allow-empty="false">
                                <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.psi_t_paket_nomor }}</strong> <span class="float-right" >[{{ option.nama_penerima }}]</span></template>
                            </multiselect>
                      </div>
                    </div>

                  

                    <div class="col-lg-1 col-sm-1 col-md-1 mb-2">
                        <button class="btn btn-danger m-0 floatCari" :disabled="loading_api" @click="cekResi">
                              <span v-if="!loading_api">  {{ $t('terimaPaket.text_8') }} </span>
                              <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                        </button>
                    </div>

                 </div>

                <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                <div class="col-lg-12" v-show="!showDetail">

                  <div class="table-responsive">
                      <table class="table table-bordered">
                        <tr style="background-color:#E8E8E8; border:1px solid #D1D1D1;">
                          <th>No</th>
                          <th class="text-left"> {{ $t('terimaPaket.text_9') }}</th>
                          <th class="text-left"> {{ $t('terimaPaket.text_10') }}</th>
                          <th class="text-left"> {{ $t('terimaPaket.text_11') }}</th>
                          <th class="text-left"> {{ $t('terimaPaket.text_12') }}</th>
                          <th class="text-left"> {{ $t('terimaPaket.text_13') }}</th>
                        </tr>
                        <tr v-for="(v, i) in options" :key="i">
                          <td> {{ i+1 }} </td>
                          <td  class="text-left"><u> <span @click="tampilDetail(v.psi_t_paket_nomor)" class="pointer"> {{ v.psi_t_paket_nomor }}</span> </u></td>
                          <td  class="text-left">{{ v.nama_penerima }}</td>
                          <td  class="text-left">{{ v.psi_m_kota_penerima_name }}</td>
                          <td  class="text-left">{{ v.paket_type }}</td>
                          <td  class="text-left">{{ v.status }}</td>
                        </tr>
                      </table>
                   </div>

                </div>

              </div>
          </div>

          
          <div class="col-lg-12  border shadow text-center pt-3" style="border-radius:10px;" v-if="dataPaketDetail.length > 0">

           
              
                   <div class="col-lg-12 border-bottom  rounded p-2 text-white avenirHeavy fz-22">
                    <p class="avenirHeavy fz-24 text-center" style="color:#FF0000;" >  {{ $t('terimaPaket.text_14') }}
                      <span class="float-right fz-20 pointer" @click="untampilDetail()" style="color:#707070;">x</span>
                    </p>
                </div>
           

            <P class="avenirHeavy fz-16 mt-3">  {{ $t('terimaPaket.text_15') }}  <span style="color:#0770CC;"> {{ dataPaketDetail[0].nomor_resi }} </span> </P>
           


             

              <div class="row text-left pb-3"> 
              
                <div class="col-lg-12 border-bottom">
                   <p class="avenirMedium fz-16" > {{ $t('terimaPaket.text_16') }}</p>
                </div>

                

                <div class="col-lg-6 pt-2">
                   <p class="avenirORoman fz-16 txtb2" > {{ $t('terimaPaket.text_17') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;">{{ dataPaketDetail[0].nama_pengirim }} </p>
                   <p class="avenirORoman fz-16 txtb2"> {{ dataPaketDetail[0].alamat_pengirim }}, {{ dataPaketDetail[0].kecamatan_pengirim }}, {{ dataPaketDetail[0].kota_kabupaten_pengirim_name }}, {{ dataPaketDetail[0].propinsi_pengirim_name }} <br/>
                    {{ dataPaketDetail[0].phone_pengirim }} </p>

                   <p class="avenirORoman fz-16 txtb2 mt-4" > {{ $t('terimaPaket.text_18') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold" style="margin-top:-10px;"> {{ dataPaketDetail[0].nama_penerima }}  </p>
                  <p class="avenirORoman fz-16 txtb2"> {{ dataPaketDetail[0].alamat_penerima }}, {{ dataPaketDetail[0].kecamatan_penerima }}, {{ dataPaketDetail[0].kota_kabupaten_penerima_name }}, {{ dataPaketDetail[0].propinsi_penerima_name }} <br/>
                    {{ dataPaketDetail[0].phone_penerima }}</p>
                </div>

                 <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-12">
                      <button :disabled="loading_api" @click="lacakpaket(dataPaketDetail[0].nomor_resi)" class="btn btn-outline-danger float-right"> 
                         <span v-if="!loading_api">  {{ $t('terimaPaket.text_19') }} </span>
                        <pulse-loader :color="'#FF0000'" :loading="loading_api"></pulse-loader>
                      </button>
                    </div>
                  </div>
                </div>

                 <div class="col-lg-12 border-bottom mt-4">
                   <p class="avenirMedium fz-16" > {{ $t('terimaPaket.text_20') }}</p>
                </div>

                 <div class="col-lg-12 pt-2">
                   <p class="avenirORoman fz-16 txtb2"> {{ $t('terimaPaket.text_21') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;"> {{ dataPaketDetail[0].isi_paket }} </p>
                   <p class="avenirORoman fz-16 txtb2"> {{ $t('terimaPaket.text_22') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;"> {{ dataPaketDetail[0].psi_m_paket_size_name }} [ {{ dataPaketDetail[0].psi_m_paket_size_panjang }}x {{ dataPaketDetail[0].psi_m_paket_size_lebar }}x {{ dataPaketDetail[0].psi_m_paket_size_tinggi }}]  </p>
                   <p class="avenirORoman fz-16 txtb2"> {{ $t('terimaPaket.text_23') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;">  {{ dataPaketDetail[0].keterangan_paket }}</p>
                    <p class="avenirORoman fz-16 txtb2"> {{ $t('terimaPaket.text_23_1') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;">
                     <span v-if="dataPaketDetail[0].status == 'done'" style="color:#ff0000;">Lunas</span>
                     <span v-if="dataPaketDetail[0].status != 'done'" style="color:#0770cc;">Belum Lunas </span>
                   </p>
                    <p class="avenirORoman fz-16 txtb2"> {{ $t('terimaPaket.text_23_2') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;">  {{ convertToRupiah(dataPaketDetail[0].total_fix) }}</p>
                </div>

                <div class="col-lg-12" v-if="dataPaketDetail[0].status_paket != 'received'">
                  <b-card no-body class="p-3 bg-secondary">
                  <b-tabs content-class="pt-3 bg-white" align="center">
                    <b-tab class="avenirORoman fz-16 text-weight-bold" :title-item-class="'avenirHeavy fz-16'" title="Konfirmasi" active>
                      <div class="row">

                        <div class="col-lg-12 text-left pt-3 pl-3">
                            <div class="form-group col-lg-12 ">
                                <div class="col-12 alert alert-warning mb-2" role="alert" v-if="data_validate_konfirmasi.length > 0">
                                    <div class="form-group col-lg-12 ">
                                      <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                                      <ol>
                                              <li class="float-left" v-for="(value,index) in data_validate_konfirmasi" :key="index">{{ value }}</li>
                                      </ol>        
                                    </div>                    
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6">
                          <div class="form-group col-12">
                                <label class="avenirORoman fz-16">  {{ $t('terimaPaket.text_24') }}<span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                <input type="text" style="border:1px solid #c4c4c4;" class="form-control" v-model="penerima" placeholder="Diterima Oleh">
                            </div>
                        </div>

                        <div class="col-lg-6 border-bottom mb-3">
                          <div class="form-group col-12">
                            <label class="avenirORoman fz-16">  {{ $t('terimaPaket.text_25') }}<span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                            <select style="border:1px solid #c4c4c4;" v-model="jenis_upload_computed" class="form-control">
                              <option selected value="foto">  {{ $t('terimaPaket.text_26') }} </option>
                              <option value="ttd">  {{ $t('terimaPaket.text_27') }} </option>
                            </select>
                          </div>
                        </div>

                      </div>
                        

                        <div class="col-lg-12">
                          <div class="row">
                            <div class="col-md-12" v-show="jenis_upload == 'foto'">
                                <!-- <h2>Current Camera</h2>
                                <code v-if="device">{{ device.label }}</code> -->
                                <div>
                                    <figure class="figure" v-show="!showCamera">
                                      <img :src="img" class="img-fluid" />
                                    </figure>
                                    <vue-web-cam
                                        v-show="showCamera"
                                        ref="webcam"
                                        :device-id="deviceId"
                                        height="100%"
                                        @started="onStarted"
                                        @stopped="onStopped"
                                        @error="onError"
                                        @cameras="onCameras"
                                        @camera-change="onCameraChange"/>
                                </div>

                                <div class="row">
                                    <div class="col-md-12" v-show="showCamera">
                                        <select v-model="camera" class="form-control">
                                            <option>-- Select Device --</option>
                                            <option
                                                v-for="device in devices"
                                                :key="device.deviceId"
                                                :value="device.deviceId"
                                            >{{ device.label }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12">
                                        <button type="button" v-show="showCamera" class="btn btn-primary float-right" @click="onCapture">  {{ $t('terimaPaket.text_28') }}</button>
                                        <button type="button" v-show="!showCamera" class="btn btn-secondary float-right" @click="onTry"> {{ $t('terimaPaket.text_29') }}</button>
                                        <!-- <button type="button" class="btn btn-danger" @click="onStop">Stop Camera</button>
                                        <button type="button" class="btn btn-success" @click="onStart">Start Camera</button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" v-show="jenis_upload == 'ttd'">
                              <div style="width:100%; height:250px; border:2px solid #2c2c2c;">
                              <VueSignaturePad
                                  id="signature"
                                  width="100%"
                                  height="100%"
                                  ref="signaturePad"
                                /></div>
                              <div class="row">
                                <div class="col-12 mt-2">
                                  <button class="btn btn-secondary float-right" @click="undo"> {{ $t('terimaPaket.text_30') }}</button>
                                </div>
                                <!-- <div class="col-6 mt-2">
                                  <button class="btn btn-primary float-right" @click="save"></button>
                                </div> -->
                              </div>
                          

                            </div>
                          </div>

                          <div class="row mt-4">
                            <div class="col-lg-12">
                                <button :disabled="loading_api" class="btn btn-danger float-right" @click="saveKonfirmasi"> 
                                    <span v-if="!loading_api">  {{ $t('terimaPaket.text_31') }} </span>
                                  <pulse-loader v-if="loading_api" :color="'#ffffff'" style="float-right"></pulse-loader>
                                </button>
                            </div>
                          </div>
                        </div>

                    </b-tab>
                    <b-tab title="Retur Paket" :title-item-class="'avenirHeavy fz-16'">
                      <div class="row">

                        <div class="col-lg-12 text-left pt-3 pl-3">
                            <div class="form-group col-lg-12 ">
                               <div class="col-12 alert alert-warning mb-2" role="alert" v-if="data_validate_retur.length > 0">
                                    <div class="form-group col-lg-12 ">
                                      <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                                      <ol>
                                              <li class="float-left" v-for="(value,index) in data_validate_retur" :key="index">{{ value }}</li>
                                      </ol>        
                                    </div>                    
                                </div>
                            </div>
                        </div>

                      


                         <div class="col-lg-2 text-left pt-3 pl-3">
                            <div class="form-group col-lg-12 ">
                             <label class="avenirORoman fz-16">  {{ $t('terimaPaket.text_32') }}<span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                            </div>
                        </div>

                      


                        <div class="col-lg-7 col-12 pt-2">
                          <div class="form-group col-lg-12 ">
                                <input type="text" style="border:1px solid #c4c4c4;" class="form-control" v-model="alasan" placeholder="Alasan">
                          </div>
                        </div>

                        <div class="col-lg-3 ">
                             <div class="form-group col-lg-12 ">
                                <button :disabled="loading_api" class="btn btn-danger floatSimpan" @click="saveRetur"> 
                                <span v-if="!loading_api">  {{ $t('terimaPaket.text_33') }} </span>
                                  <pulse-loader v-if="loading_api" :color="'#ffffff'" style="float-right"></pulse-loader>
                                </button>
                             </div>
                        </div>
                      </div>


                    </b-tab>
                  </b-tabs>
                  </b-card>
                </div>



                 <b-modal id="bv-modal-lacak" hide-header hide-footer
                            size="lg"
                            body-classes="p-0"
                            modal-classes="modal-dialog-centered">

                    <div class="row"  style="padding-bottom:0px; margin-top:10px; background-image: url('img/imgjrg/patern.png');">
              <div class="col-lg-12">
                
                <div class="col-lg-12 border-bottom mb-5">
                    <p class="avenirHeavy fz-24 text-center" style="color:#FF0000;" > {{ $t('terimaPaket.text_34') }}</p>
                    <p class="avenirHeavy fz-20 text-center" style="color:#0c73cd;" > {{ dataPaketDetail[0].nomor_resi }}</p>
                </div>

                  <div class="row" v-for="(v, i) in dataTrackingHeader" :key="i">
                      <div class="col-lg-5 text-center mb-4 avenirMedium">
                          {{ dayNameFullId(v) }}
                          
                      </div>
                      <div class="col-lg-6 pb-5">  
                        
                        <div class="col-lg-12" v-if="i == 0">
                          <div style="width:50px; height:100%; float:left;">
                            <span class="dot1"><i class="fa fa-check" aria-hidden="true"></i></span>
                          </div>
                          <div class="avenirLight" style="float:left; padding-left:50px; width:90%; height:70px;">
                            <span class="colorTLDate"> {{ splitTitikDua(splitSpace(dataTracking[0].tanggal,1),0) }}:{{ splitTitikDua(splitSpace(dataTracking[0].tanggal,1),1) }} </span> <br/>
                            {{ dataTracking[0].description }} <br/>
                          </div>
                          <div class="clearfix"></div>
                        </div>

                        <div class="col-lg-12" v-if="i > 0">

                          <div style="width:50px; height:100%; float:left;">
                            <span class="dotNonActive"><i class="fa fa-check" aria-hidden="true"></i></span>
                          </div>

                          <div class="avenirLight" style="float:left; padding-left:50px; width:90%; height:70px;">
                              <span class="colorTLDate">{{ splitTitikDua(splitSpace(dataTracking[dataTrackingNo[i]].tanggal,1),0) }}:{{ splitTitikDua(splitSpace(dataTracking[dataTrackingNo[i]].tanggal,1),1) }}</span><br/>
                            {{ dataTracking[dataTrackingNo[i]].description }} <br/>
                          </div>

                          <div class="clearfix"></div> 

                        </div>
                    
                        <div  class="col-lg-12" v-for="(value,index) in dataTracking" :key="index">
                           <div v-if="v == splitSpace(value.tanggal,0) & value.is_head == false">
                            <div style="width:50px; height:100%; float:left; margin-top:-6px; position: relative;">
                              <div class="vericalline"></div>
                              <div class="dotNonActive"><i class="fa fa-check" aria-hidden="true"></i></div>
                            </div>
                             <div class="avenirLight widthLoopTL" style="float:left; margin-top:26px; height:70px; word-wrap: break-word;">
                               <span class="colorTLDate"> {{ splitTitikDua(splitSpace(value.tanggal,1),0) }}:{{ splitTitikDua(splitSpace(value.tanggal,1),1) }}  </span><br/>
                              {{ value.description }} <br/>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div> 
                        
                      </div>
                  </div>
              </div>
          </div>

                 </b-modal>
              </div>
       

          </div>         

        </div>
    </section>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import '../assets/cssVendor/vue-multiselect.min.css';
import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Multiselect from 'vue-multiselect'
import Config from "../model/Config"
import swal from 'sweetalert';
import CarouselSlider from "./Carousel"
// import VueRecaptcha from 'vue-recaptcha';
import { WebCam } from "vue-web-cam";


export default {
  mixins:[Helper,Master,Validate],
  name: "components",
  components: {
    PulseLoader,
    multiselect: Multiselect,
    CarouselSlider,
     "vue-web-cam": WebCam,
     DatePicker
    //  datetime: DatePicker,
    // VueRecaptcha
  },
  data() {
        return {
          lang: {
                formatLocale: {
                    // MMM
                    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
                    // dds
                    weekdaysMin: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                    weekdaysShort: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                },
            },

          alasan:"",
          date_sended:"",
          penerima:"",
          catatan:"",
          img: null,
          camera: null,
          deviceId: null,
          devices: [],
          showCamera:true,
          tanggalDefault: new Date,
          dataPaketDetail:[],
          v_paket:null,
          descManifest:null,
          showDetail:false,

           login:{
            status:false,
            data:null
          },

           is_runTest:false,
           date_manifest:null,
          runTestResult:false,
          runTestParameter:"",
          runTestHeader:null,
          token_re:null,
          data_validate:[],
          data_validate_retur:[],
          data_validate_konfirmasi:[],
          jenis_upload:"foto",
          date_akhir_cek_double:"",

           options:  [],

            data_url:Config,
            dataResi:null,
            dataTracking:[],
            dataTrackingNo:[],
            loading_api_base:false,
            dataTrackingHeader:[],
        }
  },
  computed: {
      device: function() {
          return this.devices.find(n => n.deviceId === this.deviceId);
      },

    jenis_upload_computed:{
      get(){
        return this.jenis_upload
      },
      async set(v){

      

        this.jenis_upload = v;

        if( v == "foto" ){

          this.onStart();

        }else{

          this.onStop();

        }


      }
    },

    date_manifest_computed:{
      get(){
        return this.date_manifest
      },
      async set(v){
        console.log(this.dateParse(v))
        this.date_manifest = v;
        this.getDataPaketKurir(this.dateParse(v));


      }
    },

  },
  watch: {
      camera: function(id) {
          this.deviceId = id;
      },
      devices: function() {
          // Once we have a list select the first one
          const [first, ...tail] = this.devices;
          if (first) {
              console.log(tail)
              this.camera = first.deviceId;
              this.deviceId = first.deviceId;
          }
      }
  },
  created(){

    if( localStorage.getItem("login") == null){
      this.$router.push("/")
    }

  this.getDataPaketKurir();

  },
  methods: {

        aa(v){
          alert(v)
          return true
        },

      myDataCekDoubleLacak(a,b){  
          
      if( a == b & this.date_akhir_cek_double == a){
        console.log(a + " = " + b + " & " + this.date_akhir_cek_double + " == " + a  + " = true")
        return true
      }
      
      if( a == b ){
          console.log(a + " = " + b + " = false")
        this.date_akhir_cek_double = a
        return false
      }
    },

        undo() {
          this.$refs.signaturePad.undoSignature();
        },

        save() {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

          console.log(isEmpty);
          console.log(data);
        },
  

        onTry(){
            this.$refs.webcam.start();

            this.showCamera = true;
        },

       onCapture() {
            this.img = this.$refs.webcam.capture();

            console.log(this.img)

            this.$refs.webcam.stop();
            this.showCamera = false;
        },

        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
          try {
            this.$refs.webcam.stop();
          } catch (error) {
            error
          }
            
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },

      onVerify(response){
        console.log('Verify: ' + response)
        this.token_re = response;
      },

      cekResi(){

         this.data_validate = [];

        if( this.isKosong2( this.v_paket ) ){
           
            this.data_validate.push("Pilih Nomor Resi");
         
            return false
        }

        this.cekResiLoad(this.v_paket.psi_t_paket_nomor);
      },

      async tampilDetail(v){
        console.log(v)
        this.showDetail = true;
        await this.cekResiLoad(v);
        this.onStart();
      },

        async untampilDetail(){
        this.showDetail = false;
        this.dataPaketDetail = [];
        this.onStop();
      },

      async cekResiLoad(v){

         this.data_validate = [];

        this.loading_api = true

         let formData = new URLSearchParams(); 
         formData.append('nomor_resi', v);

          await axios.post( this.data_url.cekPaketDetail + "?" + formData, null, this.dataHeaderWithTrx_Token(3)).then(res => {            
                if( res.data.codestatus == "S" ){
                  console.log( res.data.resultdata )

                  if( res.data.resultdata.length > 0 ){

                    this.dataPaketDetail = res.data.resultdata

                  }else{
                        swal({
                            title: this.$t('swal.informasi'),
                            text: "Maaf, Data paket tidak ditemukan",
                            icon: '/img/imgjrg/error.png'
                        });
                  }

                }
          }).catch( ( error ) => {

            if( error.response.data.codestatus == "E" ){
         
                  if(this.searchWord(error.response.data.message, "Token")){
                      localStorage.removeItem('login');
                      this.$router.push('/redirect')
                  }

                }
                
                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
        
      },

      async saveRetur(){

        this.data_validate_retur = [];

        if( this.isKosong2( this.alasan ) ){
            this.data_validate_retur.push("Isi Alasan");
            return false
        }

        this.loading_api = true

         let formData = new URLSearchParams(); 
         formData.append('nomor_resi', this.dataPaketDetail[0].nomor_resi);
          formData.append('reason',  this.alasan )

          await axios.post( this.data_url.setPaketReturn + "?" + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){
                  this.getDataPaketKurir(this.dateParse(this.date_manifest));
                  swal({
                            title: this.$t('swal.informasi'),
                            text: "Retur Berhasil Di Rekam",
                            icon: '/img/imgjrg/error.png'
                        });
                }

              

          }).catch( ( error ) => {
              console.log(error)

               if( error.response.data.codestatus == "E" ){
         
                  if(this.searchWord(error.response.data.message, "Token")){
                      localStorage.removeItem('login');
                      this.$router.push('/redirect')
                  }

                }

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
        
      },

      async lacakpaket(v){

        this.data_validate = []

        this.loading_api = true

        this.dataTrackingHeader = null 
        this.dataTrackingNo = null

        this.dataTrackingHeader = [];
        this.dataTrackingNo = []


        let formData = new URLSearchParams(); 

        formData.append('nomor_resi', v);
 

          await axios.post( this.data_url.getTrackingPaket + "?" + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){

                  if( res.data.resultdata.length > 0 ){

                      this.runTestResult = true
                      this.dataTracking = res.data.resultdata[0].tracking
                      this.dataTracking.sort( (a,b) => (a.id < b.id) ? 1 : -1 )

                     

                      let head1 = ""
                      let a = ""
                      for (let aaa = 0; aaa < this.dataTracking.length; aaa++) {
                        a = this.dataTracking[aaa].tanggal
                        a = a.split(" ")
                        if( head1 != a[0] ){
                          head1 =  a[0]
                          this.dataTracking[aaa].is_head = true
                        }else{
                           this.dataTracking[aaa].is_head = false
                        } 
                      }

                       console.log(this.dataTracking);

                      let dateTrac = ""
                      for (let index = 0; index < this.dataTracking.length; index++) {
                        let dataFlotSplit = this.splitSpace(this.dataTracking[index].tanggal)
                        if( dataFlotSplit[0] != dateTrac ){
                            dateTrac = dataFlotSplit[0];
                            this.dataTrackingNo.push(index)
                            this.dataTrackingHeader.push(dateTrac);
                        }
                      }

                      console.log( this.dataTrackingHeader );

                      this.$bvModal.show("bv-modal-lacak");

                  }else{
                        swal({
                            title: this.$t('swal.informasi'),
                            text: "Maaf, Data Cek Resi Tidak Ada",
                            icon: '/img/imgjrg/error.png'
                        });
                  }

                }
          }).catch( ( error ) => {
              console.log(error)

                try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
          
      },

      async saveKonfirmasi(){

        this.data_validate_konfirmasi = []

        if( this.isKosong2( this.penerima ) ){
            this.data_validate_konfirmasi.push("Isi Alasan");
        }


 
        let dataJSON = {
          "nomor_resi":this.dataPaketDetail[0].nomor_resi,
          "penerima":this.penerima
        }

       

        let file = null

        if( this.jenis_upload == 'foto' ){
          if( this.isKosong2(this.img) ){
             this.data_validate_konfirmasi.push("Isi Foto Bukti Penerima");
          }else{
             file = this.dataURIToBlob( this.img );
          }
        }else{

          try {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

                if( isEmpty == false ){
                   file = this.dataURIToBlob( data );

                }else{

                  this.data_validate_konfirmasi.push("Isi Foto Tanda Tangan Penerima");
             
                }
               
              

          } catch (error) {
                file = null
                console.log(error)
          } 
        
        }

        if( this.data_validate_konfirmasi.length > 0 ){
          return false
        }
        

        this.loading_api = true


        let formData = new FormData(); 
        formData.append('json', JSON.stringify(dataJSON) );
        formData.append('file', file, "gambar_" + this.random(5) + ".jpg");

          await axios.post( this.data_url.setPaketReceivedMultipartFormType, formData, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){
                      this.getDataPaketKurir(this.dateParse(this.date_manifest));
                   
                      swal({
                            title: this.$t('swal.informasi'),
                            text: "Konfirmasi Pesanan Berhasil Di Rekam",
                            icon: '/img/imgjrg/error.png'
                        });
                }

            

          }).catch( ( error ) => {
              console.log(error)

               if( error.response.data.codestatus == "E" ){
         
                  if(this.searchWord(error.response.data.message, "Token")){
                      localStorage.removeItem('login');
                      this.$router.push('/redirect')
                  }

                }

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
        
      },

      async getDataPaketKurir(date = null){

         let formData = ""
        if(date != null){

          formData = new URLSearchParams(); 

          formData.append('tanggal_from', date);
          formData.append('tanggal_to', date);

          formData = "?" + formData;

        }

        this.loading_api_base = true

          await axios.post( this.data_url.getPSIListPaketforKurir + formData ,null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){

                  if( res.data.resultdata.length > 0 ){

                    this.options = res.data.resultdata[0].paket
                    try {
                       this.options.push(...res.data.resultdata[1].paket)
                    } catch (error) {
                      error
                    }
                    console.log(this.options);
                   
                    this.descManifest = res.data.resultdata[0]
                    this.date_manifest = new Date( this.splitDate(this.descManifest.tanggal_kirim, 0 ) )

                    this.untampilDetail()

                  }else{

                        this.untampilDetail()

                        this.options = []
                        this.descManifest = {
                          nomor_manifest:"",
                          kurir_name:"",
                          jumlah_manifest:""
                        }

                        if( date == null ){
                           this.date_manifest = new Date()
                        }else{  
                            this.date_manifest = new Date(date)
                        }
                        
                  }

                }

               
          }).catch( ( error ) => {
              console.log(error)

               if( error.response.data.codestatus == "E" ){
         
                  if(this.searchWord(error.response.data.message, "Token")){
                      localStorage.removeItem('login');
                      this.$router.push('/redirect')
                  }

                }

                if(this.searchWord(error,"401")){
                    swal({
                            title: "OWaktu Habis",
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

           this.loading_api_base = false

          
      },
      showResiTracking(){
         this.$refs['bv_modal_cek_resi'].show();
      }
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}




p.small {
  line-height: 0.7;
}

p.big {
  line-height: 1.8;
}




.txtb2{
  color:#3B3B3B;
}

.dppd2 > div > div > input[name="date"] {
  height:30px !important;
}

@media (min-width: 320px) { 

  .floatCari{
    float:right;
  }

  .floatSimpan{
    float:right;
  }

  .lacakSizeTemplate{
    width:80%; 
    height:70px;
  }
   
}

@media (min-width: 576px) { 

    .floatCari{
        float:left;
    }

    .floatSimpan{
        float:left;
    }

  .lacakSizeTemplate{
    width:80%; 
    height:70px;
  }
  
}

@media (min-width: 768px) { 

   
   

}

@media (min-width: 992px) { 
    
}
@media (min-width: 1200px) { 
    
}

@media (min-width: 1400px) { 
    

  
}

@media (min-width: 1600px) { 
 
 
    
}

</style>
