<template>
  <div>
      <div class="col-lg-12 avenirORoman fz-16 mb-2"  style="color:#8D8D8D">
         <i class="fa fa-user-circle" aria-hidden="true"></i> {{ $t('profile_accountme.text_1') }} 
      </div>
      <div class="col-lg-12 p-3 bg-white" style="border-radius:0px; border:1px solid #D1D1D1;">
          <div class="row">
              <div class="col-lg-7 avenirHeavy fz-20">
                <span class="text-capitalize" v-if="!status_edit.status"> {{ this.input.sebutan_baca }}.  {{ this.input.name }} </span>
                <span v-if="status_edit.status"> 
                    
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-4">
                                    <b-form-select size="sm" v-model="input.sebutan" :options="this.$t('locale') == 'id' ? options_call_type : options_call_type_inggris"></b-form-select>
                                </div>
                                <div class="col-lg-8">
                                     <input type="text" class="form-control form-control-sm" v-model="input.name" v-on:keypress="isLetter($event)" /> 
                                </div>
                            </div>
                        </div>
                </span>
              </div>
              <div class="col-lg-2 text-primary avenirMedium fz-20">
                  <button :disabled="loading_api" class="btn btn-outline-danger btn-sm" @click="changeProfil" > 
                      <span v-if="!loading_api"><i class="fa fa-cog" aria-hidden="true"></i> {{ !status_edit.status ? $t('profile_accountme.text_9') : $t('profile_accountme.text_10') }}</span>
                      <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                </button></div>
              <div class="col-lg-3 text-primary avenirMedium fz-20">
                  
                  <button v-if="login_sosial == 'false'" @click="$bvModal.show('bv-modal-ubah-sandi')" class="  btn-sm btn btn-outline-danger"> <i class="fa fa-cog" aria-hidden="true"></i>  {{ $t('profile_accountme.text_2') }} </button></div>

               <b-modal id="bv-modal-ubah-sandi" hide-header hide-footer
                            body-classes="p-0"
                            modal-classes="modal-dialog-centered modal-sm">

                            <card shadow
                                        class="col-lg-12">
                                        <template>
                                            <div class="text-center mb-3 avenirHeavy fz-24">
                                                <p class="font-weight-bold">{{ $t('profile_accountme.text_18') }}</p>
                                                <p>{{ $t('profile_accountme.text_19') }}</p>
                                            </div>

                                            <form role="form" class="mt-3">

                                                <base-input alternative
                                                type="password" 
                                                v-model="pass1"
                                                :placeholder="'Masukkan Sandi Baru'"></base-input>
                            
                                                
                                                <base-input alternative
                                                            type="password" 
                                                            v-model="pass2"
                                                            class="mb-3"
                                                            :placeholder="'Ulangi Sandi Baru'">
                                                </base-input>

                                            </form>

                                             <div class="text-center mb-3">

                                                <base-button type="danger" class="my-12" @click="changePassword" >
                                                    <span v-if="!loading_api">Simpan</span>
                                                    <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                                </base-button>
                                            </div>
                                        </template>
                            </card>    

               </b-modal>

          </div>
      </div>
      <div class="col-lg-12 avenirORoman fz-16 mb-2 mt-4"  style="color:#8D8D8D">
         <i class="fa fa-address-card" aria-hidden="true"></i>  {{ $t('profile_accountme.text_3') }} 
      </div>
      <div class="col-lg-12 p-3  bg-white" style="border-radius:0px; border:1px solid #D1D1D1;">
          <div class="row">
              <div class="col-lg-7 avenirHeavy fz-20">
                <span class="text-uppercase" v-if="!status_edit.status">{{  this.input.identity }} - {{  this.input.identity_nomor }}  </span>
                <span v-if="status_edit.status"> 
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-4">
                                    <b-form-select size="sm" v-model="input.identity" :options="options_identity_type"></b-form-select>
                                </div>
                                <div class="col-lg-8">
                                     <input type="number" class="form-control  form-control-sm" v-model="input.identity_nomor" /> 
                                </div>
                            </div>
                        </div>
                        
                </span>
              </div>
              <div class="col-lg-6 text-primary avenirMedium fz-20"></div>
          </div>
      </div>
      <div class="col-lg-12 avenirORoman fz-16 mb-2 mt-4"  style="color:#8D8D8D">
         <i class="fa fa-mobile" aria-hidden="true"></i> {{ $t('profile_accountme.text_4') }} 
      </div>
      <div class="col-lg-12 p-3  bg-white" style="border-radius:0px; border:1px solid #D1D1D1;">
          <div class="row">
              <div class="col-lg-6 avenirHeavy fz-20">
                 <span> {{  this.input.mobile }} </span>
                   <!-- <span v-if="status_edit.status"> <input type="text" class="form-control" v-model="input.mobile" /> </span> -->
          
              
         
              </div>
              <div class="col-lg-6 text-primary avenirMedium fz-20">
                     <button disabled @click="$bvModal.show('bv-modal-ubah-telepon')" class="btn btn-outline-danger float-right btn-sm"><i class="fa fa-cog" aria-hidden="true"></i> {{ $t('profile_accountme.text_14') }} </button>
              </div>

               <b-modal id="bv-modal-ubah-telepon" hide-header hide-footer
                            body-classes="p-0"
                            modal-classes="modal-dialog-centered modal-sm">

                            <card shadow
                                        class="col-lg-12">
                                        <template>
                                            <div class="text-center mb-3 avenirHeavy fz-24">
                                                <p class="font-weight-bold">{{ $t('profile_accountme.text_12') }}</p>
                                                <p>{{ $t('profile_accountme.text_13') }}.</p>
                                            </div>

                                            <form role="form" class="mt-3">

                                                <div class="col-lg-12 m-0 p-0">
                                                    <div class="row m-0 p-0">
                                                        <div class="col-lg-9 m-0 p-0"> 
                                                            <base-input  v-model="v_new_phone" alternative
                                                            :placeholder="'Masukkan No Telepon Baru'"></base-input>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <button class="btn btn-warning btn-sm"  @click="sendOTP(1)">
                                                                <span v-if="!loading_api">{{ $t('profile_accountme.text_11') }}</span>
                                                                <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                                            </button>
                                                        </div>
                                                    </div>
                                                       
                                                </div>
                                                

                                                <base-input alternative
                                                            v-model="v_otp"
                                                            class="mb-3"
                                                            :placeholder="' Masukan Kode OTP'">
                                                </base-input>

                                            </form>

                                             <div class="text-center mb-3">

                                               
               
                                                <!-- <div class="alert alert-danger" role="alert" v-show="wrongPassword">
                                                {{ $t('app_header.txt_sandi_salah') }}
                                                </div> -->
                                                <base-button type="danger" class="my-12" @click="toChooseeVerifikasiEmailNoTelepon(1)" >
                                                    <span v-if="!loading_api">Simpan</span>
                                                    <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                                </base-button>
                                            </div>
                                        </template>
                            </card>    

               </b-modal>

          </div>
      </div>
      <div class="col-lg-12 avenirORoman fz-16 mb-2 mt-4"  style="color:#8D8D8D">
         <i class="fa fa-envelope" aria-hidden="true"></i> {{ $t('profile_accountme.text_5') }}  
      </div>
      <div class="col-lg-12 p-3  bg-white" style="border-radius:0px; border:1px solid #D1D1D1;">
          <div class="row">
              <div class="col-lg-9 avenirHeavy fz-20" >
               <span style="white-space: normal; word-break: break-all; width:100%;">   {{  this.input.email }} </span>
              </div>
              <div class="col-lg-3 text-primary avenirMedium fz-20">
                    <button class="btn btn-outline-danger float-right  btn-sm" @click="$bvModal.show('bv-modal-ubah-email')"><i class="fa fa-cog" aria-hidden="true"></i> {{ $t('profile_accountme.text_14') }} </button>
              </div>

               <b-modal id="bv-modal-ubah-email" hide-header hide-footer
                            body-classes="p-0"
                            modal-classes="modal-dialog-centered modal-sm">

                            <card shadow
                                        class="col-lg-12">
                                        <template>
                                            <div class="text-center mb-3 avenirHeavy fz-24">
                                                <p class="font-weight-bold">{{ $t('profile_accountme.text_14') }} Email</p>
                                                <p>{{ $t('profile_accountme.text_15') }}</p>
                                            </div>

                                            <form role="form" class="mt-3">

                                                <div class="col-lg-12 m-0 p-0">
                                                    <div class="row m-0 p-0">
                                                        <div class="col-lg-9 m-0 p-0"> 
                                                            <base-input v-model="v_new_email" alternative
                                                            :placeholder="'Masukkan Email Baru'"></base-input>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <button class="btn btn-warning btn-sm" @click="sendOTP(0)">
                                                                <span v-if="!loading_api">{{ $t('profile_accountme.text_16') }} OTP</span>
                                                                <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                                            </button>
                                                        </div>
                                                    </div>
                                                       
                                                </div>
                                                

                                                <base-input alternative
                                                            v-model="v_otp"
                                                            class="mb-3"
                                                            :placeholder="' Masukan Kode OTP'">
                                                </base-input>

                                            </form>

                                             <div class="text-center mb-3">
                                                <base-button type="danger" class="my-12" @click="toChooseeVerifikasiEmailNoTelepon(0)" >
                                                    <span v-if="!loading_api">{{ $t('profile_accountme.text_17') }}</span>
                                                    <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                                </base-button>
                                            </div>
                                        </template>
                            </card>    

               </b-modal>

          </div>
      </div>
      <!-- <div class="col-lg-12 avenirORoman fz-16 mb-2 mt-4">
         <i class="fa fa-link" aria-hidden="true"></i> {{ $t('profile_accountme.text_6') }}  
      </div>
      <div class="col-lg-12 p-3  bg-white" style="border-radius:0px; border:1px solid #D1D1D1;">
          <div class="row">
              <div class="col-lg-12 avenirHeavy fz-20 mb-3">
                <img src="img/imgjrg/Facebook.svg" /> <span class="float-right text-primary fz-20 avenirMedium" style="margin-top:10px;"><i class="fa fa-plug" aria-hidden="true"></i> {{ $t('profile_accountme.text_7') }}  </span>
              </div>
            
              <div class="border-top col-lg-12 mb-3"></div>

              <div class="col-lg-12 avenirHeavy fz-20">
                 <img src="img/imgjrg/Google.svg" /> <span class="float-right text-primary fz-20 avenirMedium"  style="margin-top:10px;"><i class="fa fa-plug" aria-hidden="true"></i> {{ $t('profile_accountme.text_8') }}   </span>
              </div>
          </div>
      </div> -->
  </div>
</template>
<script>

import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import swal from 'sweetalert';

export default {
  mixins:[Validate,Master,Helper],
  name: "components",
  components: {
    PulseLoader
  },
  data() {
        return {
            v_otp:"",
            v_new_email:"",
            v_new_phone:"",
            name_to_save:"",
            dataProfile:"",
            data_url:Config,
            pass1:"",
            pass2:"",
            login_sosial:false,

            status_edit:{
                status:false,
                name_button_true:"Simpan",
                name_button_false:"Ubah",
            },

            input:{
                name:"",
                identity:"",
                mobile:"",
                email:"",
                sebutan:"",
                sebutan_baca:"",
                nomor_identitas:"",
                identity_nomor:""
            },

           options_call_type:[
                { value: 'bapak', text: 'Bapak' },
                { value: 'ibu', text: 'Ibu' },
                { value: 'saudara', text: 'Saudara' },
                { value: 'saudari', text: 'Saudari' },
                { value: 'tuan', text: 'Tuan' },
                { value: 'nyonya', text: 'Nyonya' },
             ],

             options_call_type_inggris:[
                { value: 'bapak', text: 'Sir' },
                { value: 'ibu', text: 'Madam' },
                { value: 'saudara', text: 'Brother' },
                { value: 'saudari', text: 'Miss' },
                { value: 'tuan', text: 'Mr' },
                { value: 'nyonya', text: 'Mrs' },
             ],

              options_identity_type:[
                { value: 'ktp', text: 'KTP' },
                { value: 'sim', text: 'SIM' },
                { value: 'paspor', text: 'Paspor' }
             ],

             
        }
  },
  created(){
      this.login_sosial = localStorage.getItem("login_sosial");
      this.getDataProfile();
  },
  methods: {

      async sendOTP(tipe){
        
       
        const formData = new URLSearchParams(); 

        if(tipe == 0){

            if( this.v_new_email == null ){
                 swal({
                            title: "Data Input",
                            text: this.$t('swal.v1'),
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false;
            }

              if( this.toLowerCase(this.v_new_email) == "" || this.toLowerCase(this.v_new_email) == "false" ){
                    
                     swal({
                            title: "Data Input",
                            text: this.$t('swal.v1'),
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false;
            }

            if( this.validateEmail(this.v_new_email) == false ){
                 swal({
                            title: "Data Input",
                            text: this.$t('swal.v2'),
                            icon: '/img/imgjrg/antri.png'
                        });
                 return false;
            }

            formData.append('email', this.v_new_email)

        }else{

            if( this.toLowerCase(this.v_new_phone) == "" || this.toLowerCase(this.v_new_phone) == "false" ){
              
                 swal({
                            title: "Data Input",
                            text: this.$t('swal.v3'),
                            icon: '/img/imgjrg/antri.png'
                        });
                return false;
            }

            if( Number.isInteger(this.v_new_phone) ){
         
                 swal({
                            title: "Cek Data",
                            text: this.$t('swal.v4'),
                            icon: '/img/imgjrg/antri.png'
                        });
                return false
            }

            if( ! this.phoneIsValid( this.v_new_phone) ){
         
                 swal({
                            title: this.$t('swal.peringatan'),
                            text:  this.$t('swal.no_telepon_belum_benar') ,
                            icon: '/img/imgjrg/antri.png'
                        });
                return false;
            }


            if( this.v_new_phone.lenght > 7 & this.v_new_phone.lenght <= 13 ){
          
                 swal({
                            title:  this.$t('swal.peringatan'),
                            text:  this.$t('swal.no_telepon_belum_benar'),
                            icon: '/img/imgjrg/antri.png'
                        });
                return false;
            }


            formData.append('phone', this.v_new_phone)
        }

        formData.append('lang',  this.$t('locale_lang') )
        formData.append('is_changeinfo', 'Y')
         this.loading_api = true
        await axios.post( this.data_url.send_otp + "?" + formData, null, 
        this.dataHeaderWithTrx_Token(2) ).then(res => {            
            console.log(res)
            if( res.data.codestatus == "S" ){
                swal({
                            title: this.$t('swal.informasi'),
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
            }else{
               
                 swal({
                            title:  this.$t('swal.peringatan'),
                            text:  res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
            }

        }).catch( ( error ) => {


              try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }


             if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                console.log(error) 
        } );
         this.loading_api = false
         


    },

      async changeProfil(){
          if( this.status_edit.status == false ){
              this.status_edit.status = true
          }else{
            

           

            if( this.toLowerCase(this.input.sebutan) == "" || this.toLowerCase(this.input.sebutan) == "false" ){
                 
                 this.input.name = this.name_to_save
                 swal({
                            title: "Data Input",
                            text: this.$t('swal.v5') ,
                            icon: '/img/imgjrg/antri.png'
                        });
                return false;
            }
            if( this.toLowerCase(this.input.name) == "" || this.toLowerCase(this.input.name) == "false" ){
                 this.input.name = this.name_to_save
                 swal({
                            title: "Data Input",
                            text:  this.$t('swal.v6'),
                            icon: '/img/imgjrg/antri.png'
                        });
                return false;
            }
         
         
            if( this.toLowerCase(this.input.identity) == "" || this.toLowerCase(this.input.identity) == "false" ){
                 this.input.name = this.name_to_save
                 swal({
                            title: "Data Input",
                            text:  this.$t('swal.v7'),
                            icon: '/img/imgjrg/antri.png'
                        });
                return false;
            }

            if( this.toLowerCase(this.input.identity_nomor) == "" || this.toLowerCase(this.input.identity_nomor) == "false" ){
                this.input.name = this.name_to_save
                 swal({
                            title: "Data Input",
                            text: this.$t('swal.v8') ,
                            icon: '/img/imgjrg/antri.png'
                        });
                return false;
            }


             this.loading_api = true

            let formData = new URLSearchParams(); 
            formData.append('title', this.toLowerCase(this.input.sebutan));
            console.log(this.input.sebutan);    
            formData.append('name', this.input.name);
            formData.append('identity_type',  this.toLowerCase(this.input.identity));
            formData.append('identity_no', this.toLowerCase(this.input.identity_nomor));
            formData.append('lang', this.$t('locale_lang') )
      
            await axios.post( this.data_url.setUserInfo + "?" + formData, null, this.dataHeaderWithTrx_Token(2) ).then(async res => {            
                if( res.data.codestatus == "S" ){
                   this.status_edit.status = false
                   console.log(res.data.codestatus);
                   this.getDataProfile();
                }

              if(  res.data.codestatus == "X" ){
                     swal({
                            title: this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }
 
            }).catch( ( error ) => {
                console.log(error)

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title:  this.$t('swal.t1'),
                            text:  this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });


             this.loading_api = false

          }
      },

    isLetter(e) {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if(/^[A-Z a-z]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
    },

      async toChooseeVerifikasiEmailNoTelepon(tipe){

            if(tipe == 0){
                if( this.toLowerCase(this.v_new_email) == "" || this.toLowerCase(this.v_new_email) == "false" ){
                   
                     swal({
                            title: "Data Input",
                            text:  this.$t('swal.v1'),
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false;
                }
            }else{
                if( this.toLowerCase(this.v_new_phone) == "" || this.toLowerCase(this.v_new_phone) == "false" ){
                
                     swal({
                            title: "Data Input",
                            text:  this.$t('swal.v3'),
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false;
                }
            }

            if( this.v_otp == null || this.v_otp == 'null' || this.v_otp == '' ){
           
                 swal({
                            title: "Data Input",
                            text:  this.$t('swal.v9'),
                            icon: '/img/imgjrg/antri.png'
                        });
                return false;
            }

            this.loading_api = true
            const formData = new URLSearchParams(); 
            formData.append('otp', this.v_otp)
            await axios.post( this.data_url.validateOTP + "?" + formData, null, 
            this.dataHeaderNonAuth ).then(async res => {            
                console.log(res)
                if( res.data.resultdata[0].validation == true ){

                     let formData1 = new URLSearchParams(); 
                     if(tipe == 0){
                         formData1.append('email',  this.toLowerCase(this.v_new_email));
                     }else{
                         formData1.append('mobile',  this.toLowerCase(this.v_new_phone));
                     }

                    formData1.append('otp_token', res.data.resultdata[0].otp_token );
                    await axios.post( this.data_url.setUserInfo + "?" + formData1, null, this.dataHeaderWithTrx_Token(2) ).then(async res => {  
                         console.log(res.data)        
                        if( res.data.codestatus == "S" ){
                            
                             if(tipe == 0){
                                 swal( this.$t('swal.t3'), this.$t('swal.txt3'), "success")
                             }else{
                                 swal(this.$t('swal.t4'), this.$t('swal.txt4'), "success")
                             }
                            
                            this.$bvModal.hide('bv-modal-ubah-email')
                            this.$bvModal.hide('bv-modal-ubah-telepon')

                            localStorage.removeItem('login');
                            this.$router.push('/');

                        }
                        if(  res.data.codestatus == "X" ){
                                 swal({
                            title:  this.$t('swal.t2'),
                            text:  this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                                localStorage.removeItem('login');
                                this.$router.push('/redirect')
                            }
        
                    }).catch( ( error ) => {
                        console.log(error)

                          try {

                                if( error.response.data.codestatus == "E" ){
                        
                                    if(this.searchWord(error.response.data.message, "Token")){
                                        localStorage.removeItem('login');
                                        this.$router.push('/redirect')
                                    }

                                }
                                
                                } catch (errorq) {
                                errorq
                                }

                        if(this.searchWord(error,"Network Error")){
                               swal({
                            title: this.$t('swal.t1'),
                            text:  this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                        }
                    });

                    
                }else{
                 
                     swal({
                            title:  this.$t('swal.t5'),
                            text:  this.$t('swal.txt5'),
                            icon: '/img/imgjrg/antri.png'
                        });
                }

            }).catch( ( error ) => {
                    console.log(error) 

                    if(this.searchWord(error,"Network Error")){
                       swal({
                            title:  this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

            });
            this.loading_api = false

      },

      async changePassword(){

       if( this.pass1 != this.pass2 ){
             swal({
                            title:  this.$t('swal.t6'),
                            text:  this.$t('swal.txt6'),
                            icon: '/img/imgjrg/antri.png'
                        });
            return
        }

       if( this.isKosong2(this.pass1) ){
             swal({
                            title:this.$t('swal.t6'),
                            text: this.$t('swal.txt6_1'),
                            icon: '/img/imgjrg/antri.png'
                        });
            return
        }

        this.loading_api = true;

        const formData = new URLSearchParams(); 
            formData.append('login', ( this.isKosong2(this.input.email) ? this.input.mobile : this.input.email) )
            formData.append('new_pass',  this.pass2 )
            await axios.post( this.data_url.resetpassword + "?" + formData, null, this.dataHeaderWithTrx_Token(2) ).then(res => {            
                console.log(res)
                if( res.data.codestatus == "S" ){
                    this.$bvModal.hide('bv-modal-ubah-sandi');
                    swal(this.$t('swal.t3'), this.$t('swal.txt6_2'), "success")
                }else{
                    swal({
                            title: this.$t('swal.t5'),
                            text:  res.data.messag,
                            icon: '/img/imgjrg/antri.png'
                        });
                }

            }).catch( ( error ) => {

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                 console.log(error) 
            } );

              this.loading_api = false;

      },

      async getDataProfile(){
            this.loading_api = true
            let formData = new URLSearchParams(); 
            formData.append('lang',  this.$t('locale_lang') )

            await axios.post( this.data_url.getUserInfo + "?" + formData, null, this.dataHeaderWithTrx_Token(2) ).then(async res => {    

                if( res.data.codestatus == "S" ){
                    this.$emit('getDataProfilParent', res.data.resultdata[0]);
                    this.dataProfile = res.data.resultdata[0]
                    this.input.name = this.dataProfile.name
                    this.name_to_save = this.dataProfile.name
                    this.input.sebutan = String(this.dataProfile.title_key).toLowerCase();
                    this.input.sebutan_baca = String(this.dataProfile.title).toLowerCase();
                    this.input.mobile = this.dataProfile.mobile
                    this.input.email = this.dataProfile.email
                    this.input.identity = this.dataProfile.identity_type
                    this.input.identity_nomor = this.dataProfile.identity_no
                }

                if(  res.data.codestatus == "X"){
                     swal({
                            title: this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }
                   
            }).catch( ( error ) => {

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }
               
                if(this.searchWord(error,"401")){
                     swal({
                           title: this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                console.log(error)
            });

            this.loading_api = false
      },
    
  }
};
</script>
<style>

</style>
