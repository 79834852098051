<template>
    <section id="InputePassenger" class="section-components pb-5 pt-5 bg-white">
        <carousel-slider></carousel-slider>

        <div class="container mt-4">
      
            <div class="row text-center" v-if="dataContent != null">

              <div class="col-lg-12">
                <p class="avenirLight semi-bold fz-30">{{ dataContent[0].name }}</p>
              </div>

              <div style="white-space: pre-line" class="col-lg-12" v-for="(value,index) in dataContent[0].details" :key="index"> 
                <p class="avenirLight fz-16 roman" style="text-align: justify;">{{ value.description }}</p>
              </div>

            </div>

        </div>
    </section>
</template>
<script>
import '../assets/cssVendor/vue-multiselect.min.css';
import { Helper } from '../helper.js';
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import swal from 'sweetalert';
import CarouselSlider from "./Carousel"

export default {
  mixins:[Helper,Master,Validate],
  name: "components",
  components: {
    // PulseLoader,
    CarouselSlider
  },
  data() {
        return {
            data_url:Config,
            dataContent:null,
               contentMenu:[],
            isContentMenuArray:[false,false,false,false,false,false],
            isContentMenu:false
        }
  },
  created(){
    this.getData();
  },
  methods: {


      async getData(){


          this.loading_api = true

           await axios.post( this.data_url.getPSIMenuExtraInfo, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                    for (let index = 0; index <  res.data.resultdata.length; index++) {
                        try {
                             this.contentMenu[res.data.resultdata[index].sequence] = res.data.resultdata[index]
                             this.isContentMenuArray[res.data.resultdata[index].sequence] = true
                        } catch (error) {
                            this.contentMenu[res.data.resultdata[index].sequence] = ""
                        }
                    }

                    if( res.data.resultdata.length > 0 ){
                        this.isContentMenu = true
                    }else{
                        this.isContentMenu = false
                    }
                    
                }

          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          let formData = new URLSearchParams(); 
          formData.append('menu_extra_info_id',  this.contentMenu[4].id);
           formData.append('lang',  this.$t('locale_lang') )

          await axios.post( this.data_url.getPSIExtraInfo + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                  
                  this.dataContent = res.data.resultdata[0].sections
                  console.log(this.dataContent)

                }

          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false


      },
   
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

</style>
