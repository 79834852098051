<template>
    <header id="top" class="header-globa">
        <base-nav style="background: #ff0000;" class="navbar-main shadow pt-0 pb-0" transparent type="" effect="light" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-2" to="/">
                <img src="img/imgjrg/jrg_putih.svg"  style="width:100px; height:50px;" alt="logo" />
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-10 collapse-brand">
                   <!-- <router-link to="/">
                         <img src="img/imgjrg/jrg.png" alt="logo" />
                    </router-link> -->
                    <span class=" avenirHeavy fz-20" style="color:#ff0000;">Menu Halaman JRG</span>
                </div>
                <div class="col-2 collapse-close">
                    <close-button ref="closebutton" @click="closeMenu"></close-button>
                </div>
            </div>

           

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">

                <!-- <a slot="title" @click="closeButtonNow" href="#" class="nav-link " data-toggle="dropdown" role="button">
                     <router-link to="/" class="menutext nav-link-inner--text poppins semi-bold fz-16">Utama</router-link>
                </a>  -->
              
               <base-dropdown tag="li" class="nav-item" >
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <span class="nav-link-inner--text menutext poppins semi-bold fz-16" >{{ $t('app_header.menu_1') }} <i class="fa fa-angle-down" aria-hidden="true"></i></span>
                    </a>
                    <a @click="() => {  this.$router.push('/kirimpaket'); this.closeButtonNow()  }"  class="dropdown-item avenirHeavy fz-18 pointer clickMerah"> {{ $t('app_header.menu_1_1') }}</a>
                    <a @click="() => {  this.$router.push('/cekresi'); this.closeButtonNow()  }"  class="dropdown-item avenirHeavy fz-18 pointer clickMerah"> {{ $t('app_header.menu_1_2') }}</a>
                    <a @click="() => {  this.$router.push('/cekongkir'); this.closeButtonNow()  }" class="dropdown-item avenirHeavy fz-18 pointer clickMerah">{{ $t('app_header.menu_1_3') }}</a>
                    <a v-show="is_terimapaket" @click="() => {  this.$router.push('/terimapaket'); this.closeButtonNow()  }" class="dropdown-item avenirHeavy fz-18 pointer clickMerah">{{ $t('app_header.menu_1_4') }}</a>
                    <a v-show="is_paketdriver" @click="() => {  this.$router.push('/paketantardriver'); this.closeButtonNow()  }" class="dropdown-item avenirHeavy fz-18 pointer clickMerah">{{ $t('app_header.menu_1_5') }}</a>
                </base-dropdown>

                <a @click="closeButtonNow" href="#" class="nav-link cekpemesanan" data-toggle="dropdown" role="button">
                     <router-link to="/cekpemesanan" class="menuc menutext nav-link-inner--text poppins semi-bold fz-16">{{ $t('app_header.menu_2') }}</router-link>
                </a>   
                
        
                <a  v-if="!login.status" @click="upShowLogin" href="#" class="nav-link shadow pl-2 mt-2 d-xl-none d-lg-none" data-toggle="collapse" data-target="#undefined" aria-label="Toggle navigation" style="border:1px solid #f5365c;">
                    <span class="nav-link-inner--text menutext poppins fz-16" >
                        <i class="fa fa-sign-in" aria-hidden="true"></i> Masuk 
                    </span>
                </a>

                 <a @click="() => {  this.$router.push('/register'); this.closeButtonNow()  }" slot="title" v-if="!login.status" href="#" class="nav-link shadow pl-2 mt-2 d-xl-none d-lg-none" style="border:1px solid #f5365c;" data-toggle="dropdown" role="button">
                    <span  class="nav-link-inner--text menutext poppins semi-bold fz-16" >
                         <i class="fa fa-user-plus" aria-hidden="true"></i>  Daftar
                    </span>
                </a>

            </ul>


            <ul class="navbar-nav align-items-lg-center ml-lg-auto">

                <base-dropdown tag="li" class="nav-item" v-if="login.status" >
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <span class="nav-link-inner--text avenirHeavy fz-18 menutext"><i class="fa fa-user-circle" aria-hidden="true"></i>  {{ $t('app_header.menu_member') }}  <i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                    </a>
                    <a class="pointer dropdown-item clickMerah" @click="() => {  this.$router.push('/profile'); this.closeButtonNow()  }"><img src="img/imgjrg/i1.png" height="22" width="22" /> {{ $t('app_header.halaman_member') }}</a>
                    <a class="pointer dropdown-item border-top clickMerah" ref="logOut" @click="logOut"><img src="img/imgjrg/i6.png" height="22"  width="22"/> {{ $t('app_header.btn_keluar') }}</a>

                </base-dropdown>

    
                
                <li class="nav-item d-none d-lg-block ml-lg-4">
                    <base-dropdown tag="li" class="nav-item float-right" style="width:150px;">
                        <a slot="title" href="#" class="nav-link" style="color:#FFFFFF;" data-toggle="dropdown" role="button">
                            <span :class="'mr-2 flag-icon ' + lg.format"></span> <span class="semi-bold fz-16" >{{ lg.text }}</span>
                        </a>
                        <a class="pointer dropdown-item clickMerah" @click="toLg('indonesia')"><span class="mr-2 flag-icon flag-icon-id "></span> Indonesia</a>
                        <a class="pointer dropdown-item clickMerah" @click="toLg('amerika')"><span class="mr-2 flag-icon flag-icon-gb"></span> English</a>
                    </base-dropdown>
                </li>

                <li class="nav-item d-none d-lg-block ml-lg-4" v-if="!login.status">
                        <b-button class=" poppins semi-bold fz-16" style="border-radius: 0px !important; background: rgb(255, 0, 0); box-shadow:0px 0px 0px 0px !important; border: 0px !important; color: rgb(255, 255, 255);" @click="upShowLogin">
                            <!-- <i class="fa fa-sign-in mr-2"></i> -->
                             {{ $t('app_header.btn_masuk') }} </b-button>
                    
                </li>

                <li class="nav-item d-none d-lg-block ml-lg-4" v-if="!login.status">
                        <a @click="routeRegister" class="menutext nav-link-inner--text  avenirHeavy">
                             <b-button class=" poppins semi-bold fz-16" variant="danger"  style="border-radius: 0px !important; background: rgb(255, 0, 0); box-shadow:0px 0px 0px 0px !important; border: 0px !important; color: rgb(255, 255, 255);">
                                 <!-- <i class="fa fa-address-book-o"></i> -->
                                  {{ $t('app_header.btn_daftar') }} </b-button>
                        </a>
                </li>
            </ul>

        </base-nav>

        <b-modal id="bv-modal-infoku" hide-header hide-footer
                            size="sm"
                            body-class="p-0 rounded xxx"
                            modal-class="cc"
                            modal-classes="modal-dialog-centered">
                             <div style="position:absolute; top:-20px; left:-10px; color:#ff0000;" >
                                    <img class="pointer" src="img/imgjrg/close.png" @click="$bvModal.hide('bv-modal-infoku')" />
                                </div>
                            <div class="rounded" style="width:348px; height:432px;">
                               
                                <img class="rounded" style="width:100%; height:100%;" :src="url_promo" />
                                
                            </div>  
           </b-modal>

          <b-modal id="bv-modal-masuk" hide-header hide-footer
                            body-classes="p-0"
                            modal-classes="modal-dialog-centered modal-sm">

                            <!-- emailnohpLogin -->
                             <card shadow
                                v-if="formLogin === 'emailnohp'"
                                class="col-lg-12">
                                <template>
                                    <div class="text-center mb-3 avenirHeavy fz-24">
                                        {{ $t('app_header.txt_login_sekarang') }}
                                    </div>

                                    <form role="form" class="mt-3">

                                        <base-input alternative
                                                    class="mb-3"
                                                    v-model="input.hpemail"
                                                    :placeholder="$t('app_header.txt_login_pc_1')">
                                        </base-input>

                                        <base-input alternative
                                                    class="mb-3"
                                                    :placeholder="$t('app_header.txt_login_pc_2')"
                                                    v-model="input.sandi"
                                                     type="password" 
                                                    addon-left-icon="fa fa-lock">
                                        </base-input>

                                      <p class="avenirMedium fz-14 text-right pointer text-primary" @click="showForgetPassword">{{ $t('app_header.txt_lupa_kata_sandi') }}</p>

                                    <div class="text-center mb-3">
               
                                        <div class="alert alert-danger" role="alert" v-show="wrongPassword">
                                           {{ $t('app_header.txt_sandi_salah') }}
                                        </div>
                                        <base-button type="danger" class="my-12" @click="toChooseeVerifikasi" >
                                            <span v-if="!loading_api"> {{ $t('app_header.txt_masuk') }}</span>
                                            <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                        </base-button>
                                    </div>

                                    </form>

                                    <p class="avenirMedium fz-14 text-center"> {{ $t('app_header.txt_atau') }}</p>

                                    <div class="btn-wrapper text-center" v-if="dataAuthProvider != null">
                                        <div style="width:245px; margin:auto;" class="mb-1">
                                            <v-facebook-login  @login="onLoginFb" @sdk-init="handleSdkInit" style=" cursor: pointer;" :appId="paramsfb"></v-facebook-login>
                                        </div>
                                        
                                        <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure" style="border:0px; background-color:#ffffff;"> 
                                           <base-button type="neutral" style="width:240px;" class="google-signin-button mt-1">
                                            <img slot="icon" src="img/icons/common/google.svg">
                                            Continue With Google
                                        </base-button>
                                        </GoogleLogin>
                                        
                                        <!-- <a href="" > 
                                            <base-button type="neutral" style="width:240px;" class="google-signin-button mt-1">
                                                <img slot="icon" src="img/instagram.png">
                                                Continue With Instagram
                                            </base-button>
                                        </a> -->
                                    </div>
                                </template>
                            </card>    

                             <!-- ForgetPassword -->
                             <card shadow
                                v-if="formLogin === 'forgetpassword'"
                                class="col-lg-12">
                                <template>
                                    <div class="text-center mb-3 avenirHeavy fz-24">
                                         {{ $t('app_header.txt_tlt_lupa_sandi') }}
                                    </div>

                                    <p class="avenirORoman fz-14"> {{ $t('app_header.txt_deskripsi_lupa_sandi') }}</p>

                                    <form role="form" class="mt-3">

                                        <base-input alternative
                                                    class="mb-3"
                                                    v-model="v_hporemail"
                                                    placeholder="No Telepon / Email">
                                        </base-input>

                                    <div class="text-center mb-3">
                                        <base-button type="danger" :disabled="loading_api" class="col-lg-12" @click="toChooseeVerifikasiFp">
                                         <span v-if="!loading_api" >{{ $t('app_header.txt_btn_lupa_sandi') }}</span>
                                          <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                         </base-button>
                                    </div>

                                    </form>
                                  
                                </template>
                            </card>        

                             <!-- ForgetPasswordPageVerifikasi -->
                             <card shadow
                                v-if="formLogin === 'forgetPasswordPageVerifikasi'"
                                class="col-lg-12">
                                <template>
                                    <div class="text-center mb-3 avenirHeavy fz-22">
                                       {{ $t('app_header.txt_verifikasi') }}
                                    </div>


                                     <p class="avenirORoman fz-16 text-center"> {{ $t('app_header.txt_verifikasi_1') }}<br/> 
                                     {{ v_hporemail }} <br/> {{ $t('app_header.txt_verifikasi_2') }}</p>

                                     
                                    <div class="row mb-3 justify-content-center">
                                        <CodeInput :loading="false" class="input" v-on:change="onChange" v-on:complete="onComplete" />
                                    </div>
                                  

                                    <div class="text-center mb-12">
                                        <base-button type="danger" class="col-lg-12" @click="toChangePassword" :disabled="loading_api" > 
                                            <span v-if="!loading_api" >{{ $t('app_header.txt_verifikasi_3') }}</span>
                                            <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                        </base-button>
                                        
                                    </div>

                                     <p class="avenirMedium fz-16">  {{ $t('app_header.txt_verifikasi_4') }}
                                    <span class="text-primary" v-if="isCoolDown == false"> {{ timeCoolDown.cdMinutes }}:{{ timeCoolDown.cdSecond }}</span> 
                                    <span class="text-success pointer" v-if="isCoolDown" @click="toChooseeVerifikasiFp"> {{ $t('app_header.txt_verifikasi_5') }} </span> 
                                </p>
                                </template>
                            </card>       

                              <!-- ForgetPasswordEChangePassword -->
                             <card shadow
                                v-if="formLogin === 'forgetpasswordchangepassword'"
                                class="col-lg-12">
                                <template>
                                    <div class="text-center mb-3 avenirHeavy fz-24">
                                        {{ $t('app_header.txt_kata_sandi_baru') }}
                                    </div>

                                    <p class="avenirORoman fz-14">{{ $t('app_header.txt_kata_sandi_baru_1') }} <br/>
                                    <span class="font-weight-bold">{{ v_hporemail }}</span>
                                    </p>

                                    <form role="form" class="mt-3">

                                        <base-input alternative
                                                    class="mb-3"
                                                     type="password" 
                                                     v-model="input.password1"
                                                    :placeholder="$t('app_header.txt_kata_sandi_baru_4')">
                                        </base-input>
                                        <base-input alternative
                                                    class="mb-3"
                                                     type="password" 
                                                      v-model="input.password2"
                                                     :placeholder="$t('app_header.txt_kata_sandi_baru_5')">
                                        </base-input>

                                        <p class="fz-14 avenirORoman">{{ $t('app_header.txt_kata_sandi_baru_2') }}</p>

                                    <div class="text-center mb-3">
                                        <base-button type="danger" :disabled="loading_api" class="col-lg-12" @click="prsChangePass">
                                        <span v-if="!loading_api">{{ $t('app_header.txt_kata_sandi_baru_3') }}</span>
                                        <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                        </base-button>
                                    </div>

                                    </form>
                                </template>
                            </card>        
          </b-modal>
    </header>
</template>
<script>

import CodeInput from "vue-verification-code-input";
import BaseNav from "../components/BaseNav";
import BaseDropdown from "../components/BaseDropdown";
import CloseButton from "../components/CloseButton";
import VFacebookLogin from 'vue-facebook-login-component'
import axios from "axios";
import Config from '../model/Config'
import { Helper } from '../helper';
import { Master } from '../model/Master.js';
import { Validate } from '../model/Validate';
import GoogleLogin from 'vue-google-login';
import swal from 'sweetalert';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import googleOneTap from 'google-one-tap';


export default {
  mixins: [Master,Validate,Helper],
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
    CodeInput,
    PulseLoader,
    GoogleLogin,
    VFacebookLogin
  },

  data() {
    return {

        FB: {},
        model: {},
        scope: {},

        is_runTest:false,
        runTestResult:false,
        runTestParameter:"",
        runTestHeader:null,
        runTest_data_login:null,
        is_terimapaket:false,
        is_paketdriver:false,
        url_promo:"",

        dataAuthProvider:null,
        params: { client_id: "323510194290-820qgc6os52nui3dc2omd6m8gjr4o9if.apps.googleusercontent.com" },
        paramsfb: "474450313626528",

        v_hporemail:null,
        isCoolDown:false,
        input:{
            hpemail:'',
            sandi:'',
            code_verify:'',
            password1:'',
            password2:'',
        },
        lg:{
            format:"flag-icon-id",
            text:"Indonesia"
        },
        login:{
            status:false,
            data:null
        },
        otp_token:null,
        wrongPassword:false,
        verifikasiBy:'',
        data_url:Config,
        modalku:false,
        formLogin:'emailnohp', // pilihVerifikasi // kodeverifikasi
        kodeOTPFinished:false,
        data:{
            notelepon:null,
        },
        timeCoolDown:{
            cdMinutes:0,
            cdSecond:0
        },
        goInterval:null
    };
  },
  watch:{
        $route (to, from){

            console.log(to + from);
          
            if( localStorage.getItem("login") == null){
         
                this.login.status = false
                this.login.data = null
                this.is_terimapaket = false
                this.is_paketdriver = false
                

            }else{

                let data = JSON.parse(localStorage.getItem("login"))
                
                if( data.is_kurir == true ){
                     this.is_terimapaket = true
                }else{
                     this.is_terimapaket = false
                }

                if( data.is_driver == true ){
                     this.is_paketdriver = true
                }else{
                     this.is_paketdriver = false
                }
    
                this.login.status = true
            }
        }
    },

    mounted(){

       
        this.getDataPromo();
        
    },

    
    created(){

        if(!this.is_runTest){
              this.getDataAuthProvider();
        }
      

        if( localStorage.getItem("login") == null){
         
            this.login.status = false
            this.login.data = null
              this.is_terimapaket = false
              this.is_paketdriver = false

        }else{

            let data = JSON.parse(localStorage.getItem("login"))

            if( data.is_kurir == true ){
                    this.is_terimapaket = true
            }else{
                    this.is_terimapaket = false
            }

            if( data.is_driver == true ){
                    this.is_paketdriver = true
            }else{
                    this.is_paketdriver = false
            }

            this.login.status = true
        }

    },

 
    methods:{

       

        handleSdkInit({ FB, scope }) {
            this.FB = FB
            this.scope = scope

            console.log(FB)
            console.log(scope)

        },

        async getDataPromo(){

            await axios.post( this.data_url.getImgPopup, null, 
            this.dataHeaderNonAuth ).then(res => {            
                console.log(res)
                if( res.data.codestatus == "S" ){
                    this.url_promo = res.data.resultdata[0].url_image;
                    this.$bvModal.show("bv-modal-infoku");
                }

            }).catch( ( error ) => {
                    console.log(error) 
            } );
            
        },


        onLoginFb(){
             window.FB.getLoginStatus(async response => {
                console.log('fb Login Status', response)
                if( response.status == 'connected' ){
                    
                    
                    this.loading_api = true;

                    const formData = new URLSearchParams(); 
                    formData.append('provider_id',  this.dataAuthProvider[0].id)
                    formData.append('provider_token', response.authResponse.accessToken)
                    await axios.post( this.data_url.login_oauth + "?" + formData, null, 
                    this.dataHeaderNonAuth ).then(res => {            
                        console.log(res)
                        if( res.data.codestatus == "S" ){
                            localStorage.setItem("login", JSON.stringify( res.data.resultdata));
                            localStorage.setItem("login_sosial", true );
                            this.login.status = true
                            this.login.data = res.data.resultdata
                            console.log( this.login.data )
                            this.$router.push('/profile');

                        }else{
                            
                            swal({
                                title: this.$t('swal.peringatan'),
                                text: res.data.message,
                                icon: '/img/imgjrg/antri.png'
                            });
                            window.FB.logout();
                            

                        }

                    }).catch( ( error ) => {

                        if(this.searchWord(error,"Network Error")){
                               swal({
                                    title: this.$t('swal.t1'),
                                    text: this.$t('swal.txt1'),
                                    icon: '/img/imgjrg/error.png'
                                });
                        }
                        window.FB.logout();
                        console.log(error) 
                    } );

                    this.loading_api = false;


                }
            });
            this.$bvModal.hide("bv-modal-masuk");
            
        },


        async onSuccess(googleUser) {
            console.log(googleUser);

            // This only gets the user information: id, name, imageUrl and email
            console.log(googleUser.getBasicProfile());
            let s2 = "";

            try {

                let searchtoken =  JSON.stringify(googleUser);
                let dd = searchtoken.search("access_token");
                let s1 = searchtoken.slice(dd);
                s2 = s1.split('"');
                
            } catch (error) {
                 s2 = "";
            }



            this.loading_api = true;

            const formData = new URLSearchParams(); 
            // formData.append('email', googleUser.Ft.pu)
            formData.append('provider_id',  this.dataAuthProvider[1].id)
            formData.append('provider_token',  s2[2] )
            await axios.post( this.data_url.login_oauth + "?" + formData, null, 
            this.dataHeaderNonAuth ).then(res => {            
                console.log(res)
                if( res.data.codestatus == "S" ){
                    localStorage.setItem("login", JSON.stringify( res.data.resultdata)) ;
                    localStorage.setItem("login_sosial", true );
                    this.login.status = true
                    this.login.data = res.data.resultdata
                    console.log( this.login.data )
                    this.$router.push('/profile');
                    this.$bvModal.hide("bv-modal-masuk");
                }else{
                    swal({
                            title: this.$t('swal.peringatan'),
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }

            }).catch( ( error ) => {

                    if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                    console.log(error) 
            } );

            this.loading_api = false;

        },
    
        onFailure (error) {
        console.log(error)
        },


        toLg(data){
            if(data == "indonesia"){
                this.lg = {
                    format:"flag-icon-id",
                    text:"Indonesia"
                }

                this.$i18n.locale = 'id'
            }else{
                this.lg = {
                    format:"flag-icon-gb",
                    text:"English"
                }

                this.$i18n.locale = 'en'
            }
        },

        onChange(v) {
            console.log("onChange ", v);
            this.input.code_verify = v
        },

        onComplete(v) {
            console.log("onComplete", v);
        },

     async getDataAuthProvider(){

        this.loading_api = true;

            await axios.post( this.data_url.get_provider_oauth, null, 
            this.dataHeaderNonAuth ).then(res => {            
                console.log(res)
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true
                    this.dataAuthProvider = res.data.resultdata
                    this.params = { client_id: res.data.resultdata[1].client_id }
                    console.log(this.params);
                    this.paramsfb = res.data.resultdata[0].client_id;

                    const options = {
                        // client_id: this.params.client_id, // required
                        client_id: '323510194290-820qgc6os52nui3dc2omd6m8gjr4o9if.apps.googleusercontent.com', // required
                        auto_select: false, // optional
                        cancel_on_tap_outside: true, // optional
                        context: 'signin', // optional
                    };


                    if(this.login.status){
                        this.loading_api = false;
                        return true;
                    }

                    googleOneTap(options, async (response) => {
                        
                        // const decodedToken  = VueJwtDecode.decode(response.credential);
                        // // alert(JSON.stringify(response))

                         this.loading_api = true;

                        const formData = new URLSearchParams(); 
                        formData.append('provider_id',  this.dataAuthProvider[1].id)
                        formData.append('jwt_param', response.credential)
                        await axios.post( this.data_url.login_oauth + "?" + formData, null, 
                        this.dataHeaderNonAuth ).then(res => {            
                            console.log(res)
                            if( res.data.codestatus == "S" ){
                                localStorage.setItem("login", JSON.stringify( res.data.resultdata)) ;
                                localStorage.setItem("login_sosial", true );
                                this.login.status = true
                                this.login.data = res.data.resultdata
                                console.log( this.login.data )
                                this.$router.push('/profile');
                                this.$bvModal.hide("bv-modal-masuk");
                            }else{
                                swal({
                            title: this.$t('swal.peringatan'),
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                            }

                        }).catch( ( error ) => {

                                if(this.searchWord(error,"Network Error")){
                                   swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                            }

                                console.log(error) 
                        } );

                        this.loading_api = false;


                        
                        console.log(response);
                    });

                }else{
                    swal({
                            title: this.$t('swal.peringatan'),
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }

            }).catch( ( error ) => {

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                 console.log(error) 
            } );

              this.loading_api = false;
            
    },

    async prsChangePass(){

        if( this.input.password1 != this.input.password2 ){
            swal({
                        title: "Maaf",
                        text: "Password Tidak Sama",
                        icon: '/img/imgjrg/antri.png'
                    });
            return
        }

        this.loading_api = true;

         const formData = new URLSearchParams(); 
            formData.append('login', this.v_hporemail)
            formData.append('new_pass',  this.input.password1)
            formData.append('otp_token',  this.otp_token)
            await axios.post( this.data_url.resetpassword + "?" + formData, null, 
            this.dataHeaderNonAuth ).then(res => {            
                console.log(res)
                if( res.data.codestatus == "S" ){
                       swal({
                        title: "Berhasil",
                        text: "password berhasil diubah",
                        icon: '/img/imgjrg/antri.png'
                    });
                    this.$bvModal.hide('bv-modal-masuk');
                }else{
                    swal({
                            title: this.$t('swal.peringatan'),
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }

            }).catch( ( error ) => {

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                 console.log(error) 
            } );

              this.loading_api = false;
            
    },

    logOut(){
        localStorage.removeItem('login');
        this.login.status = false
        this.login.data = null

        this.closeButtonNow();

        this.$router.push('/redirect');
    },

    

    async toChangePassword(){

        const formData = new URLSearchParams(); 

        this.loading_api = true;
        formData.append('otp', this.input.code_verify)
        await axios.post( this.data_url.validateOTP + "?" + formData, null, 
        this.dataHeaderNonAuth ).then(res => {            
            console.log(res)
            if( res.data.resultdata[0].validation == true ){
                this.otp_token =  res.data.resultdata[0].otp_token
                this.formLogin = 'forgetpasswordchangepassword'
            }else{
                  swal({
                        title: "OTP Salah",
                        text: "Masukan Kode OTP Dengan Benar",
                        icon: '/img/imgjrg/antri.png'
                    });
            }

        }).catch( ( error ) => {

                if(this.searchWord(error,"Network Error")){
                   swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
            }

                console.log(error) 
        } );
        this.loading_api = false;

    },
    toVerifikasiFp(){
         this.formLogin = 'forgetPasswordPageVerifikasi'
    },
    async toChooseeVerifikasiFp(){

         if( this.v_hporemail == "" || this.v_hporemail == null){
             alert("tidak boleh kosong");
             return
         }
        
        const formData = new URLSearchParams(); 
         if(this.isNumeric(this.v_hporemail)){

             if( ! this.phoneIsValid( this.v_hporemail) ){
        
                     swal({
                        title: this.$t('swal.peringatan'),
                        text: this.$t('swal.no_telepon_belum_benar'),
                        icon: '/img/imgjrg/antri.png'
                    });
                   return false;
             }

            formData.append('phone', this.v_hporemail)

         }else{
            formData.append('email', this.v_hporemail)
         }


        this.loading_api = true
        formData.append('lang',  this.$t('locale_lang') )
        formData.append('is_resetpass', 'Y')
        await axios.post( this.data_url.send_otp + "?" + formData, null, 
        this.dataHeaderNonAuth ).then(res => {            
            console.log(res)
            if( res.data.codestatus == "S" ){
                this.formLogin = 'forgetPasswordPageVerifikasi'
                this.setCoolDown();
            }else{
             
                  swal({
                        title: this.$t('swal.peringatan') ,
                        text: res.data.message,
                        icon: '/img/imgjrg/antri.png'
                    });
            }

        }).catch( ( error ) => {


             if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                console.log(error) 
        } );
         this.loading_api = false
         


    },
    showForgetPassword(){
           this.formLogin = 'forgetpassword'
    },
    upShowLogin(){
        this.closeButtonNow();
        this.$bvModal.show('bv-modal-masuk')
        this.formLogin = 'emailnohp'
        
    },
    routeRegister(){
         this.closeButtonNow();
         this.$router.push('/register');
    },
    closeButtonNow(){
        this.$refs.closebutton.closeButton();
    },
    onVerify(response) {
        console.log(response)
    },
    async toChooseeVerifikasi(){

       

        if( this.isKosong2(this.input.hpemail) ){
               swal({
                        title: "No Telepon / Email",
                        text: "Harus Di Isi",
                        icon: '/img/imgjrg/antri.png'
                    });
            return false
        }

        if( this.isKosong2(this.input.sandi) ){
               swal({
                        title: "Kata Sandi",
                        text: "Harus Di Isi",
                        icon: '/img/imgjrg/antri.png'
                    });
            return false
        }

         this.loading_api = true
        
        let formData = new URLSearchParams(); 

         if(!this.is_runTest){
            formData.append('username', this.input.hpemail)
            formData.append('password', this.input.sandi)
         }else{
            formData = this.runTestParameter;
        }

        await axios.post( this.data_url.login + "?" + formData, null, 
        this.dataHeaderNonAuth ).then(res => {            
            console.log(res)
            if( res.data.codestatus == "S" ){
                this.runTestResult = true
                this.runTest_data_login = res.data.resultdata

                localStorage.setItem("login", JSON.stringify( res.data.resultdata)) ;
                localStorage.setItem("login_sosial", false );
                this.login.status = true
                this.login.data = res.data.resultdata
                 if( !this.is_runTest ) console.log( this.login.data )
                this.$router.push('/profile');
                this.$bvModal.hide("bv-modal-masuk");
            }else{

                this.wrongPassword = true;
                let _ = this
                let timeAuto = setTimeout(() => {
                    _.wrongPassword = false
                     clearTimeout(timeAuto);
                }, 3000);

            }

        }).catch( ( error ) => {

             if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                 if( !this.is_runTest ) console.log(error) 
        } );

         this.loading_api = false

    },
    async toVerifikasi(mode){
        
        const formData = new URLSearchParams(); 

        if( mode == 'email'){
            formData.append('email', this.input.hpemail)
            this.verifikasiBy = this.input.hpemail
        }else{

           

             if( ! this.phoneIsValid( this.input.hpemail) ){

                     swal({
                        title: this.$t('swal.peringatan'),
                        text:  this.$t('swal.no_telepon_belum_benar') ,
                        icon: '/img/imgjrg/antri.png'
                    });
                    return false;
             }

            formData.append('phone', this.input.hpemail)

            
            this.verifikasiBy = this.input.hpemail
        }   
        this.loading_api = true
        formData.append('is_resetpass', 'Y')
        await axios.post( this.data_url.send_otp + "?" + formData, null, 
        this.dataHeaderNonAuth ).then(res => {            
            console.log(res)
            if( res.data.codestatus == "S" ){
                    this.formLogin = 'pageVerifikasi'
                    this.setCoolDown();
            }

        }).catch( ( error ) => {
                console.log(error) 

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

        } );
        this.loading_api = false
    },

    async toVerifikasiAgain(mode){
        
        const formData = new URLSearchParams(); 

        if( mode == 'email'){
            formData.append('email', this.verifikasiBy)
        }else{
            formData.append('phone', this.verifikasiBy)
        }   

        this.loading_api = true
        formData.append('is_resetpass', 'Y')
        await axios.post( this.data_url.send_otp + "?" + formData, null, 
        this.dataHeaderNonAuth ).then(res => {            
            console.log(res)
            if( res.data.codestatus == "S" ){
                    this.formLogin = 'pageVerifikasi'
                    this.setCoolDown();
            }else{
                swal({
                            title: this.$t('swal.peringatan'),
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
            }

        }).catch( ( error ) => {
                console.log(error) 
                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
        } );

        this.loading_api = false
    },
    async checkValidate(){

        this.loading_api = true
        const formData = new URLSearchParams(); 
        formData.append('otp', this.input.code_verify)
        await axios.post( this.data_url.validateOTP + "?" + formData, null, 
        this.dataHeaderNonAuth ).then(res => {            
            console.log(res)
            if( res.data.resultdata[0].validation == true ){
                this.$router.push('/profile');
                    this.$bvModal.hide('bv-modal-masuk')
            }else{
               
                swal({
                            title: this.$t('swal.peringatan'),
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
            }

        }).catch( ( error ) => {
                console.log(error) 

                if(this.searchWord(error,"Network Error")){
                   swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
            }

        });
        this.loading_api = false

    },
    setCoolDown(){

            let _ = this;
             this.isCoolDown = false
            let countDownDate = new Date(Date.now() + 0.2*60000);

            this.goInterval = setInterval(function() {
                // Dapatkan waktu dan hari ini dalam milisecond
                let now = new Date().getTime();

                // Buat jarak antara waktu sekarang dan yang menjadi hitung mundur
                let distance = countDownDate - now;

                // Kalkulasi waktu dalam bulan, hari, jam, menit dan detik
                // let month = Math.floor(distance / (1000 * 60 * 60 * 24 * 30))
                // let days = Math.floor((distance % (1000 * 60 * 60 * 24 *30)) / (1000 *60 * 60 * 24));
                // let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                _.timeCoolDown.cdMinutes = minutes

                if(seconds < 10){
                    _.timeCoolDown.cdSecond = "0" + String(seconds)
                }else{
                    _.timeCoolDown.cdSecond = seconds
                }

                // Jika hitung mundur selesai, beri keterangan teks
                if (distance < 0) {
                     clearInterval(_.goInterval);
                    _.timeCoolDown.cdMinutes = "0";
                    _.timeCoolDown.cdSecond = "00";
                    _.isCoolDown = true
                
                }
            }, 1000);
    },

     async getLogin(id){
            console.log(id)
            let formData = new URLSearchParams(); 

            formData.append('',  );
            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.$parent.activeLevelOne();
                }
            }).catch( ( error ) => {
                if(this.is_runTest == false){ console.log(error)  }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

    },
    async getCodeOTP(id){
            console.log(id)
            let formData = new URLSearchParams(); 

            this.dataIdBooking.forEach( function (item, index) {
                
                if( item.id == id ){
                    delete this.dataIdBooking[index]
                }else{
                    this.dataIdBooking.push(id)
                }

            })

            formData.append('',  );
            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.$parent.activeLevelOne();
                }
            }).catch( ( error ) => {
                if(this.is_runTest == false){ console.log(error)  }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

    },
  }
};
</script>
<style>

@media (min-width: 320px) { 

     .menutext{ color:#000000; } 
  
}

@media (min-width: 576px) { 
   
   .menutext{ color:#000000; } 
  
}

@media (min-width: 768px) { 
    
   .menutext{ color:#000000; } 
  
}

@media (min-width: 992px) { 

    .menutext{ color:#ffffff; } 
    
}

@media (min-width: 1200px) { 

   .menutext{ color:#ffffff; } 
     .cekpemesanan{
       margin-left: -15px;
   }  
}

@media (min-width: 1400px) { 
  .menutext{ color:#ffffff; } 
   .cekpemesanan{
       margin-left: -15px;
   }  
}

@media (min-width: 1600px) { 
   .menutext{ color:#ffffff; }
   .cekpemesanan{
       margin-left: -15px;
   }  
}


.noHover:hover{
   color:white !important; /* Inherits color property from its parent */
}

.pointer{
    cursor: pointer;
}

.menuc:hover{
    opacity: 0.7;
    color:#ffffff;
}

.cc {
  position: fixed !important;
  top: 25% !important;
  left: 0% !important;
}


</style>
