<template>
    <section id="InputePassenger" class="section section-components pb-5 bg-white" style="background-color:#faf5f5;">
        <div class="container">
            <div class="col-lg-12 font-weight-bold" >
                <h4>{{ $t('paymentMethod.txt_1') }}</h4>
            </div> 

            <div class="row">
                <div :class="'col-lg-4 ' + classmtd">
                    <div class="col-lg-12" v-if="loading_api">
                        <pulse-loader :color="'#f5365c'" style="float-right"></pulse-loader>
                    </div>

                    <b-list-group v-for="( value, index) in this.dataPaymentMethod.payment_methode_type" :key="'a' + index">

                        <b-list-group-item class="pt-2 pb-2" v-if="value.name == 'Virtual Account'" > <img src="img/imgjrg/mp_1.png" /> {{ $t('paymentMethod.txt_2') }}</b-list-group-item>
                            
                        <span class="" v-if="value.name == 'Virtual Account'">  
                            <b-list-group-item v-for="(valuex,indexx) in value.payment_method"  
                            @click="setMethodPayment(valuex)" style="padding-left:60px;" class="pointer pt-2 pb-2 fz-14" :key="'b' + indexx"> 
                                {{ valuex.name }} 
                                <img class="float-right" :src="'img/imgjrg/' + getImgName(valuex.name)" />
                            </b-list-group-item> 
                        </span> 

                         <b-list-group-item  class="pt-2 pb-2" v-if="value.name == 'E-Wallet'"> <img src="img/imgjrg/mp_2.png" /> Instant Payment</b-list-group-item>
                            
                        <span  v-if="value.name == 'E-Wallet'">  
                            <b-list-group-item   v-for="(valuex,indexx) in value.payment_method" style="padding-left:60px;" @click="setMethodPayment(valuex)" class="pointer  pt-2 pb-2 fz-14" :key="'c' + indexx">
                                {{ valuex.name }}  
                                <img class="float-right" :src="'img/imgjrg/' + getImgName(valuex.name)" />
                            </b-list-group-item>
                        </span>
                      
                        <b-list-group-item  class="pt-2 pb-2"  v-if="value.name == 'Retail'"> <img src="img/imgjrg/mp_1.png" /> {{ $t('paymentMethod.txt_3') }}</b-list-group-item>
                             
                        <span v-if="value.name == 'Retail'"> 

                        <b-list-group-item v-for="(valuex,indexx) in value.payment_method" :key="'d' + indexx" 
                        @click="setMethodPayment(valuex)" style="padding-left:60px;" class="pointer pt-2 pb-2 fz-14">
                            {{ valuex.name }}  
                            <img class="float-right" :src="'img/imgjrg/' + getImgName(valuex.name)" />
                        </b-list-group-item>
                        </span>

                        <b-list-group-item  class="pt-2 pb-2" v-if="value.name == 'QRIS'" style="padding-left:30px;"> <img src="img/imgjrg/mp_6.svg" /> QR Code </b-list-group-item>
                             
                        <span v-if="value.name == 'QRIS'"> 

                        <b-list-group-item v-for="(valuex,indexx) in value.payment_method" :key="'d' + indexx" 
                        @click="setMethodPayment(valuex)"  style="padding-left:60px;" class="pointer  pt-2 pb-2 fz-14">
                            {{ valuex.name }}  
                            <img class="float-right" :src="'img/imgjrg/' + getImgName(valuex.name)" />
                        </b-list-group-item>
                        </span>

                        <b-list-group-item  class="pt-2 pb-2" v-if="value.name == 'Cash On Delivery' & is_payment_ticket != true" style="padding-left:30px;"> <img width="24" height="24" src="img/imgjrg/cod.svg" /> Cash On Delivery </b-list-group-item>
                             
                        <span v-if="value.name == 'Cash On Delivery'"> 
                             <b-list-group-item v-for="(valuex,indexx) in value.payment_method" :key="'d' + indexx" 
                            @click="setMethodPayment(valuex)"  style="padding-left:60px;" class="pointer  pt-2 pb-2 fz-14">
                                {{ valuex.name }}  
                                <img class="float-right" height="24" :src="'img/imgjrg/' + getImgName(valuex.name)" />
                            </b-list-group-item>
                        </span>

                    </b-list-group>

                  
                </div>
                <div :class="'col-lg-5 pt-3 ' + classmtd2">

                     <div class="col-lg-12 shadow-sm rounded" v-show="!template_method">
                           <pulse-loader :color="'#ff0000'" style="float-right"></pulse-loader>
                     </div>

                     <div class="col-lg-12"  v-show="loading_api_finish">
                        <pulse-loader :color="'#ff0000'" style="float-right"></pulse-loader>
                    </div>

                    <div class="col-lg-12 shadow-sm rounded" v-show="template_method == true & loading_api_finish == false">

                        <!-- <InstantPayment @delMethodPayment="delMethodPayment" v-if="methodPembayaran.name == 'instantPayment'"></InstantPayment> -->
                        <TransferVirtual 
                            @delMethodPayment="delMethodPayment" 
                            @delMethodPaymentByApi="delMethodPaymentByApi" 
                            @finish="finish" 
                            :dataBank="methodPembayaran" 
                            :orderRekap="orderRekap"
                            :dataPayment="dataPaymentMethod"
                            :is_ticket="is_payment_ticket"
                            v-if="methodPembayaran.psi_m_payment_method_type_name == 'Virtual Account'">
                        </TransferVirtual>

                        <TransferRetail 
                            @delMethodPayment="delMethodPayment" 
                             @delMethodPaymentByApi="delMethodPaymentByApi"
                            @finish="finish" 
                            :orderRekap="orderRekap"
                            :dataBank="methodPembayaran" 
                            :dataPayment="dataPaymentMethod"
                            :is_ticket="is_payment_ticket"
                            v-if="methodPembayaran.psi_m_payment_method_type_name == 'Retail'">
                        </TransferRetail>

                        <Dana 
                            @delMethodPayment="delMethodPayment" 
                             @delMethodPaymentByApi="delMethodPaymentByApi"
                            @finish="finish" 
                            :orderRekap="orderRekap"
                            :dataBank="methodPembayaran" 
                            :dataPayment="dataPaymentMethod"
                            :is_ticket="is_payment_ticket"
                            v-if="methodPembayaran.name == 'DANA'">
                        </Dana>

                        <Ovo 
                            @delMethodPayment="delMethodPayment" 
                             @delMethodPaymentByApi="delMethodPaymentByApi"
                            @finish="finish" 
                            :orderRekap="orderRekap"
                            :dataBank="methodPembayaran" 
                            :dataPayment="dataPaymentMethod"
                            :is_ticket="is_payment_ticket"
                            v-if="methodPembayaran.name == 'OVO'">
                        </Ovo>

                        <LinkAja 
                            @delMethodPayment="delMethodPayment" 
                             @delMethodPaymentByApi="delMethodPaymentByApi"
                            @finish="finish" 
                            :orderRekap="orderRekap"
                            :dataBank="methodPembayaran" 
                            :dataPayment="dataPaymentMethod"
                            :is_ticket="is_payment_ticket"
                            v-if="methodPembayaran.name == 'LinkAja'">
                        </LinkAja>

                        <ShopeePay
                            @delMethodPayment="delMethodPayment" 
                             @delMethodPaymentByApi="delMethodPaymentByApi"
                            @finish="finish" 
                            :orderRekap="orderRekap"
                            :dataBank="methodPembayaran" 
                            :dataPayment="dataPaymentMethod"
                            :is_ticket="is_payment_ticket"
                            v-if="methodPembayaran.name == 'ShopeePay'">
                        </ShopeePay>

                        <Qris
                            @delMethodPayment="delMethodPayment" 
                             @delMethodPaymentByApi="delMethodPaymentByApi"
                            @finish="finish" 
                            :orderRekap="orderRekap"
                            :dataBank="methodPembayaran" 
                            :dataPayment="dataPaymentMethod"
                            :is_ticket="is_payment_ticket"
                            v-if="methodPembayaran.name == 'QRIS'">
                        </Qris>
                        
                        <Cod
                            @delMethodPayment="delMethodPayment" 
                             @delMethodPaymentByApi="delMethodPaymentByApi"
                            @finish="finish" 
                            :orderRekap="orderRekap"
                            :dataBank="methodPembayaran" 
                            :dataPayment="dataPaymentMethod"
                            :is_ticket="is_payment_ticket"
                            v-if="methodPembayaran.name == 'Cash On Delivery'">
                        </Cod>

                        
                    </div>

                    <div v-if="methodPembayaran == 'belumpilih'" class="col-lg-12" style="text-align:center;">
                         <img src="img/imgjrg/methodnull.png" style="max-width:282px; max-height:559px;"/>
                        <div class="fz-14 mt-3">{{ $t('paymentMethod.txt_4') }} </div> 
                        <div class="fz-14">{{ $t('paymentMethod.txt_5') }}</div>
                    </div>
                
                </div>


                <div :class="'col-lg-3 pt-3 ' + classmtd" v-if="is_payment_ticket">
                    <div class="col-lg-12  border border shadow-sm rounded bg-white p-3 font-weight-bold" v-if="methodPembayaran != 'belumpilih'">
                        <div class="fz-14">{{ $t('paymentMethod.txt_6') }} <button class="btn btn-sm btn-secondary ml-3" @click="copyPesanan"><i class="fa fa-clone" aria-hidden="true"></i> {{ $t('paymentMethod.txt_7') }}</button> </div> 
                        <div class="fz-16">{{ this.orderRekap.data_trx[0].kode_booking }}</div>
                        <div class="clearfix"></div>
                    </div> 

                    <div class="col-lg-12  border border shadow-sm rounded bg-white p-3">
                        <p class="fz-16"> {{ $t('paymentMethod.txt_8') }}</p>

                        <p class="fz-14" style="line-height:2; font-weight:400;">
                            <span>{{  orderRekap.data_travel.travel_1.psi_m_kendaraan_name }} </span> <br/>
                            <span class="font-weight-bold">  {{ dayNameFullId(orderRekap.data_pesan[0])  }} </span> <br/>
                            <span>{{ orderRekap.data_pesan[2].name }} - {{  orderRekap.data_pesan[3].name }}</span> <br/>
                            <span>{{  getTimeTravel(orderRekap.data_travel.travel_1.time_from) }} - {{  getTimeTravel(orderRekap.data_travel.travel_1.time_to) }}</span>
                        </p>
                        <p class="fz-14" style="line-height:2; font-weight:400;"  v-if="orderRekap.data_pesan[5] == true">
                            <span>  {{  orderRekap.data_travel.travel_2.psi_m_kendaraan_name }} </span> <br/>
                            <span class="font-weight-bold">{{ dayNameFullId(orderRekap.data_pesan[1])  }} </span> <br/>
                            <span> {{ orderRekap.data_pesan[3].name }}, - {{ orderRekap.data_pesan[2].name }}</span> <br/>
                            <span> {{  getTimeTravel(orderRekap.data_travel.travel_2.time_from) }} - {{  getTimeTravel(orderRekap.data_travel.travel_2.time_to) }}</span>
                        </p>

                        <div class="clearfix"></div>
                    </div> 

                    <div class="col-lg-12 pb-2 pl-2 border border shadow-sm rounded bg-white pt-4 pl-3" v-if="!is_runTestProp" >
                
                            <p class="fz-14"> {{ $t('paymentMethod.txt_9') }}</p>

                            <p class="text-left fz-14"  v-for="(value,index) in orderRekap.data_seat[0].keberangkatan" :key="index" >
                                <span class="font-weight-bold">{{ index + 1 }}.  {{ value.nama_lengkap }}</span>
                            </p>
                           
                            <div class="clearfix"></div>
                    </div>
                </div>


                <div :class="'col-lg-3 pt-3 ' + classmtd" v-if="!is_payment_ticket">
                    <div class="col-lg-12  border border shadow-sm rounded bg-white p-3 font-weight-bold">
                        <div class="fz-14">{{ $t('paymentMethod.txt_6') }} <button class="btn btn-sm btn-secondary ml-3" @click="copyPesanan"><i class="fa fa-clone" aria-hidden="true"></i> {{ $t('paymentMethod.txt_7') }}</button> </div> 
                        <div class="fz-16">{{ orderRekap.data_packet.nomor_resi }}</div>
                        <div class="clearfix"></div>
                    </div> 

                    <div class="col-lg-12  border border shadow-sm rounded bg-white p-3">
                        <p class="avenirMedium fz-14 text-primary"><i class="fa fa-tachometer" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_14_1') }}</p>
                        <p class="fz-13">Jenis : {{ orderRekap.data_packet.paket_type }}</p>
                        <p class="avenirMedium fz-12">{{ orderRekap.data_packet.psi_m_paket_size_name}} 0 - 2 KG <br/> 35 x 24 x 10 cm </p><br/>
                        <p class="avenirMedium fz-14 text-primary"><i class="fa fa-tag" aria-hidden="true"></i> Isi Paket</p>
                        <p class="fz-13">{{ isKosong2(orderRekap.isi_paket) ? '' : orderRekap.isi_paket }} </p>
                        <p class="avenirMedium fz-14 text-primary"><i class="fa fa-sticky-note-o" aria-hidden="true"></i> Catatan</p>
                        <p class="fz-13">  {{ isKosong2( orderRekap.keterangan_paket ) ? '' :  orderRekap.keterangan_paket }} </p>

                        <p class="avenirMedium fz-14 text-primary"><i class="fa fa-arrow-left" aria-hidden="true"></i> Asal :   {{ orderRekap.data_packet.kota_kabupaten_pengirim_name }}</p>
                        <p class="avenirMedium fz-12">{{ orderRekap.data_packet.alamat_pengirim }}, {{ orderRekap.data_packet.kota_kabupaten_pengirim_name }},  {{ orderRekap.data_packet.propinsi_pengirim_name }}<br/>Telp : {{ orderRekap.data_packet.phone_pengirim }}</p><br/>

                        <p class="avenirMedium fz-14 text-primary"><i class="fa fa-arrow-right" aria-hidden="true"></i> Tujuan : {{ orderRekap.data_packet.kota_kabupaten_penerima_name }}</p>
                        <p class="avenirMedium fz-12">{{ orderRekap.data_packet.alamat_penerima }}, {{ orderRekap.data_packet.kota_kabupaten_penerima_name }}, {{ orderRekap.data_packet.propinsi_penerima_name }} <br/>Telp :  {{ orderRekap.data_packet.phone_penerima }}</p><br/>

                    </div> 
                </div>
            </div>
        </div>
    </section>

</template>
<script>

import axios from "axios";
import TransferVirtual from "./choosePaymentMethod_tva";
import TransferRetail from "./choosePaymentMethod_retail";
import Qris from "./choosePaymentMethod_qris";
import Dana from "./choosePaymentMethod_dana";
import LinkAja from "./choosePaymentMethod_linkaja";
import Ovo from "./choosePaymentMethod_ovo";
import Cod from "./choosePaymentMethod_cod";
import ShopeePay from "./choosePaymentMethod_shopee";
import { Helper } from '../../../helper.js';
import { Master } from '../../../model/Master.js';
import { Validate } from '../../../model/Validate.js';
import swal from 'sweetalert';
import Config from "../../../model/Config"
import PulseLoader from "vue-spinner/src/PulseLoader.vue";


export default {
    mixins:[Validate,Master,Helper],
    components:{
        Dana,
        LinkAja,
        ShopeePay,
        Ovo,
        TransferVirtual,
        PulseLoader,
        TransferRetail,
        Qris,
        Cod
    },
    props: {
        is_payment_ticket: {
            type: Boolean,
            default: true
        },

         is_runTestProp:{
            type: Boolean,
            default: false
        },
        data_available_travel:{
            type: Object,
            default: null
        },
        orderRekap:{
            type: Object
        }
    },
    data() {
        return {
            classmtd:"",
            classmtd2:"d-none d-lg-block",

            is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            runTestHeader:null,
            runTest_setDataPayment:null,

            v_identity_order:{
                no_handphone:null,
                email:null
            },
            data_url:Config,
            loading_api_finish:false,
            template_method:true,

             options_use_data_people:[
                { value: null, text: 'Pakai Data Baru' },
                { value: 'a', text: 'Muhammad Miftahul Ulum' },
                { value: 'b', text: 'Budi Darmanto' }
             ],
            
             options_identity_type:[
                { value: null, text: 'KTP' },
                { value: 'a', text: 'SIM' },
                { value: 'b', text: 'KTA' },
                { value: 'b', text: 'Lain Lain' }
             ],

            v_use_data_people:[],
             
            v_identity_id:[],
            v_identity_type:[],
            v_identity_name:[],
            dataPaymentMethod:{
                payment_methode_type:[]
            },

            login:false,

            methodPembayaran:"belumpilih",

        }
    },
    created(){

        if( !this.is_runTestProp ){
            if( this.is_payment_ticket ){

                 this.orderRekap = {
                    data_pesan: this.$store.state.dataPesan,
                    data_trx:this.$store.state.dataTrx,
                    data_trx2:this.$store.state.dataTrxTwo,
                    data_travel:this.$store.state.dataChooseTravel,
                    data_seat:this.$store.state.dataSeatPassenger,
                    data_order:this.$store.state.dataOrderConfirm,
                }
               
            }else{
                this.orderRekap = {
                    data_packet:  this.$store.state.dataPacket
                }
            }

             this.getMethodPayment();
        }

            console.log(this.orderRekap)

          
    },
    methods:{

        copyPesanan(){
            if( this.is_payment_ticket ){
                navigator.clipboard.writeText(  this.orderRekap.data_trx[0].kode_booking );
            }else{
                navigator.clipboard.writeText(  this.orderRekap.data_packet.nomor_resi );
            }
            
        },
        
        async finish(){

            if( localStorage.getItem("login") != null){
                    this.$store.commit('setGoProfile', "penumpang");
                    this.$router.push('/profile');
            }else{   
                    this.$router.push('/cekpemesanan');
            }

        },

        async delMethodPayment(){
            swal({
                title: "Ganti Pembayaran",
                text: "Apakah anda yakin ingin mengganti metode pembayaran ?",
                icon: '/img/imgjrg/error.png',
                buttons: true,
                dangerMode: true,
            })
            .then(async (yes) => {

                if (yes) {
                    
                        this.loading_api = true
                        this.template_method = false

                        let formData = new URLSearchParams(); 
                        formData.append('psi_t_payment_id', this.dataPaymentMethod.id);
                        
                        await axios.post( this.data_url.validateCancelPayment + "?" + formData, null,  this.dataHeaderWithTrx_Token()).then(res => {            
                            console.log(res.data)
                            
                            if( res.data.codestatus == "S" ){
                            this.methodPembayaran = "belumpilih";
                            this.classmtd = "";
                            this.classmtd2 = "d-none d-lg-block";

                            window.scrollTo(0,0);

                            }

                            if(  res.data.codestatus == "TX" ){
                                 swal({
                                    title:this.$t('swal.t2'),
                                    text: "Silahkan mengulang pemesanan !",
                                    icon: '/img/imgjrg/error.png'
                                });
                                this.$parent.nonActiveLevelOne();
                                return false
                            }

                        }).catch( ( error ) => {

                            if(this.searchWord(error,"401")){
                                     swal({
                                        title:this.$t('swal.t2'),
                                        text: this.$t('swal.txt2'),
                                        icon: '/img/imgjrg/error.png'
                                    });
                                    localStorage.removeItem('login');
                                    this.$router.push('/redirect')
                                }

                                if(this.searchWord(error,"Network Error")){
                                   swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                            }
                                
                            console.log(error)
                        });

                        this.loading_api = false
                        this.template_method = true
                }
            });            
        },     

        async delMethodPaymentByApi(){

            this.loading_api = true
            this.template_method = false

            let formData = new URLSearchParams(); 
            formData.append('psi_t_payment_id', this.dataPaymentMethod.id);
            
            await axios.post( this.data_url.validateCancelPayment + "?" + formData, null,  this.dataHeaderWithTrx_Token()).then(res => {            
                console.log(res.data)
                if( res.data.codestatus == "S" ){
                this.methodPembayaran = "belumpilih";

                this.classmtd = "";
                this.classmtd2 = "d-none d-lg-block";

                 window.scrollTo(0,0);
                }

                if(  res.data.codestatus == "TX" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: "Silahkan Mengulang Pesanan !",
                            icon: '/img/imgjrg/error.png'
                        });
                    this.$parent.nonActiveLevelOne();
                    return false
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                         swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                    if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                    
                console.log(error)
            });

            this.loading_api = false
            this.template_method = true
                 
        },        

        getImgName(bank){
            switch (bank) {
                case "BCA VA":
                    return "mp_bca.png";
                case "Mandiri VA":
                     return "mp_mandiri.png";
                case "BNI VA":
                    return "mp_bni.png";
                case "BSI VA":
                    return "mp_bsi.png";
                case "BRI VA":
                    return "mp_bri.png";
                case "Alfamart":
                     return "mp_alfamaret.png";
                case "OVO":
                     return "mp_ovo.png";
                case "DANA":
                     return "mp_dana.png";
                case "LinkAja":
                     return "mp_linkaja.png";
                case "ShopeePay":
                     return "mp_shopee.png";
                case "QRIS":
                     return "qris.png";
                case "Cash On Delivery":
                     return "cod.svg";
            }
        },

        async getMethodPayment(){

            this.loading_api = true
            // alert("as")
            // console.log(this.orderRekap.data_packet);

            let formData = new URLSearchParams(); 
            if( !this.is_runTestProp ){
                 if( this.is_payment_ticket ){
                    formData.append('psi_t_order_id', this.orderRekap.data_trx[0].id);
                 }else{
                    formData.append('psi_t_paket_id', this.orderRekap.data_packet.id);
                 }

                formData.append('lang',  this.$t('locale_lang') )
            }else{
                formData = this.runTestParameter
            }

            let headerSet = null
            if( this.is_runTestProp ){
                console.log("" + formData );
                console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader
            }else{
                
                headerSet = this.dataHeaderWithTrx_Token()
            }   

            console.log( JSON.stringify( headerSet ))
    
            await axios.post( this.data_url.getPSIPaymentInvoice + "?" + formData, null, headerSet ).then(res => {            
                // console.log(res.data)
                if( res.data.codestatus == "S" ){

                    this.runTestResult = true;

                    this.dataPaymentMethod = res.data.resultdata[0]
                    this.runTest_setDataPayment = res.data.resultdata[0]
                }else{
                    console.log(res.data.message)
                }

                if(  res.data.codestatus == "TX" ){
                    swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                    this.$parent.nonActiveLevelOne();
                    return false
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                         swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                     if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                    
                console.log(error)
            });

             this.loading_api = false

            
        },



        setMethodPayment(dataBank){


            if(this.methodPembayaran != "belumpilih" ){
                return false;
            }

            swal({
                title: "Bayar Memakai " + dataBank.name,
                text: "Apakah anda yakin ingin membayar dengan menggunakan metode pembayaran " + dataBank.name,
                icon: '/img/imgjrg/antri.png',
                buttons: true,
                dangerMode: true,
            })
            .then((yes) => {

                if (yes) {
                    this.classmtd = "d-none d-lg-block"
                    this.classmtd2 = "";
                    this.methodPembayaran = dataBank
                }

            });
            
        },

        processInputPassenger(){

            let dataPenumpang = {
                identity_id:this.v_identity_id,
                identity_type:this.v_identity_type,
                identity_name:this.v_identity_name
            }

            this.$store.commit('setDataPenumpang', dataPenumpang);
             this.$parent.activeLevelThree();
        },
        backLevel(){
             this.$parent.activeLevelOne();
        },

      

     

     
    }
};
</script>
<style>

.pointer{
    cursor:pointer;
}

.hover {
  cursor: pointer;
}

#team {
    background: #eee !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#team .card {
    border: none;
    background: #ffffff;
}

.borderradius{
    border-radius: 25px;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #f5365c !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #f5365c !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.bg-headerform{
    background-color:#f5365c;
    color:#fff;
}

.shadow{
    box-shadow: 2px 4px 0 4px yellowgreen, 4px 8px 0 8px pink, 8px 10px 0 12px navy;
}

</style>
