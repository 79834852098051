<template>

    <div class="col-lg-12">

         <b-alert v-if="format.mode == 1" v-model="format.show" :variant="format.variant" dismissible>
            {{ format.text }}
        </b-alert>

    </div>
   
</template>
<script>

export default {
    props:{
        format:Object
    },
};
</script>
