<template>
    <section id="InputePassenger" class="section-components pb-5 pt-5 bg-white">
          <carousel-slider></carousel-slider>

          <center>
         <pulse-loader :color="'#FF0000'" :loading="descManifest == null"></pulse-loader>
         </center>
        <div class="container col-lg-8 mt-4" v-if="descManifest != null">

          <div class="col-lg-12 border shadow text-center pt-3  mb-4" style="border-radius:10px;">

              <div class="col-lg-12 rounded fz-26 avenirHeavy p-3" style="border:1px solid #FF0000; color:#FF0000;" >
                {{ $t('antarPaketDriver.txt_1') }}
              </div>
              <div class="col-lg-12 mt-2 mb-2">
                <DatePicker v-if="!loading_api_base" format="DD-MM-YYYY" v-model="date_manifest_computed"></DatePicker>
                    <pulse-loader :color="'#FF0000'" :loading="loading_api_base"></pulse-loader>
              </div>

               <div class="alert alert-warning" role="alert" v-show="descManifest.nomor_manifest == ''">
               {{ $t('antarPaketDriver.txt_2') }}
              </div>


              <div class="col-lg-12 rounded fz-16 avenirORoman p-3 mb-2" v-show="descManifest.nomor_manifest != ''">
                <span style="color:#B4B4B4;"> <i class="fa fa-check" aria-hidden="true"></i> {{ descManifest.lokasi_awal_name }}</span>
                <span style="color:#B4B4B4;"> &#x2501;&#x2501; </span>
                <span :style="finishJalan ? 'color:#B4B4B4;' : 'color:#0770CC;'"> 
                  <i v-if="!finishJalan" class="numberCircleAP">-</i>  <i  v-if="finishJalan" class="fa fa-check" aria-hidden="true"></i>  {{ descManifest.lokasi_tujuan_name }}</span>
              </div>

              <table class="fz-16 avenirMedium text-left" v-show="descManifest.nomor_manifest != ''">
               
                <tr>
                  <td>{{ $t('antarPaketDriver.txt_3') }}</td>
                  <td>: {{ descManifest.nomor_manifest }} </td>
                </tr>
                 <tr>
                  <td>{{ $t('antarPaketDriver.txt_4') }}</td>
                  <td>:  {{ descManifest.lokasi_awal_name }}</td>
                </tr>
                <tr>
                  <td>{{ $t('antarPaketDriver.txt_5') }}</td>
                  <td>:  {{ descManifest.lokasi_sekarang_id }}</td>
                </tr>
                <tr>
                  <td class="pr-5">{{ $t('antarPaketDriver.txt_6') }}</td>
                  <td>:  {{ descManifest.lokasi_tujuan_name }}</td>
                </tr>
                <tr>
                  <td>{{ $t('antarPaketDriver.txt_7') }}</td>
                  <td>:  {{ descManifest.driver }}</td>
                </tr>

                <tr>
                  <td>{{ $t('antarPaketDriver.txt_8') }}</td>
                  <td id="dppd">: {{ descManifest.tgl_kirim }}
                    <!-- <DatePicker v-if="!loading_api_base" format="DD-MM-YYYY" v-model="date_manifest_computed"></DatePicker>
                    <pulse-loader :color="'#ffffff'" :loading="loading_api_base"></pulse-loader> -->
                  </td>
                </tr>
               
                <tr>
                  <td>{{ $t('antarPaketDriver.txt_9') }}</td>
                  <td>: {{ descManifest.jumlah_manifest }}</td>
                </tr>
              </table>

              <div class="justify-content-center pb-5 mt-4" v-show="descManifest.nomor_manifest != ''">
                  <div class="row"> 
                  <div class="col-lg-6 offset-lg-3">                   

                    <div class="row">
                        <div class="col-lg-12">

                          <div class="alert alert-warning text-left mt-2 mb-2" role="alert" v-if="data_validate.length > 0">
                            <b> {{ $t('inputPassenger.txt_19') }}</b>
                              <ol>
                                  <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                              </ol>                            
                          </div>


                       
                        </div>
                      </div>
                  </div>
                </div>

                <div class="row"> 
                  
                  <div class="col-lg-7 col-md-7 col-sm-7 offset-sm-2 offset-md-2 offset-lg-2 mb-2">
                    <div class="form-group">
                          <multiselect style="width:100%; border:1px solid #8D8D8D;" v-model="v_paket"  select-label="Pilih" deselect-label=""  track-by="psi_t_paket_nomor" label="psi_t_paket_nomor" placeholder="Pilih Paket" :options="options" :searchable="true" :allow-empty="false">
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.psi_t_paket_nomor }}</strong> <span class="float-right" >[{{ option.nama_penerima }}]</span></template>
                          </multiselect>
                    </div>
                  </div>

                  <div class="col-lg-1 mb-2">
          
                        <button class="btn btn-danger m-0 floatCari" :disabled="loading_api" @click="cekResi">
                            <span v-if="!loading_api"> {{ $t('antarPaketDriver.txt_10') }}  </span>
                            <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                        </button>
                    

                        <!-- <input type="text" class="form-control text-center" v-model="dataResi" /> -->
                  </div>
                </div>
              
                <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                <div class="col-lg-12" v-show="!showDetail">
                  <div class="table-responsive">
                      <table class="table table-bordered">
                        <tr style="background-color:#E8E8E8; border:1px solid #D1D1D1;">
                          <th>No</th>
                          <th class="text-left">{{ $t('antarPaketDriver.txt_11') }}</th>
                          <th class="text-left">{{ $t('antarPaketDriver.txt_12') }}</th>
                          <th class="text-left">{{ $t('antarPaketDriver.txt_13') }}</th>
                          <th class="text-left">{{ $t('antarPaketDriver.txt_14') }}</th>
                          <th class="text-left">{{ $t('antarPaketDriver.txt_15') }}</th>
                        </tr>
                        <tr v-for="(v, i) in options" :key="i">
                          <td> {{ i+1 }} </td>
                          <td  class="text-left"><u> <span @click="tampilDetail(v.psi_t_paket_nomor)" class="pointer"> {{ v.psi_t_paket_nomor }}</span></u></td>
                          <td  class="text-left">{{ v.nama_penerima }}</td>
                          <td  class="text-left">{{ v.psi_m_kota_penerima_name }}</td>
                          <td  class="text-left">{{ v.paket_type }}</td>
                          <td  class="text-left">{{ v.status }}</td>
                        </tr>
                      </table>
                   </div>
                </div>



              </div>


              <div class="row text-left pb-3 border rounded p-3 m-2" v-show="showDetail"> 

                <div class="col-lg-12 border-bottom">
                    <p class="avenirHeavy fz-24 text-center" style="color:#FF0000;" >{{ $t('antarPaketDriver.txt_16') }} 
                      <span class="float-right fz-20 pointer" @click="untampilDetail()" style="color:#707070;">x</span>
                    </p>
                </div>
                
                <div class="col-lg-6" >
                  <div class="row">
                  
                <div class="col-lg-12 pt-2 border-bottom">
                   <p class="avenirORoman fz-16 txtb2" >{{ $t('antarPaketDriver.txt_17') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px; color:#FF0000;"> {{ dataPaketDetail.nomor_resi }} </p>
                   <p class="avenirORoman fz-16 txtb2"> </p>

                   <p class="avenirORoman fz-16 txtb2 mt-4" >{{ $t('antarPaketDriver.txt_18') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold" style="margin-top:-10px;"> {{ dataPaketDetail.status }} </p>
                  <p class="avenirORoman fz-16 txtb2"></p>
                </div>

                <div class="col-lg-12 pt-2">
                   <p class="avenirORoman fz-16 txtb2" >{{ $t('antarPaketDriver.txt_19') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;">  {{ dataPaketDetail.nama_pengirim }} </p>
                   <p class="avenirORoman fz-16 txtb2">  {{ dataPaketDetail.alamat_pengirim }}. <br/> Kab : {{ dataPaketDetail.kota_kabupaten_pengirim_name }}, Provinsi :  {{ dataPaketDetail.propinsi_pengirim_name }}, <br/>{{ $t('antarPaketDriver.txt_20') }} :  {{ dataPaketDetail.kodepos_pengirim }} </p>

                   <p class="avenirORoman fz-16 txtb2 mt-4" >{{ $t('antarPaketDriver.txt_21') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold" style="margin-top:-10px;">  {{ dataPaketDetail.nama_penerima }} </p>
                  <p class="avenirORoman fz-16 txtb2">  {{ dataPaketDetail.alamat_penerima }}. <br/> {{ $t('antarPaketDriver.txt_22') }} : {{ dataPaketDetail.kota_kabupaten_penerima_name }}, {{ $t('antarPaketDriver.txt_23') }} :  {{ dataPaketDetail.propinsi_penerima_name }}, <br/>{{ $t('antarPaketDriver.txt_20') }} :  {{ dataPaketDetail.kodepos_penerima }}</p>
                </div>

                 <div class="col-lg-12 border-bottom mt-4">
                   <p class="avenirMedium fz-16" >{{ $t('antarPaketDriver.txt_24') }}</p>
                </div>

                 <div class="col-lg-12 pt-2">
                   <p class="avenirORoman fz-16 txtb2">{{ $t('antarPaketDriver.txt_25') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;">  {{ dataPaketDetail.isi_paket }} </p>
                   <p class="avenirORoman fz-16 txtb2">{{ $t('antarPaketDriver.txt_26') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;"> {{ dataPaketDetail.psi_m_paket_size_panjang }}x{{ dataPaketDetail.psi_m_paket_size_lebar }}x{{ dataPaketDetail.psi_m_paket_size_tinggi }} </p>
                   <p class="avenirORoman fz-16 txtb2">{{ $t('antarPaketDriver.txt_27') }}</p>
                   <p class="avenirORoman fz-16 font-weight-bold"  style="margin-top:-10px;"> {{ dataPaketDetail.keterangan_paket }}  </p>
                </div>           
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-lg-12">
                      <button :disabled="loading_api" @click="lacakpaket(dataPaketDetail.nomor_resi)" class="btn btn-outline-danger float-right">
                        <span v-if="!loading_api"> {{ $t('antarPaketDriver.txt_28') }} </span>
                        <pulse-loader :color="'#FF0000'" :loading="loading_api"></pulse-loader>
                      </button>
                    </div>
                  </div>
                </div>

                 <b-modal id="bv-modal-lacak" hide-header hide-footer
                            size="lg"
                            body-classes="p-0"
                            modal-classes="modal-dialog-centered">

                   <div class="row" style="padding-bottom:0px; margin-top:10px; background-image: url('img/imgjrg/patern.png');">
                      <div class="col-lg-12">
                        
                        <div class="col-lg-12 border-bottom mb-5">
                            <p class="avenirHeavy fz-24 text-center" style="color:#FF0000;" >{{ $t('antarPaketDriver.txt_29') }}
                            </p>
                        </div>

                          <div class="row" v-for="(v, i) in dataTrackingHeader" :key="i">
                      <div class="col-lg-5 text-center mb-4 avenirMedium">
                          {{ dayNameFullId(v) }}
                          
                      </div>
                      <div class="col-lg-6 pb-5">  
                        
                        <div class="col-lg-12" v-if="i == 0">
                          <div style="width:50px; height:100%; float:left;">
                            <span class="dot1"><i class="fa fa-check" aria-hidden="true"></i></span>
                          </div>
                          <div class="avenirLight" style="float:left; padding-left:50px; width:90%; height:70px;">
                            <span class="colorTLDate"> {{ splitTitikDua(splitSpace(dataTracking[0].tanggal,1),0) }}:{{ splitTitikDua(splitSpace(dataTracking[0].tanggal,1),1) }} </span> <br/>
                            {{ dataTracking[0].description }} <br/>
                          </div>
                          <div class="clearfix"></div>
                        </div>

                        <div class="col-lg-12" v-if="i > 0">

                          <div style="width:50px; height:100%; float:left;">
                            <span class="dotNonActive"><i class="fa fa-check" aria-hidden="true"></i></span>
                          </div>

                          <div class="avenirLight" style="float:left; padding-left:50px; width:90%; height:70px;">
                              <span class="colorTLDate">{{ splitTitikDua(splitSpace(dataTracking[dataTrackingNo[i]].tanggal,1),0) }}:{{ splitTitikDua(splitSpace(dataTracking[dataTrackingNo[i]].tanggal,1),1) }}</span><br/>
                            {{ dataTracking[dataTrackingNo[i]].description }} <br/>
                          </div>

                          <div class="clearfix"></div> 

                        </div>
                    
                        <div  class="col-lg-12" v-for="(value,index) in dataTracking" :key="index">
                           <div v-if="v == splitSpace(value.tanggal,0) & value.is_head == false">
                            <div style="width:50px; height:100%; float:left; margin-top:-6px; position: relative;">
                              <div class="vericalline"></div>
                              <div class="dotNonActive"><i class="fa fa-check" aria-hidden="true"></i></div>
                            </div>
                            <div class="avenirLight widthLoopTL" style="float:left; margin-top:26px; height:70px; word-wrap: break-word;">
                               <span class="colorTLDate"> {{ splitTitikDua(splitSpace(value.tanggal,1),0) }}:{{ splitTitikDua(splitSpace(value.tanggal,1),1) }}  </span><br/>
                              {{ value.description }} <br/>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div> 
                        
                      </div>
                  </div>
                      </div>
          </div>

                 </b-modal>
              
                             
              </div>


          </div>    

        </div>
    </section>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import '../assets/cssVendor/vue-multiselect.min.css';
import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Multiselect from 'vue-multiselect'
import Config from "../model/Config"
import swal from 'sweetalert';
import CarouselSlider from "./Carousel"
// import DatePickers from './components/JavascriptComponents/DatePickers.vue';


export default {
  mixins:[Helper,Master,Validate],
  name: "components",
  components: {
    PulseLoader,
    multiselect: Multiselect,
    CarouselSlider,
    DatePicker,
    // DatePickers
  },
  data() {
        return {

          items: [
            { no: 1, resi: 'ASFAWEF453', penerima: 'Macdonald', kota_penerima: 'Gresik', tipe:'Paket' , status : 'On Going' },
            { no: 2, resi: 'AASDQRS234', penerima: 'Ulum', kota_penerima: 'Lumajang', tipe:'Dokument' , status : 'On Going' },
            { no: 3, resi: 'MNQMW4B345', penerima: 'Rifdi', kota_penerima: 'Lamongan', tipe:'Paket' , status : 'On Going' },
            { no: 4, resi: 'NBJDFY776D', penerima: 'Amin', kota_penerima: 'Semarang', tipe:'Paket' , status : 'On Going' }
          ],


          alasan:"",
          date_sended:"",
          penerima:"",
          catatan:"",
          img: null,
          camera: null,
          deviceId: null,
          devices: [],
          finishJalan:false,
          showCamera:true,
          tanggalDefault: new Date,
          dataPaketDetail:[],
          loading_api_base:false,
          v_paket:null,
          showDetail:false,
          descManifest:null,
          date_manifest:null,

           login:{
            status:false,
            data:null
          },

           is_runTest:false,
          runTestResult:false,
          runTestParameter:"",
          runTestHeader:null,
          token_re:null,
          data_validate:[],
          jenis_upload:"foto",

           options:  [],

            data_url:Config,
            dataResi:null,
            dataTracking:[],
            dataTrackingNo:[],
            dataTrackingHeader:[],
        }
  },
  computed: {
      device: function() {
          return this.devices.find(n => n.deviceId === this.deviceId);
      },

    jenis_upload_computed:{
      get(){
        return this.jenis_upload
      },
      async set(v){

        this.jenis_upload = v;

      }
    },

    date_manifest_computed:{
      get(){
        return this.date_manifest
      },
      async set(v){
        console.log(this.dateParse(v))
        this.date_manifest = v;
        this.getDataPaketDriver(this.dateParse(v));
      }
    },

  },
  watch: {
      camera: function(id) {
          this.deviceId = id;
      },
      devices: function() {
          // Once we have a list select the first one
          const [first, ...tail] = this.devices;
          if (first) {
              console.log(tail)
              this.camera = first.deviceId;
              this.deviceId = first.deviceId;
          }
      }
  },
  created(){

    if( localStorage.getItem("login") == null){
      this.$router.push("/")
    }

  this.getDataPaketDriver();

  },
  methods: {

        undo() {
          this.$refs.signaturePad.undoSignature();
        },

        save() {
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

          console.log(isEmpty);
          console.log(data);
        },
  

        onTry(){
            this.$refs.webcam.start();

            this.showCamera = true;
        },

       onCapture() {
            this.img = this.$refs.webcam.capture();

            console.log(this.img)

            this.$refs.webcam.stop();
            this.showCamera = false;
        },

        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
            console.log("On Cameras Event", cameras);
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
            console.log("On Camera Change Event", deviceId);
        },

      onVerify(response){
        console.log('Verify: ' + response)
        this.token_re = response;
      },

      cekResi(){

         this.data_validate = [];

        if( this.isKosong2( this.v_paket ) ){
           
            this.data_validate.push("Pilih Nomor Resi");
         
            return false
        }

        this.cekResiLoad(this.v_paket.psi_t_paket_nomor);
        
      },

      async cekResiLoad(v){
          this.loading_api = true

         let formData = new URLSearchParams(); 
         formData.append('nomor_resi', v);

          await axios.post( this.data_url.cekPaketDetail + "?" + formData, null, this.dataHeaderWithTrx_Token(3)).then(res => {            
                if( res.data.codestatus == "S" ){

                  if( res.data.resultdata.length > 0 ){

                    console.log(res.data.resultdata);
                    this.dataPaketDetail = res.data.resultdata[0]
                    this.showDetail = true;

                  }else{
                         swal({
                            title: this.$t('swal.informasi'),
                            text: "Maaf, Data paket tidak ditemukan",
                            icon: '/img/imgjrg/error.png'
                        });
                  }

                }
          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
      },

      async lacakpaket(v){

        this.data_validate = []

        this.loading_api = true

        let formData = new URLSearchParams(); 

        formData.append('nomor_resi', v);


          await axios.post( this.data_url.getTrackingPaket + "?" + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){

                  if( res.data.resultdata.length > 0 ){

                      this.runTestResult = true
                      this.dataTracking = res.data.resultdata[0].tracking
                      this.dataTracking.sort( (a,b) => (a.id < b.id) ? 1 : -1 )

                       let head1 = ""
                      let a = ""
                      for (let aaa = 0; aaa < this.dataTracking.length; aaa++) {
                        a = this.dataTracking[aaa].tanggal
                        a = a.split(" ")
                        if( head1 != a[0] ){
                          head1 =  a[0]
                          this.dataTracking[aaa].is_head = true
                        }else{
                           this.dataTracking[aaa].is_head = false
                        } 
                      }


                      let dateTrac = ""
                      for (let index = 0; index < this.dataTracking.length; index++) {
                        let dataFlotSplit = this.splitSpace(this.dataTracking[index].tanggal)
                        if( dataFlotSplit[0] != dateTrac ){
                            dateTrac = dataFlotSplit[0];
                            this.dataTrackingNo.push(index)
                            this.dataTrackingHeader.push(dateTrac);
                        }
                        console.log(this.dataTrackingHeader);
                      }

                      this.$bvModal.show("bv-modal-lacak");

                  }else{
                         swal({
                            title: this.$t('swal.informasi'),
                            text: "Maaf, Data Cek Resi Tidak Ada",
                            icon: '/img/imgjrg/error.png'
                        });
                  }

                }
          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
          
      },

      async tampilDetail(v){
        console.log(v)
        this.showDetail = true;

          this.cekResiLoad(v);
      },

      async untampilDetail(){
        this.showDetail = false;
      },

      async saveRetur(){

        this.loading_api = true

         let formData = new URLSearchParams(); 
         formData.append('nomor_resi', this.dataPaketDetail[0].nomor_resi);
          formData.append('reason',  this.alasan )

          await axios.post( this.data_url.setPaketReturn + "?" + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){
                      this.getDataPaketDriver();

                      swal({
                            title: this.$t('swal.informasi'),
                            text: "Retur Berhasil Di Rekam",
                            icon: '/img/imgjrg/error.png'
                        });
                      
                      this.dataPaketDetail = [];

                
                }
          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
        
      },

      async saveKonfirmasi(){

        this.loading_api = true

        let dataJSON = {
          "nomor_resi":this.dataPaketDetail[0].nomor_resi,
          "penerima":this.penerima
        }

       

        let file = null

        if( this.jenis_upload == 'foto' ){
          file = this.dataURIToBlob( this.img );
        }else{

          try {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

                console.log(isEmpty);
                file = this.dataURIToBlob( data );
          } catch (error) {
                file = null
                console.log(error)
          } 
        
        }

        

        let formData = new FormData(); 
        formData.append('json', JSON.stringify(dataJSON) );
        formData.append('file', file, "gambar_" + this.random(5) + ".jpg");

          await axios.post( this.data_url.setPaketReceivedMultipartFormType, formData, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){
                      this.getDataPaketDriver();
                 
                      swal({
                            title: this.$t('swal.informasi'),
                            text: "Konfirmasi Pesanan Berhasil Di Rekam",
                            icon: '/img/imgjrg/error.png'
                        });
                      
                       this.dataPaketDetail = [];
               
                }
          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false
        
      },

      async getDataPaketDriver(date = null){

         let formData = ""
        if(date != null){

          formData = new URLSearchParams(); 

          formData.append('tanggal_from', date);
          formData.append('tanggal_to', date);

          formData = "?" + formData;

        }

         this.loading_api_base = true

          await axios.post( this.data_url.getPSIListPaketforDriver + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){

                  if( res.data.resultdata.length > 0 ){

                    console.log(res.data.resultdata);
                    this.options = res.data.resultdata[0].paket
                    this.descManifest = res.data.resultdata[0]
                    this.date_manifest = new Date( this.splitDate(this.descManifest.tgl_kirim, 0 ) )

                    if( this.descManifest.lokasi_sekarang_id == this.descManifest.lokasi_tujuan_id ){
                      this.finishJalan = true
                    }

                    this.untampilDetail();

                  }else{
                        this.options = []
                        this.descManifest = {
                          nomor_manifest:"",
                          lokasi_tujuan_name:"",
                          lokasi_sekarang_id:"",
                          lokasi_awal_name:"",
                          driver:"",
                          jumlah_manifest:""
                        }

                        this.untampilDetail();
                        

                        if( date == null ){
                           this.date_manifest = new Date()
                        }else{  
                            this.date_manifest = new Date(date)
                        }

                  }

                }

             
                
          }).catch( ( error ) => {
              console.log(error)

               if( error.response.data.codestatus == "E" ){
         
                  if(this.searchWord(error.response.data.message, "Token")){
                      localStorage.removeItem('login');
                      this.$router.push('/redirect')
                  }

                }

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

           this.loading_api_base = false

          
      },
      showResiTracking(){
         this.$refs['bv_modal_cek_resi'].show();
      }
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}




p.small {
  line-height: 0.7;
}

p.big {
  line-height: 1.8;
}

.txtb2{
  color:#3B3B3B;
}

.numberCircleAP {
    border-radius: 50%;
    padding: 2px 5px 2px;
    background: #0770CC;
    color: #ffffff;
    text-align: center;

    font: 12px Arial, sans-serif !important;
}

#dppd > div > div > input[name="date"] {
  height:30px !important;
}

@media (min-width: 320px) { 

  .floatCari{
    float:right;
  }

  .floatSimpan{
    float:right;
  }

  .lacakSizeTemplate{
    width:90%; 
    height:70px;
  }
   
}

@media (min-width: 576px) { 

    .floatCari{
        float:left;
    }

    .floatSimpan{
        float:left;
    }

  .lacakSizeTemplate{
    width:80%; 
    height:70px;
  }
  
}

@media (min-width: 768px) { 

}

@media (min-width: 992px) { 
    
}
@media (min-width: 1200px) { 
    
}

@media (min-width: 1400px) {   

  
}

@media (min-width: 1600px) { 
 
}


</style>
