<template>
    <section id="travelSearch" class="section section-components pb-5" style="background-color:#ffffff;">
        <div class="container">
            <div class="row justify-content-center">

                <div class="col-lg-12">
                     <div class="row">
                        <div class="col-lg-6 col-12 pt-2 infoarah infoarah1 ">

                            <div class="row" v-if="!showChooseGo">
                                <div class="col-lg-12 fz-24 mb-2"> {{ data_pesan[2].name }} <img src="img/imgjrg/sync.svg" />   {{ data_pesan[3].name }}</div>
                                <div class="col-lg-12"> 
                                    <div class="mr-5 float-left fz-16">{{ dayNameFullId(data_pesan[0])  }}</div>  
                                    <div class="ml-2 fz-16"> {{ data_pesan[4].value }}  {{ $t('search_bus.txt_penumpang_dewasa') }}  </div> 
                                </div>
                            </div>

                            <div class="row" v-if="showChooseGo">
                                <div class="col-lg-12 fz-24 mb-2"> {{ data_pesan[3].name }} <img src="img/imgjrg/sync.svg" />   {{ data_pesan[2].name }}</div>
                                <div class="col-lg-12"> 
                                    <div class="mr-5 float-left fz-16">{{ dayNameFullId(data_pesan[1])  }}</div>  
                                    <div class="ml-2 fz-16"> {{ data_pesan[4].value }}  {{ $t('search_bus.txt_penumpang_dewasa') }} </div> 
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-6 col pt-2 infoarah infoarah2">
                        <a href="#searchAgain" v-smooth-scroll><button :class="c_btn_pencarian + ' avenirMedium fz-18 bgDefaultMerah btn-sm'" @click="backHome"><i class="fa fa-search"></i>  {{ $t('search_bus.txt_ubah_pencarian') }}</button></a>
                        </div>
                     </div>
                </div>

                 <div class="col-lg-12 border shadow-sm p-3 mb-1 bg-white rounded mt-5" v-if="f_show_input">
                     <div class="text-left" style="color:#42b3f5; font-size:18px; float-left" > {{ $t('search_bus.txt_tiket_bus_dan_travel') }} </div>

                            <div class="row">
                            <div class="col-lg-12 col">
                               <div class="row">
                                        <div class="col-lg-3 col-12 text-left">
                                            <div class="form-group text-danger">
                                                <label class="avenirMedium fz-14"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i> Dari</label>
                                                
                                                <multiselect style="width:100%;" class="border-bottom" v-model="v_source"  select-label="Pilih" deselect-label=""  track-by="name" label="name" placeholder="Pilih Kota Asal" :options="options" :searchable="true" :allow-empty="false" group-values="libs" group-label="language" >
                                                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                                </multiselect>

                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-12 text-left text-danger">
                                                <div class="form-group">
                                                    <label class="avenirMedium fz-14">Ke <i class="fa fa-arrow-circle-left" aria-hidden="true"></i></label>

                                                    <multiselect class="border-bottom" v-model="v_destination" select-label="Pilih" deselect-label=""  track-by="name" label="name" placeholder="Pilih Kota Tujuan" :options="options" :searchable="true" :allow-empty="false" group-values="libs" group-label="language" >
                                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                                    </multiselect>
                                                </div>
                                        </div>
                            
                                        <div class="col-lg-2 col text-left text-danger">
                                            <div class="form-group">
                                                <label class="avenirMedium fz-14"><i class="fa fa-calendar-o"></i> Berangkat</label>
                                                <datetime style="width:100%;" format="DD-MM-YYYY" :disabled-date="(date) => stopDateYesterday(date)"  :lang="lang" v-model="date_go" type="date" input-class="form-control"></datetime>
                                            </div>
                                        </div>

                                        <div class="col-lg-2 col text-right text-danger">

                                                <div class="form-group">
                                                    <label class="avenirMedium fz-14">Penumpang <i class="fa fa-user" aria-hidden="true"></i></label>
                                                    <multiselect select-label="Pilih" class="border-bottom" v-model="v_seat" deselect-label="" track-by="name" label="name" placeholder="Dewasa" :options="options_seat" :searchable="false" :allow-empty="false">
                                                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                                    </multiselect>
                                                </div>

                                        </div>

                                        <div class="col-lg-2 col text-right text-danger">
                                            <div class="form-group">
                                                    <label for="exampleInputEmail1">.</label>
                                                    <multiselect select-label="Pilih" class="border-bottom" v-model="v_seat_baby" deselect-label="" track-by="name" label="name" placeholder="Bayi ?" :options="options_baby" :searchable="false" :allow-empty="false">
                                                        <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong> </template>
                                                    </multiselect>
                                                </div>
                                        </div>
                                        </div>
                            </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8 float-left pt-3">
                                    <span style="font-size:14px;">Silahkan <b>Masuk</b> Atau <b>Daftar</b>, Untuk Mendapatkan Transaksi Yang Lebih Nyaman</span>
                                </div>
                                <div class="col-lg-4">
                                    <div class="btn-wrapper float-right">
                                        <base-button tag="a"
                                                    class="mb-3 mb-sm-0 btn-danger text-white"
                                                    type="info"
                                                    href="#order-flow" v-smooth-scroll
                                                    @click="searchTravelNow"
                                                    icon="fa fa-search">
                                            Cari
                                        </base-button>
                                    </div>
                                </div>
                            </div>
                 </div>

                <div class="col-lg-12 mt-10 mb-5 pt-5 pb-5" v-if="f_jadwal_travel == false">
                    <div class="row mt-10 mb-5 pt-5 pb-5">
                        <div class="col-lg-12 pt-3 pb-3 font-weight-bold">
                             <center>
                                 <img class="mt-2" src="img/imgjrg/not_find.svg" style="height: 100px; margin-top: -3px">
                                <br/>
                                <br/>
                                <span class="text-danger clearfix avenirHeavy fz-20" > {{ $t('search_bus.txt_jadwal_tidak_tersedia') }} </span> <br/>
                                <span class="avenirMedium fz-16"> {{ $t('search_bus.txt_jadwal_tidak_tersedia_des') }}  </span> 
                             </center>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-12 mt-5 mb-5">
                    <div class="row justify-content-center">
                        <div class="col-lg-2" style="padding:0px;">
                            <button class="btn_date" > SENIN <br/> 21 Maret 2020 </button>
                        </div>
                        <div class="col-lg-2" style="padding:0px;">
                            <button class="btn_date" > SENIN <br/> 21 Maret 2020 </button>
                        </div>
                        <div class="col-lg-2" style="padding:0px;">
                            <button class="btn_date" > SENIN <br/> 21 Maret 2020 </button>
                        </div>
                        <div class="col-lg-2" style="padding:0px;">
                            <button class="btn_date" > SENIN <br/> 21 Maret 2020 </button>
                        </div>
                        <div class="col-lg-2"  style="padding:0px;">
                            <button class="btn_date" > SENIN <br/> 21 Maret 2020 </button>
                        </div>
                    </div>
                </div> -->

                <div class="col-lg-12 mt-5" v-if="f_jadwal_travel">
                    <div class="row">
                         <div class="col-lg-3"> <span class="font-weight-bold avenirHeavy fz-20"> {{ $t('search_bus.txt_filter') }} </span>
                              <div class="accordion" role="tablist">
                                <b-card no-body class="mb-1">
                                    <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
                                        <b-button block v-b-toggle.accordion-1 class="bg-white avenirHeavy fz-14" variant="">{{ $t('search_bus.txt_waktu_berangkat') }}</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                        <b-card-body class="avenirORoman fz-14">

                                        <b-card-text>{{ $t('search_bus.txt_pagi') }} (00:00 - 12:00) <input class="float-right" type="checkbox" id="pagi" value="0000,1200" v-model="v_filterJam_computed" ></b-card-text>

                                        <b-card-text>{{ $t('search_bus.txt_sore') }} (12:01 - 18:00) <input class="float-right" type="checkbox" id="sore" value="1201,1800" v-model="v_filterJam_computed" ></b-card-text>

                                        <b-card-text>{{ $t('search_bus.txt_malam') }} (18:01 - 23:59) <input class="float-right" type="checkbox" id="malam" value="1801,2359" v-model="v_filterJam_computed"></b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>


                                <b-card no-body class="mb-1">
                                    <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
                                        <b-button block v-b-toggle.accordion-4 class="bg-white avenirHeavy fz-14" variant="">{{ $t('search_bus.txt_armada') }}</b-button>
                                    </b-card-header>
                                    <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                        <b-card-body class="avenirORoman fz-14">
                                            <b-card-text v-for="( armada , index ) in dataArmada" :key="index" > 
                                                {{ armada.name }} 
                                                <input style="margin-top:8px;" class="float-right" type="checkbox" :id="armada.id" :value="armada.id" v-model="v_filterArmada_computed">
                                            </b-card-text>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>

                     

                            </div>
                         </div>
                        <div class="col-lg-9">

                                <div class="row" v-if="showChooseGo">
                                     <div class="col-lg-6 mb-2 avenirHeavy fz-16">
                                        {{ $t('search_bus.txt_keberangkatan_pilihan') }}
                                    </div>
                                </div>

                                 <div class="row border shadow-sm p-3 mb-4 bg-white" style="border-radius:10px;" v-if="showChooseGo" >
                                    <div class="col-lg-3 col-12 col-md-3 pt-3 pb-3 d-md-block avenirHeavy fz-16">
                                        <i class="fa fa-bus" aria-hidden="true"></i> <span class="capitalize" > {{ data_set_travel.travel_1.psi_m_tipe_kendaraan_jenis }} </span> <br/> <span class="avenirMedium fz-14"> {{ data_set_travel.travel_1.psi_m_kendaraan_name }} </span>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-4 col-sm-3 pt-3 pb-3">
                                        <div class=" mb-2 text-capitalize avenirHeavy fz-16"> {{ getTimeTravel(data_set_travel.travel_1.time_from) }} </div>
                                        <div class="avenirMedium fz-14"> {{ data_set_travel.travel_1.location_from_name }}</div>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-4 col-sm-3 pt-3">
                                        <center> <img src="img/imgjrg/panah.png" width="100" /> </center>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-4 col-sm-3 pt-3 pb-3 pl- text-right">
                                        <div class=" mb-2 text-capitalize avenirHeavy fz-16"> {{ getTimeTravel(data_set_travel.travel_1.time_to) }} </div>
                                        <div class="avenirMedium fz-14"> {{ data_set_travel.travel_1.location_to_name }}</div>
                                    </div>
                                    <!-- <div class="col-lg-2 col-md-2 col-8 d-none col-sm-3 pt-3 pb-3 pl-5 text-right">
                                        <div class="avenirORoman fz-16"> {{ data_set_travel.travel_1.range }}</div>
                                    </div> -->
                                    <div class="col-lg-3 col-md-3 col-4 col-sm-2 pt-3 pb-3 pl-5">
                                        <div class="font-weight-bold mb-2 text-capitalize"> <span style="color:#ff0000;">{{ convertToRupiah(data_set_travel.travel_1.harga) }}</span> /  {{ $t('search_bus.txt_kursi') }}</div>
                                         <div class="avenirMedium fz-14">{{ $t('search_bus.txt_tersedia') }} <span style="color:#ff0000;"> {{ data_set_travel.travel_1.available_kursi }} </span> {{ $t('search_bus.txt_kursi') }} </div>
                                    </div>
                                    <div class="col-lg-1 col-md-3 col-6 col-sm-2 pt-3 pb-3 pl-1">
                                        <div><a href="#top" v-smooth-scroll><button :disabled="loading_api" class="btn btn-secondary btn-sm avenirMedium fz-18 " @click="batalOrder">
                                            <span v-if="!loading_api">{{ $t('search_bus.txt_cancel') }}</span>
                                            <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                        </button></a></div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 mb-2 avenirHeavy fz-16">
                                        {{ $t('search_bus.txt_pilih_jadwal') }} {{ $t('locale') == "id" ? data_showed.text : data_showed.text_en }}
                                    </div>

                                </div>
                                <div class="row border shadow-sm p-3 mb-1 bg-white" style="border-radius:10px;" v-for="(travel, index) in data_showed.travelFilter" :key="index">
                                    <div class="col-lg-3 col-md-2 col-sm-2 col-12 pt-3 pb-3 d-md-block avenirHeavy fz-16">
                                         <span class="capitalize">  {{ travel.psi_m_tipe_kendaraan_jenis }} </span> <br/> <span class="avenirMedium fz-14"> {{ travel.psi_m_kendaraan_name }} </span>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-4 col-sm-3 pt-3 pb-3">
                                        <div class=" mb-2 text-capitalize avenirHeavy fz-16"> {{ getTimeTravel(travel.time_from) }} </div>
                                        <div class="avenirMedium fz-14"> {{ travel.location_from_name }}</div>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-4 col-sm-2 pt-3">
                                        <center> <img src="img/imgjrg/panah.png" width="100" /> </center>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-4 col-sm-2 pt-3 pb-3 pl-5 text-right">
                                        <div class=" mb-2 text-capitalize avenirHeavy fz-16"> {{ getTimeTravel(travel.time_to) }} </div>
                                        <div class="avenirMedium fz-14"> {{ travel.location_to_name }}</div>
                                    </div>
                                    <!-- <div class="col-lg-2 col-md-2 d-none col-sm-2 pt-3 pb-3 pl-5 text-right">
                                        <div class="avenirORoman fz-16"> {{ travel.range }}</div>
                                    </div> -->
                                    <div class="col-lg-3 col-md-2 col-8 col-sm-2 pt-3 pb-3 pl-5">
                                        <div class="font-weight-bold mb-2 text-capitalize"> <span style="color:#ff0000;">{{ convertToRupiah(travel.harga) }}</span> / {{ $t('search_bus.txt_kursi') }}</div>
                                        <div class="avenirMedium fz-14">{{ $t('search_bus.txt_tersedia') }} <span style="color:#ff0000; font-size:16px; font-weight:bold;"> {{ travel.available_kursi }} </span>{{ $t('search_bus.txt_kursi') }} </div>
                                    </div>
                                    <div class="col-lg-1 col-md-2 col-4 col-sm-2 pt-3 pb-3 pl-1">
                                        <div  v-if="travel.is_available"><a href="#top" v-smooth-scroll>
                                            <button @click="processSearchBus(travel)" :disabled="loading_api" class="bgDefaultMerah btn btn-danger btn-sm avenirMedium fz-18">
                                            <span v-if="!loading_api">{{ $t('search_bus.txt_pilih') }}</span>
                                            <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                            </button></a>
                                        </div>
                                        <div  v-else>
                                            <button disabled class="btn btn-secondary btn-sm avenirMedium fz-18">
                                            <span>{{ $t('search_bus.txt_habis') }}</span>
                                            </button>
                                        </div>     
                                    </div>
                                </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import DatePicker from 'vue2-datepicker';
import '../../../assets/cssVendor/vue-datePicker.css';
import '../../../assets/cssVendor/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect' 
import swal from 'sweetalert';

import axios from "axios";
import Config from "../../../model/Config"
import { Helper } from '../../../helper.js';
import { Master } from '../../../model/Master.js'
import { Validate } from '../../../model/Validate.js';

export default {
    props:{
        is_runTestProp:{
            type: Boolean,
            default: false
        },
        data_available_travel:{
            type: Object,
            default: null
        },

        data_pesan:{
            type: Array,
        }
    },
    mixins:[Helper,Master,Validate],
    components: {
        datetime: DatePicker,
        multiselect: Multiselect,
        PulseLoader
    },
    data() {
        return {

            is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            setDataChooseTravel_1:null,

            showChooseGo:false,
            f_jadwal_travel:true,
            // data_pesan:[],

            data_set_travel:{
                travel_1:null,
                travel_2:null,
            },

            dataArmada:null,
            v_dataFilterArmada:[],
            v_filterJam:[],
            setDataTrx:null,
            setDataTrx2:null,
            is_loncat_2:false,


            data_showed:{
                text:"Keberangkatan",
                text_en:"Departure",
                travel:null,
                travelFilter:null
            },

            c_btn_pencarian:"btn btn-danger",
            // data_available_travel:null,
            f_show_input:false,
            data_url:Config,
            date_go: null,

            v_source: null,
            v_destination: null,
            v_seat: null,
            v_seat_baby: null,
            v_weight:null,
            v_dimension:null,

            options_dimension: [
                { name: '< 20x20x20', language: 'JavaScript' },
                { name: '< 50x50x50', language: 'Ruby' },
                { name: '< 100x100x100', language: 'Elixir' },
                { name: '> 100x100x100', language: 'Elixir' }
            ],
            options_seat: [
                { name: '1 Dewasa', language: 'JavaScript' },
                { name: '2 Dewasa', language: 'Ruby' },
                { name: '3 Dewasa', language: 'Elixir' }
            ],
            options_baby: [
                { name: '1 Bayi', language: 'JavaScript' },
                { name: '2 Bayi', language: 'Ruby' },
                { name: '3 Bayi', language: 'Elixir' }
            ],
            options_sort: [
                { name: 'Waktu Keberangkatan', language: 'JavaScript' },
                { name: 'Harga', language: 'Ruby' }
            ],
            options:  [
                {
                    language: 'Surabaya',
                    libs: [
                        { name: 'Sidoarjo', category: 'Front-end' },
                        { name: 'Surabaya Bungur', category: 'Backend' }
                    ]
                },
                {
                    language: 'Jakarta',
                    libs: [
                        { name: 'Tanah Abang', category: 'Backend' },
                        { name: 'Monas', category: 'Backend' }
                    ]
                },
                {
                    language: 'Jogja',
                    libs: [
                        { name: 'Malioboro', category: 'Backend' },
                        { name: 'Candi Prambanan', category: 'Backend' }
                    ]
                }
            ],
            lang: {
                formatLocale: {
                    // MMM
                    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
                    // dd
                    weekdaysMin: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                },
            },
            disabledDates: {
                to: new Date(Date.now() - 8640000)
            }
        }
    },
    created(){
        this.processGetData();   
        this.getArmada();

        if( !this.is_runTestProp ){
            this.$store.commit('setDataTrx', null);
            this.$store.commit('setDataTrxTWo', null);
            this.$store.commit('setDataChooseTravel', null);
            this.$store.commit('setDataSeatPassenger', null);
            this.$store.commit('setDataOrderConfirm', null);
        }
       

    },
    computed:{
        v_filterArmada_computed:{
            get(){
                return this.v_dataFilterArmada
            },
            set(newVal){
                this.v_dataFilterArmada = newVal;
                let _ = this
                // console.log( this.v_dataFilterArmada )

                if( _.v_dataFilterArmada.length == 0 & _.v_filterJam.length == 0 ){

                     this.data_showed.travelFilter = this.data_showed.travel

                }else{

                    let time_from = null
                    let time_from_filter = null

                    let a = false
                    let b = false

                    this.data_showed.travelFilter = this.data_showed.travel.filter(function (el) {

                         a = false
                         b = false


                        for (let i = 0; i < _.v_dataFilterArmada.length; i++) {    
                            if( el.psi_m_tipe_kendaraan_id == _.v_dataFilterArmada[i] ){
                                a = true
                            }     
                        }

                        for (let i = 0; i < _.v_filterJam.length; i++) {    
                            time_from = el.time_from.replace(":", "");
                            time_from = time_from.split(":");
                            time_from = time_from[0];

                            // console.log(time_from)
                            time_from_filter = _.v_filterJam[i].split(",");

                            if( time_from >= parseInt(time_from_filter[0]) & time_from <= parseInt(time_from_filter[1]) ){
                                b = true
                            }

                        }

                        if( a == true & b == true || a == true & _.v_filterJam.length == 0 || b == true & _.v_dataFilterArmada.length == 0 ){
                            return true
                        }
                    
                    });

                }
                
               

            }
        },
        v_filterJam_computed:{
        get(){
            return this.v_filterJam
        },
        set(newVal){
            this.v_filterJam = newVal;
            let _ = this            

            if( _.v_filterJam.length == 0 & _.v_dataFilterArmada.length == 0){

                 this.data_showed.travelFilter = this.data_showed.travel

            }else{

                let time_from = null
                let time_from_filter = null
                
                let a = false
                let b = false

                this.data_showed.travelFilter = this.data_showed.travel.filter(function (el) {

                     a = false
                     b = false

                    for (let i = 0; i < _.v_filterJam.length; i++) {    
                        time_from = el.time_from.replace(":", "");
                        time_from = time_from.split(":");
                        time_from = time_from[0];

                        // console.log(time_from)
                        time_from_filter = _.v_filterJam[i].split(",");

                        if( time_from >= parseInt(time_from_filter[0]) & time_from <= parseInt(time_from_filter[1]) ){
                            a = true
                        }

                    }

                    for (let i = 0; i < _.v_dataFilterArmada.length; i++) {    
                        if( el.psi_m_tipe_kendaraan_id == _.v_dataFilterArmada[i] ){
                            b = true
                        }     
                    }

                   if( a == true & b == true || b == true & _.v_filterJam.length == 0 || a == true & _.v_dataFilterArmada.length == 0 ){
                        return true
                    }
                
                });

            }
            
            

        }
    }
    },
    methods:{

       

        async batalOrder(){
            
            this.loading_api = true

            await axios.post( this.data_url.cancelPSIOrder, null, this.dataHeaderWithTrx_Token(1) ).then(res => {            
                if( res.data.codestatus == "S" ){

                    this.data_set_travel.travel_1 = null
                    this.showChooseGo = false
                    this.data_showed.text = "Keberangkatan";
                    this.data_showed.text_en = "Departure";

        

                    this.data_showed.travel = this.data_available_travel.jadwal_keberangkatan

                    this.data_showed.travelFilter = this.data_available_travel.jadwal_keberangkatan
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                    swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                // console.log(error)
            });

             this.loading_api = false
           



        },
        searchTravelNow(){

        },
        backHome(){
             this.$parent.nonActiveLevelOne();
             
        },

        sortingAvailable(data){

            let data_ava = []
            let data_nv = []

            console.log(data);
            for (let i = 0; i < data.length; i++) {
                if( data[i].is_available ){
                    data_ava.push( data[i] )
                }else{
                     data_nv.push( data[i] )
                }
            }

            return this.sortingTime(data_ava.concat(data_nv));

        },

        sortingTime(data){

            console.log(data);
            let temp = "";
            let a,b="";
            for (let i = 0; i < data.length; i++) {
                for(let ii = 0; ii < data.length; ii++){

                    a = data[i].time_from.split(":")
                    b = data[ii].time_from.split(":")

                    if( Number(a[0] + a[1]) < Number(b[0] + b[1]) ){

                        temp = data[i]
                        data[i] = data[ii]
                        data[ii] = temp

                    }
                   
                }
            }

            return data

        },

        processGetData(){
            
            if( !this.is_runTestProp ){ 
                this.data_available_travel = this.$store.state.dataResultTravel
                 this.data_pesan = this.$store.state.dataPesan
                // console.log( this.data_available_travel.jadwal_keberangkatan.length ); 
            }

            if(this.data_available_travel.jadwal_keberangkatan.length > 0){
                this.f_jadwal_travel = true
            }else{
                this.f_jadwal_travel = false
            }

            this.data_available_travel.jadwal_keberangkatan = this.sortingAvailable( this.data_available_travel.jadwal_keberangkatan)

           
            this.data_showed.travel = this.data_available_travel.jadwal_keberangkatan
            this.data_showed.travelFilter = this.data_available_travel.jadwal_keberangkatan
        },

        async getArmada(){

              await axios.post( this.data_url.getPSITipeKendaraan, null, this.dataHeaderNonAuth).then(res => {            
                    if( res.data.codestatus == "S" ){
                        this.runTestResult = true;
                        this.dataArmada = res.data.resultdata 
                    }
                }).catch( ( error ) => {

                    if(this.searchWord(error,"401")){
                       swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                    // console.log(error)
                });
        },
        
        async processSearchBus(jadwal){

                this.loading_api = true

                if( this.data_set_travel.travel_1 == null & this.is_loncat_2 == false){

                    let formData = new URLSearchParams(); 
                   
                     if(!this.is_runTest){
                        formData.append('psi_t_jadwal_id', jadwal.jadwal_id );
                        formData.append('jumlah_penumpang', this.data_pesan[4].value );
                        formData.append('location_from_id',this.data_pesan[2].id );
                        formData.append('location_to_id', this.data_pesan[3].id );
                        formData.append('lang', this.$t('locale_lang') )
                     }else{
                        formData = this.runTestParameter;
                     }

                    await axios.post( this.data_url.setNewPSIOrder + "?" + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                        if( res.data.codestatus == "S" ){

                            this.runTestResult = true;
                            this.setDataTrx = res.data.resultdata;
                            this.setDataChooseTravel_1 = jadwal
                            

                            this.data_set_travel.travel_1 = jadwal

                            if(!this.is_runTest) { this.$store.commit('setDataTrx', res.data.resultdata); }

                            if(  this.data_pesan[5] == false  ){
                                
                                if(!this.is_runTest) { this.$store.commit('setDataChooseTravel', this.data_set_travel); }
                                this.$parent.activeLevelTwo();

                            }else{

                                this.showChooseGo = true
                                this.data_showed.text = "Kepulangan";
                                this.data_showed.text_en = "Come Home";

                                this.data_available_travel.jadwal_kepulangan = this.sortingAvailable( this.data_available_travel.jadwal_kepulangan);


                                this.data_showed.travel = this.data_available_travel.jadwal_kepulangan
                                this.data_showed.travelFilter = this.data_available_travel.jadwal_kepulangan

                            }

                        }

                        if( res.data.codestatus == "X" ){
                             swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                            localStorage.removeItem('login');
                            this.$router.push('/redirect')
                        }

                        if(  res.data.codestatus == "TX" ){
                            swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                             
                            this.$parent.nonActiveLevelOne();
                        }

                    }).catch( ( error ) => {

                          try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }
                        
                      if(this.searchWord(error,"Network Error")){
                               swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                        }

                        if(this.searchWord(error,"401")){
                          swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                            localStorage.removeItem('login');
                            this.$router.push('/redirect')
                        }

                        // console.log(error)
                    });

                }else{
                    this.data_set_travel.travel_2 = jadwal

                    let formData = new URLSearchParams(); 

                     if(!this.is_runTest){

                        try { formData.append('psi_t_order_id', this.$store.state.dataTrx[0].id  ); } catch (error) { "as" }
                        formData.append('psi_t_jadwal_id', jadwal.jadwal_id );
                        formData.append('jumlah_penumpang', this.data_pesan[4].value );
                        formData.append('location_from_id',this.data_pesan[3].id );
                        formData.append('location_to_id', this.data_pesan[2].id );
                        formData.append('lang',  this.$t('locale_lang') )
                     }else{
                        formData = this.runTestParameter;
                     }

                    await axios.post( this.data_url.setNewPSIOrderPulang + "?" + formData, null, this.dataHeaderWithTrx_Token() ).then(res => {            
                        if( res.data.codestatus == "S" ){
                            this.setDataTrx2 = res.data.resultdata
                            this.runTestResult = true;
                            this.$store.commit('setDataTrxTWo', res.data.resultdata);

                            this.$store.commit('setDataChooseTravel', this.data_set_travel);
                            this.$parent.activeLevelTwo();                  

                        }

                        if(  res.data.codestatus == "X" ){
                            swal({
                                title: this.$t('swal.informasi'),
                                text: this.$t('swal.txt2'),
                                icon: '/img/imgjrg/error.png'
                            });
                            localStorage.removeItem('login');
                            this.$router.push('/redirect')
                        }

                        if(  res.data.codestatus == "TX" ){
                              swal({
                                title: this.$t('swal.informasi'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                             this.$parent.nonActiveLevelOne();
                        }
                        
                    }).catch( ( error ) => {

                       if(this.searchWord(error,"Network Error")){
                               swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                        }
                        
                        if(this.searchWord(error,"401")){
                             swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                            localStorage.removeItem('login');
                            this.$router.push('/redirect')
                        }


                        // console.log(error)
                    });
                    
                }

                  this.loading_api = false
        },

        showInput(){
            if(this.f_show_input == false){
                this.c_btn_pencarian = "btn btn-default"
                this.f_show_input = true
            }else{
                this.c_btn_pencarian = "btn btn-danger"
                this.f_show_input = false
            }
        }
    }
};
</script>
<style>

.hover {
  cursor: pointer;
}

.btn_date:hover{
    background-color:#e2f2ff ;
    cursor: pointer;
}
.btn_date{
     background-color: white ;
     padding:20px;
     border:1px solid rgb(241, 241, 241) ;
     width: 100%;
     height: 100%;
}
.btn_date:active{
     background-color: white ;
}
.btn_date:focus{
    border-bottom: 5px solid rgb(0, 17, 255) ;
    background-color: #e2f2ff ;
    outline: none;
     
}
.card-header{
    background-color:#f0f0f0 !important;
}

.btn {
     text-transform: none !important;
}

@media (min-width: 320px) { 

    .infoarah{ text-align: center; }

}

@media (min-width: 576px) { 

    .infoarah{ text-align: center; }
  
}

@media (min-width: 768px) { 
    .infoarah{ text-align: center; }
}

@media (min-width: 992px) { 
    .infoarah{ text-align: center; }
}

@media (min-width: 1200px) { 
    .infoarah1{ text-align: left; }
    .infoarah2{ text-align: right; }
}

@media (min-width: 1400px) { 
    .infoarah1{ text-align: left; }
    .infoarah2{ text-align: right; }
}

@media (min-width: 1600px) { 
    .infoarah1{ text-align: left; }
    .infoarah2{ text-align: right; }
}

</style>
