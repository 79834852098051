<template>
  <div>

      <div class="col-lg-12  bg-white" style="border-radius:20px; border:1px solid #B4B4B4;">
          <div class="row">

             <div class="col-lg-4 p-3" style="background: #FFE3E2; border-radius:20px;">
                 <p class="avenirHeavy fz-16 text-primary">Poin Aktif</p>
                 <p><span class="avenirORoman fz-31 text-primary">0</span> <br/>
                 <span class="avenirORoman fz-16">( Senilai Rp 0)</span></p>
                 <p  class="avenirHeavy fz-14">+0 Point Belum Aktif</p>
             </div>

             <div class="col-lg-8 p-3">
                 <div class="col-lg-12 avenirORoman fz-16">
                     Progress Poin Saya <span class="float-right">Detail</span>
                 </div>
                 
                    <div class="row">
                        <div class="col-lg-3 pt-3 avenirORoman fz-16">
                            <div class="numberCircle mb-3">.</div>
                            200 Lagi
                        </div>

                        <div class="col-lg-3 pt-3 avenirORoman fz-16">
                            <div class="numberCircle mb-3">.</div>
                            500 Lagi
                        </div>

                        <div class="col-lg-3 pt-3 avenirORoman fz-16">
                            <div class="numberCircle mb-3">.</div>
                            700 Lagi
                        </div>

                        <div class="col-lg-3 pt-3 avenirORoman fz-16">
                            <div class="numberCircle mb-3">.</div>
                            1000 Lagi
                        </div>

                    </div>
             </div>
          </div>
      </div>
     
  </div>
</template>
<script>


export default {
  name: "components",
  components: {
    
  },
  data() {
        return {
            
        }
  },
  methods: {
    
  }
};
</script>
<style>


.numberCircle {
    width: 70px;
    height: 70px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    font-size: 32px;
    border: 2px solid #FFE3E2;
}


</style>
