export const Master = {
    data: () => ({
        loading_api:false,
        dataHeaderNonAuth:{
            headers:{
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
        },
        dataHeaderNonAuthJson:{
            headers:{
                "Accept": "application/json",
                "Content-Type": "text/html"
            }
        },
        dataHeaderWithAuth:{
            headers:{
                "JRGTM-Token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMywiY29tcGFueV9pZCI6WzFdLCJjdXJfY29tcGFueV9pZCI6MSwiY3JlYXRlX2RhdGUiOiIyMDIxLTAzLTI3IDAwOjQxOjMwLjE3MDk0MSJ9.21uhq1BBh_U3FGdLs07lCLArFIGKMAkMUWI9HkH2hG4",
                "Accept": "application/json",
                "Content-Type": "text/html"
            }
        }
        
    }),
    methods:{


        getNameImg(data){
            switch (data) {
                case "BCA VA":
                    return "mp_bca.png";
                case "Mandiri VA":
                        return "mp_mandiri.png";
                case "BNI VA":
                    return "mp_bni.png";
                case "BSI VA":
                    return "mp_bsi.png";
                case "BRI VA":
                    return "mp_bri.png";
                case "Alfamart":
                        return "mp_alfamaret.png";
                case "OVO":
                        return "mp_ovo.png";
                case "DANA":
                        return "mp_dana.png";
                case "LinkAja":
                        return "mp_linkaja.png";
                case "ShopeePay":
                        return "mp_shopee.png";
                case "QRIS":
                        return "qris.png";
                case "Cash On Delivery":
                        return "cod.svg";
            }
        },

        getName(data){
            switch (data) {
                case "Alfamart":
                    return 'Alfamart';
                case "Indomaret":
                      return 'Indomaret';
                case "BCA VA":
                    return 'BCA VIRTUAL ACCOUNT';
                case "Mandiri VA":
                    return 'MANDII VIRTUAL ACCOUNT';
                case "BNI VA":
                    return 'BNI VIRTUAL ACCOUNT';
                case "BSI VA":
                    return 'BSI VIRTUAL ACCOUNT';
                case "BRI VA":
                    return 'BRI VIRTUAL ACCOUNT';
                case "OVO":
                    return "OVO";
                case "DANA":
                    return "Redirect Pembayaran";
                case "LinkAja":
                    return "Redirect Pembayaran";
                case "ShopeePay":
                    return "Shopee Pay";
                case "QRIS":
                    return "QRIS"
                case "Cash On Delivery":
                    return "Cash On Delivery";
            }
        },

        getNameVirtual(data){
            switch (data) {
                case "Alfamart":
                    return '';
                case "Indomaret":
                      return '';
                case "BCA VA":
                    return 'VIRTUAL Account';
                case "Mandiri VA":
                    return 'VIRTUAL Account';
                case "BNI VA":
                    return 'VIRTUAL Account';
                case "BSI VA":
                    return 'VIRTUAL Account';
                case "BRI VA":
                    return 'VIRTUAL Account';
                case "OVO":
                    return "Ewallet OVO";
                case "DANA":
                    return "DANA";
                case "LinkAja":
                    return "LINK AJA";
                case "ShopeePay":
                    return "Shopee Pay";
                case "QRIS":
                    return "QRIS"
            }
        },

        getNameNoVirtual(data){
            switch (data) {
                case "Alfamart":
                    return 'Kode Pembayaran';
                case "Indomaret":
                      return 'Kode Pembayaran';
                case "BCA VA":
                    return 'Nomor VIRTUAL Account';
                case "Mandiri VA":
                    return 'Nomor VIRTUAL Account';
                case "BNI VA":
                    return 'Nomor VIRTUAL Account';
                case "BSI VA":
                    return 'Nomor VIRTUAL Account';
                case "BRI VA":
                    return 'Nomor VIRTUAL Account';
                case "OVO":
                    return "OVO";
                case "DANA":
                    return "DANA";
                case "LinkAja":
                    return "LINK AJA";
                case "ShopeePay":
                    return "Shopee Pay";
                case "QRIS":
                    return "QRIS"
            }
        },
        
        dataHeaderWithTrx_Token(type = 1){

            let jrg = null
            try {
                jrg = JSON.parse(localStorage.getItem("login"));
            } catch (error) {
                jrg = null;
            }

            if(type == 1){

                try {
                    
                    let trx = this.$store.state.dataTrx[0].trx_token;

                    if( jrg == null){
                        return {
                            headers:{
                                "Accept": "application/json",
                                "Content-Type": "text/html",
                                "Trx-Token": trx
                            }
                        }
                    }else{
                        return {
                            headers:{
                                "Accept": "application/json",
                                "Content-Type": "text/html",
                                "Trx-Token": trx,
                                "JRGTM-Token": jrg.token
                            }
                        }
                    }

                } catch (error) {

                    return {
                        headers:{
                            "Accept": "application/json",
                            "Content-Type": "text/html",
                        }
                    }
                        
                }

            }

            if(type == 2){

                if( jrg == null ){

                    return {
                        headers:{
                            "Accept": "application/json",
                            "Content-Type": "text/html"
                        }
                    }

                }else{

                    return {
                        headers:{
                            "Accept": "application/json",
                            "Content-Type": "text/html",
                            "JRGTM-Token": jrg.token
                        }
                    }

                }

               
                

            }

            if(type == 3){

                return {
                    headers:{
                        "Accept": "application/json",
                        "Content-Type": "text/html"
                    }
                }
            }

            if(type == 4){

                let trx = this.$store.state.dataTrx[0].trx_token;

                if( jrg == null){
                    return {
                        headers:{
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Trx-Token": trx
                        }
                    }
                }else{
                    return {
                        headers:{
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Trx-Token": trx,
                            "JRGTM-Token": jrg.token
                        }
                    }
                }

            }

            if(type == 5){

                let trx = this.$store.state.dataTrx[0].trx_token;

                return {
                        headers:{
                            "Accept": "application/json",
                            "Content-Type": "text/html",
                            "Trx-Token": trx
                        }
                    }
                
                    

            }

          
        }
    }
}