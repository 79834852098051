<template>
    <section id="InputePassenger" class="section-components pb-5 pt-5 bg-white" >
         <carousel-slider></carousel-slider>


        <div class="container mt-4">
         
          <div class="col-lg-12 text-center pt-3 pl-0 pr-0" >
              <P class="avenirHeavy fz-20" style="color:#ff0000;"> {{ $t('cekOngkir.text_1') }}</P>
              <P class="avenirMedium fz-16">{{ $t('cekOngkir.text_2') }}</P>


              <div class="row text-left pt-3 justify-content-center">

                <div class="col-lg-3 mr-2 p-2">
                  <div class="form-group">
                        <label class="avenirORoman fz-16">{{ $t('cekOngkir.text_3') }}</label>
                          <multiselect style="width:100%;" class="border" v-model="v_source_computed"  select-label="Pilih" deselect-label=""  track-by="name" label="name" placeholder="Pilih Kota Asal" :options="options" :searchable="true" :allow-empty="false">
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                          </multiselect>
                       
                  </div>
                </div>

                <div class="col-lg-3 mr-2 p-2">
                    <div class="form-group">
                        <label class="avenirORoman fz-16">{{ $t('cekOngkir.text_4') }}</label>
                          <multiselect style="width:100%;" class="border" v-model="v_destination_computed"  select-label="Pilih" deselect-label=""  track-by="name" label="name" placeholder="Pilih Kota Asal" :options="options" :searchable="true" :allow-empty="false" >
                              <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                          </multiselect>
                  </div>
                </div>
                <div class="col-lg-3 mr-2 p-2" v-if="!ukuran">
                    <div class="form-group">
                        <label class="avenirORoman fz-16">{{ $t('cekOngkir.text_5') }}</label>
                        <b-form-select class="hitamc2Child"  v-model="v_jenis_paket_computed" :options="options_type_packet"></b-form-select>
                    </div>
                </div>
              </div>

              <div class="col-lg-12 text-left mt-4 ">
                  <div class="col-lg-12 p-0 col-md-12 mt-3" style="margin-bottom:50px; display:table;" :key="keyUkuran">
                    <p class="avenirORoman fz-16 text-center">{{ $t('cekOngkir.text_7') }} : </p>
                      <div class="row justify-content-center" :key="refreshUkuran">
                            <div class="clearfix"></div>
                            <div  v-for="(value,index) in dataPaketSize" @click="pilihPaket(value)" :class="'pointer p-3 float-left mr-2 mt-2 ' + ( value.id == dataPilihPaket ? 'dipilihPaket' : '' ) " style="position:relative; border-radius:10px; border:1px solid #8D8D8D; width:300px;" :key="index">
                                <img id="g1paket" style="display:none;" src="img/imgjrg/boxactive.svg" height="100" class="float-left mr-3" />
                                <img id="g2paket" src="img/imgjrg/box.svg" height="100" class="float-left mr-3" />
                                <p id="namePaket" class="avenirHeavy fz-16">{{ value.name }} </p>
                                <img width="25" height="25" id="g3paket" style="display:none; position:absolute; right:10px; top:10px;" src="img/imgjrg/centangmerah.png" class="float-left mr-3" />
                                <p class="avenirORoman fz-14">{{ value.berat_min }} - {{ value.berat_max }} kg <br/> {{ value.panjang }} x  {{ value.lebar }} x  {{ value.tinggi }} {{  value.uom_volume_name }}</p>
                            </div>
                      </div>
                  </div>
                  
                   <div class="clearfix"></div>
                  <div class="col-lg-12 p-0 text-center">
                    <!-- <button @click="recaptcha">Execute recaptcha</button> -->
                    <div style="width:304px; height:76px; margin:auto;" v-if="!login.status">
                      <vue-recaptcha @verify="onVerify" sitekey="6Ld9IAgbAAAAAHuEXTHcXUmDy4t_29GiTlqhxidF" :loadRecaptchaScript="true"></vue-recaptcha>
                    </div>

                    <div class="alert alert-warning text-left mt-2 mb-2" role="alert" v-if="data_validate.length > 0">
                      <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                        <ol>
                            <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                        </ol>                            
                    </div>

                    <button :disabled="loading_api" class="btn btn-danger" @click="cekOngkir"> 
                      <span v-if="!loading_api">{{ $t('cekOngkir.text_8') }} </span>
                      <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                    </button>
                    
                  </div>  
              </div>
          </div>
   


        </div>


          <div class="col-lg-12 mt-5" v-if="dataBiaya > 0">
           
            <div class="row" style="margin-top:100px;">
                <div class="col-lg-4 offset-lg-4  border shadow pt-2">
                  
                  <p class="avenirHeavy fz-22 text-center"  style="color:#ff0000;">{{ $t('cekOngkir.text_9') }}</p>
                  <div class="col-lg-12 avenirORoman fz-16">
                    <p>{{ $t('cekOngkir.text_10') }}<span class="float-right">{{ convertToRupiah(dataBiaya) }}</span></p>
                    <p>{{ $t('cekOngkir.text_11') }}<span class="float-right">{{ convertToRupiah(dataBiayaKirimKeAlamat) }}</span></p>
                  </div>

                </div>
            
            </div>
          </div>


         

        <!-- </div> -->
    </section>
</template>
<script>

import '../assets/cssVendor/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect'
import swal from 'sweetalert';
import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import CarouselSlider from "./Carousel"
import VueRecaptcha from 'vue-recaptcha';

export default {
   mixins:[Helper,Master,Validate],
  name: "components",
  components: {
    CarouselSlider,
    // Badge,
    // DownloadSection,
    multiselect: Multiselect,
    PulseLoader,
     VueRecaptcha
  },
  created(){
    this.getLocation();
    this.getPaketSize()
    
    if( localStorage.getItem("login") == null){
        this.login.status = false
        this.login.data = null
    }else{
        this.login.status = true
    }
    
  
  },

  computed:{
      v_source_computed:{
        get(){
          return this.v_source
        },set(newVal){
          this.v_source = newVal
          this.dataBiaya = 0
        }
      },
       v_destination_computed:{
        get(){
          return this.v_destination
        },set(newVal){
          this.v_destination = newVal
          this.dataBiaya = 0

        }
      },

      v_jenis_paket_computed:{
          get(){
              return this.v_jenis_paket
          },
          set(newVal){

              this.dataPilihPaket = null;
              this.dataBiaya = 0

              this.v_jenis_paket = newVal;

              if( newVal == 1 ){
                  this.dataPaketSize = []
                  for (let index = 0; index < this.dataPaketSizeAsli.length; index++) {
                    if(  this.dataPaketSizeAsli[index].type == "paket" ){
                        this.dataPaketSize.push(this.dataPaketSizeAsli[index]);
                    }
                  }

              }

              if( newVal == 2 ){
                this.dataPaketSize = []
                  for (let index = 0; index < this.dataPaketSizeAsli.length; index++) {
                    if(  this.dataPaketSizeAsli[index].type == "dokumen" ){
                        this.dataPaketSize.push(this.dataPaketSizeAsli[index]);
                    }
                  }
                
              }

              this.keyUkuran += 1;

          }
      }
    },

  data() {
        return {
          login:{
            status:false,
            data:null
          },

          data_validate:[],
          v_jenis_paket:1,
          dataPaketSizeAsli:null,

          is_runTest:false,
          runTestResult:false,
          runTestParameter:"",
          runTestHeader:null,
          token_re:null,

          keyUkuran:1,
          ukuran:false,
          data_url:Config,
          v_source: null,
          v_destination: null,
          v_weight:0,

          dataPilihPaket:null,
          refreshUkuran:0,

          dataBiaya:0,
          dataBiayaKirimKeAlamat:0,

           tipeUkuran1:"",
           tipeUkuran2:"",
           tipeUkuran3:"",
          
          options:  [
              {
                  language: 'Surabaya',
                  libs: [
                      { name: 'Sidoarjo', category: 'Front-end' },
                      { name: 'Surabaya Bungur', category: 'Backend' }
                  ]
              },
              {
                  language: 'Jakarta',
                  libs: [
                      { name: 'Tanah Abang', category: 'Backend' },
                      { name: 'Monas', category: 'Backend' }
                  ]
              },
              {
                  language: 'Jogja',
                  libs: [
                      { name: 'Malioboro', category: 'Backend' },
                      { name: 'Candi Prambanan', category: 'Backend' }
                  ]
              }
          ],
          slide:null,
          tipeUkuran:[],

           options_type_packet:[
                { value: 1, text: 'Paket' },
                { value: 2, text: 'Dokumen' }
          ],

        }
  },
  methods: {

   async cekOngkir(){

     this.data_validate = [];

     this.dataBiaya = 0;

        if( this.v_source == null ||  this.v_source == "" ){
               this.data_validate.push("Asal (Dari) Harus Di Isi")
        }

        if( this.v_destination == null ||  this.v_destination == "" ){
               this.data_validate.push("Tujuan (Ke) Harus Di Isi")
        }

      
        if( this.dataPilihPaket == null ||  this.dataPilihPaket == "" ){
            this.data_validate.push("Ukuran Harus Di Pilih")
        }

        if(!this.login.status){
                if( this.token_re == null ||  this.token_re == "" ){
                      this.data_validate.push("Captcha Harus Di Isikan")
                }
        }

        if( this.data_validate.length > 0 ){
            return false
        }


        this.loading_api = true

        let formData = new URLSearchParams(); 

        if(!this.is_runTest){

            formData.append( 'kota_asal_id', this.v_source.id );
            formData.append( 'kota_penerima_id',  this.v_destination.id );
            
            if(!this.login.status){
              formData.append('recaptcha_token', this.token_re);
            }
            
            if( this.dataPilihPaket ){
              formData.append( 'psi_m_paket_size_id', this.dataPilihPaket );
            }

        }else{

            formData = this.runTestParameter;

        }
          await axios.post( this.data_url.cekPaketHarga + "?" + formData, null, this.dataHeaderWithTrx_Token(2)).then(res => {            
                if( res.data.codestatus == "S" ){

                       this.runTestResult = true
                      if( res.data.resultdata.length > 0 ){
                        if(!this.is_runTest){ console.log(res.data.resultdata) }
                        this.dataBiaya = res.data.resultdata[0].harga
                        this.dataBiayaKirimKeAlamat = res.data.resultdata[0].harga + res.data.resultdata[0].harga_kirim_alamat

                         if( !this.login.status ){
                            this.login.status = true
                            let _ = this
                            
                            setTimeout(() => {
                               _.login.status = false
                            }, 5);
                         }
                         
                      }else{
                           swal({
                              title: this.$t('swal.informasi'),
                              text: "Maaf, Data Harga Untuk Rute Tersebut, Belum Tersedia",
                              icon: '/img/imgjrg/antri.png'
                          });
                      }

                }else{
                 
                   swal({
                            title: "Info Server",
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }
          }).catch( ( error ) => {
              console.log(error)

                try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });
          this.token_re = null
          this.loading_api = false
    },

    pilihPaket(value){
      this.dataBiaya = 0  
      this.dataPilihPaket = value.id
      this.refreshUkuran += 1;

    },

    async getPaketSize(){

        this.loading_api = true

        await axios.post( this.data_url.getPSIPaketSize, null, this.dataHeaderNonAuth).then(res => {            
              if( res.data.codestatus == "S" ){
                      this.runTestResult = true
                      this.dataPaketSize = [];
                      this.dataPaketSizeAsli = res.data.resultdata;

                      for (let index = 0; index < this.dataPaketSizeAsli.length; index++) {
                        if(  this.dataPaketSizeAsli[index].type == "paket" ){
                           this.dataPaketSize.push(this.dataPaketSizeAsli[index]);
                        }
                      }

                      
              }
        }).catch( ( error ) => {
            console.log(error)

              if(this.searchWord(error,"Network Error")){
                   swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
            }
            
        });

        this.loading_api = false

    },

    tambahWeight(){

      this.v_weight = parseInt(this.v_weight) + 1
    },
    kurangWeight(){
      if(this.v_weight > 0){
        this.v_weight = parseInt(this.v_weight) - 1
      }
      
    },
    setUkuran(tipe){

      switch (tipe) {
        case 1:
          this.tipeUkuran1 = "ukuranAktif"
          this.tipeUkuran2 = ""
          this.tipeUkuran3 = ""
          break;
        case 2: 
           this.tipeUkuran1 = ""
          this.tipeUkuran2 = "ukuranAktif"
          this.tipeUkuran3 = ""
          break;
        case 3:
           this.tipeUkuran1 = ""
          this.tipeUkuran2 = ""
          this.tipeUkuran3 = "ukuranAktif"
          break;
      
        default:
          break;

      }
    },

       onVerify(response){
        console.log('Verify: ' + response)
        this.token_re = response;
      },

      async getLocation(){

            await axios.post( this.data_url.getPSIKota, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                  this.runTestResult = true
                  if(!this.is_runTest){ console.log(res.data.resultdata); }
                  this.options = res.data.resultdata;
                }
            }).catch( ( error ) => {
                console.log(error)

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });
      },

      showResiTracking(){
         this.$refs['bv_modal_cek_resi'].show();
      }
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}


.ukuranAktif{
  background-color:#FFE3E2;
}



@media (min-width: 320px) { 
}

@media (min-width: 576px) { 

}

@media (min-width: 768px) { 
   
}

@media (min-width: 992px) { 
   
}

@media (min-width: 1200px) { 
     
}

@media (min-width: 1400px) { 
}

@media (min-width: 1600px) { 

    
}


</style>
