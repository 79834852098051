<template>
    <section id="InputePassenger" class="section section-components pb-5 bg-white" style="background-color:#faf5f5;">
        <div class="container">
            <div class="col-lg-12 font-weight-bold" >
                <span class="fz-20 avenirHeavy">Details Perjalanan</span><br/>
                 <span class="fz-16 avenirHeavy">Isi data penumpang dan review pesanan Anda</span>
            </div> 

            <div class="row">
                <div class="col-lg-8">

                    <div class="col-lg-12 border shadow-sm p-3 mb-1 bg-white borderradius mt-3">
                        <span class="avenirLight fz-18"><b>Log In Atau Daftar Untuk Mendapatkan Keuntunganya !</b></span>
                        <p class="avenirHeavy fz-14"> Pesan tiket jadi lebih cepat dan mudah dengan daftar penumpang </p>
                        
                        <br/><b>Log in</b> atau <b>daftar</b>
                    </div>

                    <div class="col-lg-12  mt-3 avenirHeavy fz-20" >
                        Daftar Pemesanan ( Untuk E-Tiket )
                     </div> 

                    <div class="col-lg-12 mt-2 border shadow-sm p-3 mb-1 bg-white borderradius mt-3">
                            <div class="form-row">
                                 <div class="form-group col-md-6" v-show="false">
                                    <label for="inputEmail4">Pakai Data Sudah Ada ?</label>
                                    <b-form-select v-model="v_use_data_people" :options="options_use_data_people"></b-form-select>
                                </div>

                                  <div class="form-group col-md-12 avenirMedium fz-16">
                                    MUHAMMAD MIFTAHUL ULUM
                                </div>

                                 <div class="form-group col-md-4">
                                    <label class="avenirMedium fz-16">No Handphone</label>
                                    <input type="text" v-model="v_identity_order.no_handphone" class="form-control" id="inputPassword4" placeholder="">
                                </div>
                                 <div class="form-group col-md-8">
                                    <label class="avenirMedium fz-16">Email</label>
                                    <input type="text" v-model="v_identity_order.email" class="form-control" id="inputPassword4" placeholder="">
                                </div>
                            </div>
                     
                    </div>  

                     <div class="col-lg-12 avenirHeavy fz-20 mt-3" >
                        <h5>Daftar Penumpang</h5>
                     </div> 

                    <div class="col-lg-12">
                        <form>
                            <div class="form-row border shadow-sm p-3 mb-1 borderradius bg-white mt-3" v-for="index in 2" :key="index">
                                <div class="col-lg-6 pt-2">
                                  <span class="avenirMedium fz-16">Zainu Rozikin Alamate</span>
                                </div>
                                <div class="col-lg-6 pt-2 text-right">
                                    <span class="card-title avenirMedium fz-16" style="color:#0770CC;"> Kursi : 9 </span>
                                </div>
                            </div>
                        </form>

                     

                        <div class="clearfix"></div>
                    </div>  

                     <div class="col-lg-12 avenirHeavy fz-20 mt-3" >
                        Detail Bayar
                     </div> 

                     <div class="col-lg-12">
                
                            <div class="form-row border shadow-sm p-3 mb-1 borderradius bg-white mt-3">
                                <div class="col-lg-6 pt-2">
                                    <span class="avenirMedium fz-16"> Total Bayar </span>
                                </div>
                                <div class="col-lg-6 pt-2 text-right">
                                    <span class="avenirMedium fz-16" style="color:#0770CC;"> Rp 400.000 </span>
                                </div>
                            </div>

                         <button type="submit" class="btn btn-danger float-right" @click="$bvModal.show('bv-modal-generate-order')">Lanjutkan</button>

                         <b-modal id="bv-modal-generate-order" hide-header hide-footer
                                size="lg"
                                body-classes="p-0"
                                modal-classes="modal-dialog-centered modal-lg">

                                <div class="row" >
                                    <div class="col-lg-3">
                                        <img src="img/imgjrg/wanita.png" height="160" />
                                    </div>
                                    <div class="col-lg-9 pt-4">
                                        <p> <span class="avenirHeavy fz-20">Apakah Pesanan Anda sudah sesuai ?</span> </p>
                                        <p> <span class="avenirORoman fz-14">Anda tidak akan diperkenankan mengubah detail 
                                                pemesanan setelah masuk ke proses pembayaran. 
                                                Apakah Anda ingin melanjutkan ?</span> </p>
                                    </div>
                                    <div class="col-lg-12 mt-4 text-right">
                                        <button class="btn btn-outline-danger avenirMedium fz-18" @click="$bvModal.hide('bv-modal-generate-order')">Periksa Kembali</button>
                                         <a href="#top" v-smooth-scroll> 
                                             <button class="btn btn-danger ml-2 avenirMedium fz-18"  @click="processToMakeOrder">Buat Pesanan</button>
                                         </a>
                                    </div>
                                </div>
                        </b-modal>

                        <a href="#top" v-smooth-scroll> 
                            <button type="button" class="btn btn-outline-dark float-left"  @click="backLevel()">Kembali</button>
                        </a>
                  
                        <div class="clearfix"></div>
                    </div>  

                </div>
                    <div class="col-lg-4 pt-3">
                        <div class="col-lg-12  border border shadow-sm rounded bg-white p-3 font-weight-bold">
                            <div class="float-left avenirHeavy fz-14"> Sarinah Mall  </div>
                            <img class="float-left ml-4 mr-4" src="img/imgjrg/sync.svg" width="32" />
                            <div class="float-left avenirHeavy fz-14"> Kampus UMN</div>
                            <div class="clearfix"></div>
                        </div> 

                        <div class="col-lg-12  pl-5 border border shadow-sm rounded bg-white pt-4">
                            <p class="avenirHeavy fz-16">Minggu, 21 Feb 2021</p>
                                <span class="avenirHeavy fz-16" >Travel</span><br/>
                                <span class="pz-12 avenirMedium" >Executive Shuttle HiAce </span> <br/><br/>

                            <div class="float-left">
                                 <p class="avenirMedium fz-12">06:00</p>
                                <p class="avenirORoman fz-10">1j 6m</p>
                                <p class="avenirMedium fz-12">07:15</p>
                            </div>
                            <div class="float-left ml-2 mr-2">
                                <img src="img/imgjrg/panahbawah.png" />
                            </div>
                            <div class="float-left">
                                <p class="avenirMedium fz-12">Kampus UMY</p>
                                <p>.</p>
                                <p class="avenirMedium fz-12">Central Park</p>
                            </div>
                            <div class="clearfix"></div>
                        </div>

                        <div class="col-lg-12  pl-5 border border shadow-sm rounded bg-white pt-4">
                            <p class="pz-16 avenirHeavy">Minggu, 21 Feb 2021</p>
                                <span class="pz-16 avenirHeavy">Travel</span> <br/>
                                <span class="pz-12 avenirMedium" >Executive Shuttle HiAce </span> <br/><br/>

                            <div class="float-left">
                                <p class="avenirMedium fz-12">06:00</p>
                                <p class="avenirORoman fz-10">1j 6m</p>
                                <p class="avenirMedium fz-12">07:15</p>
                            </div>
                            <div class="float-left ml-2 mr-2">
                                <img src="img/imgjrg/panahbawah.png" />
                            </div>
                            <div class="float-left">
                                <p class="avenirMedium fz-12">Kampus UMY</p>
                                <p>.</p>
                                <p class="avenirMedium fz-12">Central Park</p>
                            </div>
                            <div class="clearfix"></div>
                        </div>


                    </div>
                 </div>
            
           
        </div>
    </section>

</template>
<script>

import axios from "axios";
import swal from 'sweetalert';

export default {
    data() {
        return {

            v_identity_order:{
                no_handphone:null,
                email:null
            },

            // SVG

            // mySVG: require('img/iconone/passenger.svg'),

             options_use_data_people:[
                { value: null, text: 'Pakai Data Baru' },
                { value: 'a', text: 'Muhammad Miftahul Ulum' },
                { value: 'b', text: 'Budi Darmanto' }
             ],
            
             options_identity_type:[
                { value: null, text: 'KTP' },
                { value: 'a', text: 'SIM' },
                { value: 'b', text: 'KTA' },
                { value: 'b', text: 'Lain Lain' }
             ],

            v_use_data_people:[],
             
            v_identity_id:[],
            v_identity_type:[],
            v_identity_name:[],

            login:false
        }
    },
    methods:{
        processGetData(){
            console.log(this.$store.state.dataChooseTravel);

            // is_login
            // let auth = JSON.parse(localStorage.getItem("auth"))
            // if( auth.token ){
            //     this.getDataCustomer();
            //     this.getDataPenumpang();
            // }

        },
        processInputPassenger(){

            let dataPenumpang = {
                identity_id:this.v_identity_id,
                identity_type:this.v_identity_type,
                identity_name:this.v_identity_name
            }

            this.$store.commit('setDataPenumpang', dataPenumpang);
             this.$parent.activeLevelThree();
        },

        processToMakeOrder(){
             this.$parent.activeLevelFour();
        },

        backLevel(){
             this.$parent.activeLevelTwo();
        },

        async getDataCustomer(){

             let formData = new URLSearchParams(); 
            formData.append('', this.date_go );

            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.$parent.activeLevelOne();
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                    swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                if(this.is_runTest == false){ console.log(error)  }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

        },

        async getJenisIdentitas(){

             let formData = new URLSearchParams(); 
            formData.append('', this.date_go );

            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.options_identity_type = res.data.resultdata;
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                if(this.is_runTest == false){ console.log(error)  }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

        },

        async getDataPenumpang(){

             let formData = new URLSearchParams(); 
            formData.append('', this.date_go );

            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.options_identity_type = res.data.resultdata;
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                    swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                if(this.is_runTest == false){ console.log(error)  }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

        },
    }
};
</script>
<style>

.hover {
  cursor: pointer;
}

#team {
    background: #eee !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#team .card {
    border: none;
    background: #ffffff;
}

.borderradius{
    border-radius: 25px;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #f5365c !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #f5365c !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.bg-headerform{
    background-color:#f5365c;
    color:#fff;
}

.shadow{
    box-shadow: 2px 4px 0 4px yellowgreen, 4px 8px 0 8px pink, 8px 10px 0 12px navy;
}

</style>
