<template>
    <section class="section section-shaped section-lg my-0">
        <div class="shape shape-style-1 bg-gradient-default">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0">
                       
                        <template>
                            <div class="text-center text-muted mb-4">
                                <small>Or sign in with credentials</small>
                            </div>
                            <form role="form">
                                <base-input alternative
                                            class="mb-3"
                                            placeholder="Email"
                                            addon-left-icon="ni ni-email-83">
                                </base-input>
                                <base-input alternative
                                            type="password"
                                            placeholder="Password"
                                            addon-left-icon="ni ni-lock-circle-open">
                                </base-input>
                                <base-checkbox>
                                    Remember me
                                </base-checkbox>
                                <div class="text-center">
                                    <base-button type="primary" class="my-4">Sign In</base-button>
                                </div>
                            </form>
                        </template>

                         <template>
                              <div class="btn-wrapper text-center">
                                <base-button type="neutral">
                                   <i class="fa fa-facebook-official" aria-hidden="true"></i>
                                    Facebook
                                </base-button>

                                <base-button type="neutral">
                                    <img slot="icon" src="img/icons/common/google.svg">
                                    Google
                                </base-button>
                            </div>

                        </template>

                    </card>
                    <div class="row mt-3">
                        <div class="col-6">
                            <a href="#" class="text-light">
                                <small>Forgot password?</small>
                            </a>
                        </div>
                        <div class="col-6 text-right">
                            <a href="#" class="text-light">
                                <small>Create new account</small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import axios from "axios";
import swal from 'sweetalert';

export default {
     data() {
        return {
            v_username:null,
            v_login:null,
        }
    },
    methods:{
         async getLogin(){

             let formData = new URLSearchParams(); 
            formData.append('', this.date_go );

            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    console.log("s")
                }
            }).catch( ( error ) => {
                if(this.is_runTest == false){ console.log(error)  }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

        },
    }
};
</script>
<style>
</style>
