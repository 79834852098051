<template>
   <b-carousel
          id="carousel-1"
          :interval="4000"
          controls
          indicators
          background="#ffffff"
          style="text-shadow: 1px 1px 2px #333; margin-top:-10px;">

          <b-carousel-slide v-for="(v,i) in dataSlider" :img-src="v.url_image" :key="i"></b-carousel-slide>

      </b-carousel>
</template>
<script>
import { Helper } from '../helper.js';
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import swal from 'sweetalert';

export default {
  mixins:[Validate,Master,Helper],    
  name: "components",
 
  data() {
        return {
            data_url:Config,
            dataSlider:[{
                url_image:"img/imgjrg/banner-kosong.jpg"
            }]
        }
  },
  created(){
      this.getSlider();
  },
  methods:{
 
      async getSlider(){

         this.loading_api = true
   
            await axios.post( this.data_url.getImgSlider, null, this.dataHeaderNonAuth ).then(async res => {  

                if( res.data.codestatus == "S" ){
                    this.dataSlider = res.data.resultdata;
                }

               if(  res.data.codestatus == "X" || res.data.codestatus == "E" ){

                    swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }
 
            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });


             this.loading_api = false

      },
  }
};
</script>
<style>
</style>
