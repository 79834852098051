<template>
    <section class="section-shaped my-0 bg">
      
       <div class="container mt-2">
            <div class="row mt-5">
                
                <div class="col-lg-4 pt-0">
                  <p class="col-lg-12 avenirHeavy fz-18 text-primary">{{ $t('checkPemesananNonMember.txt_1') }}</p>
                     <div :class="'col-lg-12 border pt-1 pb-1 pointerMenu ' + ( page_tiket ? 'bg-merah text-white' : '' )" @click="page_tiket=true" >
                        <img src="img/imgjrg/tiket.svg" height="40" class="float-left ml-3 mr-3" /> 
                        <div class="fz-16 avenirORoman pt-2">{{ $t('checkPemesananNonMember.txt_2') }}</div>
                          <div class="clearfix"></div>
                    </div>
                      <div :class="'col-lg-12 border pt-1 pb-1 pointerMenu ' + ( !page_tiket ? 'bg-merah  text-white' : '' )"  @click="page_tiket=false" >
                        <img src="img/imgjrg/package-24px.svg" height="40" :class="'float-left ml-3 mr-3 bgputihimg ' + ( !page_tiket ? 'bg-putih' : '' )" /> 
                        <div class="fz-16 avenirORoman pt-2">{{ $t('checkPemesananNonMember.txt_3') }}</div>
                          <div class="clearfix"></div>
                    </div>
                </div>

                
                <div class="col-lg-8">

                    <div class="col-lg-12 pt-3 pb-3 pl-4 pr-4  text-white d-none d-sm-block" style="border-radius:13px; border:1px solid #0770CC; background-color:#0770CC;">
                      <p class="fz-20 avenirHeavy">{{ $t('checkPemesananNonMember.txt_4') }}</p>
                      <p class="avenirORoman fz-16">{{ $t('checkPemesananNonMember.txt_5') }}</p>
                    </div>

                    <!-- Pesanan Tiket -->

                    <div class="col-lg-12 mt-5 bg-white pl-2" style="border-radius:13px; border:1px solid #D1D1D1;" v-if="page_tiket">
                        <div class="col-lg-12 m-0 pt-3 pb-2 border-bottom">
                          <h4 class=""> {{ $t('checkPemesananNonMember.txt_23') }}  </h4>
                          {{ $t('checkPemesananNonMember.txt_6') }}
                        </div>
                        <div class="col-lg-12 m-0 pt-3 pb-3 ">

                        <div class="alert alert-warning text-left mt-2 mb-2" role="alert" v-if="data_validate.length > 0">
                            <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                              <ol>
                                  <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                              </ol>                            
                          </div>
                        
                          <div class="row" style="margin-bottom:10px;">

                              <div class="col-lg-6">
                                  <div class="form-group">
                                      <label class="fz-16 avenirORoman">{{ $t('checkPemesananNonMember.txt_7') }}</label>
                                      <input type="tezt" v-model="v_pemesanan" class="form-control borderlengkung" >
                                  </div>
                              </div>
                         
                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <label class="fz-16 avenirORoman">.</label>
                                      <button :disabled="loading_api == true" class="btn btn-danger text-white col-lg-12" @click="checkOrder" style="background-color:#FE6C6F;">
                                        <span  v-if="!loading_api">{{ $t('checkPemesananNonMember.txt_8') }} <i class="fa fa-hand-pointer-o" aria-hidden="true"></i> </span>
                                          <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                        </button>
                                  </div>
                              </div>

                              <div class="col-lg-12 p-3 mt-4" v-if="dataOrder != null">

                                  <div class="row pt-2  mt-2  bg-white" style="border-radius:13px; border:1px solid #D1D1D1;">
                                      <div class="col-lg-4">
                                          <p class="avenirORoman fz-16"><i class="fa fa-id-card-o" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_9') }}</p>
                                          <p class="avenirBlack fz-24">{{  this.dataOrder.kode_booking }}</p><br/>
                                          <p class="avenirMedium fz-16 text-danger">
                                             <span class="badge bg-danger text-white" v-if="dataOrder.status == 'expired'">{{ $t('checkPemesananNonMember.txt_10') }}</span>
                                            <span class="badge bg-success text-white" v-if="dataOrder.status == 'active'">{{ $t('checkPemesananNonMember.txt_11') }}</span>
                                            <span class="badge bg-info text-white" v-if="dataOrder.status == 'done'">{{ $t('checkPemesananNonMember.txt_12') }}</span>
                                            <span class="badge bg-warning text-white" v-if="dataOrder.status == 'not_paid'">{{ $t('checkPemesananNonMember.txt_13') }}</span>
                                          </p>
                                      </div>
                                      <div class="col-lg-4 avenirMedium fz-20">
                                          <p class="avenirMedium fz-16 text-primary"><i class="fa fa-bus" aria-hidden="true"></i> {{  this.dataOrder.order_lines[0].kendaraan_name }} [{{ $t('checkPemesananNonMember.txt_24') }}]</p>
                                          <p class="avenirMedium fz-16">{{  this.dataOrder.order_lines[0].location_from_name }} <i class="fa fa-arrow-right" aria-hidden="true"></i> {{  this.dataOrder.order_lines[0].location_to_name }} <br/>
                                           {{ dayNameFullId(dataOrder.order_lines[0].tanggal_berangkat) }}
                                           </p><br/>

                                          <p class="avenirMedium fz-16 text-primary" v-if="dataOrder.order_lines.length > 1"><i class="fa fa-bus" aria-hidden="true"></i> {{  this.dataOrder.order_lines[1].kendaraan_name }} [{{ $t('checkPemesananNonMember.txt_25') }}]</p>
                                          <p class="avenirMedium fz-16" v-if="dataOrder.order_lines.length > 1">{{  this.dataOrder.order_lines[1].location_from_name }} <i class="fa fa-arrow-right" aria-hidden="true"></i> {{  dataOrder.order_lines[1].location_to_name }} <br/>
                                          {{ dayNameFullId(dataOrder.order_lines[1].tanggal_berangkat) }}
                                          </p><br/>

                                          <p class="avenirORoman text-primary fz-16" ><i class="fa fa-calendar" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_26') }} </p>
                                          <p class="avenirMedium fz-16 "> {{ this.dataOrder.tanggal_order }}</p>
                                      </div>
                                      <div class="col-lg-4 avenirMedium fz-20">
                                         <p class="avenirMedium fz-16 text-primary"><i class="fa fa-address-book-o" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_14') }}</p>
                                         <p class="avenirMedium fz-16">{{ this.dataOrder.order_lines[0].passengers.length }} {{ $t('checkPemesananNonMember.txt_15') }}</p>

                                         <!-- <p class="avenirORoman text-primary fz-16" v-if="dataOrder.order_lines.length > 1"><i class="fa fa-address-book-o" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_16') }}</p>
                                         <p class="avenirMedium fz-16" v-if="dataOrder.order_lines.length > 1">{{  this.dataOrder.order_lines[1].passengers.length }} {{ $t('checkPemesananNonMember.txt_17') }}</p> -->

                                           <p class="avenirMedium fz-16 text-primary"><i class="fa fa-address-card-o" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_27') }}</p>
                                        <span>
                                            <p class="avenirMedium fz-16" v-for="(v,i) in dataOrder.order_lines[0].passengers" :key="i">{{ ( i + 1 ) + ". " + v.nama_lengkap }}</p>
                                        </span>

                                        <p class="text-primary" style="position: absolute; bottom:0px; cursor:pointer;"  v-if="dataOrder.status == 'active'" @click="lihatDetailPayment">
                                            <button :disabled="loading_api" class="btn btn-sm" style="background-color:#0770cc; color:#fff;">
                                                 <span  v-if="!loading_api"><i class="fa fa-bars" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_29') }} </span>
                                                <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                            </button>
                                        </p>

                                        <p class="text-primary" style=" cursor:pointer;"  v-if="dataOrder.status == 'not_paid'" >
                                            <button :disabled="loading_api" @click="lihatDetailPayment" class="btn btn-sm" style="background-color:#0770cc; color:#fff;">
                                                <span  v-if="!loading_api"><i class="fa fa-bars" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_30') }} </span>
                                                <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                            </button>
                                        </p>
                                      </div>
                                  </div>
                              </div>
                          </div>

                        </div>
                    </div>


                    <!-- Pesanan Packet -->

                    <div class="col-lg-12 mt-5 bg-white" style="border-radius:13px; border:1px solid #D1D1D1;" v-if="!page_tiket">
                        <div class="col-lg-12 m-0 pt-3 pb-2 border-bottom">
                          <h4 class=""> {{ $t('checkPemesananNonMember.txt_31') }} </h4>
                          {{ $t('checkPemesananNonMember.txt_6_1') }}
                        </div>
                        <div class="col-lg-12 m-0 pt-3 pb-3 ">

                              <div class="alert alert-warning text-left mt-2 mb-2" role="alert" v-if="data_validate1.length > 0">
                                <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                                <ol>
                                    <li  v-for="(value,index) in data_validate1" :key="index">{{ value }}</li>
                                </ol>                            
                            </div>
                          
                          <div class="row" style="margin-bottom:10px;">
                              <div class="col-lg-6">
                                  <div class="form-group">
                                      <label class="fz-16 avenirORoman">{{ $t('checkPemesananNonMember.txt_32') }}</label>
                                      <input type="tezt" v-model="v_resi" class="form-control borderlengkung" >
                                  </div>
                              </div>
                         
                              <div class="col-lg-4">
                                  <div class="form-group">
                                      <label class="fz-16 avenirORoman">.</label>
                                      <button @click="cekOrderPacket" :disabled="loading_api" class="btn btn-danger text-white col-lg-12" style="background-color:#FE6C6F;">
                                        <span  v-if="!loading_api">{{ $t('checkPemesananNonMember.txt_33') }} <i class="fa fa-hand-pointer-o" aria-hidden="true"></i> </span>
                                          <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                        </button>
                                  </div>
                              </div>

                              <div class="col-lg-12 p-3 mt-4" v-if="dataOrderPacket != null">

                                  <div class="row pt-2  mt-2  bg-white" style="border-radius:13px; border:1px solid #D1D1D1;">
                                      <div class="col-lg-4">
                                          <p class="avenirORoman fz-16"><i class="fa fa-id-card-o" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_9_1') }}</p>
                                          <p class="avenirBlack fz-20">{{ dataOrderPacket.nomor_resi }}</p><br/>
                                          <p class="avenirMedium fz-16 text-danger">
                                             <span class="badge bg-danger text-white"  v-if="dataOrderPacket.status == 'expired'" >{{ $t('checkPemesananNonMember.txt_10') }}</span>
                                            <span class="badge bg-success text-white" v-if="dataOrderPacket.status == 'active'">{{ $t('checkPemesananNonMember.txt_11') }}</span>
                                            <span class="badge bg-info text-white" v-if="dataOrderPacket.status == 'done'">{{ $t('checkPemesananNonMember.txt_12') }}</span>
                                            <span class="badge bg-warning text-white" v-if="dataOrderPacket.status == 'not_paid'">{{ $t('checkPemesananNonMember.txt_13') }}</span>
                                          </p>
                                      </div>
                                      <div class="col-lg-5 avenirMedium fz-20">
                                          <p class="avenirMedium fz-16 text-primary"><i class="fa fa-arrow-left" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_34') }} : {{ dataOrderPacket.kota_kabupaten_pengirim }}</p>
                                          <p class="avenirMedium fz-16">{{ dataOrderPacket.alamat_pengirim }}. {{ dataOrderPacket.kecamatan_pengirim }}, {{ dataOrderPacket.kota_kabupaten_pengirim }}. {{ dataOrderPacket.propinsi_pengirim_name }}<br/>{{ $t('checkPemesananNonMember.txt_35') }} :{{ dataOrderPacket.phone_pengirim }}</p><br/>

                                          <p class="avenirMedium fz-16 text-primary"><i class="fa fa-arrow-right" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_36') }} : {{ dataOrderPacket.kota_kabupaten_penerima }}</p>
                                          <p class="avenirMedium fz-16">{{ dataOrderPacket.alamat_penerima }}. {{ dataOrderPacket.kecamatan_penerima }}, {{ dataOrderPacket.kota_kabupaten_penerima }}. {{ dataOrderPacket.propinsi_penerima_name }}<br/>{{ $t('checkPemesananNonMember.txt_37') }} : {{ dataOrderPacket.phone_penerima }}</p><br/>

                                        
                                          <p class="avenirMedium fz-16 "><i class="fa fa-calendar-o" aria-hidden="true"></i> {{ dataOrderPacket.tanggal_paket }}</p>
                                      </div>
                                      <div class="col-lg-3 avenirMedium fz-20">
                                         <p class="avenirMedium fz-16 text-primary"><i class="fa fa-tachometer" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_14_1') }}</p>
                                         <p>{{ $t('checkPemesananNonMember.txt_38') }} : {{ dataOrderPacket.paket_type }} </p>
                                         <p class="avenirMedium fz-16"> {{ dataOrderPacket.psi_m_paket_size_name }}  {{ dataOrderPacket.psi_m_paket_size_berat_min }} -  {{ dataOrderPacket.psi_m_paket_size_berat_max }} KG <br/>  </p><br/>
                                         <p class="avenirMedium fz-16 text-primary"><i class="fa fa-tag" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_39') }}</p>
                                         <p>{{ isKosong2(dataOrderPacket.isi_paket) ? '' : dataOrderPacket.isi_paket }}  </p>
                                         <p class="avenirMedium fz-16 text-primary"><i class="fa fa-sticky-note-o" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_40') }}</p>
                                         <p> {{ isKosong2( dataOrderPacket.keterangan_paket ) ? '' :  dataOrderPacket.keterangan_paket }} </p>

                                        <p class="text-primary"  v-if="dataOrderPacket.status == 'not_paid'">
                                              <button  :disabled="loading_api" @click="lihatDetailPaymentPacket" class="btn btn-sm" style="background-color:#0770cc; color:#fff;">
                                              <span  v-if="!loading_api"> <i class="fa fa-bars" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_41') }} </span>
                                                <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                            </button>
                                        </p>

                                        <p class="text-primary" v-if="dataOrderPacket.status == 'active'">
                                            <button  :disabled="loading_api" @click="lihatDetailPaymentPacket" class="btn btn-sm" style="background-color:#0770cc;color:#fff;">
                                             <span  v-if="!loading_api"> {{ $t('checkPemesananNonMember.txt_42') }} </span>
                                               <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                             </button>
                                        </p>
                                      </div>
                                  </div>
                              </div>                              
                          </div>

                        </div>
                    </div>

                     <b-modal id="bv-modal-pembayaran" hide-header hide-footer
                        size="lg"
                        body-classes="p-0"
                        v-if="infoBayarBool"
                        modal-classes="modal-dialog-centered modal-lg">

                          <div class="col-lg-12" >
                            <p><span class="avenirHeavy fz-20 font-weight-bold">{{ $t('checkPemesananNonMember.txt_19') }}  {{ infoPembayaran.payment_method_name }}</span></p>

                            <p class="border-bottom"><span class="avenirORoman fz-16">{{ getNameVirtual( infoPembayaran.payment_method_name ) }}</span> <br/><span class="avenirHeavy fz-18">{{ getName(infoPembayaran.payment_method_name) }}</span> <img height="25" class="float-right" :src="'img/imgjrg/' + getNameImg(infoPembayaran.payment_method_name)" /></p>

                            <p class="border-bottom" v-if="infoPembayaran.payment_method_name == 'BCA VA' || infoPembayaran.payment_method_name == 'Mandiri VA' || infoPembayaran.payment_method_name == 'Alfamart'"><span class="avenirORoman fz-16">{{ getNameNoVirtual( infoPembayaran.payment_method_name ) }}</span> <br/>
                                <span class="avenirHeavy fz-18" v-if="!loading_api">{{  numberWithSpaces(infoPembayaran.kode_payment) }}</span>
                            </p>

                            <p><span class="avenirORoman fz-16" v-if="infoPembayaran.payment_method_name == 'DANA'">
                                {{ $t('checkPemesananNonMember.txt_43') }}

                                <a :href="infoPembayaran.xendit_ewallet_mobile_web_checkout_url" target="blank" v-if="!loading_api">
                                    <span> <button :disabled="loading_api" class="btn col-lg-12 btn-sm btn-primary ml-3 float-right"><i class="fa  fa-hand-o-right" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_44') }}<i class="fa fa-hand-o-left" aria-hidden="true"></i> </button></span>
                                </a>
                                </span>
                                <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
                            </p>

                            <p><span class="avenirORoman fz-16" v-if="infoPembayaran.payment_method_name == 'OVO'">
                                {{ $t('checkPemesananNonMember.txt_45') }}
                                <input type="number" v-model="mobile_phone" class="form-control" placeholder="No Telepon Ovo ( 0877-0878-2222 )">
                                <button :disabled="loading_api" class="btn col-lg-12 btn-sm btn-primary ml-3 float-right" @click="bayar">               
                                    <span v-if="!loading_api"><i class="fa fa-hand-o-right" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_46') }} <i class="fa fa-hand-o-left" aria-hidden="true"></i></span>
                                    <pulse-loader v-if="loading_api" :color="'#ffffff'" style="float-right"></pulse-loader>
                                </button>
                                    
                                    </span>
                            </p>

                            <p><span class="avenirORoman fz-16" v-if="infoPembayaran.payment_method_name == 'LinkAja'">
                                {{ $t('checkPemesananNonMember.txt_47') }}

                                <a :href="infoPembayaran.xendit_ewallet_mobile_web_checkout_url" target="blank" v-if="!loading_api">
                                    <span> <button :disabled="loading_api" class="btn col-lg-12 btn-sm btn-primary ml-3 float-right"><i class="fa  fa-hand-o-right" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_48') }} <i class="fa fa-hand-o-left" aria-hidden="true"></i> </button></span>
                                </a>
                                </span>
                                <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
                            </p>

                            <p v-if="infoPembayaran.payment_method_name == 'ShopeePay'"><span class="avenirORoman fz-16">
                                {{ $t('checkPemesananNonMember.txt_49') }}
                                </span>
                            </p>

                            <p class="text-center" v-if="infoPembayaran.payment_method_name == 'ShopeePay'">
                                <vue-qrcode v-if="!loading_api" :value="infoPembayaran.xendit_ewallet_qr_checkout_string" :options="{ width: 350 }"></vue-qrcode>
                                <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
                            </p>

                            <p><span class="avenirORoman fz-16" v-if="infoPembayaran.payment_method_name == 'QRIS'">
                                {{ $t('checkPemesananNonMember.txt_50') }}
                                </span>
                            </p>
                            
                            <p class="text-center" v-if="infoPembayaran.payment_method_name == 'QRIS'">
                                <vue-qrcode v-if="!loading_api" :value="infoPembayaran.xendit_qr_string" :options="{ width: 350 }"></vue-qrcode>
                                <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
                            </p>


                            <br/>
                            <br/>
                            <p class="border-bottom"><span class="avenirORoman fz-16">{{ $t('checkPemesananNonMember.txt_22') }}</span> <br/><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ convertToRupiah(infoPembayaran.nominal) }}</span></p>

                            <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('checkPemesananNonMember.txt_20') }}</span></p>
                            <p class="avenirMedium fz-14 border-bottom">
                            <ol>
                                <li v-for="(value, index) in infoPembayaran.info_penting_pembayaran" :key="index" >{{ value.description }}</li>
                            </ol>
                            </p>

                            <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('checkPemesananNonMember.txt_21') }}</span></p>
                            <b-card no-body class="mb-1 border-0" v-for="(value,index) in infoPembayaran.cara_pembayaran" :key="'a' + index">
                                <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
                                    <b-button v-b-toggle="'accordion-' + index" class="bg-white avenirHeavy fz-14" variant=""> {{ value.name }}</b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body class="avenirORoman fz-14">
                                      <b-card-text v-for="(value2, index2) in value.detail" :key="index2">
                                        <span v-if="value2.description != false">{{ value2.no }}.  {{ value2.description }}</span>
                                      </b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                          </div>

                      </b-modal>

                </div>
            </div>
         </div>
         <div class="clearfix"></div>
    </section>
</template>
<script>
// import Badge from '../components/Badge.vue';
import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import swal from 'sweetalert';
import Config from "../model/Config"

export default {
  mixins:[Validate,Master,Helper],
  name: "components",
  components: {
    PulseLoader,
      VueQrcode
  },
  data() {
        return {
          is_runTest:false,
          runTestResult:false,
          runTestParameter:"",
          runTestHeader:null,
          data_validate1:[],
          data_validate:[],

           page_tiket:true,
           infoPembayaran:{
              payment_method_name:"Mandiri",
              kode_payment:"",
              nominal:0,
              info_penting_pembayaran:[],
              cara_pembayaran:[],
            },

            v_pemesanan:null,
            v_resi:null,
             data_url:Config,
             dataOrder:null,
             dataOrderPacket:null,
             infoBayarBool:true,
        }
  },
  methods: {

    async bayar(){

                let formData = new URLSearchParams(); 
                if( !this.is_runTestProp ){

                    if( this.trims(this.mobile_phone).length <= 10 ){
                       
                        swal({
                            title: this.$t('swal.informasi'),
                            text: "Masukkan No Telepon Harus lebih Dari 10 Digit",
                            icon: '/img/imgjrg/antri.png'
                        });
                        return false;
                    }

                    if( String(this.trims(this.mobile_phone)).charAt(0) != '0' ){
                        
                         swal({
                            title: this.$t('swal.informasi'),
                            text: "Masukkan No Telepon Harus mulai dari 0",
                            icon: '/img/imgjrg/antri.png'
                        });
                        return false;
                    }

                    this.loading_api = true
                    
                    formData.append( 'id', this.dataOrder.payment_id );
                    formData.append('psi_m_payment_method_id', this.dataOrder.payment_method_id);
                    formData.append('mobile_phone', "+62" + String(this.trims(this.mobile_phone)).substring(1, String(this.mobile_phone).length));
                }else{
                    formData = this.runTestParameter
                }

                let headerSet = null
                if( this.is_runTestProp ){
                    console.log("" + formData );
                    console.log(JSON.stringify( this.runTestHeader ));
                    headerSet = this.runTestHeader
                }else{
                    headerSet = this.dataHeaderWithTrx_Token()
                }   
            
            await axios.post( this.data_url.setPSIPaymentEWallet + "?" + formData, null, headerSet ).then(res => {            
                console.log(res.data)
                if( res.data.codestatus != "S" ){
                    swal("Ada Kesalahan", "Maaf, Ada Kesalahan \n " + res.data.message, "error");
                }else{
                     this.runTestResult = true;
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                    return false
                }

                if(this.searchWord(error,"401")){
                    swal({
                            title: this.$t('swal.informasi'),
                            text: "Silahkan Login Kembali",
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                    return false
                }
                        
                this.$emit('delMethodPaymentByApi');
                console.log(error)
            });

            this.loading_api = false

        },

    async lihatDetailPaymentPacket(){
        if( this.dataOrderPacket.status != 'not_paid' ){
             this.$store.commit("setDataPacket", this.dataOrderPacket );
            this.$router.push("/tpacket")
          }else{


              this.loading_api = true
              let formData = new URLSearchParams(); 

            if(!this.is_runTest){

                formData.append('nomor_resi',this.dataOrderPacket.nomor_resi);
                formData.append('lang', this.$t('locale_lang') )

            }else{
                formData = this.runTestParameter;
            }

              await axios.post( this.data_url.getInfoPembayaran + "?" + formData, null, this.dataHeaderWithTrx_Token(3) ).then(async res => {            
                  if( res.data.codestatus == "S" ){
                          this.runTestResult = true
                          this.infoBayarBool = true
                          this.infoPembayaran =  res.data.resultdata[0];
                          this.$bvModal.show('bv-modal-pembayaran');
                    
                  }

                  if(  res.data.codestatus == "X" ){
                      swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                      localStorage.removeItem('login');
                      this.$router.push('/redirect')
                  }

              }).catch( ( error ) => {
                  this.dataOrder = null;

                   if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                  console.log(error)
              });

              this.loading_api = false            

          }
    },

    async lihatDetailPayment(){

          if( this.dataOrder.status != 'not_paid' ){

              this.$store.commit("setDataTicket", this.dataOrder );
              this.$router.push("/tiketku")

          }else{


              this.loading_api = true
              let formData = new URLSearchParams(); 

            if(!this.is_runTest){

                formData.append('kode_booking',this.dataOrder.kode_booking);
                formData.append('lang', this.$t('locale_lang') )

            }else{
                formData = this.runTestParameter;
            }

              await axios.post( this.data_url.getInfoPembayaran + "?" + formData, null, this.dataHeaderWithTrx_Token(3) ).then(async res => {            
                  if( res.data.codestatus == "S" ){
                          this.runTestResult = true
                          this.infoBayarBool = true
                          this.infoPembayaran =  res.data.resultdata[0];
                          this.$bvModal.show('bv-modal-pembayaran');
                    
                  }

                  if(  res.data.codestatus == "X" ){
                       swal({
                            title: this.$t('swal.informasi'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                      localStorage.removeItem('login');
                      this.$router.push('/redirect')
                  }

              }).catch( ( error ) => {
                  this.dataOrder = null;

                   if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                  console.log(error)
              });

              this.loading_api = false            

          }

      },

      async checkOrder(){

            this.data_validate = []

            if( this.v_pemesanan == null ||  this.v_pemesanan == "" ){
                this.data_validate.push("Nomor Tiket Harus DI Isikan")
            }

            if( this.data_validate.length > 0 ){
                return false
            }

            this.loading_api = true

            let formData = new URLSearchParams(); 

            if(!this.is_runTest){
                formData.append('kode_booking',this.v_pemesanan);
                formData.append('lang',  this.$t('locale_lang') );
             }else{
                formData = this.runTestParameter;
            }

            await axios.post( this.data_url.cekPesananDetail + "?" + formData, null, this.dataHeaderWithTrx_Token(3) ).then(async res => {            
                if( res.data.codestatus == "S" ){
                this.runTestResult = true
                  if( res.data.resultdata.length > 0 ){
                      this.dataOrder = res.data.resultdata[0];
                  }else{
                      swal({
                                title: this.$t('swal.informasi'),
                                text: "Data Tidak Ditemukan",
                                icon: '/img/imgjrg/error.png'
                            });
                      this.dataOrder = null
                  }

                }else{
                    swal({
                            title: "Order Tidak Terselesaikan",
                            text: res.data.message,
                            icon: '/img/imgjrg/error.png'
                        });
                }
 
            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });


             this.loading_api = false

      },
      async cekOrderPacket(){

           
            this.data_validate1 = []

            if( this.v_resi == null ||  this.v_resi == "" ){
                this.data_validate1.push("Nomor Resi Harus DI Isikan")
            }

            if( this.data_validate1.length > 0 ){
                return false
            }

             this.loading_api = true


            let formData = new URLSearchParams(); 

            if(!this.is_runTest){
                formData.append('nomor_resi', this.v_resi);
             }else{
                formData = this.runTestParameter;
            }

            await axios.post( this.data_url.cekPaketDetail + "?" + formData, null, this.dataHeaderWithTrx_Token(3) ).then(async res => {            
                if( res.data.codestatus == "S" ){
                this.runTestResult = true
                  if( res.data.resultdata.length > 0 ){
                      this.dataOrderPacket = res.data.resultdata[0];
                  }else{
                        swal({
                                title: this.$t('swal.informasi'),
                                text: "Data Tidak Ditemukan",
                                icon: '/img/imgjrg/error.png'
                            });
                      this.dataOrderPacket = null
                  }

                }else{
                    swal({
                            title: "Order Tidak Terselesaikan",
                            text: res.data.message,
                            icon: '/img/imgjrg/error.png'
                        });
                }
 
            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });


             this.loading_api = false

      }
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

.bg-putih{
   filter: brightness(0) invert(1);
} 

.bg{
    background-color:#EEF2F6;
}   

.pointerMenu:hover{
    background-color:#ff0000;
    color:#ffffff;
    cursor:pointer;
}

.pointerMenu:hover > .bgputihimg {
     filter: brightness(0) invert(1);
}


.pointerMenu:active{
    background-color:#FFE3E2;
}

.pointerMenu{
    background-color:#ffffff;
}

.bg-merah{
   background-color:#ff0000;
}

</style>
