<template>
    <section class="section-hero section-shaped my-0">
      
       <div class="container mt-2 pt-5 ">
            <div class="row mt-5 pb-3">
               <div class="col-lg-12 pt-5 avenirHeavy fz-20">
                  <i class="fa fa-check" aria-hidden="true"></i> {{ $t('publishPacket.txt_1') }} 
                  
              </div>
              <div class="col-lg-12 mt-2 shadow p-0" style="border:1px solid #B4B4B4; border-radius: 25px;">
                 <div class="row bgda m-0" style="border-top-left-radius: 25px; border-top-right-radius: 25px;">
                    <div class="col-lg-6 pt-3">
                      <p class="fz-16 avenirMedium text-primary text-white"> <i class="fa fa-ticket" aria-hidden="true"></i> {{ $t('publishPacket.txt_2') }}  <span style="display:inline;" > {{ dataOrderPacket.tanggal_paket }} </span> </p>
                    </div>
                    <div class="col-lg-6 pt-2"> 
                      <span class="float-right">
                        <img src="img/imgjrg/logo_jrg_white.svg" height="40" alt="logo" />
                      </span>
                    </div>
                 </div>

                 <div class="row pl-3" style="position: relative;">

                   <div class="half-circle"></div>

                   <div class="col-lg-2 offset-lg-1 ">
                        <p>.</p>
                        <p class="fz-14 avenirRoman "><i class="fa fa-id-card-o" aria-hidden="true"></i> {{ $t('publishPacket.txt_3') }} </p>
                        <p class="fz-13 avenirHeavy text-primary font-weight-bold">{{ dataOrderPacket.nomor_resi }}</p>
                        <p class="fz-13">* {{ $t('publishPacket.txt_4') }} </p>
                     </div>

                     <div class="col-lg-3">
                        <p>.</p>
                        <p class="avenirMedium fz-14 pl-3"  style="background-color:#E6EAED;"><i class="fa fa-tachometer" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_14_1') }}</p>
                        <p class="avenirMedium fz-13 pl-3"> {{ $t('publishPacket.txt_5') }}  : {{ dataOrderPacket.paket_type }}</p>
                        <p class="avenirMedium fz-12 pl-3">{{ dataOrderPacket.psi_m_paket_size_name }} {{ dataOrderPacket.psi_m_paket_size_berat_min }} - {{ dataOrderPacket.psi_m_paket_size_berat_max }} KG <br/> {{ dataOrderPacket.psi_m_paket_size_panjang }} x {{ dataOrderPacket.psi_m_paket_size_lebar }} x {{ dataOrderPacket.psi_m_paket_size_tinggi }} {{ dataOrderPacket.psi_m_paket_size_uom_volume_name }} </p>
                     </div>

                     <div class="col-lg-6">
                      
                       <div class="row">
                       
                         <div class="col-lg-6">
                           <p>.</p>
                          <p class="avenirMedium fz-14 pl-3"  style="background-color:#E6EAED;"><i class="fa fa-arrow-left" aria-hidden="true"></i> {{ $t('publishPacket.txt_5_1') }} : {{ dataOrderPacket.kota_kabupaten_pengirim }}</p>
                          <p class="avenirMedium fz-12 pl-3">{{ dataOrderPacket.alamat_pengirim }}. {{ dataOrderPacket.kecamatan_pengirim }}, {{ dataOrderPacket.kota_kabupaten_pengirim }}. {{ dataOrderPacket.propinsi_pengirim_name }}
                            <br/>Telp : {{ dataOrderPacket.phone_pengirim }}
                            <br/>{{ $t('publishPacket.txt_6') }} : {{ dataOrderPacket.counter_pengirim_name }}</p> 

                            
                         </div>
                       
                         <div class="col-lg-6">

                           <p>.</p>
                            <p class="avenirMedium fz-14 pl-3"  style="background-color:#E6EAED;"><i class="fa fa-arrow-right" aria-hidden="true"></i> {{ $t('publishPacket.txt_7') }} : {{ dataOrderPacket.kota_kabupaten_penerima }}</p>
                            <p class="avenirMedium fz-12 pl-3">{{ dataOrderPacket.alamat_penerima }}. {{ dataOrderPacket.kecamatan_penerima }}, {{ dataOrderPacket.kota_kabupaten_penerima }}. {{ dataOrderPacket.propinsi_penerima_name }}
                              <br/>{{ $t('publishPacket.txt_7_1') }} : {{ dataOrderPacket.phone_penerima }}
                              <br/>{{ $t('publishPacket.txt_8') }}  : {{ ( dataOrderPacket.counter_penerima_name == 'null' || dataOrderPacket.counter_penerima_name == '' ? ' - ' :  dataOrderPacket.counter_penerima_name ) }}</p>
                         </div>

                       </div>
                     </div>

                      <div class="half-circle-right" style="position: absolute;"></div>
                     
                  </div>

                 <div class="row">
                    <div class="col-lg-3"></div>
                     <div class="col-lg-3">
                     
                        <p class="avenirMedium fz-14 pl-3"  style="background-color:#E6EAED;"><i class="fa fa-tag" aria-hidden="true"></i> {{ $t('publishPacket.txt_9') }} </p>
                        <p class="fz-13 pl-3">{{ dataOrderPacket.isi_paket }}</p>
                      
                     </div>
                     <div class="col-lg-6">
                      
                       <div class="row">
                       
                         <div class="col-lg-6">
                          <p class="avenirMedium fz-14 pl-3"  style="background-color:#E6EAED;"><i class="fa fa-sticky-note-o" aria-hidden="true"></i> {{ $t('publishPacket.txt_10') }}</p>
                          <p class="fz-13 pl-3"> {{ dataOrderPacket.keterangan_paket == "null" ? "" :  dataOrderPacket.keterangan_paket }} </p>                
                         </div>
                       
                       </div>
                     </div>
                    
                  </div>

                  <div class="row m-0" style="border-bottom-left-radius: 25px; border-bottom-right-radius: 25px; border-top: 5px solid red;">
                    <div class="col-lg-12 pt-3">
                      <p class="fz-12 avenirMedium text-center">{{ $t('publishPacket.txt_11') }} </p>
                    </div>
                 </div>

              </div>



            </div>
       </div>
       
    </section>
</template>
<script>

import { Helper } from '../helper.js';
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
// import axios from "axios";
import Config from "../model/Config"

export default {
  mixins:[Validate,Master,Helper],
  // components: {
  //   PulseLoader
  // },
  created(){
    this.dataOrderPacket = this.$store.state.dataPacket;
    console.log(this.dataOrderPacket);
  },
  data() {
        return {
          data_url:Config,
          dataOrderPacket:null,
        }
  },
  methods: {
   
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

.bgda{
  background-color: #ff0000;
}

.half-circle {
    width: 30px;
    height: 50px;
    background-color: #e9ecef;
    border-top-right-radius: 110px;
    border-bottom-right-radius: 110px;
    border: 1px solid gray;
    border-left: 0;
    position: absolute;
    top:56%;
    left:1%;
}

.half-circle-right {
    width: 30px;
    height: 50px;
    background-color: #e9ecef;
    border-top-left-radius: 110px;
    border-bottom-left-radius: 110px;
    border: 1px solid gray;
    border-right: 0;
    position: absolute;
    top:56%;
    right:1%;

}

</style>
