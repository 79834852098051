import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Home from "./views/Home.vue";
import Redirect from "./views/Redirect.vue";
import CekResi from "./views/CekResi.vue";
import TerimaPaket from "./views/TerimaPaket.vue";
import AntarPaketDriver from "./views/AntarPaketDriver.vue";
import SendPacket from "./views/SendPacket.vue";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Profile from "./views/Profile.vue";
import PublishTicket from "./views/PublishTicket.vue";
import PublishPacket from "./views/PublishPacket.vue";
import CheckOngkir from "./views/Checkongkir.vue";
import PesanBanyak from "./views/Pesan_Banyak.vue";
import HowOrder from "./views/HowOrder.vue";
import Terms from "./views/Terms.vue";
import HowPacket from "./views/hal_howpacket.vue";
import Contact from "./views/hal_contact.vue";
import AboutMe from "./views/AboutMe.vue";
import CheckPemesananNonMember from "./views/CheckPemesananNonMember.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      name: "components",
      components: {
        header: AppHeader,
        default: Home,
        footer: AppFooter
      }
    },
    {
      path: "/redirect",
      name: "redirect",
      components: {
        header: AppHeader,
        default: Redirect,
        footer: AppFooter
      }
    },
    {
      path: "/searchAgain",
      name: "searchAgain",
      components: {
        header: AppHeader,
        default: Redirect,
        footer: AppFooter
      }
    },
    {
      path: "/top",
      name: "redirect2",
      components: {
        header: AppHeader,
        default: Redirect,
        footer: AppFooter
      }
    },
    {
      path: "/cekresi",
      name: "cekresi",
      components: {
        header: AppHeader,
        default: CekResi,
        footer: AppFooter
      }
    },
    {
      path: "/terimapaket",
      name: "terimapaket",
      components: {
        header: AppHeader,
        default: TerimaPaket,
        footer: AppFooter
      }
    },
    {
      path: "/howpacket",
      name: "HowPacket",
      components: {
        header: AppHeader,
        default: HowPacket,
        footer: AppFooter
      }
    },
    {
      path: "/contact",
      name: "Contact",
      components: {
        header: AppHeader,
        default: Contact,
        footer: AppFooter
      }
    },
    {
      path: "/paketantardriver",
      name: "paketanterdriver",
      components: {
        header: AppHeader,
        default: AntarPaketDriver,
        footer: AppFooter
      }
    },
    {
      path: "/cekongkir",
      name: "cekongkir",
      components: {
        header: AppHeader,
        default: CheckOngkir,
        footer: AppFooter
      }
    },
    {
      path: "/kirimpaket",
      name: "kirimpaket",
      components: {
        header: AppHeader,
        default: SendPacket,
        footer: AppFooter
      }
    },
    {
      path: "/landing",
      name: "landing",
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    {
      path: "/tiketku",
      name: "tiketku",
      components: {
        header: AppHeader,
        default: PublishTicket,
        footer: AppFooter
      }
    },
    {
      path: "/tpacket",
      name: "ticket_packet",
      components: {
        header: AppHeader,
        default: PublishPacket,
        footer: AppFooter
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        header: AppHeader,
        default: Login,
        footer: AppFooter
      }
    },
    {
      path: "/cekpemesanan",
      name: "cekpemesanan",
      components: {
        header: AppHeader,
        default: CheckPemesananNonMember,
        footer: AppFooter
      }
    },
    {
      path: "/pesanbanyak",
      name: "pesanbanyak",
      components: {
        header: AppHeader,
        default: PesanBanyak,
        footer: AppFooter
      }
    },
    {
      path: "/terms",
      name: "KebijakanDanPrivasi",
      components: {
        header: AppHeader,
        default: Terms,
        footer: AppFooter
      }
    },
    {
      path: "/howorder",
      name: "CaraOrder",
      components: {
        header: AppHeader,
        default: HowOrder,
        footer: AppFooter
      }
    },
    {
      path: "/aboutme",
      name: "TentangKami",
      components: {
        header: AppHeader,
        default: AboutMe,
        footer: AppFooter
      }
    },
    {
      path: "/register",
      name: "register",
      components: {
        header: AppHeader,
        default: Register,
        footer: AppFooter
      }
    },
    {
      path: "/profile",
      name: "profile",
      components: {
        header: AppHeader,
        default: Profile,
        footer: AppFooter
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
