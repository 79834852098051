export const Validate = {
    methods:{
        
        vadEmail(va) {
            console.log(va)
            // if (/^[\-0-9a-zA-Z\.\+_]+@[\-0-9a-zA-Z\.\+_]+\.[a-zA-Z]{2,}$/.test(va)) {
            //     return true
            // } else {
            //     return false
            // }
        },
    }
}