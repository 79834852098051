<template>
  <div>

     <section class="section section-components pb-0" id="section-components">
        <div class="container">
            <div class="row justify-content-center">

                 <div class="col-lg-12 content" v-if="dataMengapa_ada_data" >
                     <center>
                        <span class="poppins semi-bold fz-30" > {{ dataMengapa.name }} </span>
                     </center>
                     <div class="row mt-4 pt-5">
                         <div class="col-lg-4">
                             <center>
                                <img src="img/imgjrg/Safe_Transaction.svg" /><br/>
                                <span class="poppins semi-bold fz-22"> {{ dataMengapa.sections[0].name }}  </span><br/><br/>
                                <span class="fz-16 opensans regular"> {{ dataMengapa.sections[0].details[0].description }} </span>
                             </center>
                         </div>
                         <div class="col-lg-4">
                                <center>
                                <img src="img/imgjrg/Seat_Booking.svg" /> <br/>
                                 <span class="poppins semi-bold fz-22"> {{ dataMengapa.sections[1].name }} </span><br/><br/>
                                <span  class="fz-16 opensans regular">  {{ dataMengapa.sections[1].details[0].description }} </span>
                             </center>
                         </div>
                         <div class="col-lg-4">
                                <center>
                                       <img src="img/imgjrg/Payment-method.svg" /><br/>
                                 <span class="poppins semi-bold fz-22"> {{ dataMengapa.sections[2].name }}  </span><br/><br/>
                                <span  class="fz-16 opensans regular">  {{ dataMengapa.sections[2].details[0].description }}  </span>
                             </center>
                         </div>
                     </div>

                 </div>


                  <div class="col-lg-12 pt-5 mt-5">
                     <center>
                        <span class="poppins semi-bold fz-30" >{{ $t('contentUtama.text_8') }} </span>
                     </center>
                     <div class="row mt-4 pt-5 text-center" style="color:#434343;" v-if="dataRute != 0">
                         <div v-for="( secData , index ) in dataRute.sections[0].details" :key="index" class="col-lg-4 fz-16 opensans">
                            <p class="regular">{{ secData.name }}</p>
                         </div>
                     </div>
                 </div> 
                 
            </div>
        </div>
    </section>

    <div style="background-image: linear-gradient(180deg, #ffffff, #e2dede);" >
      <section>
        <div class="col-lg-12 pt-5 mt-5 mb-5">
                  <center>
                    <span class="poppins semi-bold fz-30"> {{ $t('contentUtama.text_9') }}</span>
                  </center>
                  <div class="row mt-4 pt-5 justify-content-center" v-if="dataImgMethodPayment_ada_data" >
                      <div class="ml-2 text-center mb-2" v-for="(data, i) in dataImgMethodPayment_land" :key="i" >
                          <img :src="data" />
                      </div>
                  </div>
              </div> 
      </section>

      <section class="section pt-3" style="padding-bottom:0px; margin-top:100px; background-image: url('img/imgjrg/patern.png');">
          <div class="container">
              <div class="row" >
                  <div class="col-lg-8 pb-3">
                      <span class="poppins fz-36 semi-bold">{{ $t('contentUtama.text_10') }}  </span>   
                      <p class="montser fz-20 regular">{{ $t('contentUtama.text_11') }}</p> 
                      <div class="float-left ">
                          <img src="img/imgjrg/qr-code.png" height="140" />
                      </div>
                      <div class="gps float-left ml-3">
                         <a href="https://play.google.com/store/apps/details?id=id.sistematika.trans_jrg" target="blank"> 
                          <img src="img/imgjrg/gp.svg" />
                        </a>
                      </div>
                  </div>
                  <div class="col-lg-4">
                      <div class="float-left ml-3">
                          <img style="position:absolute; top:-260px;" src="img/imgjrg/gambarmobilejrg.png" />
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>


  </div>
</template>
<script>

import axios from "axios";
import Config from "../../model/Config"
import { Helper } from '../../helper.js';
import { Master } from '../../model/Master.js'
import swal from 'sweetalert';
import { Validate } from '../../model/Validate.js';

export default {
   mixins:[Helper,Master,Validate],
  data() {
    return {
      loading_api:false,
      dataRute:0,
      dataImgMethodPayment:[],
      dataImgMethodPayment_land:[],
      dataImgMethodPayment_ada_data:false,
      dataMengapa:0,
      lang:"id",
      dataMengapa_ada_data:false,
      data_url:Config,
      checkboxes: {
        unchecked: false,
        checked: true,
        uncheckedDisabled: false,
        checkedDisabled: true
      },
      radio: {
        radio1: "radio1",
        radio2: "radio3"
      },
      switches: {
        off: false,
        on: true
      },
      sliders: {
        slider1: 0,
        slider2: [150, 400]
      }
    };
  },



 created(){

   this.getRute();

 },

updated(){
      if( this.$i18n.locale != this.lang ){
        this.lang = this.$i18n.locale;
        this.dataMengapa_ada_data = false
        this.dataImgMethodPayment_ada_data = false
        this.dataRute = 0
         this.getRute();
      }
  },


  methods:{

      async getRute(){

        this.loading_api = true

        let formData = new URLSearchParams(); 
        formData.append('lang',  this.$t('locale_lang') )

            await axios.post( this.data_url.getPSIBodyExtraInfo + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                        this.runTestResult = true

                        if( !this.is_runTest ){ console.log(res.data.resultdata); }

                        try {
                          this.dataRute = this.getDataInfo( res.data.resultdata, "rute");

                          if( this.dataRute == null || this.dataRute == 'null' || this.dataRute == '' || this.dataRute.length == 0 ){
                             this.dataRute = 0;
                          }

                        } catch (error) {
                          this.dataRute = 0;
                        }

                        try {
                          this.dataMengapa = this.getDataInfo( res.data.resultdata, "benefit");
                          this.dataMengapa_ada_data = true;

                          if( this.dataMengapa == null || this.dataMengapa == 'null' || this.dataMengapa == '' ){
                             this.dataMengapa = 0;
                             this.dataMengapa_ada_data = false;
                          }

                        } catch (error) {
                          this.dataMengapa = 0;
                           this.dataMengapa_ada_data = false;
                        }


                        try {
                          this.dataImgMethodPayment = this.getDataInfo( res.data.resultdata, "paymentmethod");
                          this.dataImgMethodPayment_ada_data = true

                          if( this.dataImgMethodPayment == null || this.dataImgMethodPayment == 'null' || this.dataImgMethodPayment == '' ){
                                this.dataImgMethodPayment = 0;
                                this.dataImgMethodPayment_ada_data = false
                                return false
                          }

                          for (let index = 0; index < this.dataImgMethodPayment.sections.length; index++) {
                            this.dataImgMethodPayment_land[index] = this.dataImgMethodPayment.sections[index].url_image[0].url;
                          }
                          

                        } catch (error) {
                         
                          this.dataImgMethodPayment = 0;
                           this.dataImgMethodPayment_ada_data = false
                        }

                        

                }
            }).catch( ( error ) => {
                console.log(error)

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });


         this.loading_api = false
        },


       

  }
};
</script>
<style>


@media (min-width: 320px) { 
  .gps{
     display: none;
  }
}

@media (min-width: 576px) { 
   .gps{
     display: none;
  }
  
}

@media (min-width: 768px) { 
    .gps{
    display: none;
  }
}

@media (min-width: 992px) { 

   .content{
     margin-top:200px;
  }
  
   .gps{
    display: inline;
  }
}

@media (min-width: 1200px) { 

   .content{
     margin-top:200px;
  }
    .gps{
    display: inline;
  }
}

@media (min-width: 1400px) { 

  .content{
     margin-top:200px;
  }

  .gps{
     display: inline;
  }
}

@media (min-width: 1600px) { 

  .content{
     margin-top:200px;
  }

  .gps{
    display: inline;
  }
    
}

</style>
