<template>
    <div>
        
        <div class="col-lg-12">

            <p class="border-bottom"><span class="avenirORoman fz-16"> {{ dataBank.name }}</span> <img class="float-right" :src="'img/imgjrg/' + payment.imageName" /></p>
            

            <p class="border-bottom"><span class="avenirORoman fz-16">{{ $t('paymentMethodRetail.txt_3') }}</span> <br/><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ convertToRupiah(totalHarga()) }}</span></p>

            <p><span class="avenirORoman fz-16">
                Pembayaran dengan OVO akan dilakukan menggunakan no telepon, Silahkan masukan no telepon ovo dan klik bayar, dan tunggu aplikasi ovo akan terbuka otomatis dan lakukan pembayaran.
                <input type="number" v-model="mobile_phone" class="form-control" placeholder="No Telepon Ovo ( 0877-0878-2222 )">
                <button :disabled="loading_api" class="btn col-lg-12 btn-sm btn-primary ml-3 float-right" @click="bayar" style="background-color:#0770CC;" >               
                    <span v-if="!loading_api"><i class="fa fa-hand-o-right" aria-hidden="true"></i> Bayar Sekarang <i class="fa fa-hand-o-left" aria-hidden="true"></i></span>
                    <pulse-loader v-if="loading_api" :color="'#ffffff'" style="float-right"></pulse-loader>
                </button>
                    
                    </span>
            </p>

            <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('paymentMethodRetail.txt_4') }}</span></p>
            <p class="avenirMedium fz-14 border-bottom">
               <ol>
                   <li v-for="(value, index) in dataBank.info_penting_pembayaran" :key="index" >{{ value.description }}</li>
               </ol>
            </p>

            <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('paymentMethodRetail.txt_5') }}</span></p>
           <b-card no-body class="mb-1 border-0" v-for="(value, index) in dataBank_detail" :key="index">
                <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="bg-white avenirHeavy fz-14" variant=""> {{ value.name }} </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body class="avenirORoman fz-14">
                    <b-card-text v-for="(valuex, index) in value.detail" :key="index">{{ valuex.no }}.  {{ valuex.description }}</b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

        </div>

        <button  class="btn btn-outline-danger float-left hov" @click="changeMethod"> {{ $t('paymentMethodRetail.txt_7') }}</button>
            <a href="#top" v-smooth-scroll> 
                <button :disabled="!is_ovo" class="bgDefaultMerah btn btn-danger float-right" @click="finish"> {{ $t('paymentMethodRetail.txt_8') }} </button>
            </a>

        <div class="clearfix"></div>


       

    </div>
</template>
<script>

import axios from "axios";
import { Helper } from '../../../helper.js';
import { Master } from '../../../model/Master.js';
import { Validate } from '../../../model/Validate.js';
import Config from "../../../model/Config"
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import swal from 'sweetalert';

export default {
    mixins:[Validate,Master,Helper],
    components:{
        PulseLoader
    },
    data() {
        return {

            is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            runTestHeader:null,
            runTest_setDataPayment:null,
            dataOrderPassenger:null,

            is_ovo:false,
            data_url:Config,
            dataBank_detail:[],
            payment:{
                methodName:null,
                virtualName:null,
                imageName:null,
                nomorVA:null,
            },
            mobile_phone:null,
            result_setpyment:null,
        }
    },
    props:{
        id:String,
        delMethodPayment:Function,
        dataBank:Object,
        dataPayment:Object,
        orderRekap:Object,
        is_ticket:Boolean,

        is_payment_ticket: {
            type: Boolean,
            default: true
        },

         is_runTestProp:{
            type: Boolean,
            default: false
        },
        data_available_travel:{
            type: Object,
            default: null
        }
    },
    async created(){
        this.getName();
        this.dataOrderPassenger = this.$store.state.dataOrderPassenger;
        console.log(this.dataOrderPassenger)

        try { this.mobile_phone = this.dataOrderPassenger.phone_pemesan } catch (error) { error }
        
       
        try {
              this.dataBank_detail = this.dataBank.cara_pembayaran
        } catch (error) {
            this.dataBank_detail = []
        }
      
    },
    methods:{

         async getInfoPay(){

             this.loading_api = true

            let formData = new URLSearchParams(); 

            if( !this.is_runTestProp ){

                formData.append('id', this.dataPayment.id);
                formData.append('psi_m_payment_method_id', this.dataBank.id);
                formData.append('mobile_phone', "+6287759752522");
             }else{
                formData = this.runTestParameter
            }

            let headerSet = null
            if( this.is_runTestProp ){
                console.log("" + formData );
                console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader
            }else{
                headerSet = this.dataHeaderWithTrx_Token()
            }   
            
            await axios.post( this.data_url.setPSIPaymentEWallet + "?" + formData, null, headerSet ).then(res => {            
                console.log(res.data)

                if( res.data.codestatus == "S" ){

                    this.runTestResult = true;

                    this.result_setpyment = res.data.resultdata[0]
                    console.log(this.result_setpyment.desktop_web_checkout_url)
                }else{
                     swal({
                            title: "Ada Kesalahan",
                            text: "Maaf, Silahkan pilih kembali metode pembayaran",
                            icon: '/img/imgjrg/error.png'
                        });
                    this.$emit('delMethodPaymentByApi');
                }

            }).catch( ( error ) => {

                if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                    this.$emit('delMethodPaymentByApi');
                    return false
                }

                if(this.searchWord(error,"401")){
                             swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                            localStorage.removeItem('login');
                            this.$router.push('/redirect')
                }
                        
                this.$emit('delMethodPaymentByApi');
                console.log(error)
            });

            this.loading_api = false

        },

        async bayar(){

                let formData = new URLSearchParams(); 
                if( !this.is_runTestProp ){

                    if( this.trims(this.mobile_phone).length <= 10 ){
                        swal({
                            title: this.$t('swal.informasi'),
                            text: "Masukkan No Telepon Harus lebih Dari 10 Digit",
                            icon: '/img/imgjrg/antri.png'
                        });
                        return false;
                    }

                    if( String(this.trims(this.mobile_phone)).charAt(0) != '0' ){

                        swal({
                            title: this.$t('swal.informasi'),
                            text: "Masukkan No Telepon Harus mulai dari 0",
                            icon: '/img/imgjrg/antri.png'
                        });
                        return false;
                    }

                    this.loading_api = true
                    
                    formData.append('id', this.dataPayment.id);
                    formData.append('psi_m_payment_method_id', this.dataBank.id);
                    formData.append('mobile_phone', "+62" + String(this.trims(this.mobile_phone)).substring(1, String(this.mobile_phone).length));
                }else{
                    formData = this.runTestParameter
                }

                let headerSet = null
                if( this.is_runTestProp ){
                    console.log("" + formData );
                    console.log(JSON.stringify( this.runTestHeader ));
                    headerSet = this.runTestHeader
                }else{
                     if(this.is_ticket){
                        headerSet = this.dataHeaderWithTrx_Token()
                    }else{
                        headerSet = this.dataHeaderWithTrx_Token(2)
                    }
                }   
            
            await axios.post( this.data_url.setPSIPaymentEWallet + "?" + formData, null, headerSet ).then(res => {            
                console.log(res.data)
                if( res.data.codestatus != "S" ){
                    if( res.data.message == ""){
                        swal("Ada Kesalahan", "Maaf, Ada kesalahan saat menghubungi provider OVO", "error");
                    }else{
                        swal("Ada Kesalahan", "Maaf, Ada Kesalahan \n " + res.data.message, "error");
                    }
                    
                }else{
                     this.runTestResult = true;
                     this.is_ovo = true
                }
            }).catch( ( error ) => {

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                    return false
                }

                if(this.searchWord(error,"401")){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                    return false
                }
                        
                this.$emit('delMethodPaymentByApi');
                console.log(error)
            });

            this.loading_api = false

        },

        copyCodeVirtual(){
             navigator.clipboard.writeText(  this.dataBank.va_number );
        },
        finish(){
                   this.$emit('finish');    
        },
        changeMethod(){
            this.$emit('delMethodPayment');
        },

         totalHarga(){

            if( this.is_ticket )
            {

                try {
                    if( this.orderRekap.data_trx2.length > 0 ){
                    return this.orderRekap.data_trx2[0].total_amount
                    }
                    
                } catch (error) {
                    return this.orderRekap.data_trx[0].total_amount
                }
            }else{
                try {
                    return this.orderRekap.data_packet.total_fix
                    } catch (error) {
                        return 0 ;
                }
            }

        },

        getName(){
            switch (this.dataBank.name) {
                case "OVO":
                    this.payment.methodName = "OVO"
                    this.payment.imageName = 'mp_ovo.png';
                break;                  
            }
        }
    }
}
</script>