<template>
    <section class="section d-none d-lg-block" style="padding-bottom:0px; background-color:#FF0000;">
        <div class="container-full  ">
            <div class="row text-white" >
                <div class="col-lg-8" style="padding-left:10%;">
                    <span  class="poppins fz-26 semi-bold"> {{ $t('search_bus.txt_pil_pdb') }} </span>   
                    <p class="avenirLight fz-16 roman"> {{ $t('search_bus.txt_pil_pdb_des1') }} </p> 

                    <p class="avenirLight fz-16 roman">{{ $t('search_bus.txt_pil_pdb_des2') }}</p>

                    <div class="float-left ml-3">
                        <img src="img/imgjrg/gp.svg" />
                    </div>

                </div>
                <div class="col-lg-4">
                     <div class="float-right ml-3">
                        <img src="img/imgjrg/bus.png" width="500" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
// import BaseNav from "@/components/BaseNav";
// import CloseButton from "@/components/CloseButton";

export default {
  // components: {
  //   BaseNav,
  //   CloseButton
  // },
  data() {
    return {
      checkboxes: {
        unchecked: false,
        checked: true,
        uncheckedDisabled: false,
        checkedDisabled: true
      },
      radio: {
        radio1: "radio1",
        radio2: "radio3"
      },
      switches: {
        off: false,
        on: true
      },
      sliders: {
        slider1: 0,
        slider2: [150, 400]
      }
    };
  }
};
</script>
<style>
</style>
