<template>
  <div>
    
    <div class="row text-center pl-4 pr-4">
        <div @click="showTravel" :class="'col-lg-6 col-6 pointer pb-2 ' + classBtnTravel">{{ $t('profile_myorder.text_1') }}</div>
        <div @click="showPacket" :class="'col-lg-6 col-6 pointer pb-2 '+ classBtnPacket">{{ $t('profile_myorder.text_2') }}</div>
    </div>
    
    <div class="col-lg-12 p-3 mt-2" v-if="showRegister == 'tiket'">
        <center>
            <button @click="filter_tiket('belum_bayar')" :class="myclass.btn_belumbayar + ' mb-1'"><i class="fa fa-money" aria-hidden="true"></i> {{ $t('profile_myorder.text_3') }}</button>
            <button @click="filter_tiket('aktif')" :class="myclass.btn_aktif + ' mb-1'"><i class="fa fa-circle" aria-hidden="true"></i> {{ $t('profile_myorder.text_4') }}</button>
            <button @click="filter_tiket('selesai')" :class="myclass.btn_selesai"><i class="fa fa-circle-o" aria-hidden="true"></i> {{ $t('profile_myorder.text_5') }}</button>
            <button @click="filter_tiket('kadaluarsa')" :class="myclass.btn_kadaluarsa"><i class="fa fa-clock-o" aria-hidden="true"></i> {{ $t('profile_myorder.text_6') }}</button>
        </center>

     <div class="col-lg-12 p-3 mt-2 text-center" v-if="!template_pesanan">
         <pulse-loader :color="'#f5365c'"></pulse-loader>
     </div>

     <div class="col-lg-12 p-3 mt-2" v-if="dataOrder != null">

        <div class="row pt-2  mt-2  bg-white" style="border-radius:0px; border:1px solid #D1D1D1;" v-for="(value, index) in dataOrder_filter" :key="index">
            <div class="col-lg-4">
                <p class="avenirORoman fz-16"><i class="fa fa-id-card-o" aria-hidden="true"></i> {{ $t('profile_myorder.text_7') }}</p>
                <p class="avenirMedium fz-24">{{  value.kode_booking }}</p><br/>
               <p class="avenirMedium fz-16 text-danger">
                            <span class="badge bg-danger text-white" v-if="value.status == 'expired'">{{ $t('profile_myorder.text_8') }}</span>
                            <span class="badge bg-success text-white" v-if="value.status == 'active'">{{ $t('profile_myorder.text_9') }}</span>
                            <span class="badge bg-info text-white" v-if="dataOrder.status == 'done'">{{ $t('profile_myorder.text_10') }}</span>
                            <span class="badge bg-warning text-white" v-if="value.status == 'not_paid'">{{ $t('profile_myorder.text_11') }}</span>
                            </p>
            </div>
            <div class="col-lg-5 avenirMedium fz-20">

                <p class="avenirMedium fz-16 text-primary" v-if="value.order_lines.length > 0"><i class="fa fa-bus" aria-hidden="true"></i> {{ typeof(value.order_lines[0].kendaraan_name) === "undefined" ? "" : value.order_lines[0].kendaraan_name }}{{ $t('profile_myorder.text_12') }}</p>
                <p class="avenirMedium fz-16" v-if="value.order_lines.length > 0">{{ value.order_lines[0].location_from_name }} <i class="fa fa-arrow-right" aria-hidden="true"></i> {{ value.order_lines[0].location_to_name }}</p>
                 <p class="avenirMedium fz-16 "><i class="fa fa-calendar-o" aria-hidden="true"></i> {{ $t('profile_myorder.text_13') }}  {{ dayNameFullId(value.order_lines[0].tanggal_berangkat) }}</p><hr/>

                <p class="avenirMedium fz-16 text-primary" v-if="value.order_lines.length > 1"><i class="fa fa-bus" aria-hidden="true"></i> {{ typeof(value.order_lines[1].kendaraan_name) === "undefined" ? "" : value.order_lines[1].kendaraan_name }} {{ $t('profile_myorder.text_14') }} </p>
                <p class="avenirMedium fz-16" v-if="value.order_lines.length > 1">{{ value.order_lines[1].location_from_name }} <i class="fa fa-arrow-right" aria-hidden="true"></i> {{ value.order_lines[1].location_to_name }}</p>
                <p class="avenirMedium fz-16" v-if="value.order_lines.length > 1"><i class="fa fa-calendar-o" aria-hidden="true"></i>{{ $t('profile_myorder.text_15') }}  {{ dayNameFullId(value.order_lines[1].tanggal_berangkat) }}</p><br/>

               
                
            </div>
            <div class="col-lg-3 text-primary avenirMedium fz-20">

          

                <p  v-if="value.status == 'active' || value.status == 'not_paid'" style="position: absolute; bottom:0px; cursor: pointer;" @click="lihatDetail(value)">
                    <button class="btn btn-sm btn-primary" :disabled="loading_api">
                    <span v-if="!loading_api"><i class="fa fa-bars" aria-hidden="true"></i> {{ value.status == 'active' ? 'Lihat Tiket' : 'Lihat pembayaran' }}</span>
                    <pulse-loader :color="'#5e72e4'" :loading="loading_api"></pulse-loader></button>
                </p>
            </div>
        </div>
    </div>
      
    </div>









    <div class="col-lg-12 p-3 mt-2" v-if="showRegister == 'paket'">
        <center>
            <button @click="filter_packet('belum_bayar')" :class="myclass_packet.btn_belumbayar  + ' mb-1'"><i class="fa fa-money" aria-hidden="true"></i> {{ $t('profile_myorder.text_3') }}</button>
            <button @click="filter_packet('aktif')" :class="myclass_packet.btn_aktif + ' mb-1'"><i class="fa fa-circle" aria-hidden="true"></i> {{ $t('profile_myorder.text_4') }}</button>
            <button @click="filter_packet('selesai')" :class="myclass_packet.btn_selesai"><i class="fa fa-circle-o" aria-hidden="true"></i> {{ $t('profile_myorder.text_5') }}</button>
            <button @click="filter_packet('kadaluarsa')" :class="myclass_packet.btn_kadaluarsa"><i class="fa fa-clock-o" aria-hidden="true"></i> {{ $t('profile_myorder.text_6') }}</button>
        </center>

     <div class="col-lg-12 p-3 mt-2" v-if="dataOrder != null">

        
        <div class="col-lg-12 p-3 ">

            <div class="row pt-2  mt-2  bg-white" style="border-radius:0px; border:1px solid #D1D1D1;" v-for="(value, index) in dataOrder_packet_filter" :key="index">
                <div class="col-lg-4">
                    <p class="avenirORoman fz-16"><i class="fa fa-id-card-o" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_9') }}</p>
                    <p class="avenirMedium fz-20">{{ value.nomor_resi }}</p><br/>
                    <p class="avenirMedium fz-16 text-danger">
                    <span class="badge bg-danger text-white" v-if="value.status == 'expired'" >{{ $t('checkPemesananNonMember.txt_10') }}</span>
                    <span class="badge bg-success text-white" v-if="value.status == 'active'">{{ $t('checkPemesananNonMember.txt_11') }}</span>
                    <span class="badge bg-info text-white" v-if="value.status == 'done'">{{ $t('checkPemesananNonMember.txt_12') }}</span>
                    <span class="badge bg-warning text-white" v-if="value.status == 'not_paid'">{{ $t('checkPemesananNonMember.txt_13') }}</span>
                    </p>
                </div>
                <div class="col-lg-5 avenirMedium fz-20">
                    <p class="avenirMedium fz-16 text-primary"><i class="fa fa-arrow-left" aria-hidden="true"></i> {{ $t('profile_myorder.text_21') }}  : {{ value.kota_kabupaten_pengirim }}</p>
                    <p class="avenirMedium fz-16"> {{ value.alamat_pengirim }}.  {{ value.kecamatan_pengirim }}, {{ value.kota_kabupaten_pengirim }}.  {{ value.propinsi_pengirim_name }}<br/>{{ $t('profile_myorder.text_22') }}  :  {{ value.phone_pengirim }}</p><br/>

                    <p class="avenirMedium fz-16 text-primary"><i class="fa fa-arrow-right" aria-hidden="true"></i> {{ $t('profile_myorder.text_23') }} : {{ value.kota_kabupaten_penerima }}</p>
                    <p class="avenirMedium fz-16"> {{ value.alamat_penerima }}.  {{ value.kecamatan_penerima }}, {{ value.kota_kabupaten_penerima }}.  {{ value.propinsi_penerima_name }}<br/>{{ $t('profile_myorder.text_24') }} :  {{ value.phone_penerima }}</p><br/>

                
                    <!-- <p class="avenirMedium fz-16 "><i class="fa fa-calendar-o" aria-hidden="true"></i> 26 April 2021</p> -->
                </div>
                <div class="col-lg-3 avenirMedium fz-20">
                    <p class="avenirMedium fz-16 text-primary"><i class="fa fa-tachometer" aria-hidden="true"></i> {{ $t('checkPemesananNonMember.txt_14_1') }}</p>
                    <p>{{ $t('profile_myorder.text_25') }} : {{ value.paket_type }}</p>
                    <p class="avenirMedium fz-16">{{ value.psi_m_paket_size_name }} </p><br/>
                    <p class="avenirMedium fz-16 text-primary"><i class="fa fa-tag" aria-hidden="true"></i> {{ $t('profile_myorder.text_26') }}</p>
                    <p>{{ ( value.isi_paket == "null" ? "(Kosong)" : value.isi_paket ) }} </p>
                    <p class="avenirMedium fz-16 text-primary"><i class="fa fa-sticky-note-o" aria-hidden="true"></i> {{ $t('profile_myorder.text_27') }}</p>
                    <p>{{  ( value.keterangan_paket == "null" ? "(Kosong)" : value.keterangan_paket ) }} </p>

                    <p v-if="value.status == 'active' || value.status == 'not_paid'" class="text-primary" @click="lihatDetailPacket(value)"><button class="btn btn-sm btn-primary"><i class="fa fa-bars" aria-hidden="true"></i> {{  value.status == 'active' ? 'Lihat Resi' : 'Lihat pembayaran' }} </button></p>
                </div>
            </div>
        </div>           

    </div>
      
    </div>




       <b-modal id="bv-modal-pembayaran" hide-header hide-footer
                                size="lg"
                                v-if="infoBayarBool"
                                body-classes="p-0"
                                modal-classes="modal-dialog-centered modal-lg">

                                  <div class="col-lg-12" >
                                    <p><span class="avenirHeavy fz-20 font-weight-bold">{{ $t('profile_myorder.text_17') }} {{ infoPembayaran.payment_method_name }}</span></p>
                                    <p><span class="avenirHeavy fz-14">{{ $t('profile_myorder.text_18') }}</span></p>

                                    <p class="border-bottom" ><span class="avenirORoman fz-16">{{ getNameVirtual( infoPembayaran.payment_method_name ) }}</span> <br/><span class="avenirHeavy fz-18">{{ getName(infoPembayaran.payment_method_name) }}</span> <img class="float-right" height="25"  :src="'img/imgjrg/' + getNameImg(infoPembayaran.payment_method_name)" /></p>

                                    <p class="border-bottom" v-if="infoPembayaran.payment_method_name == 'BCA VA' || infoPembayaran.payment_method_name == 'Mandiri VA' || infoPembayaran.payment_method_name == 'Alfamart'"><span class="avenirORoman fz-16">{{ getNameNoVirtual( infoPembayaran.payment_method_name ) }}</span> <br/>
                                        <span class="avenirHeavy fz-18" v-if="!loading_api">{{  numberWithSpaces(infoPembayaran.kode_payment) }}</span>
                                    </p>

                                     <p><span class="avenirORoman fz-16" v-if="infoPembayaran.payment_method_name == 'DANA'">
                                        {{ $t('profile_myorder.text_28') }} 

                                        <a :href="infoPembayaran.xendit_ewallet_mobile_web_checkout_url" target="blank" v-if="!loading_api">
                                            <span> <button :disabled="loading_api" class="btn col-lg-12 btn-sm btn-primary ml-3 float-right"><i class="fa  fa-hand-o-right" aria-hidden="true"></i> {{ $t('profile_myorder.text_29') }} <i class="fa fa-hand-o-left" aria-hidden="true"></i> </button></span>
                                        </a>
                                        </span>
                                        <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
                                    </p>

                                    <p><span class="avenirORoman fz-16" v-if="infoPembayaran.payment_method_name == 'OVO'">
                                        {{ $t('profile_myorder.text_30') }} 
                                        <input type="number" v-model="mobile_phone" class="form-control" placeholder="No Telepon Ovo ( 0877-0878-2222 )">
                                        <button :disabled="loading_api" class="btn col-lg-12 btn-sm btn-primary ml-3 float-right" @click="bayar">               
                                            <span v-if="!loading_api"><i class="fa fa-hand-o-right" aria-hidden="true"></i>  {{ $t('profile_myorder.text_32') }} <i class="fa fa-hand-o-left" aria-hidden="true"></i></span>
                                            <pulse-loader v-if="loading_api" :color="'#ffffff'" style="float-right"></pulse-loader>
                                        </button>
                                            
                                            </span>
                                    </p>

                                    <p><span class="avenirORoman fz-16" v-if="infoPembayaran.payment_method_name == 'LinkAja'">
                                        {{ $t('profile_myorder.text_31') }} 

                                        <a :href="infoPembayaran.xendit_ewallet_mobile_web_checkout_url" target="blank" v-if="!loading_api">
                                            <span> <button :disabled="loading_api" class="btn col-lg-12 btn-sm btn-primary ml-3 float-right"><i class="fa  fa-hand-o-right" aria-hidden="true"></i> {{ $t('profile_myorder.text_32') }} <i class="fa fa-hand-o-left" aria-hidden="true"></i> </button></span>
                                        </a>
                                        </span>
                                        <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
                                    </p>

                                    <p v-if="infoPembayaran.payment_method_name == 'ShopeePay'"><span class="avenirORoman fz-16">
                                        {{ $t('profile_myorder.text_33') }}
                                        </span>
                                    </p>

                                    <p class="text-center" v-if="infoPembayaran.payment_method_name == 'ShopeePay'">
                                        <vue-qrcode v-if="!loading_api" :value="infoPembayaran.xendit_ewallet_qr_checkout_string" :options="{ width: 350 }"></vue-qrcode>
                                        <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
                                    </p>

                                    <p><span class="avenirORoman fz-16" v-if="infoPembayaran.payment_method_name == 'QRIS'">
                                        {{ $t('profile_myorder.text_34') }}
                                        </span>
                                    </p>
                                    
                                    <p class="text-center" v-if="infoPembayaran.payment_method_name == 'QRIS'">
                                        <vue-qrcode v-if="!loading_api" :value="infoPembayaran.xendit_qr_string" :options="{ width: 350 }"></vue-qrcode>
                                        <pulse-loader v-if="loading_api" :color="'#f5365c'" style="float-right"></pulse-loader>
                                    </p>


                                     <p class="border-bottom"><span class="avenirORoman fz-16">{{ $t('profile_myorder.text_19') }}</span> <br/><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ convertToRupiah(infoPembayaran.nominal) }}</span></p>

                                    <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('profile_myorder.text_35') }} :</span></p>
                                        <p class="avenirMedium fz-14 border-bottom">
                                        <ol>
                                            <li v-for="(value, index) in infoPembayaran.info_penting_pembayaran" :key="index" >{{ value.description }}</li>
                                        </ol>
                                    </p>

                                    <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('profile_myorder.text_20') }}</span></p>
                                    <b-card no-body class="mb-1 border-0" v-for="(value,index) in infoPembayaran.cara_pembayaran" :key="index">
                                        <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
                                            <b-button v-b-toggle="'accordion-' + index" class="bg-white avenirHeavy fz-14" variant=""> {{ value.name }}</b-button>
                                        </b-card-header>
                                        <b-collapse :id="'accordion-' + index" visible accordion="my-accordion" role="tabpanel">
                                            <b-card-body class="avenirORoman fz-14">
                                            <b-card-text v-for="(value2, index2) in value.detail" :key="index2"><span v-if="value2.description != false" >{{ value2.no }}.  {{ value2.description }}</span></b-card-text>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>

            </b-modal>
      

  </div>
</template>
<script>
import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import swal from 'sweetalert';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  mixins:[Validate,Master,Helper],    
  name: "components",
  components: {
     PulseLoader,
     VueQrcode
  },
  data() {
        return {
            is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            runTestHeader:null,
            runtTest_dataOrder:null,


            data_url:Config,
            classBtnTravel:"borderbottomnyala",
            classBtnPacket:"borderbottomnonyala",
            showRegister:"tiket",
            dataOrder:[],
            dataOrder_packet:[],
            dataOrder_filter:null,
            dataOrder_packet_filter:[],
            myclass:{
                class_filter_aktif:"btn btn-danger",
                class_filter_non_aktif:"btn btn-outline-danger",

                btn_belumbayar:"btn btn-outline-danger",
                btn_aktif:"btn btn-outline-danger ",
                btn_selesai:"btn btn-outline-danger ",
                btn_kadaluarsa:"btn btn-outline-danger ",
                btn_batal:"btn btn-outline-danger ",
            },
            myclass_packet:{
                class_filter_aktif:"btn btn-danger",
                class_filter_non_aktif:"btn btn-outline-danger",

                btn_belumbayar:"btn btn-outline-danger",
                btn_aktif:"btn btn-outline-danger ",
                btn_selesai:"btn btn-outline-danger ",
                btn_kadaluarsa:"btn btn-outline-danger ",
                btn_batal:"btn btn-outline-danger",
            },
             infoPembayaran:{
              payment_method_name:"Mandiri",
              kode_payment:"",
              nominal:0,
              info_penting_pembayaran:[],
              cara_pembayaran:[],
            },
            infoBayarBool:true,
            template_pesanan:false,
            
        }
  },
  async created(){
    await this.checkOrder()
    this.checkOrderPacket()
    this.myclass.btn_belumbayar = this.myclass.class_filter_aktif
    this.myclass_packet.btn_belumbayar = this.myclass_packet.class_filter_aktif
  },
  methods: {

      async lihatDetail(data){

          if( data.status != 'not_paid' ){

            this.loading_api = true

            let formData = new URLSearchParams(); 

             if( !this.is_runTest ){

            formData.append('kode_booking',data.kode_booking);
            formData.append('lang',  this.$t('locale_lang') )

             }else{
                 formData = this.runTestParameter;
             }

            let headerSet = null;
             if( this.is_runTest ){

                if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader

            }else{
                
                headerSet = this.dataHeaderWithTrx_Token(2)
            }   

            await axios.post( this.data_url.cekPesananDetail + "?" + formData, null, headerSet ).then(async res => {            
                if( res.data.codestatus == "S" ){

                    this.runTestResult = true

                    if( res.data.resultdata.length > 0 ){
                        this.$store.commit("setDataTicket",  res.data.resultdata[0] );
                        this.$router.push("/tiketku")
                    }

                }

                 if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });


            this.loading_api = false

          }else{

            this.loading_api = true
            let formData = new URLSearchParams(); 

             if( !this.is_runTest ){
                formData.append('kode_booking',data.kode_booking);
                formData.append('lang',  this.$t('locale_lang') )
             }else{
                 formData = this.runTestParameter;
             }

            let headerSet = null;
             if( this.is_runTest ){

                if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader

            }else{
                
                headerSet = this.dataHeaderWithTrx_Token(2)
            }   

            await axios.post( this.data_url.getInfoPembayaran + "?" + formData, null, headerSet ).then(async res => {            
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true

                  if( res.data.resultdata.length > 0 ){ 
                        this.infoBayarBool = true
                        this.infoPembayaran =  res.data.resultdata[0];
                        this.$bvModal.show('bv-modal-pembayaran');
                  }
                }

                 if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });

            this.loading_api = false            
          }

      },

      async lihatDetailPacket(data){

          if( data.status != 'not_paid' ){

            this.loading_api = true

            let formData = new URLSearchParams(); 

             if( !this.is_runTest ){

            formData.append('nomor_resi',data.nomor_resi);
            formData.append('lang',  this.$t('locale_lang') )

             }else{
                 formData = this.runTestParameter;
             }

            let headerSet = null;
             if( this.is_runTest ){

                if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader

            }else{
                
                headerSet = this.dataHeaderWithTrx_Token(2)
            }   

            await axios.post( this.data_url.cekPesananDetail + "?" + formData, null, headerSet ).then(async res => {            
                if( res.data.codestatus == "S" ){

                    this.runTestResult = true

                    if( res.data.resultdata.length > 0 ){
                        this.$store.commit("setDataPacket", res.data.resultdata[0] );
                        this.$router.push("/tpacket")
                    }

                }

                 if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });


            this.loading_api = false

          }else{

            this.loading_api = true
            let formData = new URLSearchParams(); 

             if( !this.is_runTest ){
                formData.append('nomor_resi',data.nomor_resi);
                formData.append('lang',  this.$t('locale_lang') )
             }else{
                 formData = this.runTestParameter;
             }

            let headerSet = null;
             if( this.is_runTest ){

                if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader

            }else{
                
                headerSet = this.dataHeaderWithTrx_Token(2)
            }   

            await axios.post( this.data_url.getInfoPembayaran + "?" + formData, null, headerSet ).then(async res => {            
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true

                  if( res.data.resultdata.length > 0 ){ 
                        this.infoBayarBool = true
                        this.infoPembayaran =  res.data.resultdata[0];
                        this.$bvModal.show('bv-modal-pembayaran');
                  }
                }

                 if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });

            this.loading_api = false            
          }

      },

      async filter_tiket(key){

          switch (key) {
              case "belum_bayar":

                  this.setClassBtn();
                  this.myclass.btn_belumbayar = this.myclass.class_filter_aktif

                   this.dataOrder_filter = this.dataOrder.filter(function (el) {
                    if(el.status == "not_paid"){
                        return true;
                    }
                });
                  
                  break;
              case "aktif":

                  this.setClassBtn();
                  this.myclass.btn_aktif = this.myclass.class_filter_aktif

                   this.dataOrder_filter = this.dataOrder.filter(function (el) {
                    if(el.status == "active"){
                        return true;
                    }
                });
                  
                  break;
              case "selesai":

                  this.setClassBtn();
                  this.myclass.btn_selesai = this.myclass.class_filter_aktif

                   this.dataOrder_filter = this.dataOrder.filter(function (el) {
                    if(el.status == "done"){
                        return true;
                    }
                });
                  
                  break;
              case "kadaluarsa":

                    this.setClassBtn();
                    this.myclass.btn_kadaluarsa = this.myclass.class_filter_aktif

                this.dataOrder_filter = this.dataOrder.filter(function (el) {
                    if(el.status == "expired"){
                        return true;
                    }
                });
                  
                  break;
          
              default:
                  break;
          }

      },


      async filter_packet(key){

          switch (key) {

              case "belum_bayar":

                this.setClassBtn_packet();
                this.myclass_packet.btn_belumbayar = this.myclass_packet.class_filter_aktif
if( this.dataOrder_packet.length > 0 ){
                this.dataOrder_packet_filter = this.dataOrder_packet.filter(function (el) {
                    if(el.status == "not_paid"){
                        return true;
                    }
                });
}
                  
                break;
              case "aktif":

                  this.setClassBtn_packet();
                  this.myclass_packet.btn_aktif = this.myclass_packet.class_filter_aktif

if( this.dataOrder_packet.length > 0 ){
                   this.dataOrder_packet_filter = this.dataOrder_packet.filter(function (el) {
                    if(el.status == "active"){
                        return true;
                    }
                });

}
                  
                  break;
              case "selesai":

                  this.setClassBtn_packet();
                  this.myclass_packet.btn_selesai = this.myclass_packet.class_filter_aktif

if( this.dataOrder_packet.length > 0 ){
                   this.dataOrder_packet_filter = this.dataOrder_packet.filter(function (el) {
                    if(el.status == "done"){
                        return true;
                    }
                });

}
                  
                  break;
              case "kadaluarsa":

                    this.setClassBtn_packet();
                    this.myclass_packet.btn_kadaluarsa = this.myclass_packet.class_filter_aktif

                   
                   

                     if( this.dataOrder_packet.length > 0 ){

                          this.dataOrder_packet_filter = this.dataOrder_packet.filter(function (el) {
                                if(el.status == "expired"){
                                    return true;
                                }
                            });
                        
                    }
                  
                  break;
          
              default:
                  break;
          }

      },


       

      setClassBtn(){

                this.myclass.btn_belumbayar = "btn btn-outline-danger"
                this.myclass.btn_aktif = "btn btn-outline-danger "
                this.myclass.btn_selesai = "btn btn-outline-danger "
                this.myclass.btn_kadaluarsa = "btn btn-outline-danger "
                this.myclass.btn_batal = "btn btn-outline-danger "
           
      },

      setClassBtn_packet(){

                this.myclass_packet.btn_belumbayar = "btn btn-outline-danger "
                this.myclass_packet.btn_aktif = "btn btn-outline-danger "
                this.myclass_packet.btn_selesai = "btn btn-outline-danger "
                this.myclass_packet.btn_kadaluarsa = "btn btn-outline-danger "
                this.myclass_packet.btn_batal = "btn btn-outline-danger "
           
      },

       async checkOrder(){

         this.loading_api = true
         this.template_pesanan = false

            let headerSet = null
            if( this.is_runTest ){
                if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader
            }else{
                
                headerSet = this.dataHeaderWithTrx_Token(2)
            }   
   
            await axios.post( this.data_url.cekPesananList, null, headerSet ).then(async res => {            
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true
                    if(!this.is_runTest) console.log(res.data) ;
                  if( res.data.resultdata.length > 0 ){
                    this.dataOrder = res.data.resultdata
                    this.dataOrder_filter = res.data.resultdata
                    this.runtTest_dataOrder = res.data.resultdata

                    this.dataOrder_filter = this.dataOrder.filter(function (el) {
                        if(el.status == "not_paid"){
                            return true;
                        }
                    })


                      if(!this.is_runTest) console.log( this.dataOrder);
                  }else{
                      this.dataOrder = null
                  }

                }

               if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

               if(  res.data.codestatus == "E" ){
                    
                     swal({
                            title: "Info Server",
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }
 
            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });

            this.template_pesanan = true
             this.loading_api = false

      },

       async checkOrderPacket(){

         this.loading_api = true
         this.template_pesanan = false

            let headerSet = null
            if( this.is_runTest ){
                if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader
            }else{
                
                headerSet = this.dataHeaderWithTrx_Token(2)
            }   
   
            await axios.post( this.data_url.cekPaketList, null, headerSet ).then(async res => {            
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true
                    if(!this.is_runTest) console.log(res.data) ;
                  if( res.data.resultdata.length > 0 ){
                    this.dataOrder_packet = res.data.resultdata
                    this.dataOrder_packet_filter = res.data.resultdata

                    this.dataOrder_packet_filter = this.dataOrder_packet.filter(function (el) {
                        if(el.status == "not_paid"){
                            return true;
                        }
                    })


                      if(!this.is_runTest) console.log( this.dataOrder_packet);
                  }else{
                      this.dataOrder_packet = []
                  }

                }

               if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

               if(  res.data.codestatus == "E" ){
                  
                    swal({
                            title: "Info Server",
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }
 
            }).catch( ( error ) => {
                this.dataOrder = null;
                console.log(error)

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            });

            this.template_pesanan = true
             this.loading_api = false

      },

     showTravel(){
        this.classBtnTravel = "borderbottomnyala";
        this.classBtnPacket = "borderbottomnonyala";
        this.showRegister = "tiket"
    },

    showPacket(){
        this.classBtnTravel = "borderbottomnonyala";
        this.classBtnPacket = "borderbottomnyala";    
        this.showRegister = "paket"
    },
  }
};
</script>
<style>
.pointer{
    cursor: pointer;
}

.borderbottomnyala{
    border-bottom-style: solid; 
    border-bottom-color: #235C9F;
}

.borderbottomnonyala{
    border-bottom-style: solid; 
    border-bottom-color: #bbbbbb;
}
</style>
