<template>
    <section class="section-shaped my-0">
      
       <div class="container mt-2 pt-5 ">
            <div class="row mt-5 pb-3">
               <div class="col-lg-12 pt-5 avenirHeavy fz-20">
                 <i class="fa fa-check" aria-hidden="true"></i>  {{ $t('publishTicket.txt_1') }}
                 <button :disabled="loading_api" @click="dwnload" style="background-color:#ca3f3f;" class="btn btn-danger float-right"> 
                    <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                    <span v-if="!loading_api">
                      <i class="fa fa-download" aria-hidden="true"></i> Unduh Tiket
                    </span>
                  </button>
              </div>

              <div class="col-lg-12 mt-2 shadow p-0" style="border:1px solid #B4B4B4; border-radius: 25px;">

                 <div class="row bgda m-0" style="border-top-left-radius: 25px; border-top-right-radius: 25px;">
                    <div class="col-lg-6 pt-3">
                      <p class="fz-16 avenirMedium text-primary text-white"> <i class="fa fa-ticket" aria-hidden="true"></i> E-Tiket Travel </p>
                    </div>
                    <div class="col-lg-6 pt-2"> 
                      <span class="float-right">
                        <img src="img/imgjrg/logo_jrg_white.svg" height="40" alt="logo" />
                      </span>
                    </div>
                 </div>

                 <div class="row pl-3" style="position: relative;">

                    <div class="half-circle"></div>
                    <div class="half-circle-right"></div>

                     <div class="col-lg-2 offset-lg-1">
                        <p>.</p>
                        <p class="fz-16 avenirRoman"><i class="fa fa-id-card-o" aria-hidden="true"></i>  {{ $t('publishTicket.txt_4') }}</p>
                        <p class="fz-20 avenirHeavy text-primary">{{ dataOrder.kode_booking }}</p>
                     </div>

                     <div class="row mt-3 col-lg-9">

                       <div class="col-lg-12">
                           <p class="fz-16 avenirMedium pl-3" style="background-color:#E6EAED;"><i class="fa fa-info-circle" aria-hidden="true"></i> {{ $t('publishTicket.txt_2') }} </p>
                       </div>
                          <div class="col-lg-12 " >
                            <div class="row">
                                <div class="col-lg-3">
                                      <p class="fz-16 avenirHeavy"><i class="fa fa-bus" aria-hidden="true"></i>  {{ $t('publishTicket.txt_3') }} </p>
                                      <p class="fz-14 avenirORoman">{{ dataOrder.order_lines[0].kendaraan_name }}</p>
                                      <p class="fz-12 avenirORoman"></p>
                                </div>

                                <div class="col-lg-9">
                                  <div class="row">
                                    <div class="col-lg-4">
                                      <p class="fz-16 avenirHeavy">{{ dataOrder.order_lines[0].location_from_name }}</p>
                                      <p class="fz-16 avenirHeavy"><i class="fa fa-clock-o" aria-hidden="true"></i> {{ getTimeTravel(dataOrder.order_lines[0].time_from[0]) }}</p>
                                      <p class="fz-12 avenirRoman"><i class="fa fa-calendar-o" aria-hidden="true"></i> {{ dayNameFullId(dataOrder.order_lines[0].tanggal_berangkat) }}</p>                        
                                    </div>
                                    <div class="col-lg-4">
                                        <p><center><img src="img/imgjrg/panah.png" /></center></p>
                                        <p class="fz-12 avenirRoman text-center"> {{ dataOrder.order_lines[0].range_time }}  </p>      
                                    </div>
                                    <div class="col-lg-4">
                                        <p class="fz-16 avenirHeavy">{{ dataOrder.order_lines[0].location_to_name }}</p>
                                        <p class="fz-16 avenirHeavy"><i class="fa fa-clock-o" aria-hidden="true"></i> {{ getTimeTravel(dataOrder.order_lines[0].time_to[0]) }}</p>
                                        <p class="fz-12 avenirRoman"><i class="fa fa-calendar-o" aria-hidden="true"></i> {{ dayNameFullId(dataOrder.order_lines[0].tanggal_sampai) }}</p>      
                                    </div>
                                  </div>
                                </div>
                            </div>
                       </div>
                    </div>
                  </div>

                  <div class="row mt-2 pb-3">

                     <div class="col-lg-9 offset-lg-3">
                       <p class="fz-16 avenirMedium pl-3" style="background-color:#E6EAED;"><i class="fa fa-address-book-o" aria-hidden="true"></i> {{ $t('publishTicket.txt_5') }}  </p>
                     </div>

                     <div class="col-lg-9 offset-lg-3">
                       <div style="text-align:center;" class="row mb-2">
                          <div class="col-lg-1 fz-16 avenirRoman"> {{ $t('publishTicket.txt_6') }}</div>
                          <div class="col-lg-3 fz-16 avenirRoman"> {{ $t('publishTicket.txt_7') }}</div>
                          <!-- <div class="col-lg-2 fz-16 avenirRoman"> {{ $t('publishTicket.txt_8') }}</div> -->
                          <div class="col-lg-3 fz-16 avenirRoman"> {{ $t('publishTicket.txt_9') }}</div>
                          <div class="col-lg-3 fz-16 avenirRoman"> {{ $t('publishTicket.txt_10') }}</div>
                       </div>

                       <div style="text-align:center;" class="row" v-for="(value,index) in dataOrder.order_lines[0].passengers" :key="'a' + index">
                          <div class="col-lg-1 fz-16 avenirMedium">{{ index + 1 }}.</div>
                          <div class="col-lg-3 fz-16 avenirMedium">{{ value.nama_lengkap }}</div>
                          <!-- <div class="col-lg-2 fz-16 avenirMedium"> {{ $t('publishTicket.txt_11') }}</div> -->
                          <div class="col-lg-3 fz-16 avenirMedium"><i class="fa fa-tag" aria-hidden="true"></i> {{ value.kursi }}</div>
                          <div class="col-lg-3 fz-16 avenirMedium"><i class="fa fa-ticket" aria-hidden="true"></i> {{ value.nomor_tiket }}</div>
                       </div>                      
                     </div>
                  </div>

                  <div class="row m-0" style="border-bottom-left-radius: 25px; border-bottom-right-radius: 25px; border-top: 5px solid red;">
                    <div class="col-lg-12 pt-3">
                      <p class="fz-12 avenirMedium text-center"> {{ $t('publishTicket.txt_22') }} </p>
                    </div>
                 </div>

              </div>

























              <div class="col-lg-12 mt-2 shadow p-0" style="border:1px solid #B4B4B4; border-radius: 25px;" v-if="dataOrder.order_lines.length > 1">

                 <div class="row bgda m-0" style="border-top-left-radius: 25px; border-top-right-radius: 25px;">
                    <div class="col-lg-6 pt-3">
                      <p class="fz-16 avenirMedium text-primary text-white"> <i class="fa fa-ticket" aria-hidden="true"></i> E-Tiket Travel </p>
                    </div>
                    <div class="col-lg-6 pt-2"> 
                      <span class="float-right">
                        <img src="img/imgjrg/logo_jrg_white.svg" height="40" alt="logo" />
                      </span>
                    </div>
                 </div>


                 <div class="row mt-3 pl-3" style="position: relative;">

                    <div class="half-circle"></div>
                    <div class="half-circle-right"></div>

                     <div class="col-lg-2 offset-lg-1">
                        <p>.</p>
                        <p class="fz-16 avenirRoman"><i class="fa fa-id-card-o" aria-hidden="true"></i> {{ $t('publishTicket.txt_14') }}</p>
                        <p class="fz-20 avenirHeavy text-primary">{{ dataOrder.kode_booking }}</p>
                     </div>

                      <div class="row mt-3 col-lg-9">
                           <div class="col-lg-12">
                              <p class="fz-16 avenirMedium pl-3" style="background-color:#E6EAED;"> <i class="fa fa-info-circle" aria-hidden="true"></i>  {{ $t('publishTicket.txt_2') }} </p>
                           </div>
                            <div class="col-lg-12">
                              <div class="row">
                                   <div class="col-lg-3">
                                     

                                      <p class="fz-16 avenirHeavy"><i class="fa fa-bus" aria-hidden="true"></i> {{ $t('publishTicket.txt_13') }} </p>
                                      <p class="fz-14 avenirORoman">{{ dataOrder.order_lines[1].kendaraan_name }}</p>
                                      <p class="fz-12 avenirORoman"></p>
                                    </div>

                                    <div class="col-lg-6">
                              
                                      <div class="row">
                                        <div class="col-lg-4">
                                          <p class="fz-16 avenirHeavy">{{ dataOrder.order_lines[1].location_from_name }}</p>
                                          <p class="fz-16 avenirHeavy"><i class="fa fa-clock-o" aria-hidden="true"></i> {{ getTimeTravel(dataOrder.order_lines[1].time_from[0]) }}</p>
                                          <p class="fz-12 avenirRoman"><i class="fa fa-calendar-o" aria-hidden="true"></i> {{ dayNameFullId(dataOrder.order_lines[1].tanggal_berangkat) }}</p>                        
                                        </div>
                                        <div class="col-lg-4">
                                            <p><center><img src="img/imgjrg/panah.png" /></center></p>
                                            <p class="fz-12 avenirRoman text-center">{{  dataOrder.order_lines[1].range_time }}</p>      
                                        </div>
                                        <div class="col-lg-4">
                                            <p class="fz-16 avenirHeavy">{{ dataOrder.order_lines[1].location_to_name }}</p>
                                            <p class="fz-16 avenirHeavy"><i class="fa fa-clock-o" aria-hidden="true"></i> {{ getTimeTravel(dataOrder.order_lines[1].time_to[0]) }}</p>
                                            <p class="fz-12 avenirRoman"><i class="fa fa-calendar-o" aria-hidden="true"></i> {{ dayNameFullId(dataOrder.order_lines[1].tanggal_sampai) }}</p>      
                                        </div>
                                      </div>
                                    </div>
                              </div>
                            </div>
                      </div>

                    

                  </div>

                  <div class="row mt-2 pb-3">

                     <div class="col-lg-9 offset-lg-3">
                       <p class="fz-16 avenirMedium pl-3" style="background-color:#E6EAED;"><i class="fa fa-address-book-o" aria-hidden="true"></i>  {{ $t('publishTicket.txt_15') }}</p>
                     </div>

                     <div class="col-lg-9 offset-lg-3">
                       <div style="text-align:center;" class="row mb-2">
                          <div class="col-lg-1 fz-16 avenirRoman"> {{ $t('publishTicket.txt_16') }}</div>
                          <div class="col-lg-3 fz-16 avenirRoman"> {{ $t('publishTicket.txt_17') }}</div>
                          <!-- <div class="col-lg-2 fz-16 avenirRoman"> {{ $t('publishTicket.txt_18') }}</div> -->
                          <div class="col-lg-3 fz-16 avenirRoman"> {{ $t('publishTicket.txt_19') }}</div>
                          <div class="col-lg-3 fz-16 avenirRoman"> {{ $t('publishTicket.txt_20') }}</div>
                       </div>

                       <div style="text-align:center;" class="row" v-for="(value,index) in dataOrder.order_lines[1].passengers" :key="index">
                          <div class="col-lg-1 fz-16 avenirMedium">1.</div>
                          <div class="col-lg-3 fz-16 avenirMedium">{{ value.nama_lengkap }}</div>
                          <!-- <div class="col-lg-2 fz-16 avenirMedium"> {{ $t('publishTicket.txt_21') }}</div> -->
                          <div class="col-lg-3 fz-16 avenirMedium"><i class="fa fa-tag" aria-hidden="true"></i> {{ value.kursi }}</div>
                          <div class="col-lg-3 fz-16 avenirMedium"><i class="fa fa-ticket" aria-hidden="true"></i> {{ value.nomor_tiket }}</div>
                       </div>                      
                     </div>
                     
                  </div>


                  <div class="row m-0" style="border-bottom-left-radius: 25px; border-bottom-right-radius: 25px; border-top: 5px solid red;">
                    <div class="col-lg-12 pt-3">
                      <p class="fz-12 avenirMedium text-center">Jika mengalami kendala mohon hubungi Customer service SMS/WhatsApp 0811 6816 900 atau email admin@jrgindonesia.co.id </p>
                    </div>
                 </div>


              </div>


























            </div>
       </div>
       
    </section>
</template>
<script>

import { Helper } from '../helper.js';
import swal from 'sweetalert';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"

export default {
  mixins:[Validate,Master,Helper],
  created(){
    this.dataOrder = this.$store.state.dataTicket;
    console.log(this.dataOrder);
  },
  components:{
      PulseLoader
  },
  data() {
        return {
          data_url:Config,
          dataOrder:null,
          loading_api:false
        }
  },
  methods: {

    async dwnload(){

      this.loading_api = true

      let formData = new URLSearchParams(); 
      formData.append('kode_booking', this.dataOrder.kode_booking);
        await axios.post( this.data_url.getETicket + "?" + formData, null, this.dataHeaderWithTrx_Token(2) ).then(res => {            
          if( res.data.codestatus == "S" ){
              console.log(res.data.resultdata)
              document.location = res.data.resultdata[0].url;
          }

          if(  res.data.codestatus == "TX" ){
              swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                this.$router.push('/redirect')
          }

      }).catch( ( error ) => {
          console.log(error)
      });

      this.loading_api = false

    }
   
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}



.half-circle {
    width: 30px;
    height: 50px;
    background-color: #e9ecef;
    border-top-right-radius: 110px;
    border-bottom-right-radius: 110px;
    border: 1px solid gray;
    border-left: 0;
    position: absolute;
    top:56%;
    left:1%;
}

.half-circle-right {
    width: 30px;
    height: 50px;
    background-color: #e9ecef;
    border-top-left-radius: 110px;
    border-bottom-left-radius: 110px;
    border: 1px solid gray;
    border-right: 0;
    position: absolute;
    top:56%;
    right:1%;
}

.bgda{
  background-color: #ff0000;
}

</style>
