<template>
    <section id="InputePassenger" class="section section-components pb-5 bg-white" style="background-color:#faf5f5;">
        <div class="container">
            <div class="col-lg-12 avenirHeavy fz-20 mb-3" >
                Status Penerbitan Tiket Anda
            </div> 

            <div class="row">
               
                <div class="col-lg-8">

                    <div class="col-lg-12 shadow p-3 mb-5 bg-white rounded">
                        <p class="avenirORoman fz-14">
                            Terima kasih telah melakukan pemesanan di JRG Travel. Pembayaran Anda telah kami terima. dan saat ini e-tiket Anda sedang dalam proses penerbitan di sistem kami.
                        </p>

                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-5 text-right">
                                    <img src="img/imgjrg/time.png" height="100" /> 
                                </div>
                                <div class="col-lg-6">
                                     <p class="avenirORoman fz-14">
                                        E tiket akan diterbitkan dalam waktu
                                    </p>
                                    <p class="avenirORoman fz-16">
                                        30 menit 56 detik
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                    </div>

                    <div class="col-lg-12 avenirHeavy fz-20 mb-3" >
                        Tanda Bukti Pemesanan (bukan tiket)
                    </div> 

                    <div class="col-lg-12 shadow p-3 mb-5 bg-white rounded">
                        <p>
                            <span class="avenirMedium fz-14 opc-6"> ID PEMESANAN</span><br/>
                            <span class="avenirHeavy fz-14"> 08977566789</span>
                        </p>

                    </div>


                    <div class="col-lg-12 avenirHeavy fz-20 mb-3" >
                        Rincian Bus & Travel
                    </div> 

                    <div class="col-lg-12 shadow p-3 mb-2 bg-white rounded">
                        <p>
                            <span class="avenirHeavy fz-16 font-weight-bold"> Tiket Berangkat</span><br/>
                        </p>

                        <div class="row">
                            <div class="col-lg-12 avenirMedium fz-14 mb-3"> Executive Shuttle HiAce</div>
                            <div class="col-lg-12 text-center">1 Jam 23 Menit</div>
                            <div class="col-lg-3 text-right">
                                <p>
                                    06:00 <br/>
                                    Kampus UMN
                                </p>
                            </div>
                            <div class="col-lg-6 mt-2">
                                <span class="dut float-left"></span>
                                <span class="dline"></span>
                                <span class="doot float-right"></span>
                            </div>
                            <div class="col-lg-3">
                                  <p>
                                    06:00 <br/>
                                    Kampus UMN
                                </p>
                            </div>
                        </div>
                    </div>

                     <div class="col-lg-12 shadow p-3 mb-2 bg-white rounded">
                        <p>
                            <span class="avenirHeavy fz-16 font-weight-bold"> Tiket Pulang</span><br/>
                        </p>

                        <div class="row">
                            <div class="col-lg-12 avenirMedium fz-14 mb-3"> Executive Shuttle HiAce</div>
                            <div class="col-lg-12 text-center">1 Jam 23 Menit</div>
                            <div class="col-lg-3 text-right">
                                <p>
                                    06:00 <br/>
                                    Kampus UMN
                                </p>
                            </div>
                            <div class="col-lg-6 mt-2">
                                <span class="dut float-left"></span>
                                <span class="dline"></span>
                                <span class="doot float-right"></span>
                            </div>
                            <div class="col-lg-3">
                                  <p>
                                    06:00 <br/>
                                    Kampus UMN
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 avenirHeavy fz-20 mb-3 mt-4" >
                      Details Bayar
                    </div> 

                    <div class="col-lg-12 shadow p-3 mb-5 bg-white rounded">
                        <p>
                            <span class="avenirMedium fz-16 opc-6"> Total Bayar : Rp 160.000</span><br/>
                            <span class="avenirHeavy fz-16"> Cara Pembayaran : Link AJa</span>
                        </p>

                    </div>


                </div>

              


                <div class="col-lg-4 bg-white">
                    <div class="col-lg-12  border border shadow rounded bg-white p-3 font-weight-bold">
                        <div class="fz-14">ID PEMESANAN  </div> 
                        <div class="fz-16">0997182897</div>
                        <div class="clearfix"></div>
                    </div> 

                    <div class="col-lg-12  border border shadow rounded bg-white p-3">
                        <p class="fz-16"> Rincian Bus & Travel</p>

                        <p class="fz-14">
                            <span> Travel HiAce </span> <br/>
                            <span class="font-weight-bold"> Minggu, 21 Feb 2021 </span> <br/>
                            <span> kampus UMN, - Central Park</span> <br/>
                            <span> 06:00 - 07:15</span>
                        </p>
                        <p class="fz-14">
                            <span> Travel HiAce </span> <br/>
                            <span class="font-weight-bold"> Minggu, 21 Feb 2021 </span> <br/>
                            <span> kampus UMN, - Central Park</span> <br/>
                            <span> 06:00 - 07:15</span>
                        </p>

                        <div class="clearfix"></div>
                    </div> 

                    <div class="col-lg-12 pb-2 pl-2 border border shadow rounded bg-white pt-4"  >
                
                             <p class="fz-14"> Daftar Penumpang</p>

                            <p class="text-left fz-14">
                                <span class="font-weight-bold"  >Muhammad MFitahul Ulum</span>
                                <span class="float-right">
                                    DEWASA
                                </span>
                            </p>

                            <p class="text-left fz-14">
                                   <span class="font-weight-bold" >Nia Rahmadahni</span>
                                <span class="float-right">
                                    DEWASA
                                </span>
                            </p>
                           
                            <div class="clearfix"></div>
                    </div>
                </div>
           
            </div>
           
        </div>
    </section>

</template>
<script>

import axios from "axios";

export default {
    data() {
        return {

            v_identity_order:{
                no_handphone:null,
                email:null
            },

            // SVG

            // mySVG: require('img/iconone/passenger.svg'),

             options_use_data_people:[
                { value: null, text: 'Pakai Data Baru' },
                { value: 'a', text: 'Muhammad Miftahul Ulum' },
                { value: 'b', text: 'Budi Darmanto' }
             ],
            
             options_identity_type:[
                { value: null, text: 'KTP' },
                { value: 'a', text: 'SIM' },
                { value: 'b', text: 'KTA' },
                { value: 'b', text: 'Lain Lain' }
             ],

            v_use_data_people:[],
             
            v_identity_id:[],
            v_identity_type:[],
            v_identity_name:[],

            login:false
        }
    },
    methods:{
   
        processGetData(){
            console.log(this.$store.state.dataChooseTravel);

            // is_login
            // let auth = JSON.parse(localStorage.getItem("auth"))
            // if( auth.token ){
            //     this.getDataCustomer();
            //     this.getDataPenumpang();
            // }

        },
        processInputPassenger(){

            let dataPenumpang = {
                identity_id:this.v_identity_id,
                identity_type:this.v_identity_type,
                identity_name:this.v_identity_name
            }

            this.$store.commit('setDataPenumpang', dataPenumpang);
             this.$parent.activeLevelThree();
        },
        backLevel(){
             this.$parent.activeLevelOne();
        },

        async getDataCustomer(){

             let formData = new URLSearchParams(); 
            formData.append('', this.date_go );

            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.$parent.activeLevelOne();
                }
            }).catch( ( error ) => {
                if(this.is_runTest == false){ console.log(error)  }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

        },

        async getJenisIdentitas(){

             let formData = new URLSearchParams(); 
            formData.append('', this.date_go );

            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.options_identity_type = res.data.resultdata;
                }
            }).catch( ( error ) => {
                if(this.is_runTest == false){ console.log(error)  }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

        },

        async getDataPenumpang(){

             let formData = new URLSearchParams(); 
            formData.append('', this.date_go );

            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.options_identity_type = res.data.resultdata;
                }
            }).catch( ( error ) => {
                if(this.is_runTest == false){ console.log(error)  }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

        },
    }
};
</script>
<style>

.hover {
  cursor: pointer;
}

#team {
    background: #eee !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#team .card {
    border: none;
    background: #ffffff;
}

.borderradius{
    border-radius: 25px;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #f5365c !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #f5365c !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.bg-headerform{
    background-color:#f5365c;
    color:#fff;
}

.shadow{
    box-shadow: 2px 4px 0 4px yellowgreen, 4px 8px 0 8px pink, 8px 10px 0 12px navy;
}

.doot {
  height: 15px;
  width: 15px;
  background-color: #2600d1;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right:0;
  z-index: 2;
}

.dut {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #c1c5fd;
  border-radius: 50%;
  display: inline-block;
  z-index: 2;
  left: 0px;
}

.dline{
    height: 2px;
    width: 100%;
    background-color: #949494;
    position: absolute;
    left: 0px;
    top: 6px;
    z-index: 1;
}

</style>
