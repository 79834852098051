<template>
    <section id="InputePassenger" class="section section-components pb-5 bg-white" style="background-color:#faf5f5;">
        <div class="container" v-if="orderRekap != null">
            <div class="col-lg-12 avenirHeavy fz-30" >
                {{ $t('finsihOrder.txt_review_pesanan') }}
            </div> 

            <div class="row">
                <div class="col-lg-8">

                  
                    <div class="col-lg-12 avenirHeavy fz-22 mt-3" >
                          {{ $t('finsihOrder.txt_1') }}
                     </div> 

                    <div class="col-lg-12 mt-2 border shadow-sm p-3 mb-1 bg-white borderradius mt-3">
                            <div class="form-row">
                                 <div class="col-lg-12  avenirHeavy fz-16">
                                      {{ $t('finsihOrder.txt_2') }} 
                                 </div>
                                <div class="col-lg-12  pl-5 bg-white pt-4">
                                       
                                       <span class="avenirHeavy fz-16"> {{ $t('finsihOrder.txt_3') }} </span><br/>
                                       <span class="avenirMedium fz-14"> {{  orderRekap.data_travel.travel_1.psi_m_kendaraan_name }}</span><br/>
                                        <br/><br/>

                                    <div class="float-left">
                                        <p class="avenirMedium fz-16">{{  getTimeTravel(orderRekap.data_travel.travel_1.time_from) }}</p>
                                        <p class="avenirORoman fz-14">{{ orderRekap.data_travel.travel_1.range }}</p>
                                        <p  class="avenirMedium fz-16">{{  getTimeTravel(orderRekap.data_travel.travel_1.time_to) }}</p>
                                    </div>
                                    <div class="float-left ml-2 mr-2">
                                        <img src="img/imgjrg/panahbawah.png" />
                                    </div>
                                    <div class="float-left">
                                         <p class="avenirMedium fz-16">{{ orderRekap.data_pesan[2].name }} <br/> <span class="avenirMedium fz-14 roman">{{ dayNameFullId(orderRekap.data_pesan[0])  }}</span></p>
                                        <p class="avenirMedium fz-16 pt-2">{{  orderRekap.data_pesan[3].name }}  <br/></p>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                    </div>  

                     <div class="col-lg-12 mt-2 border shadow-sm p-3 mb-1 bg-white borderradius mt-3"  v-if="orderRekap.data_pesan[5] == true">
                            <div class="form-row">
                                 <div class="col-lg-12  avenirHeavy fz-16">
                                      {{ $t('finsihOrder.txt_4') }}
                                 </div>
                                <div class="col-lg-12  pl-5 bg-white pt-4">
                                       
                                       <span class="avenirHeavy fz-16"> {{ $t('finsihOrder.txt_5') }}</span><br/>
                                       <span class="avenirMedium fz-14"> {{  orderRekap.data_travel.travel_2.psi_m_kendaraan_name }}</span><br/>
                                        <br/><br/>

                                    <div class="float-left">
                                        <p class="avenirMedium fz-14"> {{  getTimeTravel(orderRekap.data_travel.travel_2.time_from) }}</p>
                                        <p class="avenirORoman fz-10">{{ orderRekap.data_travel.travel_2.range }} </p>
                                        <p  class="avenirMedium fz-14">{{  getTimeTravel(orderRekap.data_travel.travel_2.time_to) }}</p>
                                    </div>
                                    <div class="float-left ml-2 mr-2">
                                        <img src="img/imgjrg/panahbawah.png" />
                                    </div>
                                    <div class="float-left">
                                        <p class="avenirMedium fz-14">{{ orderRekap.data_pesan[3].name }} <br/> {{ dayNameFullId(orderRekap.data_pesan[1])  }}</p>
                                        <p>.</p>
                                        <p class="avenirMedium fz-14">{{ orderRekap.data_pesan[2].name }} <br/> </p>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                    </div>  

                     <div class="col-lg-12 avenirHeavy fz-22 mt-3" >
                         {{ $t('finsihOrder.txt_6') }}
                     </div> 

                    <div class="col-lg-12" v-if="!is_runTestProp">
                        <form>
                            <div class="form-row border shadow-sm p-3 mb-1 borderradius bg-white mt-3">
                                <div class="col-lg-12 pt-2"  v-for="(value,index) in orderRekap.data_seat[0].keberangkatan" :key="index">
                                    <span class="card-title m-0 avenirMedium fz-16">{{ index + 1 }}.  {{ value.nama_lengkap }} </span>
                                    <div class="col-lg-12 pl-4 avenirMedium fz-14">

                                        <div class="row mt-1 mb-1">
                                            <div class="col-lg-4"> {{ $t('finsihOrder.txt_7') }} <span class="d-lg-none" >:</span></div>
                                            <div class="col-lg-6  font-weight-bold">
                                                <span class="d-none d-lg-block text-uppercase" >: {{ isKosong2(value.identity_type) ? '' : value.identity_type }} -  {{ isKosong2(value.identity_no) ? '' : value.identity_no }}</span>
                                                <span class="d-lg-none" >{{ isKosong2(value.identity_type) ? '' : value.identity_type }} -  {{ isKosong2(value.identity_no) ? '' : value.identity_no }}</span>
                                             </div>
                                        </div>

                                        <div class="row mt-1 mb-1">
                                            <div class="col-lg-4"> {{ $t('finsihOrder.txt_8') }} <span class="d-lg-none" >:</span></div>
                                            <div class="col-lg-6  font-weight-bold">
                                                <span class="d-none d-lg-block">: {{ value.kursi }} </span>
                                                <span class="d-lg-none">{{ value.kursi }}</span>  </div>
                                        </div>

                                        <div class="row mt-1 mb-1"  v-if="orderRekap.data_pesan[5] == true">
                                            <div class="col-lg-4"> {{ $t('finsihOrder.txt_9') }} <span class="d-lg-none" >:</span></div>
                                            <div class="col-lg-6  font-weight-bold">
                                                <span class="d-none d-lg-block">: {{ orderRekap.data_seat[1].kepulangan[index].kursi }}</span> 
                                                <span class="d-lg-none">{{ orderRekap.data_seat[1].kepulangan[index].kursi }}</span> 
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="clearfix"></div>
                    </div>  

                     <div class="col-lg-12 justify-content-center">
                                 
                        <a href="#top" v-smooth-scroll class="d-none d-lg-block"> 
                            <button type="button" class="hover btn btn-secondary disabled float-left avenirMedium fz-18" :disabled="loading_api" @click="batalOrder" >
                                <span v-if="!loading_api"> {{ $t('finsihOrder.txt_10') }}</span>
                                <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                </button>
                            <button type="button" class="mr-2 btn btn-danger float-right avenirMedium fz-18 bgDefaultMerah"  @click="processToMakeOrder"> 
                                <span v-if="!loading_api">{{ $t('finsihOrder.txt_11') }}</span>
                                 <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                            </button>
                        </a>

                         <b-modal id="bv-modal-generate-order" hide-header hide-footer
                                size="lg"
                                body-classes="p-0"
                                modal-classes="modal-dialog-centered modal-lg">

                                <div class="row" >
                                    <div class="col-lg-3">
                                        <img src="img/imgjrg/wanita.png" height="160" />
                                    </div>
                                    <div class="col-lg-9 pt-4">
                                        <p> <span class="avenirHeavy fz-20"> {{ $t('finsihOrder.txt_12') }}</span> </p>
                                        <p> <span class="avenirORoman fz-14"> {{ $t('finsihOrder.txt_3') }}</span> </p>
                                    </div>
                                    <div class="col-lg-12 mt-4 text-right">
                                        <button :disabled="loading_api" class="btn btn-outline-danger avenirMedium fz-18" @click="$bvModal.hide('bv-modal-generate-order')"> 
                                               <span v-if="!loading_api">{{ $t('finsihOrder.txt_14') }}</span>
                                               <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                        </button>
                                         <a href="#top" v-smooth-scroll> 
                                            <button  :disabled="loading_api" class="btn btn-danger ml-2 avenirMedium fz-18"  @click="processToMakeOrder">
                                                <span v-if="!loading_api"> {{ $t('finsihOrder.txt_15') }}</span>
                                               <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                            </button>
                                         </a>
                                    </div>
                                </div>
                        </b-modal>
                  
                        <div class="clearfix"></div>
                    </div>  

                </div>

                <div class="col-lg-4">

<!--                    <div class="col-lg-12 border border shadow-sm rounded bg-white p-3 font-weight-bold">-->
<!--                        <div class="float-left avenirMedium fz-16 mt-2"> {{ $t('finsihOrder.txt_16') }}   </div>-->
<!--                        <div class="float-right avenirHeavy fz-16"> {{ this.orderRekap.data_trx[0].kode_booking }} <button class="btn btn-sm btn-secondary ml-3" @click="copyPesanan"><i class="fa fa-clone" aria-hidden="true"></i> {{ $t('finsihOrder.txt_17') }} </button></div>-->
<!--                        <div class="clearfix"></div>-->
<!--                    </div> -->

                    <div class="col-lg-12 avenirHeavy fz-16 mt-2 pl-3 mb-2" >
                            {{ $t('finsihOrder.txt_18') }}
                    </div> 

                    <div class="col-lg-12 pb-2 pl-2 border border shadow-sm rounded bg-white pt-4">
                
                            <div class="col-lg-12 pl-2">
                                <div class="float-left medium avenirMedium fz-14">{{ orderRekap.data_pesan[2].name }} - {{ orderRekap.data_pesan[3].name }}</div>
                                <div class="float-right medium avenirMedium fz-14">{{ convertToRupiah(orderRekap.data_trx[0].order_lines[0].total_harga) }} </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="col-lg-12 pl-2" v-if="orderRekap.data_pesan[5] == true">
                                <div class="float-left medium avenirMedium fz-14">{{ orderRekap.data_pesan[3].name }} - {{ orderRekap.data_pesan[2].name }}</div>
                                <div class="float-right medium avenirMedium fz-14"> {{ convertToRupiah(orderRekap.data_trx2[0].order_lines[1].total_harga) }} </div>
                            </div>
                    
                            <div class="clearfix"></div>
                    </div>

                    <div class="col-lg-12  border border shadow-sm rounded bg-white p-3 font-weight-bold">
                        <div class="float-left avenirHeavy fz-16">  {{ $t('finsihOrder.txt_19') }}  </div>
                        <div class="float-right avenirHeavy fz-18" style="color:#0770CC;">{{ convertToRupiah(totalHarga()) }}</div>
                        <div class="clearfix"></div>
                    </div> 
                
                    <a href="#top" v-smooth-scroll class="d-lg-none"> 
                            <button type="button" class="hover btn btn-secondary disabled float-left avenirMedium fz-18" :disabled="loading_api" @click="batalOrder" >
                                <span v-if="!loading_api"> {{ $t('finsihOrder.txt_10') }}</span>
                                <pulse-loader :color="'#000000'" :loading="loading_api"></pulse-loader>
                                </button>
                            <button type="button" class="mr-2 btn btn-danger float-right avenirMedium fz-18"  @click="processToMakeOrder"> 
                                <span v-if="!loading_api">{{ $t('finsihOrder.txt_11') }}</span>
                                 <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                            </button>
                        </a>

                </div>
            </div>
            
           
        </div>
    </section>

</template>
<script>

import axios from "axios";
import Config from "../../../model/Config"
import { Helper } from '../../../helper.js';
import { Master } from '../../../model/Master.js'
import { Validate } from '../../../model/Validate.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import swal from 'sweetalert';

export default {
     mixins:[Validate,Master,Helper],
     components:{
         PulseLoader
     },
    props:{
        is_runTestProp:{
            type: Boolean,
            default: false
        },
        data_available_travel:{
            type: Object,
            default: null
        }
    },
    data() {
        return {
            data_url:Config,
            
            is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            runTestHeader:null,
            dataKursi_testResult:null,
            runTest_setDataOrderConfirm:null,

        }
    },
    created(){

        if( !this.is_runTestProp ){
        this.orderRekap = {
                data_pesan: this.$store.state.dataPesan,
                data_trx:this.$store.state.dataTrx,
                data_trx2:this.$store.state.dataTrxTwo,
                data_travel:this.$store.state.dataChooseTravel,
                data_seat:this.$store.state.dataSeatPassenger
            }
        }

        console.log(this.orderRekap)
    },
    methods:{

        async batalOrder(){
             this.$parent.activeLevelTwoShow();
        },

        copyPesanan(){
             navigator.clipboard.writeText(  this.orderRekap.data_trx[0].kode_booking );
        },

        totalHarga(){

            try {
                if( this.orderRekap.data_trx2.length > 0 ){
                   return this.orderRekap.data_trx2[0].total_amount
                }
                
            } catch (error) {
                 return this.orderRekap.data_trx[0].total_amount
            }

        },
        async processToMakeOrder(){

            this.loading_api = true

            let formData = new URLSearchParams();

            if( !this.is_runTestProp ){  
                formData.append('psi_t_order_id', this.orderRekap.data_trx[0].id );
            }else{
                formData = this.runTestParameter;
            }


            await axios.post( this.data_url.confirmPSIOrder + "?" + formData, null, ( !this.is_runTestProp ? this.dataHeaderWithTrx_Token() : this.runTestHeader) ).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true
                    this.runTest_setDataOrderConfirm = res.data.resultdata
                    this.$store.commit('setDataOrderConfirm', res.data.resultdata) 
                    this.$parent.activeLevelFive();
                }

                if(  res.data.codestatus == "TX" ){
                         swal({
                            title:this.$t('swal.t2'),
                            text: "Silahkan Mengulang Pesanan !",
                            icon: '/img/imgjrg/error.png'
                        });
                        this.$parent.nonActiveLevelOne();
                }

            }).catch( ( error ) => {
                if(this.searchWord(error,"401")){
                         swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                     if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                    
                console.log(error)
            });

              this.loading_api = false

        }

    }
};
</script>
<style>

.hover {
  cursor: pointer;
}

#team {
    background: #eee !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#team .card {
    border: none;
    background: #ffffff;
}

.borderradius{
    border-radius: 25px;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #f5365c !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #f5365c !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.bg-headerform{
    background-color:#f5365c;
    color:#fff;
}

.shadow{
    box-shadow: 2px 4px 0 4px yellowgreen, 4px 8px 0 8px pink, 8px 10px 0 12px navy;
}

</style>
