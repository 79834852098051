<template>
    <div>
        
        <div class="col-lg-12">
            <p><span class="avenirHeavy fz-20 font-weight-bold">{{ payment.methodName }}</span></p>
            <p><span class="avenirHeavy fz-14">{{ $t('paymentMethodTva.txt_1') }}</span></p>

            <p class="border-bottom"><span class="avenirORoman fz-16">{{ $t('paymentMethodTva.txt_2') }}</span> <br/><span class="avenirHeavy fz-18">{{ payment.virtualName }}</span> <img class="float-right" :src="'img/imgjrg/' + payment.imageName" /></p>
            <p class="border-bottom"><span class="avenirORoman fz-16">{{ $t('paymentMethodTva.txt_3') }}</span> <br/>
                <span class="avenirHeavy fz-18" v-if="!loading_api">{{ numberWithSpaces(vaData.kode_payment) }}</span>
                <pulse-loader :color="'#f5365c'" :loading="loading_api"></pulse-loader>
                <button class="btn btn-sm btn-secondary ml-3 float-right" @click="copyCodeVirtual" style="margin-top:-10px;"><i class="fa fa-clone" aria-hidden="true"></i> {{ $t('paymentMethodTva.txt_4') }}</button>
             </p>

            <p class="border-bottom"><span class="avenirORoman fz-16">{{ $t('paymentMethodTva.txt_5') }}</span> <br/><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ convertToRupiah(totalHarga()) }}</span></p>

            <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('paymentMethodTva.txt_6') }}</span></p>
            <p class="avenirMedium fz-14 border-bottom">
               <ol>
                   <li v-for="(value, index) in dataBank.info_penting_pembayaran" :key="index" >{{ value.description }}</li>
               </ol>
            </p>

            <p><span class="avenirHeavy fz-16 font-weight-bold" style="color:#0770CC;">{{ $t('paymentMethodTva.txt_7') }}</span></p>
            
            <b-card no-body class="mb-1 border-0" v-for="(value, index) in dataBank_detail" :key="index">
                <b-card-header header-tag="header" class="p-1 bg-white" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="bg-white avenirHeavy fz-14" variant=""> {{ value.name }} </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body class="avenirORoman fz-14">
                    <b-card-text v-for="(valuex, index) in value.detail" :key="index">{{ valuex.no }}.  {{ valuex.description }}</b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>

        </div>

        <button class="btn btn-outline-danger float-left hov" @click="changeMethod"> {{ $t('paymentMethodTva.txt_9') }} </button>
            <a href="#top" v-smooth-scroll> 
                <button class="btn btn-danger float-right bgDefaultMerah" @click="finish"> {{ $t('paymentMethodTva.txt_10') }} </button>
            </a>

        <div class="clearfix"></div>
    </div>
</template>
<script>

import axios from "axios";
import { Helper } from '../../../helper.js';
import { Master } from '../../../model/Master.js';
import { Validate } from '../../../model/Validate.js';
import Config from "../../../model/Config"
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import swal from 'sweetalert';

export default {
    mixins:[Validate,Master,Helper],
    components:{
        PulseLoader
    },
    data() {
        return {

             is_runTest:false,
            runTestResult:false,
            vaData:{
              kode_payment:""
            },
            runTestParameter:"",
            runTestHeader:null,
            runTest_setDataPayment:null,

            data_url:Config,
            dataBank_detail:[],
            payment:{
                methodName:null,
                virtualName:null,
                imageName:null,
                nomorVA:null,
            }
        }
    },
    props:{
        id:String,
        delMethodPayment:Function,
        dataBank:Object,
        dataPayment:Object,
        orderRekap:Object,
        is_ticket:Boolean,

        is_payment_ticket: {
            type: Boolean,
            default: true
        },

         is_runTestProp:{
            type: Boolean,
            default: false
        },
        data_available_travel:{
            type: Object,
            default: null
        }
    },
    async created(){
        this.getName();

        console.log(this.dataBank)
        console.log(this.dataPayment)

        this.getInfoPay();

        try {
              this.dataBank_detail = this.dataBank.cara_pembayaran
        } catch (error) {
            this.dataBank_detail = []
        }
      
    },
    methods:{
        async getInfoPay(){
            
            this.loading_api = true

            let formData = new URLSearchParams(); 

           

            if( this.dataBank.va_number == false ){
                swal("Ada Kesalahan", "Maaf, Kode Pembayaran Untuk Metode Pembayaran Ini Belum Tersedia", "error");
                this.$emit('delMethodPaymentByApi');
                return false
            }

             if( !this.is_runTestProp ){

                formData.append('id', this.dataPayment.id);
                formData.append('psi_m_payment_method_id', this.dataBank.id);
                formData.append('kode_payment', this.dataBank.va_number);

            }else{
                formData = this.runTestParameter
            }

            let headerSet = null
            if( this.is_runTestProp ){
                console.log("" + formData );
                console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader
            }else{
                 if(this.is_ticket){
                     headerSet = this.dataHeaderWithTrx_Token()
                }else{
                     headerSet = this.dataHeaderWithTrx_Token(2)
                }
            }   
            
            await axios.post( this.data_url.setPSIPaymentInvoice + "?" + formData, null,  headerSet ).then(res => {            
                console.log(res.data)
                if( res.data.codestatus != "S" || res.data.resultdata[0].kode_payment == 'false' ){
                     swal({
                            title: "Ada Kesalahan",
                            text: "Maaf, Metode pembayaran yang dipilih mengalami gangguan, Silahkan memilih metode pembayaran lainya",
                            icon: '/img/imgjrg/error.png'
                        });
                    this.$emit('delMethodPaymentByApi');
                }else{
                     this.runTestResult = true;
                     this.vaData = res.data.resultdata[0];
                }

                if(  res.data.codestatus == "TX" ){
                    swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                    this.$parent.nonActiveLevelOne();
                    return false
                }

            }).catch( ( error ) => {

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                    return false
                }

                if(this.searchWord(error,"401")){
                             swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                            localStorage.removeItem('login');
                            this.$router.push('/redirect')
                        }
                        
                    

                this.$emit('delMethodPaymentByApi');
                console.log(error)
            });

            this.loading_api = false

        },
        copyCodeVirtual(){
             navigator.clipboard.writeText(  this.vaData.kode_payment );
        },
        finish(){
            this.$emit('finish');    
        },
        changeMethod(){
            this.$emit('delMethodPayment');
        },

        totalHarga(){

            if( this.is_ticket )
            {

                try {
                    if( this.orderRekap.data_trx2.length > 0 ){
                    return this.orderRekap.data_trx2[0].total_amount
                    }
                    
                } catch (error) {
                    return this.orderRekap.data_trx[0].total_amount
                }
            }else{
                 try {
                    return this.orderRekap.data_packet.total_fix
                    } catch (error) {
                        return 0 ;
                }
            }

        },

        getName(){
            switch (this.dataBank.name) {
                case "BCA VA":
                    this.payment.methodName = "BCA Virtual"
                    this.payment.virtualName = "BCA Vritual Account"
                    this.payment.imageName = 'mp_bca.png';
                break;
                case "Mandiri VA":
                    this.payment.methodName = "Mandiri Virtual"
                    this.payment.virtualName = "Mandiri Vritual Account"
                    this.payment.imageName = 'mp_mandiri.png';
                break;
                case "BNI VA":
                    this.payment.methodName = "BNI Virtual"
                    this.payment.virtualName = "BNI Vritual Account"
                    this.payment.imageName = 'mp_bni.png';
                break;
                case "BSI VA":
                    this.payment.methodName = "BSI Virtual"
                    this.payment.virtualName = "BSI Vritual Account"
                    this.payment.imageName = 'mp_bsi.png';
                break;
                case "BRI VA":
                    this.payment.methodName = "BRI Virtual"
                    this.payment.virtualName = "BRI Vritual Account"
                    this.payment.imageName = 'mp_bri.png';
                break;
                  
            }
        }
    }
}
</script>