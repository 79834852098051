

import * as moment from "moment/moment";

export const Helper = {
    methods:{


        /**
         * ========================================================================================================================= 
         *                                                               UMUM
         * =========================================================================================================================
         */

        random(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * 
         charactersLength));
           }
           return result;
        },

          /**
         * ========================================================================================================================= 
         *                                                               FORM DATA
         * =========================================================================================================================
         */

        // convert base64 to upload form data
        dataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    
            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)
    
            return new Blob([ia], { type: mimeString })
        },


          /**
         * ========================================================================================================================= 
         *                                                                 SPLIT
         * =========================================================================================================================
         */

        
        splitDate( v, index ){
            let a = v.split(" ");
            return a[index];
        },

        splitSpace(v, index = 100){
            if(index == 100){
                return v.split(" ");
            }else{
                let a = v.split(" ");
                return a[index];
            }
           
        },

        splitTitikDua(v, index = 100){
            if(index == 100){
                return v.split(":");
            }else{
                let a = v.split(":");
                return a[index];
            }
           
        },

          /**
         * ========================================================================================================================= 
         *                                                                CONVERT
         * =========================================================================================================================
         */

        convertToRupiah(angkaInt)
        {
            let angka = angkaInt.toFixed();

            var rupiah = '';		
            var angkarev = angka.toString().split('').reverse().join('');
            for(var i = 0; i < angkarev.length; i++) if(i%3 == 0) rupiah += angkarev.substr(i,3)+'.';
            return 'Rp. '+rupiah.split('',rupiah.length-1).reverse().join('');
        },

      

        numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

       

       

        snackBar(color, text){
            this.snackbar=true
            this.snackbar_text=text
            this.snackbar_color=color
        },

        searchWord(whatString, searchWord){
            let n = String(whatString)
            let t = n.search(searchWord);
            if(t>0){
                return true
            }
                return false

        },
    
        callLogOut(){
            localStorage.clear();
            // this.$router.push('/dashboard');
            this.$router.push('/');
        },

        wrongToken(msg){
            if( msg == "Wrong Token" ){
                localStorage.clear();
                this.$router.push('/');
            }else{
                console.log(msg)
            }
        },

        trims(msg){
            return String(msg).trim()
        },

        wrongTokenCo(res){
            if( res.data.msg == "Wrong Token" ){
                localStorage.clear();
                this.$router.push('/');
            }else{
                console.log(res)
            }
        },

        toLowerCase(string){
            return string.toString().toLowerCase();
        },

        cekData(data, sub = null){
            try {
                let a = null
                if(sub == null){
                    if( data == null || data == '' || data == undefined ){
                        a = false
                    }else{
                        a = true
                    }
                }else{
                    if( this.getNested(data, sub) == null || 
                    this.getNested(data, sub) == '' || 
                    this.getNested(data, sub) == undefined ){
                        a = false
                    }else{
                        a = true
                    }
                }

                return a
               
            } catch (error) {
                return false
            }
        },
       
        getNested (theObject, path, separator) {
            try {
                separator = separator || '.';
            
                let d = path.
                        replace('[', separator).replace(']','').
                        split(separator).
                        reduce(
                            function (obj, property) { 
                                return obj[property];
                            }, theObject
                        );
                
                if(d == false){
                    return '' 
                }
                if(d == null ){ 
                    return '' 
                }
                if(d == undefined ){ 
                    return '' 
                }

                return d
                            
            } catch (err) {
                return '';
            }   
        },

        cekFalse(data){
            if(data == false || data == 'false'){
                return ''
            }else{
                return data
            }
        },

        /**
         * ========================================================================================================================= 
         *                                                                  NOMINAL MONEY
         * =========================================================================================================================
         */

        cekDataNominal(data, data1){
            try {
                return Intl.NumberFormat().format( this.getNested(data, data1) )
            } catch (error) {
                return 0
            }
        },


        toMoney(number){
            return new Intl.NumberFormat().format(number)
        },


        /**
         * ========================================================================================================================= 
         *                                                                  DATE DATE
         * =========================================================================================================================
         */

        dateParse(data){
            var d = Date.parse(data);

            var setDate = new Date(d);

            let month = setDate.getMonth() + 1
            let day = setDate.getDate()
            let year = setDate.getFullYear()
            if(day<=9)
                day = '0' + day
            if(month<10)
                month = '0' + month
            return  year + '-' + month + '-' + day
        },

        dataDateTimeAPI(data){
            let dateOrder = data;
            dateOrder = dateOrder.split(".");
            let devdat_replace =  dateOrder[0].replace("T", " ");
            return devdat_replace
        },

        dataDateNowFull(){

            let d = new Date();

            let curr_date = d.getDate();
            let curr_month = d.getMonth();
            let curr_year = d.getFullYear();

            let curr_min = d.getMinutes();
            let curr_hr = d.getHours();
            let curr_sc = d.getSeconds();

            return curr_year + "-" + ( curr_month < 10 ? "0" + curr_month : curr_month ) + "-" + ( curr_date < 10 ? "0" + curr_date : curr_date ) + " " + ( curr_min < 10 ? "0" + curr_min : curr_min ) + ":" + ( curr_hr < 10 ? "0" + curr_hr : curr_hr ) + ":" + ( curr_sc < 10 ? "0" + curr_sc : curr_sc ) ;

        },

        moveDayMonthYear(data){
            let d = data.split("-");
            return d[2] + "-" + d[1] + "-" + d[0]; //  run : yyyy-mm-dd to result : dd-mm-yyyy
        },

        dayName(date){
            let dt = moment(date, "YYYY-MM-DD HH:mm:ss");
            return dt.format('dddd');  // run : yyyy-mm-dd to result : sunday, monday
        },

        dayNameId(date){
            let days = ['Mingu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
            let d = new Date(date);
            return days[d.getDay()]; // run : yyyy-mm-dd to result : Senin, Minggu, Selasa
        },

        dayNameFullId(date){
           
            let days = ['Mingu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
            let months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli','Agustus','September','Oktober','November','Desember'];
            let d = new Date(date);

            // alert(date);
            return days[d.getDay()] + ", " + d.getDate() + " " + months[d.getMonth()] + " " + d.getFullYear(); // run : yyyy-mm-dd to result : Senin, Minggu, Selasa
        },


      

        /**
         * ========================================================================================================================= 
         *                                                                TIME TIME
         * =========================================================================================================================
         */

        timeDifference(previous) {
    
            let msPerMinute = 60 * 1000;
            let msPerHour = msPerMinute * 60;
            let msPerDay = msPerHour * 24;
            let msPerMonth = msPerDay * 30;
            let msPerYear = msPerDay * 365;

            let dtUTC = new Date(previous)
            let dateNow = new Date(new Date().toISOString().replace("T"," "));
            console.log(dtUTC)
            console.log(dateNow)

            let elapsed = dateNow - dtUTC;
            
            if (elapsed < msPerMinute) {
                 return Math.round(elapsed/1000) + ' Detik lalu';   
            }
            
            else if (elapsed < msPerHour) {
                 return Math.round(elapsed/msPerMinute) + ' Menit lalu';   
            }
            
            else if (elapsed < msPerDay ) {
                 return Math.round(elapsed/msPerHour ) + ' Jam lalu';   
            }
        
            else if (elapsed < msPerMonth) {
                 return 'Sekitar ' + Math.round(elapsed/msPerDay) + ' Hari lalu';   
            }
            
            else if (elapsed < msPerYear) {
                 return 'Sekitar ' + Math.round(elapsed/msPerMonth) + ' Bulan Lalu';   
            }
            
            else {
                 return 'Sekitar ' + Math.round(elapsed/msPerYear ) + ' Tahun Lalu';   
            }
        },

        getTimeTravel(data){
            try {
                let d = data.split(":")
                return d[0] + ":" +d[1]  // run : "07:21:00" to result : 07:21
            } catch (error) {
                error
            }
          
        },

       


        
        /**
         * ========================================================================================================================= 
         *                                                                 VALIDATE
         * =========================================================================================================================
         */

        validateEmail(email) {
            //eslint-disable-next-line
            if (/^[\-0-9a-zA-Z\.\+_]+@[\-0-9a-zA-Z\.\+_]+\.[a-zA-Z]{2,}$/.test(email)) {
                return true
            } else {
                return false
            }
        },

        isKosong(v){
            try {
                if( v == "" || v == null || v == "null" ){
                    return true
                }else{
                    return false
                }
            } catch (error) {
                return true
            }
        },
        isKosong2(v){
            try {
                if( v == "" || v == null || v == "null" || v == undefined || v == 'undefined' ){
                    return true
                }else{
                    return false
                }
            } catch (error) {
                return true
            }
        },


       

        isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
          },
        
        getLogin(){
            // Retrieves the user token from localStorage
            try {
                let data = this.cookies.get("token")
                if( data == null || typeof data === 'undefined')
                {
                    data = localStorage.getItem("token");
                    if( data == null || typeof data === 'undefined')
                    {
                        return null
                    }
                }
                return JSON.parse(data);
            } catch (error) {
                return null;
            }
        },

        phoneIsValid(p) {
            if( p.length > 10 ){
                var phoneRe = /^[0-9]+$/;
                var digits = p.replace(/\D/g, "");
                return phoneRe.test(digits);
            }
            return false
        },


        /**
         * ========================================================================================================================= 
         *                                                                 HELPER INTERNAL
         * =========================================================================================================================
         */
        
        cekNull(data){
            try {
                if( data != null & data != "" & data != undefined ) return data
            } catch (error) {
                return ""
            }
        },

         getDataInfo(data, selected) {

            try {

                for (let i = 0; i < data.length; i++) {
                    if( data[i].code == selected ){
                        return data[i];
                    }
                }
                
            } catch (error) {

                console.log(error)
                
            }

        },



    }
}