<template>
  <div>
    
    <div class="col-lg-12 p-3 mt-0" v-if="showPassenger == 'no_data'">
        <div class="row pt-2" style="border-radius:0px; border:1px solid #D1D1D1;">
           <p class="avenirORoman text-center fz-16">   {{ $t('profile_listpassenger.text_1') }}</p>
        </div>

        <button class="btn btn-danger col-lg-12 mt-3 rounded"> {{ $t('profile_listpassenger.text_2') }}</button>
    </div>


    <!-- <div class="bg" v-if="showPassenger == 'any_data'">
        <p class="avenirHeavy fz-22" >  {{ $t('profile_listpassenger.text_3') }} Daftar Penumpang </p>
        <p class="venirRoman fz-16">  {{ $t('profile_listpassenger.text_4') }}Anda dapat menambahkan sampai dengan 3 Penumpang </p>
        <div class="col-lg-12 p-3 " v-if="showPassenger == 'any_data'">
            <div class="row pt-2 bg-white" style="border-radius:20px; border:1px solid #D1D1D1;">
               <p class="col-lg-12"><i class="fa fa-address-book-o" aria-hidden="true"></i>  1. Tuan Atha Rayhaan <span class="text-primary pointer avenirMedium fz-18 float-right ml-2 mr-2"> Hapus </span> <span class="text-primary pointer avenirMedium fz-18 float-right"> Edit </span> </p>
            </div>

            <button class="btn btn-danger col-lg-12 mt-3" style="border-radius:20px;">Tambah Penumpang</button>
        </div>
    </div> -->

     <div class="col-lg-12 pt-0 " v-if="showPassenger == 'edit_data'">
        <div class="row pt-2" style="border-radius:0px; border:1px solid #D1D1D1; background-color:white;">
            <p class="fz-22 avenirHeavy pl-3"><i class="fa fa-address-card" aria-hidden="true"></i> {{ $t('profile_listpassenger.text_3') }}</p>
           <p class="avenirORoman pl-3 fz-16">{{ $t('profile_listpassenger.text_4') }}</p>
        </div>
       

        <div class="row pt-2 mt-3 mb-3" style="border-radius:0px; border:1px solid #D1D1D1; background-color:white;">
            <p class="fz-22 col-lg-12 avenirHeavy pl-3"> {{ $t('profile_listpassenger.text_5') }}</p>
            <p class="avenirORoman pl-3 fz-16">{{ $t('profile_listpassenger.text_6') }}</p>
        </div>

        <div>
            <div class="row pt-2 mt-3 mb-3" style="border-radius:0px; border:1px solid #D1D1D1; background-color:white;" v-show="data_passenger.length > 0">
                <div class="text-center col-lg-12" v-if="!template_passenger">
                     <pulse-loader :color="'#ffffff'"></pulse-loader>
                </div>
                <p class="fz-22 col-lg-12 avenirHeavy pl-3" v-for="(value,index) in data_passenger" :key="index"><i class="fa fa-user" aria-hidden="true"></i> {{ index + 1 }}. {{ value.name }}
                    <button :disabled="loading_api" class="float-right btn btn-outline-danger btn-sm" @click="delPenumpang(value)"><span v-if="!loading_api"><i class="fa fa-trash-o" aria-hidden="true"></i> {{ $t('profile_listpassenger.text_7') }}</span>
                    <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                    </button> 
                    <button class="float-right btn btn-outline-danger btn-sm mr-2" @click="editPenumpang(value)" ><i class="fa fa-cog" aria-hidden="true"></i> {{ $t('profile_listpassenger.text_8') }}</button>
                </p>
                
            </div>
        </div>

        <div class="row">
            <form>
                <div  style="border-radius:0px; border:1px solid #D1D1D1; background-color:white;" class="form-row p-3 mb-1  bg-white mt-3" v-if="data_passenger.length < 4 || id_edit != null">

                    <div class="col-lg-12 pt-2">
                        <span class="card-title avenirHeavy fz-16">{{ $t('profile_listpassenger.text_9') }} </span>
                        <div class="border-bottom mb-3 mt-2"></div>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="avenirMedium fz-14 regulir abuabu">{{ $t('profile_listpassenger.text_10') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span> </label>
                        <b-form-select class="hitamc2Child" v-model="passenger_sebutan" :options="this.$t('locale') == 'id' ? options_call_type : options_call_type_inggris"></b-form-select>
                    </div>

                        <div class="form-group col-md-9">
                        <label class="avenirMedium fz-14 regulir abuabu">{{ $t('profile_listpassenger.text_12') }}<span style="font-weight:bold; color:red; font-size:16px;" > *</span></label>
                        <input type="text"  v-model="passenger_name_depan" class="form-control hitamc2Child" id="inputPassword4" placeholder="">
                    </div>

                        <div class="form-group col-md-3">
                        <label class="avenirMedium fz-14 regulir abuabu">{{ $t('profile_listpassenger.text_15') }} </label>
                        <b-form-select class="hitamc2Child" v-model="passenger_id_type" :options="options_identity_type"></b-form-select>
                    </div>

                    <div class="form-group col-md-9">
                        <label class="avenirMedium fz-14 regulir abuabu">{{ $t('profile_listpassenger.text_16') }}</label>
                        <input type="number" v-model="passenger_id_identity" class="form-control hitamc2Child" id="inputEmail4" placeholder="">
                    </div>

                    <div class="alert alert-warning" role="alert" v-if="data_validate.length > 0">
                        <b>{{ $t('profile_listpassenger.text_18') }} </b>
                        <ol>
                            <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                        </ol>                            
                    </div>

                    <button :disabled="loading_api" @click="actionSimpanPenumpang" class="btn btn-outline-danger col-lg-12 mt-3 rounded">
                         <span v-if="!loading_api">
                         <i class="fa fa-floppy-o" aria-hidden="true"></i> {{ id_edit == null ? "Tambah Data Penumpang" : "Perbarui Data Penumpang" }} 
                         </span>
                        <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                    </button>
                     <button v-if="id_edit != null" @click="batalEdit" class="btn btn-outline-danger col-lg-12 mt-3 rounded"><i class="fa fa-close" aria-hidden="true"></i> {{ $t('profile_listpassenger.text_19') }} </button>

                </div>
            </form>
        </div>

       
    </div>



  </div>
</template>
<script>

import { Helper } from '../helper.js';
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import swal from 'sweetalert';

export default {
  mixins:[Validate,Master,Helper],   
  name: "components",
  components: {
    PulseLoader
  },
  data() {
        return {

        is_runTest:false,
        runTestResult:false,
        runTestParameter:"",
        runTestHeader:null,

        data_url:Config,
        data_validate:[],
        options_call_type:[
            { value: 'bapak', text: 'Bapak' },
            { value: 'ibu', text: 'Ibu' },
            { value: 'saudara', text: 'Saudara' },
            { value: 'saudari', text: 'Saudari' },
            { value: 'tuan', text: 'Tuan' },
            { value: 'nyonya', text: 'Nyonya' },
        ],

        options_call_type_inggris:[
            { value: 'bapak', text: 'Sir' },
            { value: 'ibu', text: 'Madam' },
            { value: 'saudara', text: 'Brother' },
            { value: 'saudari', text: 'Miss' },
            { value: 'tuan', text: 'Mr' },
            { value: 'nyonya', text: 'Mrs' },
        ],

        template_passenger:false,

        options_identity_type:[
                { value: '', text: 'Tanpa Identitas' },
                { value: 'ktp', text: 'KTP' },
                { value: 'sim', text: 'SIM' },
                { value: 'paspor', text: 'Paspor' }
            ],
        showPassenger:'edit_data', // no_data // any_data // edit_data ,
        passenger_sebutan:"bapak",
        passenger_name_depan:null,
        passenger_id_type:"",
        passenger_id_identity:null,
        id_edit:null,
        data_passenger:[],
        }
  },
  created(){
      this.getPenumpang();
     
  },
  methods: {
    
    async getPenumpang(){

        this.loading_api = true
        this.template_passenger = false

          let formData = new URLSearchParams(); 

            if( !this.is_runTest ){
                formData.append('lang',  this.$t('locale_lang') )
            }else{
                formData = this.runTestParameter;
            }

            let headerSet = null;
            if( this.is_runTest ){

                if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
                headerSet = this.runTestHeader

            }else{
                
                headerSet = this.dataHeaderWithTrx_Token(2)
            }   

         await axios.post( this.data_url.getPenumpang + "?" + formData, null, headerSet ).then(async res => { 

                 if( !this.is_runTest ) console.log(res.data)
                
                if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                if( res.data.codestatus == "S" ){
                    this.runTestResult = true
                    this.data_passenger =  res.data.resultdata
                
                }

            }).catch( ( error ) => {
                console.log(error)

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"401")){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            });

        this.loading_api = false
         this.template_passenger = true

    },
    async delPenumpang(value){

        this.loading_api = true
         let formData = new URLSearchParams();
         if( !this.is_runTest ){
            formData.append('id', String(value.id) );
        }else{
            formData = this.runTestParameter;
        } 

        let headerSet = null;
        if( this.is_runTest ){

        if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
            headerSet = this.runTestHeader
        }else{
            headerSet = this.dataHeaderWithTrx_Token(2)
        }   
   
         await axios.post( this.data_url.deletePenumpang  + "?" + formData, null, headerSet ).then(async res => { 
                
                 if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                if( res.data.codestatus == "S" ){
                    this.runTestResult = true;
                    this.getPenumpang();
                    this.batalEdit();
                  
                
                }

            }).catch( ( error ) => {

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                if(this.searchWord(error,"401")){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                console.log(error)
            });

        this.loading_api = false

    },

    async editPenumpang(data){
        console.log(data)
        this.id_edit = data.id
        this.passenger_sebutan = String(data.title_key).toLowerCase()
        this.passenger_name_depan = data.name
        this.passenger_id_type = ( this.isKosong2(data.identity_type) ? "" : data.identity_type )
        this.passenger_id_identity =  data.identity_no

    },

    async batalEdit(){
        this.id_edit = null
        this.passenger_sebutan = "bapak"
        this.passenger_name_depan = null
        this.passenger_id_type = ""
        this.passenger_id_identity = null
    },

    async actionSimpanPenumpang(){

       

        let formData = new URLSearchParams(); 

        if( !this.is_runTest ){

            if( this.id_edit != null  ){
                formData.append('id', String(this.id_edit) );
            }

            console.log("data " + String(this.passenger_sebutan));

            this.data_validate = [];

            if(  String(this.passenger_sebutan) == "" ||  this.passenger_sebutan == null ){
                this.data_validate.push("Panggilan Untuk Penumpang Harus Di isi")
            }    

            if( String(this.passenger_name_depan) == "" || this.passenger_name_depan == null ){
                this.data_validate.push("Nama Penumpang Harus Di isi")
            }   

            // ktp ada, dan nomor ktp tidak di isikan
            if( String(this.passenger_id_type) != "" & this.isKosong2(String(this.passenger_id_identity)) ){
                this.data_validate.push("Nomor Identitas Harus Di Isikan")
            
            // ktp ada, dan nomor ktp di isikan
            } else if ( String(this.passenger_id_type) != "" & ! this.isKosong2(String(this.passenger_id_identity) )) {
                 formData.append('identity_no', String(this.passenger_id_identity) );
            } else {
                 formData.append('identity_no', "" );
            }

            if( this.data_validate.length > 0 ){
                return false
            }

            this.loading_api = true
            formData.append('title', String(this.passenger_sebutan));
            formData.append('name', String(this.passenger_name_depan));
            formData.append('identity_type', ( String(this.passenger_id_type) == "" ? "" : String(this.passenger_id_type)) );
            formData.append('lang', this.$t('locale_lang') )

        }else{
            formData = this.runTestParameter;
        } 

        let headerSet = null;
        if( this.is_runTest ){

        if( !this.is_runTest ) console.log(JSON.stringify( this.runTestHeader ));
        headerSet = this.runTestHeader
        }else{
            headerSet = this.dataHeaderWithTrx_Token(2)
        }   


        await axios.post( this.data_url.setPenumpang + "?" + formData, null, headerSet ).then(async res => { 
        
        if(  res.data.codestatus == "X" ){
             swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
            localStorage.removeItem('login');
            this.$router.push('/redirect')
        }

        if( res.data.codestatus == "S" ){
            this.runTestResult = true;

            this.getPenumpang();
            this.batalEdit();

            window.scrollTo(0,700);
        
        }

        }).catch( ( error ) => {

              try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }
                  }
                  
                } catch (errorq) {
                  errorq
                }

             if(this.searchWord(error,"401")){
                 swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                localStorage.removeItem('login');
                this.$router.push('/redirect')
            }

             if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

            console.log(error)
        });

      
        this.loading_api = false

    }
    
  }
};
</script>
<style>
.pointer{
    cursor: pointer;
}



.borderbottomnyala{
    border-bottom-style: solid; 
    border-bottom-color: #235C9F;
}

.borderbottomnonyala{
    border-bottom-style: solid; 
    border-bottom-color: #bbbbbb;
}

.borderlengkung{
    border-radius:15px;
}

</style>
