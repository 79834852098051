<template>
    <section>
        <div class="container-fluit" style="margin-top:-10px; background-color:#FF0000;">
            <div class="container pt-4 pb-4">
                <div class="col-lg-12 p-4 text-white" >
                    <p class="avenirHeavy fz-35">
                         {{ $t('register.text_1') }} 
                    </p>
                    <p  class="avenirHeavy fz-16">
                         {{ $t('register.text_2') }} 
                    </p>
                </div>
            </div>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center" >

                 <div class="col-lg-6">
                 
                     <div class="row ">
                         <div class="col-lg-12 mb-4">
                             <p><img src="img/imgjrg/Safe_Transaction.svg" class="mr-3" style="float:left">
                             <span class="avenirMedium fz-24">  {{ $t('register.text_3') }} </span><br/>
                                <span class="fz-16 avenirORoman">  {{ $t('register.text_4') }}  </span>
                            </p>
                         </div>
                         <div class="col-lg-12 mb-4">
                            <p><img src="img/imgjrg/Seat_Booking.svg" class="mr-3" style="float:left">
                                    <span class="avenirMedium fz-24">  {{ $t('register.text_5') }}  </span><br/>
                                    <span  class="fz-16 avenirORoman">  {{ $t('register.text_6') }}  </span>
                            </p>
                         </div>
                         <div class="col-lg-12">
                            <p><img src="img/imgjrg/Payment-method.svg" class="mr-3" style="float:left">
                                    <span class="avenirMedium fz-24"> {{ $t('register.text_7') }}  </span><br/>
                                        <span  class="fz-16 avenirORoman">  {{ $t('register.text_8') }}  </span>
                            </p>
                         </div>
                     </div>
                 </div>

                <div class="col-lg-6">

                    <!-- inputNoHpEmail -->
                    <card shadow
                          v-if="formRegister == 'inputNoHpEmail'"
                          class="offset-lg-4 col-lg-8 df">
                        <template>
                            <div class="text-center mb-3 avenirHeavy fz-24">
                                 {{ $t('register.text_9') }} 
                            </div>
             
                            <div class="row text-center">
                                <div @click="showHp" :class="'col-lg-6 col-6 pointer pb-2 ' + classBtnNoHp"> {{ $t('register.text_10') }}</div>
                                <div @click="showEmail" :class="'col-lg-6 col-6 pointer pb-2 '+ classBtnEmail"> {{ $t('register.text_11') }}</div>
                            </div>

                            <form role="form" class="mt-3">

                                <base-input alternative
                                            v-model="input.phone"
                                            v-if="showRegister == 'nohp'"
                                            type="number"
                                            disabled
                                            class="mb-3"
                                            placeholder="No Telepon"
                                            addon-left-icon="ni ni-mobile-button">
                                </base-input>

                                <validate-temp v-if="showRegister == 'email'" :format="validateTemplate.email" ></validate-temp>

                                <base-input alternative
                                            v-model="input.email"
                                            v-if="showRegister == 'email'"
                                            class="mb-3"
                                            placeholder="Email"
                                            addon-left-icon="ni ni-email-83">
                                </base-input>

                                <p class="avenirORoman fz-10"><i class="fa fa-lock" aria-hidden="true"></i>  {{ $t('register.text_12') }}</p>
                               
                                <div class="text-center">
                                    <base-button type="danger" :disabled="loading_api == true || showRegister == 'nohp'" class="my-4" @click="toVerifikasi()">
                                        <span v-if="!loading_api"> {{ $t('register.text_13') }}</span>
                                        <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                    </base-button>
                                </div>

                                <div class="col-lg-12 text-center ">
                                    <p class="avenirORoman fz-12"> {{ $t('register.text_14') }}</p>
                                </div>
                            </form>
                        </template>
                    </card>


                     <!-- verifikasi -->
                    <card shadow
                          v-if="formRegister == 'verifikasi'"
                          class="offset-lg-4 col-lg-8 df">
                        <template>
                            <div class="text-center mb-3 avenirHeavy fz-24">
                                 {{ $t('register.text_16') }}
                            </div>

                             <div class="col-lg-12 text-center ">
                                <p class="avenirORoman fz-15"> {{ $t('register.text_17') }} <br/>
                                        <span class="font-weight-bold">{{ verifikasiTo }}</span><br/>
                                         {{ $t('register.text_18') }}
                                </p>
                            </div>


                             <p class="avenirORoman fz-16"><i class="fa fa-lock" aria-hidden="true"></i>  {{ $t('register.text_19') }}</p>
                            <form role="form" class="mt-3">

                                <base-input alternative
                                            v-model="input.code_verifikasi"
                                            class="mb-3"
                                            placeholder="Kode Verifikasi">
                                </base-input>

                                <p class="avenirMedium fz-16">  {{ $t('register.text_20') }}
                                    <span class="text-primary" v-if="isCoolDown == false"> {{ timeCoolDown.cdMinutes }}:{{ timeCoolDown.cdSecond }}</span> 
                                    <span class="text-success" v-if="isCoolDown" @click="toVerifikasi">  {{ $t('register.text_21') }} </span> 
                                </p>
                            
                         
                                <div class="text-center">
                                    <base-button type="danger" :disabled="loading_api" class="my-4" @click="toInfoAuth">
                                    <span v-if="!loading_api"> {{ $t('register.text_22') }}</span>
                                    <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                    </base-button>

                                </div>

                            </form>

                        </template>
                    </card>




                     <!-- InfoAuth -->
                    <card shadow
                          v-if="formRegister == 'InfoAuth'"
                          class="offset-lg-4  col-lg-8 df">
                        <template>
                            <div class="text-center mb-3 avenirHeavy fz-24">
                                {{ $t('register.text_23') }} 
                            </div>

                             <div class="col-lg-12 text-center ">
                                <p class="avenirORoman fz-15"> {{ $t('register.text_24') }}</p>
                            </div>


                           
                            <form role="form" class="mt-3">
                                <p class="avenirORoman fz-16">  {{ $t('register.text_25') }}</p>

                                <base-input alternative
                                            class="mb-3"
                                            v-model="identitas.full_name"
                                            placeholder="Nama Lengkap">
                                </base-input>
                                <p class="avenirORoman fz-16">  {{ $t('register.text_26') }}</p>

                                <base-input alternative
                                            type="password"
                                            v-model="identitas.password1"
                                            class="mb-3"
                                            placeholder="Sandi">
                                </base-input>
                                <p class="avenirORoman fz-16"> {{ $t('register.text_27') }}</p>

                                <base-input alternative
                                            type="password"
                                             v-model="identitas.password2"
                                            class="mb-3"
                                            placeholder="Ulangi sandi">
                                </base-input>

                                <!-- <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                    <label class="form-check-label" for="exampleCheck1"> {{ $t('register.text_28') }}</label>
                                </div> -->

                                <div class="text-center">
                                    <base-button type="danger" class="my-4" @click="toIdentitas"> {{ $t('register.text_29') }}</base-button>
                                </div>

                            </form>

                        </template>
                    </card>




                     <!-- identitas -->
                    <card shadow
                          v-if="formRegister == 'identitas'"
                          class="offset-lg-4  col-lg-8 df">
                        <template>
                            <div class="text-center mb-3 avenirHeavy fz-24">
                                 {{ $t('register.text_30') }} 
                            </div>

                             <div class="col-lg-12 text-center ">
                                <p class="avenirORoman fz-15">  {{ $t('register.text_31') }} </p>
                            </div>


                           
                            <form role="form" class="mt-3">
                                <p class="avenirORoman fz-16">   {{ $t('register.text_32') }}  </p>

                                <b-form-select :options="optionsGender" v-model="identitas.gender"></b-form-select>

                                <p class="avenirORoman fz-16 mt-2">   {{ $t('register.text_33') }} </p>

                                <datetime style="width:100%;" format="DD-MM-YYYY" :lang="lang" v-model="identitas.date_birth" type="date" input-class="form-control"></datetime>
                          
                                <div class="text-center">
                                    <base-button type="danger" :disabled="loading_api" class="my-4" @click="toFinish">  
                                        <span v-if="!loading_api">{{ $t('register.text_34') }}</span>
                                        <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                    </base-button>
                                </div>


                                 <b-modal id="bv-modal-thanks-register" hide-header hide-footer
                                        size="md"
                                        body-classes="p-0"
                                        modal-classes="modal-dialog-centered modal-lg">

                                        <div class="row" >
                                          
                                            <div class="col-lg-12 pt-4 text-center">
                                                <p> <span class="avenirORoman fz-26">{{ $t('register.text_35') }}</span> </p>
                                                <p> <span class="avenirORoman fz-16">{{ $t('register.text_36') }}</span> </p>
                                            </div>
 
                                            <div class="col-lg-12 text-center">
                                                <img src="img/imgjrg/wanita.png" height="160" />
                                            </div>

                                              <div class="col-lg-12 pt-4 text-center">
                                                <p> <span class="avenirORoman fz-16">{{ $t('register.text_37') }}</span> </p>
                                            </div>

                                            <div class="col-lg-12 mt-4 text-center">
                                                <button class="btn btn-outline-danger avenirMedium fz-18" @click="toHome">{{ $t('register.text_38') }}</button>
                                            </div>
                                        </div>
                                </b-modal>

                            </form>

                        </template>
                    </card>

                </div>
            </div>
        </div>
    </section>
</template>
<script>

import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import DatePicker from 'vue2-datepicker';
import '../assets/cssVendor/vue-datePicker.css';
import axios from "axios";
import swal from 'sweetalert';
import ValidateTemp from '../model/ValidateTemplate.vue';
import Config from '../model/Config.js'
import { Helper } from '../helper';
import { Master } from '../model/Master.js';
import { Validate } from '../model/Validate.js';

export default {
    mixins: [Master,Validate,Helper],
    components: {
        datetime: DatePicker,
        ValidateTemp,
        PulseLoader
    },
    data(){
        return{
            token_otp:null,
            identitas:{
                full_name:'',
                password1:'',
                password2:'',
                gender:'',
                date_birth:''
            },
            isCoolDown:false,
            validateTemplate:{
                email:{ show:false, mode:1, variant:"danger", text:"apa aja" },
            },
             timeCoolDown:{
                cdMinutes:0,
                cdSecond:0
            },
            input:{
                phone:'',
                email:'',
                code_verifikasi:'',
            },
            data_url:Config,
            classBtnNoHp:"borderbottomnyala",
            classBtnEmail:"",
            showRegister:"nohp",
            formRegister:"inputNoHpEmail", // verifikasi // infoauth // identitas
             optionsGender: [
                { value: 'male', text: 'Laki Laki' },
                { value: 'female', text: 'Perempuan' },
            ],
            date_birth:"",
            lang: {
                formatLocale: {
                    // MMM
                    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
                    // dd
                    weekdaysMin: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                },
            },
            verifikasiTo:''
        }
    },
    methods:{
        showEmail(){

            this.classBtnEmail = "borderbottomnyala";
            this.classBtnNoHp = "";
            this.showRegister = "email"
        },

        showHp(){
            this.classBtnEmail = "";
            this.classBtnNoHp = "borderbottomnyala";    
            this.showRegister = "nohp"
        },

        async toVerifikasi(){

            const formData = new URLSearchParams();            

            if(this.showRegister == 'email'){

                if( this.input.email == "" || this.input.email == null ){
                     
                      swal({
                            title: "Cek Data",
                            text: "Email Kosong",
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false
                }

                if( this.validateEmail( this.input.email ) == false ){
                 
                      swal({
                            title: "Cek Data",
                            text: "Format Email Salah",
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false
                }

               

                formData.append('email', this.input.email)
                this.verifikasiTo = this.input.email

            }else{
                
                 if( this.input.phone == null ||  this.input.phone == "" ){
              
                     swal({
                            title: "Cek Data",
                            text: "No Telepon Kosong",
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false
                }

                 if( Number.isInteger(this.input.phone) ){
                
                    swal({
                            title: "Cek Data",
                            text: "Isi No Telepon Harus Nomor",
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false
                }

                 if( ! this.phoneIsValid( this.input.phone) ){
             
                    swal({
                            title: this.$t('swal.peringatan') ,
                            text:  this.$t('swal.no_telepon_belum_benar'),
                            icon: '/img/imgjrg/antri.png'
                        });
                    return false;
                }


                formData.append('phone', this.input.phone)
                 this.verifikasiTo = this.input.phone
            }

            this.loading_api = true ;
             formData.append('is_newmember', 'Y');
             formData.append('lang', this.$t('locale_lang') )
            await axios.post( this.data_url.send_otp + "?" + formData, null, 
            this.dataHeaderNonAuth ).then(res => {            
                console.log(res)
                if( res.data.codestatus == "S" ){
                     this.formRegister = "verifikasi"
                     this.setCoolDown();
                }else{
                  
                    swal({
                            title: this.$t('swal.ada_kesalahan'),
                            text:res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }

            }).catch( ( error ) => {
                 console.log(error) 
                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
            } );
            this.loading_api = false ;
        },

        toHome(){
            this.$router.push('/');
        },

         setCoolDown(){

            let _ = this;

            this.isCoolDown = false
            let countDownDate = new Date(Date.now() + 2*60000);

            this.goInterval = setInterval(function() {

                // Dapatkan waktu dan hari ini dalam milisecond
                let now = new Date().getTime();

                // Buat jarak antara waktu sekarang dan yang menjadi hitung mundur
                let distance = countDownDate - now;

                // Kalkulasi waktu dalam bulan, hari, jam, menit dan detik
                // let month = Math.floor(distance / (1000 * 60 * 60 * 24 * 30))
                // let days = Math.floor((distance % (1000 * 60 * 60 * 24 *30)) / (1000 *60 * 60 * 24));
                // let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                _.timeCoolDown.cdMinutes = minutes

                if(seconds < 10){
                    _.timeCoolDown.cdSecond = "0" + String(seconds)
                }else{
                    _.timeCoolDown.cdSecond = seconds
                }

                 // Jika hitung mundur selesai, beri keterangan teks
                if (distance <= 0) {
                    clearInterval(_.goInterval);
                    _.timeCoolDown.cdMinutes = "0";
                    _.timeCoolDown.cdSecond = "00";
                    _.isCoolDown = true
                }

            }, 1000);
        },


        async toInfoAuth(){

            this.loading_api = true

            const formData = new URLSearchParams(); 
            formData.append('otp', this.input.code_verifikasi)
            await axios.post( this.data_url.validateOTP + "?" + formData, null, 
            this.dataHeaderNonAuth ).then(res => {            
                console.log(res)
                if( res.data.resultdata[0].validation == true ){
                    this.otp_token = res.data.resultdata[0].otp_token;
                    this.formRegister = "InfoAuth";
                }else{
                     swal({
                            title: "Kode Salah",
                            text: "Cek Kembali Kode Yang Telah Terkirim",
                            icon: '/img/imgjrg/antri.png'
                        });
                }

            }).catch( ( error ) => {
                 console.log(error) 

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

            } );

             this.loading_api = false


           
        },
        toIdentitas(){

            if( this.identitas.full_name == '' ){
                  swal({
                        title: "Data Kurang Lengkap",
                        text: "Nama Lengkap Harus Di isikan",
                        icon: '/img/imgjrg/antri.png'
                    });
                return false
            }

             if( this.identitas.password1.length < 6 ){
                swal({
                        title: "Kata Sandi",
                        text: "Kata sandi harus lebih dari 5 karakter",
                        icon: '/img/imgjrg/antri.png'
                    });
                return false
            } 

            if( this.identitas.password1 == '' ){
                swal({
                        title: "Data Tidak Lengkap",
                        text: "Kata Sandi Harus Di isi",
                        icon: '/img/imgjrg/antri.png'
                    });
                return false
            }

            if( this.identitas.password2 == '' ){
                swal({
                        title: "Data Tidak Lengkap",
                        text: "Konfirmasi Sandi Harus Di isi",
                        icon: '/img/imgjrg/antri.png'
                    });
                return false
            }

            if( this.identitas.password1 != this.identitas.password2 ){
                swal({
                        title: "Data Tidak Valid",
                        text: "kata sandi dan konfirmasi kata sandi tidak sama",
                        icon: '/img/imgjrg/antri.png'
                    });
                return false
            }

            this.formRegister = "identitas"
        },
        async toFinish(){

         
            if( this.identitas.gender == '' ){
                swal({
                                    title: "Data Tidak Lengkap",
                                    text: "Jenis Kelamin Harus Di Isi",
                                    icon: '/img/imgjrg/antri.png'
                                });
                return false
            }

            if( this.identitas.date_birth == '' ){
                 swal({
                        title: "Data Tidak Lengkap",
                        text: "Tanggal Lahir Harus Di isi",
                        icon: '/img/imgjrg/antri.png'
                    });
                return false
            }

            this.loading_api = true

            const formData = new URLSearchParams(); 
            formData.append('name', this.identitas.full_name)
            formData.append('password', this.identitas.password1)
            formData.append('email', this.input.email)
            formData.append('phone', this.input.phone)
            formData.append('gender', this.identitas.gender)
            formData.append('otp_token', this.otp_token)
            formData.append('birthdate', this.dateParse( this.identitas.date_birth ))
            await axios.post( this.data_url.createNewUser + "?" + formData, null, 
            this.dataHeaderNonAuth ).then(res => {            
                console.log(res)
                if( res.data.codestatus == "S" ){
                     this.$bvModal.show('bv-modal-thanks-register');
                }

            }).catch( ( error ) => {
                 console.log(error) 

                  if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                
            } );

            this.loading_api = false


           
        }
    }
};
</script>
<style>

.pointer{
    cursor: pointer;
}

.borderbottomnyala{
    border-bottom-style: solid; 
    border-bottom-color: #235C9F;
}

@media (min-width: 320px) { 

}

@media (min-width: 576px) { 

  
}

@media (min-width: 768px) { 
   
}

@media (min-width: 992px) { 
   
}

@media (min-width: 1200px) { 
    .df{
        position:absolute; 
        top:-220px; 
        z-index:99; 
        right:0px;
    }
}

@media (min-width: 1400px) { 
    .df{
        position:absolute; 
        top:-220px; 
        z-index:99; 
        right:0px;
    }
}

@media (min-width: 1600px) { 
    .df{
        position:absolute; 
        top:-220px; 
        z-index:99; 
        right:0px;
    }
}

</style>
