<template>
    <section id="InputePassenger" class="section section-components pb-5 bg-white" style="background-color:#faf5f5;">
        <div class="container" v-if="orderRekap != null">
            <div class="col-lg-12 font-weight-bold" >
                <span class="avenirHeavy fz-20">{{ $t('inputPassenger.txt_daftar_pesanan') }}</span>
                <p class="avenirHeavy fz-16">{{ $t('inputPassenger.txt_isi_data_penumpang') }}</p>
            </div> 

            <div class="row">
                <div class="col-lg-8">

                    <div class="col-lg-12 mt-3 avenirHeavy fz-20" >
                       {{ $t('inputPassenger.txt_daftar_pemesan_1') }} 
                     </div> 

                    <div class="col-lg-12 mt-2 border shadow-sm p-3 mb-1 bg-white borderradius mt-3">
                            <div class="form-row" v-if="!loading_form_passenger">
                                 <div class="form-group col-md-6" v-show="false">
                                    <label class="avenirMedium fz-14 regulir abuabu">{{ $t('inputPassenger.txt_data_1') }}</label>
                                    <b-form-select class="hitamc2Child" :disabled="is_copypemesanan" :options="options_use_data_people"></b-form-select>
                                </div>

                                 <div class="form-group col-md-3">
                                    <label class="avenirMedium fz-14 regulir abuabu">{{ $t('inputPassenger.txt_data_2') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                     <!-- :disabled="login" -->
                                    <b-form-select class="hitamc2Child hoveroption"  :disabled="is_copypemesanan" v-model="input.sebutan" :options="$t('locale') == 'id' ? options_call_type : options_call_type_inggris"></b-form-select>
                                </div>

                                  <div class="form-group col-md-9">
                                    <label class="avenirMedium fz-14 regulir abuabu">{{ $t('inputPassenger.txt_data_3') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span> </label>
                                    <input type="text" style="border-radius: 4px;" :disabled="is_copypemesanan" v-model="input.nama_depan" class="form-control hitamc2" placeholder="">
                                </div>


                                <div class="form-group col-md-4">
                                    <label class="avenirMedium fz-14 regulir abuabu">{{ $t('inputPassenger.txt_data_4') }} </label>
                                    <b-form-select class="hitamc2Child hoveroption" :disabled="is_copypemesanan" v-model="input_tipe_identitas_computed" :options="options_identity_type"></b-form-select>
                                </div>

                                <div class="form-group col-md-8">
                                    <label class="avenirMedium fz-14 regulir abuabu">{{ $t('inputPassenger.txt_data_5') }}</label>
                                    <input  :disabled="is_copypemesanan == true || disabled_id_identitas == true " type="number"   v-model="input.nomor_identitas" class="form-control hitamc2" id="inputEmail4" placeholder="">
                                    <small>{{ $t('inputPassenger.txt_data_6') }}</small>
                                </div>

                                <div class="form-group col-md-2 col-4">
                                    <label class="avenirMedium fz-14 regulir abuabu">{{ $t('inputPassenger.txt_data_7') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span> </label>
                                    <input  :disabled="is_copypemesanan" type="text" style="text-align:center;" v-model="input.phone_code" readonly class="form-control hitamc2" id="inputEmail4">
                                    <small>{{ $t('inputPassenger.txt_data_8') }} </small>
                                </div>

                                <div class="form-group col-md-5 col-8">
                                    <label class="avenirMedium fz-14 regulir abuabu">  .</label>
                                    <input type="number" v-model="input.phone" class="form-control hitamc2" id="inputEmail4" placeholder="">
                                    <small>{{ $t('inputPassenger.txt_data_9') }} </small>
                                </div>

                                <div class="form-group col-md-5">
                                    <label class="avenirMedium fz-14 regulir abuabu">Email <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                     <!-- :disabled="login" -->
                                    <input  type="text" v-model="input.email" class="form-control hitamc2" id="inputEmail4" placeholder="">
                                    <small>{{ $t('inputPassenger.txt_data_10') }} </small>
                                </div>
                            </div>
                            <div v-else class="form-row">
                                <pulse-loader :color="'#f5365c'"></pulse-loader>
                            </div>
                     
                    </div>  
                    <div class="row">
                        <div class="col-lg-12 mt-3 avenirHeavy fz-20" >
                             {{ $t('inputPassenger.txt_data_11') }}
                            <div class="float-right fz-16 avenirMedium" style="margin:auto;">
                                <div class="mt-1 float-left mr-1" >{{ $t('inputPassenger.txt_data_12') }}</div>
                                
                                <switchButton @action="copyDataOrder" ></switchButton></div>
                            
                        </div> 
                    </div>

                    <div class="col-lg-12 pl-0 pr-0">
                        <form :key="componentPenumpangKey">
                            <div class="form-row border shadow-sm p-3 mb-1 borderradius bg-white mt-3" v-for="index in orderRekap.data_pesan[4].value" :key="index">
                                <div class="col-lg-12 pt-2">
                                    <span class="card-title avenirHeavy fz-14 regulir abuabu"> {{ $t('inputPassenger.txt_data_13') }}  {{ index }}</span>
                                     <b-dropdown v-if="login" id="dropdown-1" :text="$t('locale') == 'id' ? 'Ambil Data Penumpang' : 'Retrieve Passenger Data'" class="m-md-2 float-right hitamc2">
                                        <b-dropdown-item class="clickMerahip" v-for="(value,indexx) in data_passenger_admin" @click="setDataPenumpangAuto( value ,index)" :key="'a' + indexx" >{{ indexx + 1 }}. {{ value.name }}</b-dropdown-item>
                                    </b-dropdown><br/><br/>
                                    <div class="border-bottom mb-3 mt-2"></div>
                                    <div class="clearfix"></div>
                                </div>

                                <div class="form-group col-md-3">
                                    <label class="avenirMedium fz-14 regulir abuabu"> {{ $t('inputPassenger.txt_data_14') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                    <b-form-select class="hitamc2" :disabled="passenger_dd[index-1]" v-model="passenger_sebutan[index-1]" :options="$t('locale') == 'id' ? options_call_type : options_call_type_inggris "></b-form-select>
                                </div>

                                <div class="form-group col-md-9">
                                    <label class="avenirMedium fz-14 regulir abuabu"> {{ $t('inputPassenger.txt_data_15') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                    <input type="text"  :disabled="passenger_dd[index-1]" v-model="passenger_name_depan[index-1]" class="form-control hitamc2" id="inputPassword4" placeholder="">
                                </div>

                                <div class="form-group col-md-9">
                                    <label class="avenirMedium fz-14 regulir abuabu"> {{ $t('inputPassenger.txt_data_7') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                    <input type="text" v-model="passenger_phone[index-1]" class="form-control hitamc2" id="inputPhonePassenger41" placeholder="">
                                </div>

                                <div class="form-group col-md-9">
                                    <label class="avenirMedium fz-14 regulir abuabu"> {{ $t('inputPassenger.txt_data_16a') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                    <input type="text" v-model="passenger_alamat1[index-1]" class="form-control hitamc2" id="inputAlamat41" placeholder="">
                                </div>

                                <div class="form-group col-md-9">
                                    <label class="avenirMedium fz-14 regulir abuabu"> {{ $t('inputPassenger.txt_data_16b') }} <span style="font-weight:bold; color:red; font-size:16px;" >*</span></label>
                                    <input type="text" v-model="passenger_alamat2[index-1]" class="form-control hitamc2" id="inputAlamat42" placeholder="">
                                </div>

                                <div class="form-group col-md-4">
                                    <label class="avenirMedium fz-14 regulir abuabu"> {{ $t('inputPassenger.txt_data_16') }} </label>
                                    <b-form-select class="hitamc2" :disabled="passenger_dd[index-1]" v-model="passenger_id_type[index-1]" :options="options_identity_type"></b-form-select>
                                </div>

                                <div class="form-group col-md-8">
                                    <label class="avenirMedium fz-14 regulir abuabu"> {{ $t('inputPassenger.txt_data_17') }} </label>
                                    <input  :disabled="passenger_dd[index-1]" type="number" v-model="passenger_id_identity[index-1]" class="form-control hitamc2" id="inputEmail4" placeholder="">
                                    <small> {{ $t('inputPassenger.txt_data_18') }}</small>
                                </div>
                            </div>
                        </form>

                       <div  class="alert alert-warning" role="alert" v-if="data_validate.length > 0">
                           <b> {{ $t('inputPassenger.txt_data_19') }}</b>
                            <ol>
                                <li  v-for="(value,index) in data_validate" :key="index">{{ value }}</li>
                            </ol>                            
                        </div>

                        <button type="submit" class="btn btn-secondary float-left" :disabled="loading_api" @click="batalOrder">
                            <span v-if="!loading_api"> {{ $t('inputPassenger.txt_data_20_1') }}</span>
                            <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                        </button>
                      
                        <button type="submit" class="btn btn-danger float-right bgDefaultMerah" :disabled="loading_api" @click="activeLevelThree">
                            <span v-if="!loading_api"> {{ $t('inputPassenger.txt_data_20') }}</span>
                            <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                        </button>

                        <!-- <button type="submit" class="btn btn-outline-danger float-right mr-2 ml-2" :disabled="loading_api_kursi" @click="processSeat">
                            <span v-if="!loading_api_kursi">Pilih Kursi</span>
                            <pulse-loader :color="'#ffffff'" :loading="loading_api_kursi"></pulse-loader>
                        </button> -->

                        <!-- no-close-on-backdrop -->
                        <b-modal id="bv-modal-seat-position" hide-header hide-footer
                                size="lg"
                                body-classes="p-0"
                                no-close-on-backdrop
                                :static="true"
                                modal-classes="modal-dialog-centered modal-lg">

                                <div class="row">
                                    <div class="col-lg-5">
                                        
                                        <div class="alert alert-primary avenirHeavy fz-16 mb-2" style="background-color:#0770CC;" role="alert">
                                               {{ $t('inputPassenger.txt_pilih_kursi_berangkat') }}
                                        </div>

                                        <span class="avenirHeavy fz-16" >{{ orderRekap.data_pesan[2].name }} - {{ orderRekap.data_pesan[3].name }}</span>
                                        <p class="avenirORoman fz-12">{{  orderRekap.data_travel.travel_1.psi_m_kendaraan_name }} | {{ orderRekap.data_travel.travel_1.range }}</p>

                                        <div v-for="(item, index) in this.dataPassengerSeat.keberangkatan" :key="index">
                                            <div class="col-lg-12">
                                                <span class="font-weight-bold avenirHeavy fz-16" style="color:#0770CC;"> {{ index + 1 }}. {{ item.nama_lengkap }}  </span> 
                                            </div>
                                            <div class="col-lg-12 pl-5">
                                                <span class="font-weight-bold avenirHeavy fz-16">  {{ $t('inputPassenger.txt_data_21') }} {{ item.kursi }} </span> 
                                                <button class="btn btn-outline-danger btn-sm float-right" style="margin-top:-1px;" v-if="changeChair.passenger_id != item.passenger_id" @click="selectChangeChair(item,index)" >{{ $t('inputPassenger.txt_data_22') }} </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                            <div class="col-lg-12">
                                                
                                                    <div class="form-row border mb-2 pb-2">
                                                        <div class="col-md-12 mb-3">
                                                            <div style="height:55px; background-color:#e0e0e0 !important; color:#000000;" class="alert alert-warning text-center avenirMedium fz-16 pt-2" role="alert">
                                                            {{ $t('inputPassenger.txt_data_23') }} <span class="font-weight-bold display-4" style="color:#ff0000;"> {{ timeCoolDown.cdMinutes }}:{{ timeCoolDown.cdSecond }} </span>
                                                            </div>
                                                        </div>
                                                    
                                                        <div class="col-md-12">
                                                            <div class="row">
                                                                <!-- <div class="col-lg-4 col-4">
                                                                    <span style="width:30px; height:30px;border-radius:10px; background-repeat:round; border-color:#f2f2f2;" class="bg-white float-left" >
                                                                        <img width="32" src="img/imgjrg/toilet.svg" style="margin-top:3px;" />
                                                                    </span>
                                                                    <div class="align-top mt-2 avenirMedium fz-12"  style="margin-left:40px;"> Toilet</div>
                                                                </div>
                                                                <div class="col-lg-4 col-4">
                                                                    <span style="width:30px; height:30px;border-radius:10px; background-repeat:round; border-color:#f2f2f2;" class="bg-white float-left" >
                                                                        <img width="32" src="img/imgjrg/driver.svg" style="margin-top:3px;" />
                                                                    </span>
                                                                    <div class="align-top mt-2 avenirMedium fz-12" style="margin-left:40px;"> Sopir </div>
                                                                </div>
                                                                <div class="col-lg-4 col-4">
                                                                   <button style="width:30px; height:30px;border-radius:10px; border-color:#235C9F; cursor:pointer;" class="bg-white text-danger  float-left"> 00 </button> 
                                                                    <div class="align-top avenirMedium fz-12" style="margin-left:40px; margin-top:6px;"> Merokok </div>
                                                                </div>
                                                                <br/><br/> -->
                                                                <div class="col-lg-4 col-4">
                                                                    <button style="width:30px; height:30px;border-radius:6px; border:1px solid #b5afaf;" class="bg-white float-left" ></button> 
                                                                    <div class="align-top mt-1 avenirMedium fz-12" style="margin-left:40px; margin-top:6px;"> {{ $t('inputPassenger.txt_data_24') }} </div>
                                                                </div>
                                                                <div class="col-lg-4 col-4">
                                                                     <button style="width:30px; height:30px;border-radius:6px; background: url(img/imgjrg/silang.png); background-repeat:round;  border:1px solid #b5afaf;" class="bg-white  float-left" ></button> 
                                                                    <div class="align-top avenirMedium fz-12" style="margin-left:40px; margin-top:8px;"> {{ $t('inputPassenger.txt_data_25') }} </div>
                                                                </div>
                                                                <div class="col-lg-4 col-4">
                                                                     <button style="width:30px; height:30px;border-radius:6px; background-color:#0064d2;  border:1px solid #b5afaf;" class=" float-left" ></button> 
                                                                    <div class="align-top mt-1  avenirMedium fz-12" style="margin-left:40px; margin-top:8px;"> {{ $t('inputPassenger.txt_data_26') }} </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                            
                                                    <div class="col-lg-12 border" style="padding:10px 2px 10px 2px; margin-bottom:10px;" :key="componentKey">
                                                        <div v-show="loading_api_pilihkursi" class="loader"></div>
                                                        <div class="row m-1 justify-content-center" v-for="index in seat.baris" :key="index">

                                                            <div v-for="(seatConf, indexx) in seat.template" :key="indexx">
                                                                <div class="col-lg-2 col-3 p-1 justify-content-center"  v-if="seatConf.baris == index">

                                                                    <buttonSeat :typeBtn="'keberangkatan'" v-if="seatConf.status == '7' || seatConf.status == '8' || seatConf.status == '1' || ( seatConf.status == '2' & seatConf.passenger_id != false & seatConf.passenger_id != null )" :type_button="seat.v_seat_status[seatConf.psi_m_detail_kursi_id]" @chooseSeatKeberangkatan="chooseSeat" :text_button="seatConf.psi_m_detail_kursi_name" :idSeat="[seatConf.psi_m_detail_kursi_id, seatConf.passenger_id, seatConf.psi_m_detail_kursi_name]" ></buttonSeat>

                                                                    <div style="width:40px;" v-if="seatConf.status == '5'"></div>

                                                                    <buttonSeat v-if="seatConf.status == '9'" :type_button="'toilet'" ></buttonSeat>

                                                                    <buttonSeat v-if="seatConf.status == '10'" :type_button="'extra'" ></buttonSeat>

                                                                    <buttonSeat v-if="seatConf.status == '3'" :type_button="'na'" ></buttonSeat>

                                                                    <buttonSeat v-if="seatConf.status == '6'" :type_button="'driver'" ></buttonSeat>

                                                                    <buttonSeat v-if="( seatConf.status == '2' || seatConf.status == '8') & seatConf.passenger_id == false" :type_button="'silang'" ></buttonSeat>

                                                                    <buttonSeat v-if="( seatConf.status == '2' || seatConf.status == '8') & seatConf.passenger_id == null" :type_button="'silang'" ></buttonSeat>

                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                       
                                                    </div>
                                            </div>
                                    </div>
                                </div>

                                <div class="row border-top pt-3" v-if="orderRekap.data_pesan[5] == true">
                                    <div class="col-lg-5">

                                        <div class="alert alert-primary avenirHeavy fz-16 mb-2" style="background-color:#0770CC;" role="alert">
                                               {{ $t('inputPassenger.txt_pilih_kursi_pulang') }}
                                        </div>

                                        <span class="avenirHeavy fz-16" >{{ orderRekap.data_pesan[3].name }} - {{ orderRekap.data_pesan[2].name }}</span>
                                        <p class="avenirORoman fz-12">{{  orderRekap.data_travel.travel_2.psi_m_kendaraan_name }} | {{ orderRekap.data_travel.travel_2.range }}</p>

                                        <div v-for="(item, index) in this.dataPassengerSeat2.kepulangan" :key="index">
                                            <div class="col-lg-12">
                                                <span class="font-weight-bold avenirHeavy fz-16" style="color:#0770CC;"> {{ index + 1 }}. {{ item.nama_lengkap }}  </span> 
                                            </div>
                                            <div class="col-lg-12 pl-5">
                                                <span class="font-weight-bold avenirHeavy fz-16"> {{ $t('inputPassenger.txt_data_27') }}   {{ item.kursi }} </span> 
                                                  <button style="margin-top:-1px;" class="btn btn-outline-danger btn-sm float-right" v-if="changeChair2.passenger_id != item.passenger_id" @click="selectChangeChair2(item,index)" >{{ $t('inputPassenger.txt_data_28') }} </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                            <div class="col-lg-12" >
                                                
                                                    <div class="col-lg-12 border" style="padding:10px 2px 10px 2px; margin-bottom:10px;" :key="componentKey2" v-if="showChair2">
                                                   
                                                        <div class="row m-1 justify-content-center" v-for="index in seat.baris2" :key="index">

                                                            <div v-for="(seatConf, indexx) in seat.template2" :key="indexx">
                                                                <div class="col-lg-2 col-3 p-1 justify-content-center" v-if="seatConf.baris == index">
                                                                        <buttonSeat 
                                                                            v-if="seatConf.status == '7' || seatConf.status == '8' || seatConf.status == '1' || (
                                                                            seatConf.status == '2' & seatConf.passenger_id != false & seatConf.passenger_id != null )" :type_button="seat.v_seat_status2[seatConf.psi_m_detail_kursi_id]" :typeBtn="'kepulangan'" @chooseSeatKepulangan="chooseSeatHome" :text_button="seatConf.psi_m_detail_kursi_name" :idSeat="[seatConf.psi_m_detail_kursi_id, seatConf.passenger_id, seatConf.psi_m_detail_kursi_name]" ></buttonSeat>

                                                                        <buttonSeat v-if="seatConf.status == '6'" :type_button="'driver'" ></buttonSeat>

                                                                        <div style="width:40px;" v-if="seatConf.status == '5'"></div>

                                                                        <buttonSeat v-if="seatConf.status == '10'" :type_button="'extra'" ></buttonSeat>

                                                                        <buttonSeat v-if="seatConf.status == '9'" :type_button="'toilet'" ></buttonSeat>

                                                                        <buttonSeat v-if="seatConf.status == '3'" :type_button="'na'" ></buttonSeat>

                                                                        <buttonSeat v-if="( seatConf.status == '2' || seatConf.status == '8') & seatConf.passenger_id == false" :type_button="'silang'" ></buttonSeat>

                                                                        <buttonSeat v-if="( seatConf.status == '2' || seatConf.status == '8') & seatConf.passenger_id == null" :type_button="'silang'" ></buttonSeat>
                                                                </div>
                                                            </div>
                                                          
                                                        </div>
                                                       
                                                    </div>
                                            </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                            <a href="#top" v-smooth-scroll><button type="submit" :disabled="loading_api" @click="processSeatPosition()" class="btnHov btn btn-danger float-right avenirMedium bgDefaultMerah fz-18" style="border-radius:8px !important;">
                                                    <span v-if="!loading_api">{{ $t('inputPassenger.txt_data_29') }} </span>
                                                    <pulse-loader :color="'#ffffff'" :loading="loading_api"></pulse-loader>
                                                    </button>
                                            </a>
                                    </div>
                                </div>
                               
                        </b-modal>

                        <div class="clearfix"></div>
                    </div>  
                </div>
                    <div class="col-lg-4 pt-3 d-none d-lg-block">
                        <div class="col-lg-12  border border shadow-sm rounded bg-white p-3 font-weight-bold">
                            <div class="float-left avenirHeavy fz-16" style="margin-top:5px;"> {{ orderRekap.data_pesan[2].name }} </div>
                            <img class="float-left ml-4 mr-4" src="img/imgjrg/sync.svg" width="32" />
                            <div class="float-left  avenirHeavy fz-16" style="margin-top:5px;"> {{ orderRekap.data_pesan[3].name }}</div>
                            <div class="clearfix"></div>
                        </div> 

                        <div class="col-lg-12  pl-3 border border shadow-sm rounded bg-white pt-4">
                            <p class="pz-16 avenirHeavy">{{ dayNameFullId(orderRekap.data_pesan[0])  }}</p>
                                <br/>
                                <span class="pz-16 avenirHeavy">Travel</span> <br/>
                                <span class="pz-14 avenirMedium" >{{  orderRekap.data_travel.travel_1.psi_m_kendaraan_name }} </span> <br/><br/>

                            <div class="float-left">
                                <p class="avenirMedium fz-14">{{  getTimeTravel(orderRekap.data_travel.travel_1.time_from) }}</p>
                                <p class="avenirORoman fz-12">{{ orderRekap.data_travel.travel_1.range }}</p>
                                <p class="avenirMedium fz-14">{{  getTimeTravel(orderRekap.data_travel.travel_1.time_to) }}</p>
                            </div>
                            <div class="float-left ml-2 mr-2">
                                <img src="img/imgjrg/panahbawah.png" />
                            </div>
                            <div class="float-left">
                                <p class="avenirMedium fz-14">{{ orderRekap.data_pesan[2].name }}</p>
                                <p>.</p>
                                <p class="avenirMedium fz-14">{{ orderRekap.data_pesan[3].name }}</p>
                            </div>
                            <div class="clearfix"></div>
                        </div>

                        <div class="col-lg-12  pl-3 border border shadow-sm rounded bg-white pt-4" v-if="orderRekap.data_pesan[5] == true">
                            <p class="pz-16 avenirHeavy">{{ dayNameFullId(orderRekap.data_pesan[1])  }}</p>
                                <br/>
                                <span class="pz-16 avenirHeavy">Travel</span> <br/>
                                <span class="pz-14 avenirMedium" >{{  orderRekap.data_travel.travel_2.psi_m_kendaraan_name }} </span> <br/><br/>

                            <div class="float-left">
                                <p class="avenirMedium fz-14">{{  getTimeTravel(orderRekap.data_travel.travel_2.time_from) }}</p>
                                <p class="avenirORoman fz-10">{{ orderRekap.data_travel.travel_2.range }}</p>
                                <p class="avenirMedium fz-14">{{  getTimeTravel(orderRekap.data_travel.travel_2.time_to) }}</p>
                            </div>
                            <div class="float-left ml-2 mr-2">
                                <img src="img/imgjrg/panahbawah.png" />
                            </div>
                            <div class="float-left">
                                <p class="avenirMedium fz-14">{{ orderRekap.data_pesan[3].name }}</p>
                                <p>.</p>
                                <p class="avenirMedium fz-14">{{ orderRekap.data_pesan[2].name }}</p>
                            </div>
                            <div class="clearfix"></div>
                        </div>

                        <div class="col-lg-12 mt-5 border border shadow-sm rounded bg-white p-3 font-weight-bold">
                            <div class="float-left avenirHeavy fz-16"> {{ $t('inputPassenger.txt_data_30') }}  </div>
                            <div class="float-right avenirHeavy fz-16" style="color:#0770CC;">{{ convertToRupiah(total_harga) }} </div>
                            <div class="clearfix"></div>
                        </div> 

                        <div class="col-lg-12 pb-2 pl-2 border border shadow-sm rounded bg-white pt-4">

                             <div class="col-lg-12">
                                    <div class="float-left avenirMedium fz-14"> {{ orderRekap.data_pesan[2].name }} - {{ orderRekap.data_pesan[3].name }}</div>
                                    <div class="float-right avenirMedium fz-14"> {{ convertToRupiah(orderRekap.data_trx[0].order_lines[0].total_harga) }}</div>
                                </div>

                                <div class="clearfix"></div>

                             <div class="col-lg-12" v-if="orderRekap.data_pesan[5] == true">
                                    <div class="float-left avenirMedium fz-14">{{ orderRekap.data_pesan[3].name }} - {{ orderRekap.data_pesan[2].name }}</div>
                                    <div class="float-right avenirMedium fz-14">{{ convertToRupiah(orderRekap.data_trx2[0].order_lines[1].total_harga) }}</div>
                                </div>

                                <div class="clearfix"></div>
                              
                        </div>
                    </div>
                 </div>
            
           
        </div>
    </section>

</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import axios from "axios";
import buttonSeat from "./InputSeatPositionBtn";
import swal from 'sweetalert';

import Config from "../../../model/Config"
import { Helper } from '../../../helper.js';
import { Master } from '../../../model/Master.js'
import { Validate } from '../../../model/Validate.js';
import switchButton from '../JavascriptComponents/switchButton'


export default {
    
    mixins:[Validate,Master,Helper],
    components:{
        buttonSeat,
        PulseLoader,
        switchButton
    },
    props:{
        is_runTestProp:{
            type: Boolean,
            default: false
        },
        data_available_travel:{
            type: Object,
            default: null
        }
    },
    data() {
        return {

            is_runTest:false,
            runTestResult:false,
            runTestParameter:"",
            runTestHeader:null,
            dataKursi_testResult:null,
            runtest_dataPassengerSeat:null,
            setDataPenumpang:null,
            orderRekap:null,

            input:{
                sebutan:"bapak",
                sebutan_baca:"Bapak",
                nama_depan:null,
                nama_belakang:null,
                tipe_identitas:"",
                nomor_identitas:null,
                name_order:null,
                phone:null,
                phone_code:"+62",
                email:null,
                alamat1:null,
                alamat2:null
            },
            is_copypemesanan:false,
            data_validate:[],

            dataPassengerSeat:{
                keberangkatan:[],
                maxPilih:0,
                nowPilih:0,
                pilihKursiPassengerName:null,
                pilihKursiPassengerId:null,
                pilihKursiPassengerIdArray:null,
            },

            dataPassengerSeat2:{
                kepulangan:[],
                maxPilih:0,
                nowPilih:0,
                pilihKursiPassengerName:null,
                pilihKursiPassengerId:null,
                pilihKursiPassengerIdArray:null,
            },
            data_passenger_admin:null,
            total_harga:0,
            coolDownTime:0,

            loading_api_pilihkursi:false,
            data_url:Config,

            componentPenumpangKey:0,

            showChair2:true,

            passenger_sebutan:["bapak","bapak","bapak"],
            passenger_name_depan:[],
            passenger_name_belakang:[],
            passenger_id_type:["","",""],
            passenger_id_identity:[],
            passenger_dd:[],
            passenger_phone:[],
            passenger_alamat1:[],
            passenger_alamat2:[],

            dataPesan:null,
            dataIdBooking:[],
            timeCoolDown:{
                cdMinutes:0,
                cdSecond:0
            },
            componentKey:0,
            componentKey2:0,

             options_use_data_people:[
                { value: null, text: 'Pakai Data Baru' },
                { value: 'a', text: 'Muhammad Miftahul Ulum' },
                { value: 'b', text: 'Budi Darmanto' }
             ],

             options_identity_type:[
                { value: '', text: 'Tanpa Identitas' },
                { value: 'ktp', text: 'KTP' },
                { value: 'sim', text: 'SIM' },
                { value: 'paspor', text: 'Paspor' }
             ],

             options_call_type:[
                { value: 'bapak', text: 'Bapak' },
                { value: 'ibu', text: 'Ibu' },
                { value: 'saudara', text: 'Saudara' },
                { value: 'saudari', text: 'Saudari' },
                { value: 'tuan', text: 'Tuan' },
                { value: 'nyonya', text: 'Nyonya' },
             ],

             options_call_type_inggris:[
                { value: 'bapak', text: 'Sir' },
                { value: 'ibu', text: 'Madam' },
                { value: 'saudara', text: 'Brother' },
                { value: 'saudari', text: 'Miss' },
                { value: 'tuan', text: 'Mr' },
                { value: 'nyonya', text: 'Mrs' },
             ],

            v_use_data_people:[],
             
            v_identity_id:[],
            v_identity_type:[],
            v_identity_name:[],

            loading_api_kursi:false,
            loading_api:false,
            login:false,

            flag_habis_do:false,

            disabled_id_identitas:false,

            seat:{
                template:null,
                template2:null,
                baris:null,
                baris2:null,
                v_seat_status:[],
                v_seat_status2:[]
            },

            changeChair:{
                passenger_id:null
            },
            changeChair2:{
                 passenger_id:null
            },
            dataProfile:null,
            loading_form_passenger:false,
        }
    },

    computed:{
        input_tipe_identitas_computed:{
            get(){
                return this.input.tipe_identitas
            },
            set(newVal){

                this.input.tipe_identitas = newVal;

                if( newVal == '' ){
                    // this.disabled_id_identitas = true
                    this.input.nomor_identitas = ''
                }else{
                    // this.disabled_id_identitas = false
                }


            }
        }
    },

    async created(){

        if( !this.is_runTestProp ){
              this.orderRekap = {
                data_pesan: this.$store.state.dataPesan,
                data_trx:this.$store.state.dataTrx,
                data_trx2:this.$store.state.dataTrxTwo,
                data_travel:this.$store.state.dataChooseTravel
            }
           
        }

        this.loading_api = true
        this.loading_form_passenger = true

        this.totalHarga();

        if( localStorage.getItem("login") != null){
    
            this.login = true;

            this.getPenumpang();
            let formData = new URLSearchParams(); 
            formData.append('lang',  this.$t('locale_lang') )

            await axios.post( this.data_url.getUserInfo + "?" + formData, null, this.dataHeaderWithTrx_Token(2) ).then(async res => { 
                
                if(  res.data.codestatus == "X" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                console.log( res.data );

               

                if( res.data.codestatus == "S" ){
                    this.dataProfile = res.data.resultdata[0]

                    let sebutan =  String(this.dataProfile.title_key).toLowerCase();
                    let tipe_identitas = this.dataProfile.identity_type

                    this.input.nama_depan = this.dataProfile.name
                    this.input.nama_belakang = this.dataProfile.name
                    this.input.sebutan = ( this.isKosong2(sebutan ) ? "bapak" : sebutan )
                    this.input.sebutan_baca = String(this.dataProfile.title).toLowerCase()
                    this.input.phone = this.dataProfile.mobile
                    this.input.email = this.dataProfile.email
                    this.input.tipe_identitas = ( this.isKosong2(tipe_identitas ) ? "" : tipe_identitas )
                    this.input.nomor_identitas = this.dataProfile.identity_no
                }

                if(  res.data.codestatus == "TX" ){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/')
                }
                
            }).catch( ( error ) => {

                  try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                console.log(error)
            });

        }

        this.loading_api = false;
        this.loading_form_passenger = false
        if( !this.is_runTestProp ){ console.log(this.orderRekap); }
    },
    watch:{

        $route(to, from){
            console.log(to + " - " + from);

            // try {
            //      clearInterval(this.goInterval);
            // } catch (error) {
            //      console.log(error)
            // }
          
        }

    },
    methods:{

        setDataPenumpangAuto(data, index){

            this.passenger_sebutan[index - 1] = String(data.title).toLowerCase();
            this.passenger_name_depan[index - 1] = data.name
            if( data.identity_type != false & data.identity_type != "false" ){
                 this.passenger_id_type[index - 1] = data.identity_type
            }else{
                 this.passenger_id_type[index - 1] = "";
            }
               
            this.passenger_id_identity[index - 1] = data.identity_no

            this.componentPenumpangKey += 1; 
        },

        copyDataOrder(){
            if( !this.is_copypemesanan ){
                this.is_copypemesanan = true

                this.passenger_sebutan[0] = String(this.input.sebutan).toLowerCase();
                this.passenger_name_depan[0] = this.input.nama_depan
                this.passenger_name_belakang[0] = this.input.nama_belakang
                this.passenger_id_type[0] = this.input.tipe_identitas
                this.passenger_id_identity[0] = this.input.nomor_identitas
                this.passenger_phone[0] = this.input.phone
                this.passenger_alamat1[0] = this.input.alamat1
                this.passenger_alamat2[0] = this.input.alamat2

                this.passenger_dd[0] = true

            }else{
                this.passenger_dd[0] = false

                this.is_copypemesanan = false

                 this.passenger_sebutan[0] = "bapak"
                this.passenger_name_depan[0] = ""
                this.passenger_name_belakang[0] = ""
                this.passenger_id_type[0] = ""
                this.passenger_id_identity[0] = ""
                this.passenger_phone[0] = ""
                this.passenger_alamat1[0] = ""
                this.passenger_alamat2[0] = ""

            }

            this.componentPenumpangKey += 1; 
        },

        async  batalOrder(){
            
            this.loading_api = true

            await axios.post( this.data_url.cancelPSIOrder, null, this.dataHeaderWithTrx_Token(1)).then(res => {            
                if( res.data.codestatus == "S" ){

                      this.$router.push('/redirect')
                    // window.scrollTo(0,0);
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                    
                }

                if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                // console.log(error)
            });

             this.loading_api = false

        },
        

        processGetData(){
            console.log(this.orderRekap);
        },

        selectChangeChair(dataPassenger,index){

            console.log(dataPassenger)

            this.changeChair = dataPassenger
            this.changeChair.index = index
            this.changeChair.status = true

        },
       

        async chooseSeat(idSeat){

            if(  this.changeChair.status != true ){
                return false
            }

            this.loading_api_pilihkursi = true

            let formData = new URLSearchParams(); 

             if(!this.is_runTestProp){ 
                formData.append('psi_t_order_line_id', this.orderRekap.data_trx[0].order_lines[0].id);
                formData.append('psi_t_passenger_id', this.changeChair.passenger_id );
                formData.append('psi_m_kursi_baru_id', idSeat[0] );
                formData.append('psi_m_kursi_awal_id', this.changeChair.kursi_id );
             }else{

                formData = this.runTestParameter;
             }  
          
      
            await axios.post( this.data_url.changePSIKursi + "?" + formData, null, 
             ( !this.is_runTestProp ? this.dataHeaderWithTrx_Token() : this.runTestHeader) ).then(async res => {            
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true        

                    this.runtest_dataPassengerSeat =  this.dataPassengerSeat
                    
                    this.dataPassengerSeat.keberangkatan[this.changeChair.index].kursi =  idSeat[2] ;
                    this.dataPassengerSeat.keberangkatan[this.changeChair.index].kursi_id = idSeat[0];

                    this.orderRekap.data_trx[0].order_lines[0].passengers[this.changeChair.index].kursi = idSeat[2]
                    this.orderRekap.data_trx[0].order_lines[0].passengers[this.changeChair.index].kursi_id = idSeat[0]

                    this.changeChair.kursi_id = idSeat[0]
                    this.changeChair.kursi_name = idSeat[2]

                    this.runtest_dataPassengerSeat =  this.dataPassengerSeat

                }

                if(  res.data.codestatus == "TX" ){
                        swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                        this.$parent.nonActiveLevelOne();
                }

                if( res.data.resultdata.length > 0 ){
               
                     //UPDATE POSISI KURSI
                    this.seat.template = res.data.resultdata[0].detail_kursi

                    this.updatePosisi_1();

                }

                   
            }).catch( ( error ) => {
                console.log(error)
            });

            this.loading_api_pilihkursi = false
            this.componentKey += 1;

        },

         selectChangeChair2(dataPassenger,index){

            console.log(dataPassenger)

            this.changeChair2 = dataPassenger
            this.changeChair2.index = index
            this.changeChair2.status = true

        },

        updatePosisi_1(){

            let sumBaris = 0;
            let flagBaris = "";
            for (let i = 0; i <  this.seat.template.length; i++) {

                if(  this.seat.template[i].baris != flagBaris ){
                    flagBaris = this.seat.template[i].baris
                    sumBaris = 1 + sumBaris;
                }     
    
                if( this.seat.template[i].status == '1' ){
                    this.seat.v_seat_status[this.seat.template[i].psi_m_detail_kursi_id] = 'kosong'
                }

                if( this.seat.template[i].status == '7' ){
                    
                    this.seat.v_seat_status[this.seat.template[i].psi_m_detail_kursi_id] = 'kosong_rokok'
                }

                if( ( this.seat.template[i].status == '2' ||  this.seat.template[i].status == '8') & this.seat.template[i].passenger_id > 0 ){
                    this.seat.v_seat_status[this.seat.template[i].psi_m_detail_kursi_id] = 'dipilih'
                }

            }

            this.seat.baris = sumBaris

        },

        updatePosisi_2(){

            let sumBaris = 0;
            let flagBaris = "";
            for (let i = 0; i <  this.seat.template2.length; i++) {

                if(  this.seat.template2[i].baris != flagBaris ){
                    flagBaris = this.seat.template2[i].baris
                    sumBaris = 1 + sumBaris;
                }     
    
                if( this.seat.template2[i].status == '7' ){
                    this.seat.v_seat_status2[this.seat.template2[i].psi_m_detail_kursi_id] = 'kosong_rokok'
                }

                if( this.seat.template2[i].status == '1' ){
                    this.seat.v_seat_status2[this.seat.template2[i].psi_m_detail_kursi_id] = 'kosong'
                }

                if( ( this.seat.template2[i].status == '2' || this.seat.template2[i].status == '8' ) & this.seat.template2[i].passenger_id > 0){
                    this.seat.v_seat_status2[this.seat.template2[i].psi_m_detail_kursi_id] = 'dipilih'
                }

            }

            this.seat.baris2 = sumBaris
        },

        async chooseSeatHome(idSeat){

            if(  this.changeChair2.status != true ){
                return false
            }
            
            this.loading_api_pilihkursi = true


             let formData = new URLSearchParams();

             if(!this.is_runTestProp){

                formData.append('psi_t_order_line_id', this.orderRekap.data_trx2[0].order_lines[1].id);
                formData.append('psi_t_passenger_id', this.changeChair2.passenger_id );
                formData.append('psi_m_kursi_baru_id', idSeat[0] );
                formData.append('psi_m_kursi_awal_id', this.changeChair2.kursi_id );

             }else{

                 formData = this.runTestParameter;

             }
            
      
            await axios.post( this.data_url.changePSIKursi + "?" + formData, null, this.dataHeaderWithTrx_Token() ).then(async res => {            
                if( res.data.codestatus == "S" ){

                    this.runTestResult = true

                    this.dataPassengerSeat2.kepulangan[this.changeChair2.index].kursi =  idSeat[2];
                    this.dataPassengerSeat2.kepulangan[this.changeChair2.index].kursi_id = idSeat[0];

                    this.orderRekap.data_trx2[0].order_lines[1].passengers[this.changeChair2.index].kursi = idSeat[2]
                    this.orderRekap.data_trx2[0].order_lines[1].passengers[this.changeChair2.index].kursi_id = idSeat[0]

                    this.changeChair2.kursi_id = idSeat[0]
                    this.changeChair2.kursi_name = idSeat[2]

                }

                if(  res.data.codestatus == "TX" ){
                        swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                         this.$router.push('/redirect')
                }


                if( res.data.resultdata.length > 0 ){
                  //UPDATE POSISI KURSI
                    this.showChair2 = false
                    this.seat.template2 = res.data.resultdata[0].detail_kursi

                    this.updatePosisi_2();

                }

                   
            }).catch( ( error ) => {
                console.log(error)
            });

            this.loading_api_pilihkursi = false
          

            this.showChair2 = true
            this.componentKey2 += 1;
        },

        totalHarga(){

            try {
                if( this.orderRekap.data_trx2.length > 0 ){
                    this.total_harga =  this.orderRekap.data_trx2[0].total_amount
                }
                
            } catch (error) {
                 this.total_harga = this.orderRekap.data_trx[0].total_amount
            }

        },

        async processSeat(){

            let _ = this

                let formData = new URLSearchParams(); 
            if( !this.is_runTestProp ){
                formData.append('psi_t_order_line_id', this.orderRekap.data_trx[0].order_lines[0].id);
            }else{
                formData = this.runTestParameter;
            }
           
             await axios.post( this.data_url.getPSIAvailableKursi + "?" + formData, null, ( !this.is_runTestProp ? this.dataHeaderWithTrx_Token() : this.runTestHeader) ).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.runTestResult = true;
                    this.dataKursi_testResult = res.data.resultdata[0].detail_kursi
                    
                    console.log(res.data.resultdata)
                    this.seat.template = res.data.resultdata[0].detail_kursi

                    this.coolDownTime = res.data.resultdata[1].time_range
                    this.setCoolDown();

                   
                    this.updatePosisi_1();          

                    this.dataPassengerSeat.nowPilih = this.passenger_name.length
                    this.dataPassengerSeat.maxPilih = this.passenger_name.length
                    this.dataPassengerSeat.nowPilih = this.passenger_name.length
                    
                }

                if(  res.data.codestatus == "TX" ){
                    swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                    _.$router.push('/redirect')
                }


            }).catch( ( error ) => {
                console.log(error)
            });

        },

        async processSeat2(){


            let formData = new URLSearchParams(); 
            formData.append('psi_t_order_line_id', this.orderRekap.data_trx2[0].order_lines[1].id);
             await axios.post( this.data_url.getPSIAvailableKursi + "?" + formData, null, this.dataHeaderWithTrx_Token() ).then(res => {            
                if( res.data.codestatus == "S" ){
                    console.log(res.data.resultdata)
                    this.seat.template2 = res.data.resultdata[0].detail_kursi

                    this.updatePosisi_2();         

                    this.dataPassengerSeat2.nowPilih = this.passenger_name.length
                    this.dataPassengerSeat2.maxPilih = this.passenger_name.length
                    this.dataPassengerSeat2.nowPilih = this.passenger_name.length
                    
                }

                if(  res.data.codestatus == "TX" ){
                    swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                     this.$router.push('/redirect')
                }


            }).catch( ( error ) => {
                console.log(error)
            });


        },

        async activeLevelThree(){

            let dataJson = null;


            if( !this.is_runTestProp ){

                    console.log(this.orderRekap);
                    this.data_validate = []

                    //==================================== VALIDASI ==================================

                    if( this.input.nama_depan == null ||  this.input.nama_depan == "" ){
                        this.data_validate.push( this.$t('valIP.v1') )
                    }

                    if( this.input.email == null ||  this.input.email == "" ){
                        this.data_validate.push(this.$t('valIP.v2') )
                    }

                    if( this.validateEmail(this.input.email) == false ){
                        this.data_validate.push(this.$t('valIP.v3'))
                    }

                    if( this.input.phone == null ||  this.input.phone == "" ){
                        this.data_validate.push(this.$t('valIP.v4') )
                    }

                    try {
                        if( this.input.tipe_identitas != '' ){
                            if( this.input.nomor_identitas.length > 16 ){
                                this.data_validate.push( this.$t('valIP.v5') )
                            }else if( this.input.nomor_identitas.length < 7 ){
                                this.data_validate.push(this.$t('valIP.v6') )
                            }
                        }else{
                            // alert("a");
                            this.input.nomor_identitas = ''
                        }
                        
                    } catch (error) {
                        console.log(error)
                    }

                    if( ! this.phoneIsValid( this.input.phone ) ){
                        this.data_validate.push( this.$t('valIP.v7'))
                    }


                    for (let i = 0; i < this.orderRekap.data_pesan[4].value; i++) {

                       

                        if( this.passenger_sebutan[i] == "" || this.passenger_sebutan[i] == null ){
                            this.data_validate.push(this.$t('valIP.v8')  + ( i + 1) + this.$t('valIP.v8_1') )
                        }    

                        if( this.passenger_name_depan[i] == "" || this.passenger_name_depan[i] == null ){
                            this.data_validate.push(this.$t('valIP.v9') + ( i + 1) + this.$t('valIP.v9_1') )
                        }     

                        if( this.passenger_name_depan[i].length < 4 ){
                            this.data_validate.push(this.$t('valIP.v10')  + ( i + 1) + this.$t('valIP.v10_1') )
                        }

                        try {

                             if( this.passenger_id_type[i] != '' ){
                                if( this.passenger_id_identity[i].length > 16 ){
                                      this.data_validate.push(this.$t('valIP.v11')  + ( i + 1) + this.$t('valIP.v11_1'))
                                }else if( this.passenger_id_identity[i].length < 7 ){
                                      this.data_validate.push(this.$t('valIP.v12') + ( i + 1) + this.$t('valIP.v12_1') )
                                }
                            }else{
                                 this.passenger_id_identity[i] = ''
                            }


                            if( this.passenger_id_identity[i].length > 16 ){
                                this.data_validate.push(this.$t('valIP.v13') + ( i + 1) + this.$t('valIP.v13_1'))
                            } 
                        } catch (error) {
                            console.log(error)
                        }
                                       
                    }

                    if( this.data_validate.length > 0 ){
                        return false
                    }

                    //==================================== SUSUNAN JSON ORDER =================================

                    // Get array Passenger Untuk Kebenangkatan
                    let dataPassengerGo = []
                    let datapass = null

                    console.log( this.orderRekap.data_trx )
                    
                    for (let i = 0; i < this.passenger_name_depan.length; i++) {
                        datapass = {
                                        passenger_id: String(this.orderRekap.data_trx[0].order_lines[0].passengers[i].id),
                                        nama_lengkap: this.passenger_name_depan[i] ,
                                        // user_id: "string",
                                        identity_type: ( this.isKosong2(this.passenger_id_type[i]) ? "" : this.passenger_id_type[i] ),
                                        identity_no: ( this.isKosong2( String(this.passenger_id_identity[i])) ? "" :  String(this.passenger_id_identity[i]) ),
                                        phone: ( this.isKosong2(this.passenger_phone[i]) ? "" : String(this.passenger_phone[i]) ),
                                        street: ( this.isKosong2(this.passenger_alamat1[i]) ? "" : String(this.passenger_alamat1[i]) ),
                                        street2: ( this.isKosong2(this.passenger_alamat2[i]) ? "" : String(this.passenger_alamat2[i]) ),
                                        kursi: String(this.orderRekap.data_trx[0].order_lines[0].passengers[i].kursi),
                                        kursi_id: String(this.orderRekap.data_trx[0].order_lines[0].passengers[i].kursi_id),
                                        // nomor_tiket: "string"
                                    }

                        dataPassengerGo.push(datapass)

                        this.dataPassengerSeat.keberangkatan[i] = datapass
                        
                    }
                    
                    // Get Array Order Line
                    let dataOrder = []
                    let dataor = null
                
                    //data keberangkatan
                    dataor = {
                                orderline_id: String(this.orderRekap.data_trx[0].order_lines[0].id),
                                psi_t_jadwal_id: String(this.orderRekap.data_travel.travel_1.jadwal_id),
                                jumlah_penumpang: String(this.orderRekap.data_pesan[4].value),
                                total_harga: String(this.orderRekap.data_travel.travel_1.harga),
                                location_from_id: String(this.orderRekap.data_pesan[2].id),
                                location_to_id: String(this.orderRekap.data_pesan[3].id),
                                passengers: dataPassengerGo
                            }
                    
                    dataOrder.push(dataor)

                    if(  this.orderRekap.data_pesan[5] == true  ){

                        // Get array Passenger Untuk Kepulangan
                        let dataPassengerHome = []
                    
                        for (let i = 0; i < this.passenger_name_depan.length; i++) {
                            datapass = {
                                            passenger_id: String(this.orderRekap.data_trx2[0].order_lines[1].passengers[i].id),
                                            nama_lengkap: String(this.passenger_name_depan[i] ),
                                            // user_id: "string",
                                            identity_type: ( this.isKosong2(String(this.passenger_id_type[i])) ? "" : String(this.passenger_id_type[i]) ),
                                            identity_no: ( this.isKosong2( String(this.passenger_id_identity[i])) ? "" :  String(this.passenger_id_identity[i]) ) ,
                                            phone: ( this.isKosong2(this.passenger_phone[i]) ? "" : String(this.passenger_phone[i]) ),
                                            street: ( this.isKosong2(this.passenger_alamat1[i]) ? "" : String(this.passenger_alamat1[i]) ),
                                            street2: ( this.isKosong2(this.passenger_alamat2[i]) ? "" : String(this.passenger_alamat2[i]) ),

                                            kursi: String(this.orderRekap.data_trx2[0].order_lines[1].passengers[i].kursi),
                                            kursi_id: String(this.orderRekap.data_trx2[0].order_lines[1].passengers[i].kursi_id),
                                            // nomor_tiket: "string"
                                        }

                            dataPassengerHome.push(datapass)
                            this.dataPassengerSeat2.kepulangan[i] = datapass
                            
                        }

                        dataor = {
                                orderline_id:  String(this.orderRekap.data_trx2[0].order_lines[1].id),
                                psi_t_jadwal_id: String(this.orderRekap.data_travel.travel_2.jadwal_id),
                                jumlah_penumpang: String(this.orderRekap.data_pesan[4].value),
                                total_harga: String(this.orderRekap.data_travel.travel_2.harga),
                                location_from_id: String(this.orderRekap.data_pesan[3].id),
                                location_to_id: String(this.orderRekap.data_pesan[2].id),
                                passengers: dataPassengerHome
                        }
                    
                        dataOrder.push(dataor)

                    }

                    dataJson = {
                                        id: String(this.orderRekap.data_trx[0].id) ,
                                        nama_pemesan: String( this.input.nama_depan ),
                                        phone_pemesan: String( this.input.phone ),
                                        email_pemesan: String( this.input.email ),
                                        identity_type_pemesan: ( this.isKosong2(String( this.input.tipe_identitas)) ? "" : String( this.input.tipe_identitas )),
                                        identity_no_pemesan: ( this.isKosong2(String( this.input.nomor_identitas)) ? "" : String( this.input.nomor_identitas )),
                                        title_pemesan: String( this.input.sebutan ).toLowerCase(),
                                        tanggal_order: String( this.orderRekap.data_trx[0].tanggal_order ),
                                        orderline: dataOrder
                                    }

                    

                    console.log(dataJson);
                    
                    this.$store.commit('setDataPassenger', dataJson);

                    console.log(JSON.stringify( dataJson ))

            }else{

                dataJson = this.runTestParameter
                console.log( JSON.stringify(dataJson) )
                console.log( JSON.stringify(this.runTestHeader) )
            }

             //==================================== SUSUNAN JSON ORDER =================================

            this.loading_api = true
           
        
             await axios.post( this.data_url.setPSIOrderFullJSONType, JSON.stringify( dataJson ), ( !this.is_runTestProp ? this.dataHeaderWithTrx_Token(4) : this.runTestHeader) ).then(async res => {      
                 console.log(res.data)      
                if( res.data.codestatus == "S" ){

                    this.runTestResult = true;
                    this.setDataPenumpang = res.data.resultdata;

                    if( !this.is_runTestProp ){
                        this.$store.commit('setDataPenumpang', res.data.resultdata);

                        await this.processSeat();
                        if(this.orderRekap.data_pesan[5] == true){
                            await this.processSeat2();
                        }
                        this.$bvModal.show('bv-modal-seat-position');
                    }
                   
                }else{

                     swal({
                            title: this.$t('swal.informasi'),
                            text: res.data.message ,
                            icon: '/img/imgjrg/error.png'
                        });

                }

                if(!this.is_runTestProp){
                     if(  res.data.codestatus == "TX" ){
                         swal({
                            title:this.$t('swal.t2'),
                            text: "Silahkan Mengulang Pesanan !",
                            icon: '/img/imgjrg/error.png'
                        });
                        this.$router.push('/redirect')
                        return false
                    }

                    if(  res.data.codestatus == "X" ){
                        swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }
                }

            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                    swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                     if( !this.is_runTestProp ){ localStorage.removeItem('login'); }
                    if( !this.is_runTestProp ){ this.$router.push('/redirect') }
                }

                 if(this.searchWord(error,"Network Error")){
                           swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                        return false
                    }

                this.loading_api = false
                console.log(error)
            });

            this.loading_api = false

        },

        backLevel(){
             this.$parent.activeLevelOne();
        },

        async getPenumpang(){

          

            let formData = new URLSearchParams(); 
            formData.append('lang',  this.$t('locale_lang') )
            await axios.post( this.data_url.getPenumpang + "?" + formData, null, this.dataHeaderWithTrx_Token(2) ).then(async res => { 

                    console.log(res.data)
                    
                    if(  res.data.codestatus == "X" ){
                         swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                    if(  res.data.codestatus == "TX" ){
                        swal({
                            title:this.$t('swal.t2'),
                            text: "Silahkan Pengulang Pesanan !",
                            icon: '/img/imgjrg/error.png'
                        });
                         this.$router.push('/redirect')
                        return false
                    }

                    if( res.data.codestatus == "S" ){

                        this.data_passenger_admin =  res.data.resultdata
                    
                    }

                }).catch( ( error ) => {

                      try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }

                     if(this.searchWord(error,"Network Error")){
                           swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                        return false
                    }

                    if(this.searchWord(error,"401")){
                         swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                    console.log(error)
                });

            

        },
        setCoolDown(){

            let _ = this;

            let countDownDate = new Date(Date.now() + (this.coolDownTime * 1000) );

            clearInterval(_.goInterval);

            this.goInterval = setInterval(function() {
                // Dapatkan waktu dan hari ini dalam milisecond
                let now = new Date().getTime();

                // Buat jarak antara waktu sekarang dan yang menjadi hitung mundur
                let distance = countDownDate - now;

                // Kalkulasi waktu dalam bulan, hari, jam, menit dan detik
                // let month = Math.floor(distance / (1000 * 60 * 60 * 24 * 30))
                // let days = Math.floor((distance % (1000 * 60 * 60 * 24 *30)) / (1000 *60 * 60 * 24));
                // let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // display hasil ke container dengan id "clock"
                //  month + "<span>Bulan</span></div><div>" + days + "<span>Hari</span></div> <div>" +hours + "<span>Jam</span></div> <div>" + minutes + "<span>Menit</span></div> <div>" + seconds + "<span>Detik</span></div>";

                _.timeCoolDown.cdMinutes = minutes

                if(seconds < 10){
                    _.timeCoolDown.cdSecond = "0" + String(seconds)
                }else{
                    _.timeCoolDown.cdSecond = seconds
                }

                // Jika hitung mundur selesai, beri keterangan teks
                if (distance < 0) {
                    if(!this.is_runTestProp){
                        try {
                             clearInterval(_.goInterval);
                            if(!_.flag_habis_do){
                                _.flag_habis_do = true;
                                swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                                _.$router.push('/redirect')
                            }
                        } catch (error) {
                            console.log(error)
                        }
                       
                    }
                }
            }, 1000);
        },

        async processSeatPosition(){

            // this.loading_api = true

            // let formData = new URLSearchParams();
            // formData.append('psi_t_order_id', this.orderRekap.data_trx[0].id );
            // await axios.post( this.data_url.confirmPSIOrder + "?" + formData, null, this.dataHeaderNonAuthJson).then(res => {            
            //     if( res.data.codestatus == "S" ){
                clearInterval(this.goInterval);
                this.$bvModal.hide('bv-modal-seat-position');
                this.$store.commit('setDataSeatPassenger', [this.dataPassengerSeat, this.dataPassengerSeat2]) 
                    // this.$store.commit('setDataOrderConfirm', res.data.resultdata) 
                this.$parent.activeLevelFour();
            //     }
            // }).catch( ( error ) => {
            //     console.log(error)
            // });

            //   this.loading_api = false

        },
     

        async getDataPenumpang(){

             let formData = new URLSearchParams(); 
            formData.append('', this.date_go );

            await axios.post( this.data_url.data_url_searchTravel + "?" + formData, null, {
            headers:{
                "GANO2C-Token": this.auth.token,
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded"
            }
            }).then(res => {            
                if( res.data.codestatus == "S" ){
                    this.options_identity_type = res.data.resultdata;
                }

                if(  res.data.codestatus == "TX" ){
                    swal({
                                title:this.$t('swal.t2'),
                                text: "Silahkan mengulang pemesanan !",
                                icon: '/img/imgjrg/error.png'
                            });
                    this.$parent.nonActiveLevelOne();
                    return false
                }
            }).catch( ( error ) => {

                if(this.searchWord(error,"401")){
                         swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                     if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }
                    
                if(this.is_runTest == false){ console.log(error)  }
                
                try { if(error.response.status === 401) { this.callLogOut() }} catch (error) {if(this.is_runTest == false){ console.log(error)  } }
            });

        },
    }
};
</script>
<style>

.loader{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.2;
  background: url('//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif') 
              50% 50% no-repeat rgb(249,249,249);
}


.hover {
  cursor: pointer;
}

#team {
    background: #eee !important;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}


section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

.clickMerahip > a:active{
  background-color:#0770cc !important;
  color:#ffffff !important;
}

#team .card {
    border: none;
    background: #ffffff;
}

.borderradius{
    border-radius: 25px;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.btnHov:hover{
    background-color:#dc3545 !important;
}
.hoveroption > option:hover{
    background-color:#ff0000 !important;
}

 

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}


.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #f5365c !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #f5365c !important;
}


.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.bg-headerform{
    background-color:#f5365c;
    color:#fff;
}

.shadow{
    box-shadow: 2px 4px 0 4px yellowgreen, 4px 8px 0 8px pink, 8px 10px 0 12px navy;
}


@media (min-width: 992px){
  .modal-lg {
    max-width: auto !important;
   }
}


@media (min-width: 576px){
  .modal-dialog {
    max-width: auto !important;

  }
}

</style>
