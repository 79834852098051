<template>
    <div>
        <button style="width:40px; height:40px;border-radius:6px; border:1px solid #b5afaf; cursor:pointer;" @click="actionDipilih(idSeat)" class="bg-white" v-if="type_button == 'kosong'" > {{ text_button }} </button> 

        <button style="width:40px; height:40px;border-radius:6px; border:1px solid #b5afaf; cursor:pointer;" @click="actionDipilih(idSeat)" class="bg-white text-danger" v-if="type_button == 'kosong_rokok'" > {{ text_button }} </button> 

        <button v-if="type_button == 'silang'" style="width:40px; height:40px;border-radius:6px; border:1px solid #b5afaf; color:#ffffff; background-color:#ccd0d1;">X</button> 

        <button v-if="type_button == 'extra'" style="width:40px; height:40px;border-radius:6px;  border:1px solid #b5afaf; color:white; background-color:#ccd0d1;"><b>XT</b></button> 

        <div v-if="type_button == 'na'" disabled style="width:40px; height:40px; background-repeat:round;" class="bg-white" ></div> 

        <span v-if="type_button == 'driver'" style="width:40px; height:40px;border-radius:6px; background-repeat:round; border:0px solid #b5afaf;" class="bg-white" ><img src="img/imgjrg/driver.svg" /></span>

        <span v-if="type_button == 'toilet'" style="width:40px; height:40px;border-radius:6px; background-repeat:round; border:1px solid #b5afaf;" class="bg-white" >
            <img height="37" src="img/imgjrg/toilet.svg" style="margin-top:3px;" />
        </span>

        <button  v-if="type_button == 'dipilih'"  @click="actionDipilih(idSeat)" style=" cursor:pointer; width:40px; height:40px;border-radius:6px; background-color:#0064d2; border:1px solid #b5afaf; color:#ffffff;" > {{ text_button }}</button> 
    </div>
</template>
<script>
export default {
    props:{
        type_button:String,
        text_button:String,
        idSeat:Array,
        typeBtn:String
    },
    methods:{
        actionDipilih(idSeat){
            if(this.typeBtn == 'keberangkatan'){
                this.$emit('chooseSeatKeberangkatan', idSeat);
            }else{
                this.$emit('chooseSeatKepulangan', idSeat);
            }

        }
    }
}
</script>