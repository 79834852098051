<template>
    <div>
        <!-- BERANDA -->
        <div v-show="levelOrder == 'SEARCH_SCHEDULE'" >
          <top-page></top-page>
          <basic-elements></basic-elements>
          <custom-controls></custom-controls>
        </div>


        <!-- CHOOSEE -->
        <div class="mt-5" v-if="levelOrder == 'CHOOSE_TRAVEL'" >
          <com-pLay-store></com-pLay-store>
          <transition name="fade"> <search-bus :data_pesan="dataPesan" :data_available_travel="dataResultTravel" ref="searchbusRef"></search-bus></transition>
        </div>
        
        <div class="mt-5" v-show="levelOrder_Two" v-if="levelOrder2 == 'INFORMATION_CUSTOMER'" >
          <transition  name="fade"> <input-passenger :data_available_travel="dataResultTravel" ref="inputpassengerRef"></input-passenger></transition>
        </div>
        
        <div class="mt-5" v-if="levelOrder == 'REVIEW_INFORMATION_CUSTOMER'" >
          <transition  name="fade"> <review-input-passenger ref="reviewinputpassengerRef"></review-input-passenger></transition>
        </div>

        <div class="mt-5" v-if="levelOrder == 'FINISH_ORDER'" >
          <transition  name="fade"> <finish-order ref="finishOrderRef"></finish-order></transition>
        </div>
        
        <div class="mt-5" v-if="levelOrder == 'PAYMENT_METHOD'" >
          <transition  name="fade"> <choose-payment-method ref="paymentMethodRef"></choose-payment-method></transition>
        </div>
        
        <div class="mt-5" v-if="levelOrder == 'PROOF_PAYMENT'" >
          <transition  name="fade"> <proof-of-payment ref="proofOfPayment"></proof-of-payment></transition>
        </div>

        <div class="mt-5" v-if="levelOrder == 'WAITING_TICKET'" >
          <transition  name="fade"> <waiting-ticket ref="waitingTicket"></waiting-ticket></transition>
        </div>
      
    </div>
</template>
<script>
// BERANDA
import TopPage from "./components/TopPage";
import BasicElements from "./components/BasicElements";
import CustomControls from "./components/CustomControls";

import SearchBus from "./components/travel/SearchBus";
import ComPLayStore from "./components/ComPlayStore";

// import OrderFlow from "./components/travel/OrderFlow";

import InputPassenger from "./components/travel/InputPassenger";
import reviewInputPassenger from "./components/travel/reviewInputPassenger";

import finishOrder from "./components/travel/finishOrder";

import choosePaymentMethod from "./components/travel/choosePaymentMethod";

import proofOfPayment from "./components/travel/proofOfPayment";

import waitingTicket from "./components/travel/waitingTicket";
// import InputSeatPosition from "./components/travel/InputSeatPosition";
// import ChoosePaymentMethod from "./components/travel/ChoosePaymentMethod";
// import StatusWaitingPayment from "./components/travel/StatusWaitingPayment";

// import OrderFlowShipping from './components/shipping/OrderFlowShipping.vue';
// import PacketAdmin from "./components/shipping/PacketAdmin";
// import PacketInvoice from "./components/shipping/PacketInvoice";
// import PacketPaymentMethod from "./components/shipping/PacketPaymentMethod";
// import WaitingPayPacket from "./components/shipping/WaitingPayPacket";

// import Inputs from "./components/Inputs";

// import Navigation from "./components/Navigation";
// import JavascriptComponents from "./components/JavascriptComponents";
// import Carousel from "./components/Carousel";
// import Icons from "./components/Icons";
// import Examples from "./components/Examples";
// import DownloadSection from "./components/DownloadSection";

export default {
  name: "components",
  components: {
    TopPage,
    BasicElements,
    CustomControls,

    SearchBus,
    ComPLayStore,

    InputPassenger,
    reviewInputPassenger,

    finishOrder,
    choosePaymentMethod,

    proofOfPayment,

    waitingTicket
    // OrderFlow,
    // SearchBus,
    // InputSeatPosition,
    // ChoosePaymentMethod,
    // StatusWaitingPayment,

    // OrderFlowShipping,
    // PacketAdmin,
    // PacketInvoice,
    // PacketPaymentMethod,
    // WaitingPayPacket,
    // OrderFlowShipping,
    
  },
  data() {
        return {  
            //  levelOrder:"PUBLISH_TICKET", //CHOOSE_TRAVEL //INFORMATION_CUSTOMER //CHOOSE_SEAT //CHOOSE_METHOD_PAYMENT //STATUS_ORDER,
             levelOrder:"SEARCH_SCHEDULE", //CHOOSE_TRAVEL //INFORMATION_CUSTOMER //CHOOSE_SEAT //CHOOSE_METHOD_PAYMENT //STATUS_ORDER,
             levelOrder2:"",
             startOrder:false,
             startOrderShipping:false,

             dataResultTravel:null,
             dataPesan:null,
            orderRekapProp:null,
            levelOrder_Two:true,

             data_pemesanan:{
               dataSearchBus:null,
               dataPesan:null,
               dataInformasiPenumpang:null,
               dataInformasiNomorSeat:null,
               dataInformasiMethodPayment:null,
               dataInformasiLyananTambahan:null
             },
        }
  },
  methods: {
      nonActiveLevelOne(){
        this.levelOrder = "SEARCH_SCHEDULE"
        this.startOrderShipping = false
      },

      //searchbusRef
      activeLevelOne(){

         this.dataResultTravel = this.$store.state.dataResultTravel
        this.dataPesan = this.$store.state.dataPesan
        
        this.levelOrder = "CHOOSE_TRAVEL"
        this.startOrderShipping = false

       
        // this.$refs.searchbusRef.processGetData();
      //   this.$refs.OrderFlowRef.searchTravelNow(this.levelOrder);
      },

      //input-passenger
      activeLevelTwo(){

        this.orderRekapProp = {
            data_pesan: this.$store.state.dataPesan,
            data_trx:this.$store.state.dataTrx,
            data_trx2:this.$store.state.dataTrxTwo,
            data_travel:this.$store.state.dataChooseTravel
        }

        this.levelOrder2 = "INFORMATION_CUSTOMER"
        this.levelOrder = ""

         
        
        // this.$refs.inputpassengerRef.processGetData();
        // this.$refs.OrderFlowRef.searchTravelNow(this.levelOrder)
      },

      activeLevelTwoShow(){

  
        this.levelOrder_Two = true
         this.levelOrder = ""

        
        // this.$refs.inputpassengerRef.processGetData();
        // this.$refs.OrderFlowRef.searchTravelNow(this.levelOrder)
      },

      //inputSeat-position
      activeLevelThree(){
        this.levelOrder = "REVIEW_INFORMATION_CUSTOMER"
        

        // this.$refs.inputseatpositionRef.processGetData();
        // this.$refs.OrderFlowRef.searchTravelNow(this.levelOrder)
      },

      //choose-payment-method
      activeLevelFour(){
        this.levelOrder = "FINISH_ORDER"
        this.levelOrder_Two = false

        //  this.$refs.choosepaymentmethodRef.processGetData();
        // this.$refs.OrderFlowRef.searchTravelNow(this.levelOrder)
      },

      activeLevelFive(){
        this.levelOrder = "PAYMENT_METHOD"
 

        //  this.$refs.statuswaitingpaymentRef.processGetData();
        // this.$refs.OrderFlowRef.searchTravelNow(this.levelOrder)
      },

      activeLevelSix(){
        this.levelOrder = "PROOF_PAYMENT"
 

        //  this.$refs.statuswaitingpaymentRef.processGetData();
        // this.$refs.OrderFlowRef.searchTravelNow(this.levelOrder)
      },

      activeLevelSeven(){
        this.levelOrder = "WAITING_TICKET"
 

        //  this.$refs.statuswaitingpaymentRef.processGetData();
        // this.$refs.OrderFlowRef.searchTravelNow(this.levelOrder)
      },

      activeLevelOnePacket(){
        this.levelOrder = "INFORMATION_PACKET"
        this.startOrder = false
        this.startOrderShipping = true

        this.$refs.packetAdmin.processGetData();
        this.$refs.OrderFlowShippingRef.searchTravelNow(this.levelOrder);
      },

      activeLevelTwoPacket(){
        this.levelOrder = "DETAILS_INVOICE"

        this.$refs.packetInvoice.processGetData();
        this.$refs.OrderFlowShippingRef.searchTravelNow(this.levelOrder);
      },

      activeLevelThreePacket(){
        this.levelOrder = "CHOOSE_METHOD_PAYMENT_SHIPPING"

        this.$refs.packetPaymentMethod.processGetData();
        this.$refs.OrderFlowShippingRef.searchTravelNow(this.levelOrder);
      },

      activeLevelFourPacket(){
        this.levelOrder = "WAITING_PAYMENT_SHIPPING"

        this.$refs.waitingpayPacket.processGetData();
        this.$refs.OrderFlowShippingRef.searchTravelNow(this.levelOrder);
      },


  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

</style>
