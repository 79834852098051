<template>
    <section class="section-shaped my-0 bg-white ">
      
         <div class="container mt-2 ">
            <div class="row mt-5">
                <div class="col-lg-4 pt-4 pb-5  bg-white mb-4" style="border-radius:0px; border:1px solid #D1D1D1;">

                    <div class="col-lg-12 mb-5">
                        <img :src="pp" class="float-left mr-3 rounded-circle" style="border: 2px solid #d1d1d1;" height="80" /> 
                        <p class="fz-16 avenirHeavy text-capitalize">{{ dataProfil.name.substring(0,30) }}  <span style="color:white;">.</span> <pulse-loader :color="'#ff0000'" :loading="loading_api"></pulse-loader> <br/>
                        <span class="fz-16 avenirORoman">{{ dataProfil.mobile }} </span> <span style="color:white;">.</span><span> <pulse-loader :color="'#ff0000'" :loading="loading_api"></pulse-loader></span> </p>
                        <div class="clearfix"></div>
                        <div class="clearfix"></div>
                        <div class="clearfix"></div>
                    </div>
                   
                    <div :class="'col-lg-12 border pt-1 pb-1 pointerMenu' + ( flagMenu == 'myorder' ? ' selectedMenu' : '' )"  @click="goMyOrder">
                        <img src="img/imgjrg/history-2.svg" height="40" class="float-left ml-3 mr-3 bgput" /> 
                        <div class="fz-16 avenirORoman pt-2" > {{ $t('profile.text_1') }}</div>
                          <div class="clearfix"></div>
                    </div>

                    <div class="clearfix"></div>

                    <div :class="'col-lg-12 border pt-1 pb-1 pointerMenu' + ( flagMenu == 'passenger' ? ' selectedMenu' : '' )"  @click="goMyPeople">
                        <img src="img/imgjrg/Daftar_Penumpang_2.svg" height="40" class="float-left ml-3 mr-3 bgput" /> 
                        <div class="fz-16 avenirORoman pt-2" >{{ $t('profile.text_2') }}</div>
                          <div class="clearfix"></div>
                    </div>

                    <div class="clearfix"></div>
                    <div :class="'col-lg-12 border pt-1 pb-1 pointerMenu' + ( flagMenu == 'myaccount' ? ' selectedMenu' : '' )" @click="goMyAccount">
                        <img src="img/imgjrg/akun-24x24px.svg" height="40" class="float-left ml-3 mr-3 bgput" /> 
                        <div class="fz-16 avenirORoman pt-2" >{{ $t('profile.text_3') }}</div>
                          <div class="clearfix"></div>
                    </div>

                    <div class="clearfix"></div>
                     <div class="col-lg-12 border pt-1 pb-1 pointerMenu d-none d-lg-block mt-5 bg-secondary" @click="goLogout">
                        <img src="img/imgjrg/logoutblue.svg" height="40" class="float-left ml-3 mr-3 bgput" style="color:#0770CC;" /> 
                        <div class="fz-16 avenirORoman pt-2">{{ $t('profile.text_4') }}</div>
                          <div class="clearfix"></div>
                    </div>

                    <div class="clearfix"></div>
                </div>
                <div class="col-lg-8">

                    <AccountMe @getDataProfilParent="getDataProfilParent" v-if="flagMenu == 'myaccount'" ></AccountMe>
                    <Poin v-if="flagMenu == 'mypoin'" ></Poin>
                    <Card v-if="flagMenu == 'mycard'" ></Card>
                    <MyOrder v-if="flagMenu == 'myorder'" ></MyOrder>
                    <ListPassenger v-if="flagMenu == 'passenger'" ></ListPassenger>
                </div>
            </div>
         </div>
       
    </section>
</template>
<script>
import { Helper } from '../helper.js';
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import AccountMe from "./Profile_accountme"
import Poin from "./Profile_poin"
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Card from "./Profile_card"
import MyOrder from "./Profile_myorder"
import ListPassenger from "./Profile_listpassenger"
import swal from 'sweetalert';
import axios from "axios";
import Config from "../model/Config"
export default {
  mixins:[Validate,Master,Helper],
  name: "components",
  components: {
    AccountMe,
    Poin,
    Card,
    MyOrder,
    ListPassenger,
    PulseLoader
  },
  data() {
        return {
            flagMenu:"myorder",
            data_url:Config,
            dataProfil:{
              name:"",
              mobile:""
            },
            pp:'img/imgjrg/Avatar.svg',
        }
  },
  async created(){

    if( this.$store.state.goProfile == "penumpang"){
        this.flagMenu = "myorder"
    }

     await this.getDataProfile();
     this.$store.commit('setGoProfile', "");

  },
  methods: {

    async getDataProfile(){
            this.loading_api = true
            let formData = new URLSearchParams(); 
            formData.append('lang',  this.$t('locale_lang') )

            await axios.post( this.data_url.getUserInfo + "?" + formData, null, this.dataHeaderWithTrx_Token(2) ).then(async res => {    

                if( res.data.codestatus == "S" ){
                    this.dataProfil.name = res.data.resultdata[0].name
                    this.dataProfil.mobile = res.data.resultdata[0].mobile

                    this.pp = "data:image/jpeg;base64," + res.data.resultdata[0].image
                }else{
                    swal({
                            title: "Info Server",
                            text: res.data.message,
                            icon: '/img/imgjrg/antri.png'
                        });
                }

                if(  res.data.codestatus == "X"){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }
                   
            }).catch( ( error ) => {

                try {

                  if( error.response.data.codestatus == "E" ){
         
                    if(this.searchWord(error.response.data.message, "Token")){
                        localStorage.removeItem('login');
                        this.$router.push('/redirect')
                    }

                  }
                  
                } catch (errorq) {
                  errorq
                }
               
                if(this.searchWord(error,"401")){
                     swal({
                            title:this.$t('swal.t2'),
                            text: this.$t('swal.txt2'),
                            icon: '/img/imgjrg/error.png'
                        });
                    localStorage.removeItem('login');
                    this.$router.push('/redirect')
                }

                 if(this.searchWord(error,"Network Error")){
                       swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
                }

                console.log(error)
            });

            this.loading_api = false
      },

      getDataProfilParent(data){
        this.dataProfil = data
        console.log(this.dataProfil)
      },
      goMyAccount(){
            this.flagMenu = "myaccount"
      },
      goMyPoin(){
            this.flagMenu = "mypoin"
      },
      goMyCard(){
            this.flagMenu = "mycard"
      },
      goMyOrder(){
            this.flagMenu = "myorder"
      },
      goMyPeople(){
            this.flagMenu = "passenger"
      },
      goLogout(){
        localStorage.removeItem('login');
        this.$router.push('/');
      }

    
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

.bg{
    background-color:#EEF2F6;
}

.pointerMenu:hover{
    background-color:#ff0000 !important;
    cursor:pointer;
    color: #ffffff !important;
}

.pointerMenu:hover > .bgput{
   filter: brightness(0) invert(1);
}

.selectedMenu{
    background-color:#ff0000 !important;
    cursor:pointer;
    color: #ffffff !important;
}

.selectedMenu > .bgput{
   filter: brightness(0) invert(1);
}

.pointerMenu:active{
    background-color:#ff0000;
}

</style>
