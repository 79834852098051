/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import './registerServiceWorker'

// import { VueReCaptcha } from 'vue-recaptcha-v3'
// Vue.use(VueReCaptcha, { siteKey: '6LchEQgbAAAAABhkL5NLRIYxLNXkwav0Hp6-iN4R' });
// import { VueReCaptcha } from 'vue-recaptcha-v3'
// // For more options see below
// Vue.use(VueReCaptcha, { siteKey: '6LchEQgbAAAAABhkL5NLRIYxLNXkwav0Hp6-iN4R' })

import VueSignature from 'vue-signature-pad';
Vue.use(VueSignature);


import * as LocationPicker from 'vue2-location-picker'
Vue.use(LocationPicker, {
  installComponents: false, // If true, create it globally
})

import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
  state: {
    dataPesan:null,
    dataResultTravel:null,
    dataChooseTravel:null,
    dataPenumpang:null,
    dataChooseSeat:null,
    dataChoosePaymentMethod:null,

    dataOrderPacket:null,
    dataAdminPacket:null,
    dataMethodPaymentPacket:null,

    dataTrx:null,
    dataTrxTwo:null,
    dataOrderPassenger:null,
    dataSeatPassenger:null,
    dataOrderConfirm:null,

    goProfile:"",
    dataTicket:null,
    dataPacket:null,

    flagMenuProfil:null,
  },
  mutations: {
    setDataPacket: function(state, payload){
      state.dataPacket = payload
    },
    setDataTicket: function(state, payload){
      state.dataTicket = payload
    },
    setGoProfile: function(state, payload){
      state.goProfile = payload
    },
    setDataOrderConfirm: function(state, payload){
      state.dataOrderConfirm = payload
    },
    setMenuProfil: function(state, payload){
      state.flagMenuProfil = payload
    },
    setDataSeatPassenger: function(state, payload){
      state.dataSeatPassenger = payload
    },
    setDataTrx: function(state, payload){
      state.dataTrx = payload
    },
    setDataTrxTWo: function(state, payload){
      state.dataTrxTwo = payload
    },
    setDataPassenger: function(state, payload){
      state.dataOrderPassenger = payload
    },
    setDataPesan: function(state, payload){
      state.dataPesan = payload
    },
    setDataResultBus: function(state, payload){
      state.dataResultTravel = payload
    },
    setDataChooseTravel: function(state, payload){
      state.dataChooseTravel = payload
    },
    setDataPenumpang: function(state, payload){
      state.dataPenumpang = payload
    },
    setDataChooseSeat: function(state, payload){
      state.dataChooseSeat = payload
    },
    setDataChooseMethodPayment: function(state, payload){
      state.dataChooseMethodPayment = payload
    },

    setDataOrderPacket: function(state, payload){
      state.dataOrderPacket = payload
    },
    setDataAdminPacket: function(state, payload){
      state.dataAdminPacket = payload
    },
    setDataMethodPaymentPacket: function(state, payload){
      state.dataMethodPaymentPacket = payload
    },
    
  }
})

import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll, {
  duration:700,
  updateHistory: false,
})

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './main.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

import { messages } from './translate'
const i18n = new VueI18n({
  locale: 'id',
  messages
});

Vue.config.productionTip = false;
Vue.use(Argon);
new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount("#app");




