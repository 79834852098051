<template>
    <section id="InputePassenger" class="section-components pb-5 pt-5 bg-white">
        <carousel-slider></carousel-slider>

        <div class="container mt-4">
      
            <div class="row text-center" v-if="dataContent != null">
              <div class="col-lg-12">
                <p class="avenirLight semi-bold fz-30">{{ dataContent[0].name }}</p>
                <p class="avenirLight medium fz-16">
                    {{ dataContent[0].details[0].description }}
                          <br/>
                          <br/>
                    {{ dataContent[0].details[1].description }}
                          <br/>
                          <br/>
                    {{ dataContent[0].details[2].description }}
                </p>

                <br/>
                <br/>
                <br/>
              <p class="avenirLight semi-bold fz-30">{{ dataContent[1].name }}</p>
              </div>

              <div style="white-space: pre-line" class="col-lg-4" v-for="(value,index) in dataContent[1].details" :key="index"> 
                <p class="avenirLight fz-16 semi-bold">{{ value.name }}</p>
                <p class="avenirLight fz-16 roman">{{ value.description }}</p>
              </div>


              <div class="col-lg-12 mt-4"> 
                    <p class="avenirLight semi-bold fz-30">{{ dataContent[2].name }}</p>
              </div>    

               <div style="white-space: pre-line" class="col-lg-4" v-for="(value,index) in dataContent[2].details" :key="index"> 
                 <p class="avenirLight fz-16 semi-bold">{{ value.name }}</p>
                <p class="avenirLight fz-16 roman">{{ value.description }}</p>
              </div>         



              </div>
      

        </div>
    </section>
</template>
<script>
import '../assets/cssVendor/vue-multiselect.min.css';
import { Helper } from '../helper.js';
// import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Master } from '../model/Master.js'
import { Validate } from '../model/Validate.js';
import axios from "axios";
import Config from "../model/Config"
import swal from 'sweetalert';
import CarouselSlider from "./Carousel"

export default {
  mixins:[Helper,Master,Validate],
  name: "components",
  components: {
    // PulseLoader,
    CarouselSlider
  },
  data() {
        return {
            data_url:Config,
            dataContent:null,
        }
  },
  created(){
    this.getData();
  },
  methods: {


      async getData(){


          this.loading_api = true

          let formData = new URLSearchParams(); 
          formData.append('menu_extra_info_id', '6');

          await axios.post( this.data_url.getPSIExtraInfo + "?" + formData, null, this.dataHeaderNonAuth).then(res => {            
                if( res.data.codestatus == "S" ){
                  
                  this.dataContent = res.data.resultdata[0].sections
                  console.log(this.dataContent)

                }

          }).catch( ( error ) => {
              console.log(error)

                if(this.searchWord(error,"Network Error")){
                     swal({
                            title: this.$t('swal.t1'),
                            text: this.$t('swal.txt1'),
                            icon: '/img/imgjrg/error.png'
                        });
              }
              
          });

          this.loading_api = false


      },
   
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

</style>
