export const messages = {
        id: {

          locale:"id",
          locale_lang:"id_ID",

          message: {
            top_titlediv1: 'Tiket Bus & Shuttle',
            top_form_dari: 'Dari',
            top_form_ke: 'Ke',
            top_form_berangkat: 'Berangkat',
            top_form_pulang: 'Pulang',
            top_form_penumpang: 'Penumpang',
            top_form_deskripsi: 'Masuk atau daftar untuk mendapatkan keuntungannya !',
            top_form_cari: 'Cari',
            usia_des:'Usia Diatas 3 Tahun',

            top_form_placeholder_dari: 'Pilih Kota Asal',
            top_form_placeholder_ke: 'Pilih Kota Tujuan',
            top_form_placeholder_dewasa: 'Dewasa',

            top_options_seat: [
              { name: '1 Dewasa', value: 1 },
              { name: '2 Dewasa', value: 2 },
              { name: '3 Dewasa', value: 3 }
            ],
          },

          cekOngkir:{
            text_1:"Cek Ongkos Kirim kamu",
            text_2:"Masukan Nomro Seri dan klik tombol lacak paket untuk melihat posisinya",
            text_3:"Dari",
            text_4:"Ke",
            text_5:"Jenis Pengiriman Barang",
            text_6:"Ukuran",
            text_7:"Ukuran",
            text_8:"Cek Ongkos Kirim",
            text_9:"Biaya Kirim Paket",
            text_10:"Paket diambil di Counter JRG",
            text_11:"Paket diantar ke alamat Tujuan"
          },


          contentUtama:{
            text_1 : "Mengapa Menggunakan JRG Indonesia ? ",
            text_2 : "Transaksi Aman",
            text_3 : "Transaksi online  dilindungi teknologi SSL & e-tiket akan dikirim langsung ke e-mail atau no. handphone",
            text_4 : " Bisa Pilih Kursi",
            text_5 : "Pilih bus dan tempat duduk, tempat keberangkatan, tujuan da klik Pembayaran",
            text_6 : "Beragam Metode Pembayaran",
            text_7 : "Pembayaran dapat dilakukan melalui transfer atm, internet mbanking, alfamaret, kartu debit dan kredit, mandiri clikpay bca mobile.",
            text_8 : "Rute Bus & Shuttle.",
            text_9 : " Metode Pembayaran.",
            text_10 : " Instal aplikasi JRG di smartphone android kamu",
            text_11 : "Dapatkan kemudahan saat memesan tiket dimanapun kamu berada."
          },


          profile:{
            text_1:"Pesanan Saya",
            text_2:"Daftar Penumpang",
            text_3:"Akun Saya",
            text_4:"Keluar Dari Akun",
          },

          profile_accountme:{
            text_1:"Nama Lengkap",
            text_2:"Ubah Sandi Masuk",
            text_3:"No Identitas",
            text_4:"No Handphone",
            text_5:"Email",
            text_6:"Akun Yang Terhubung",
            text_7:"Gabungkan Akun",
            text_8:"Gabungkan Akun",
            text_9: "Ubah Profil",
            text_10: "Simpan",
            text_11: "Kirim OTP",

            text_12:"Ubah No Telepon",
            text_13:"Untuk melakukan pengubahan data no telepon diperlukan kode otp ke no telepon baru.",
            text_14:"Ubah",
            text_15:"Untuk melakukan pengubahan data email diperlukan kode otp ke email baru.",
            text_16:"Kirim.",
            text_17:"Simpan.",
            text_18:"Ubah Sandi.",
            text_19:"Silahkan isikan password baru dan ulangi password untuk mengubah kata sandi anda.",
          },

          profile_listpassenger:{
            text_1:"Isi detail penumpang sekarang, tinggal pilih penumpang kemudian. Dengan Daftar Penumpang, Anda tidak perlu lagi mengisi detail penumpang yang sama setiap kali memesan.",
            text_2:"Tambah Penumpang",
            text_3:"Daftar Penumpang",
            text_4:"Isi detail penumpang sekarang, tinggal pilih penumpang kemudian. Dengan Daftar Penumpang, Anda tidak perlu lagi mengisi detail penumpang yang sama setiap kali memesan.",
            text_5:"Detail Penumpang",
            text_6:"Anda dapat menambahkan sampai dengan 4 penumpang",
            text_7:"Hapus",
            text_8:"Edit",
            text_9:"Dewasa",
            text_10:"Panggilan",
            text_11:"( Harus Di Isi)",
            text_12:"Nama Depan",
            text_13:"( Harus Di Isi)",
            text_14:"( Harus Di Isi)",
            text_15:"Tipe Identitas",
            text_16:"Nomor Identitas (Sesuai KTP/paspor/lainnya)",
            text_17:"",
            text_18:"Maaf, Silahkan Lengkapi Terlebih Dahulu",
            text_19:"Batal",
          },

          profile_myorder:{
            text_1:"Tiket Saya",
            text_2:"Paket Saya",
            text_3:"Belum Bayar",
            text_4:"Aktif",
            text_5:"Selesai",
            text_6:"Kadaluarsa",
            text_7:"Kode Pemesanan",
            text_8:"Pesanan Kadaluarsa",
            text_9:"Aktif",
            text_10:"Selesai",
            text_11:"Belum Bayar",
            text_12:"( Berangkat )",
            text_13:"berangkat :",
            text_14:"( Pulang )",
            text_15:"berangkat :",
            text_16:"Lihat Detail",
            text_17:"Metode Pembayaran : ",
            text_18:"Silahkan Transfer Ke",
            text_19:"Total Pembayaran",
            text_20:"Cara Pembayaran :",
          },

          terimaPaket:{
            text_1:"Manifest Paket",
            text_2:"Data Belum Tersedia",
            text_3:"No. Manifest",
            text_4:"Kurir",
            text_5:"Tanggal",
            text_6:"Jumlah Manifest",
            text_7:"Masukan Nomor Seri dan Klik Tombol Cari Data Resi",
            text_8:"Cari",
            text_9:"No Resi",
            text_10:"Penerima",
            text_11:"Kota Penerima",
            text_12:"Tipe",
            text_13:"Status",
            text_14:"Konfirmasi Pengiriman Paket",
            text_15:"No Resi : ",
            text_16:"Detail Pengiriman Paket",
            text_17:"Nama Pengirim",
            text_18:"Nama Penerima",
            text_19:"Lacak",
            text_20:"Informasi Paket",
            text_21:"Isi Paket",
            text_22:"Ukuran Paket",
            text_23:"Catatan Paket",
            text_23_1:"Status Biaya",
            text_23_2:"Biaya Paket",
            text_24:"Diterima Oleh",
            text_25:"Bukti Pengiriman",
            text_26:"Foto",
            text_27:"TTD",
            text_28:"Tangkap Foto",
            text_29:"Ulangi Foto",
            text_30:"Ulangi TTD",
            text_31:"Simpan",
            text_32:"Alasan",
            text_33:"Simpan",
            text_34:"Lacak Paket",
          },

          cekResi:{
            text_1:"Pengirimanmu Aman, Periksa Posisinya",
            text_2:"Masukan Nomor Seri dan klik tombol lacak paket untuk melihat posisinya",
            text_3:"Cek Paket",
            text_4:"Nomor Seri Berhasil Ditemukan",
            text_5:"Nama Pengirim ",
            text_6:"Nama Penerima",
            text_7:"Tipe Paket",
            text_8:"Isi Paket",
            text_9:"Ukuran Paket"
          },

          register:{
              text_1:"Jadilah anggota JRG Indonesia",
              text_2:"Daftar sekarang, nikmati kemudahan dan keuntungan berikut",
              text_3:"Transaksi Aman",
              text_4:"Masukan tempat keberangkatan, tujuan, Penumpang. Tanggal Perjalanan Dan kemudian klik CARI",
              text_5:"Bisa Pilih Kursi",
              text_6:"Pilih bus dan tempat duduk, tempat keberangkatan, tujuan da klik Pembayaran",
              text_7:"Beragam Metode Pembayaran",
              text_8:"Pembayaran dapat dilakukan melalui transfer atm, internet mbanking, alfamaret, kartu debit dan kredit, mandiri clikpay bca mobile.",
              text_9:"Daftar Sekarang",
              text_10:"No Handphone",
              text_11:"Email",
              text_12:"Data anda akan dilindungi dan tidak akan disebarluaskan",
              text_13:"Daftar",
              text_14:"Dengan Mendaftar Saya Menyetujui, Syarat dan ketentuan yang berlaku serta kebijakan privasi",
              text_15:"Atau Daftar Dengan",
              text_16:"Verifikasi Akun",
              text_17:"Kami telah mengirimkan kode verifikasi ke",
              text_18:"Gunakan kode tersebut untuk verifikasi",
              text_19:"Kode Verifikasi",
              text_20:"Kirim kode Verifikasi ?",
              text_21:"Kirim Lagi",
              text_22:"Kirim",
              text_23:"Verifikasi Akun",
              text_24:"Selangkah lagi untuk menjadi anggota  JRG Indonesia. Mohon Isi Nama lengkap dan kata sandi untuk login.",
              text_25:"Nama Lengkap",
              text_26:"Kata Sandi",
              text_27:"Konfirmasi kata sandi",
              text_28:"Kirimi saya info tentang promosi dan diskon JRG Indonesia",
              text_29:"Lanjut",
              text_30:"Informasi Tambahan",
              text_31:"Lengkapi isi informasi dibawah ini untuk menerima penawaran istimewa hanya untuk Anda.",
              text_32:"Jenis Kelamin",
              text_33:"Tanggal Lahir",
              text_34:"Simpan & Selesai",
              text_35:"Terimakasih",
              text_36:"Anda Telah Menjadi Member JRG",
              text_37:"Silahkan melakukan masuk akun dihalaman utama untuk Menikmati kemudahan pemesanan tiket dan dapatkan manfaat menjadi member JRG ",
              text_38:"Pergi ke Halaman Utama",
          },

          footer:{
            text_1:"Layanan",
            text_2:"Tiket Bus Dan Shuttle",
            text_3:"Kirim Paket",
            text_4:"Tentang JRG",
            text_5:"Pelayanan Pelanggan",
            text_6:"© PT Jasa Rahayu Gumpueng",
          },

          app_header:{
            menu_1:'Paket',
            menu_1_1:'Kirim Paket',
            menu_1_2:'Lacak Paket',
            menu_1_3:'Cek Tarif',
            menu_1_4:'Paket Kurir',
            menu_1_5:'Paket Driver',
            menu_2:'Cek Pemesanan',

            btn_masuk:'Masuk',
            btn_daftar:'Daftar',

            menu_member:'Menu Member',
            halaman_member:'Halaman Member',
            btn_keluar:'Keluar',

            txt_login_sekarang:'Login Sekarang',
            txt_lupa_kata_sandi:'Lupa Kata Sandi ?',
            txt_sandi_salah:'Kata Sandi Salah',
            txt_masuk:'Masuk',
            txt_atau:'Atau Masuk Dengan',

            txt_login_pc_1:'No Telepon / Email',
            txt_login_pc_2:'Kata Sandi',

            txt_tlt_lupa_sandi:'Lupa Kata Sandi',
            txt_deskripsi_lupa_sandi:'Masukkan alamat email atau no. handphone Anda dan kami akan mengirimkan instruksi untuk mengganti Kata Sandi.',
            txt_btn_lupa_sandi:'Verifikasi',

            txt_verifikasi:'Verifikasi Akun',
            txt_verifikasi_1:'Kode verifikasi telah dikirimkan ke',
            txt_verifikasi_2:'Masukkan kode yang telah Anda terima untuk melakukan verifikasi dan mengganti kata sandi baru ',
            txt_verifikasi_3:'Kirim',
            txt_verifikasi_4:'Kirim kode Verifikasi ?',
            txt_verifikasi_5:'Kirim Lagi',

            txt_kata_sandi_baru:'Kata Sandi Baru',
            txt_kata_sandi_baru_1:'Buat kata sandi baru Anda untuk mengakses akun JRG  dengan akun : ',
            txt_kata_sandi_baru_2:'Setelah kata sandi diubah silahkan masuk kembali dengan kata sandi baru di semua perangkatmu',
            txt_kata_sandi_baru_3:'Simpan',
            txt_kata_sandi_baru_4:'Kata Sandi baru',
            txt_kata_sandi_baru_5:'Ulangi Sandi baru',
          },


          search_bus:{
            txt_penumpang_dewasa:"Penumpang Dewasa",
            txt_ubah_pencarian:"Ubah Pencarian",
            txt_tiket_bus_dan_travel:"Tiket Bus Dan Shuttle",
            txt_filter:"Filter",
            txt_waktu_berangkat:"Waktu Berangkat",

            txt_pagi:"Pagi",
            txt_sore:"Sore",
            txt_malam:"Malam",
            txt_armada:"Armada",

            txt_keberangkatan_pilihan:"Shuttle Kebarangkatan Pilihan Anda :",
            txt_travel:"Shuttle",

            txt_kursi:"Kursi",
            txt_tersedia:"Tersedia",
            txt_cancel:"Cancel",
            txt_pilih_jadwal:"Pilih Jadwal",

            txt_pilih:"Pilih",
            txt_habis:"Habis",

            txt_jadwal_tidak_tersedia:"JADWAL TIDAK TERSEDIA",
            txt_jadwal_tidak_tersedia_des:"Perjalanan yang anda inginkan tidak tersedia saat ini Cobalah untuk mencoba perjalanan lain",

            txt_pil_pdb:"Pilih, Pesan dan Berangkat! ",
            txt_pil_pdb_des1:"Pemesanan tiket bus & Shuttle semudah berbelanja online. dari mencari jadwal, memilih tempat duduk sampai pembayaran bisa dilakukan sambil bersantai di rumah, tidak perlu repot antre! ",
            txt_pil_pdb_des2:"Beli tiket bus & Shuttle melalui aplikasi JRG Indonesia",
          },

          inputPassenger:{
            txt_daftar_pesanan:"Daftar Pesanan",
            txt_isi_data_penumpang:"Isi Data Penumpang Dan Review Kembali Pesanan Anda",
            txt_daftar_pemesan_1:"Daftar Pemesan ( Untuk E-Tiket )",

            txt_pilih_kursi_berangkat:"Pilih Kursi Berangkat",
            txt_pilih_kursi_pulang:"Pilih Kursi Pulang",

            txt_data_1:"Pakai Data Sudah Ada ?",
            txt_data_2:"Panggilan",
            txt_data_3:"Nama Lengkap",
            txt_data_4:"Tipe Identitas",
            txt_data_5:"Nomor Identitas (Sesuai KTP/paspor/lainnya)",
            txt_data_6:"Untuk usia dibawah 17 tahun, Pilih tanpa identitas pada kolom tipe identitas",
            txt_data_7:"No Telepon",
            txt_data_8:"Kode Telepon",
            txt_data_9:"Isikan No telepon Yang Bisa Dihubungi",
            txt_data_10:"Isikan Alamat Email Aktif dengan benar",
            txt_data_11:"Daftar Penumpang",
            txt_data_12:"Sama dengan pemesan",
            
            txt_data_13:"Dewasa",
            txt_data_14:"Panggilan",
            txt_data_15:"Nama Lengkap",
            txt_data_16:"Tipe Identitas",
            txt_data_16a:"Alamat 1",
            txt_data_16b:"Alamat 2",
            txt_data_17:"Nomor Identitas (Sesuai KTP/paspor/lainnya)",
            txt_data_18:"Untuk usia dibawah 17 tahun, pilih tanpa identitas pada kolom tipe identitas",
            txt_data_19:"Maaf, Ada Beberapa Yang Perlu Diperbaiki",
            txt_data_20:"Simpan & Pilih Kursi",
            txt_data_20_1:"Batal",

            txt_data_21:"Kursi Nomor : ",
            txt_data_22:"Ubah",
            txt_data_23:"Waktu Anda Tinggal :",
            txt_data_24:"Kosong",
            txt_data_25:"Terisi",
            txt_data_26:"Dipilih",
            txt_data_27:"Kursi Nomor :",
            txt_data_28:"Ubah",
            txt_data_29:"Simpan & Lanjutkan",
            txt_data_30:"Total Bayar ",

          },

          finsihOrder:{
            txt_review_pesanan:"Konfirmasi Pesanan",
            txt_1:"Detail Pesanan",
            txt_2:"Tiket Berangkat",
            txt_3:"Shuttle",
            txt_4:"Tiket Pulang",
            txt_5:"Shuttle",
            txt_6:"Daftar Penumpang",
            txt_7:"Tipe & No.  Identitas",
            txt_8:"Kursi berangkat",
            txt_9:"Kursi Pulang",
            txt_10:"Kembali",
            txt_11:"Verifikasi & Bayar",
            txt_12:"Apakah Pesanan Anda sudah sesuai ?",
            txt_13:"Anda tidak akan diperkenankan mengubah detail pemesanan setelah masuk ke proses pembayaran. Apakah Anda ingin melanjutkan ?",
            txt_14:"Periksa Kembali",
            txt_15:"Sudah Sesuai",
            txt_16:"ID PEMESANAN",
            txt_17:"Salin",
            txt_18:"Detail Bayar",
            txt_19:"Total Bayar",
          },

          paymentMethod:{
            txt_1:"Metode Pembayaran",
            txt_2:"Transfer Virtual Account",
            txt_3:"Gerai Retail",
            txt_4:"Metode Pembayaran Belum Dipilih",
            txt_5:"Pilih metode pembayaran yang memudahkan anda",
            txt_6:"ID PEMESANAN",
            txt_7:"Salin",
            txt_8:"Rincian Bus & Shuttle",
            txt_9:"Daftar Penumpang",
          },

          paymentMethodTva:{
            txt_1:"Silahkan Transfer Ke",
            txt_2:"VIRTUAL Account",
            txt_3:"Nomor Virtual Account",
            txt_4:"Salin",
            txt_5:"Total Pembayaran",
            txt_6:"Informasi Penting :",
            txt_7:"Cara Pembayaran :",
            txt_8:"Melalui Transfer",
            txt_9:"Ganti Pembayaran",
            txt_10:"Selesai",
          },

          paymentMethodRetail:{
            txt_1:"Kode Pembayaran",
            txt_2:"Salin",
            txt_3:"Total Pembayaran",
            txt_4:"Informasi Penting :",
            txt_5:"Cara Pembayaran :",
            txt_6:"Melalui Transfer",
            txt_7:"Ganti Pembayaran ",
            txt_8:"Selesai",
            txt_9:"Verifikasi COD"
          },

          sendPacket:{
            text_1:"KIRIM PAKET / DOKUMEN",
            text_2:"Data Pengirim",
            text_3:"Nama Pengirim",
            text_4:"Provinsi",
            text_5:"Kota",
            text_6:"Kecamatan",
            text_7:"No Handphone",
            text_8:"Alamat Pengirim",
            text_9:"Kode Pos ",
            text_10:"Pilih Lokasi Outlet Untuk Antar Paket ",
            text_11:"Lanjut ",
            text_12:"Antar Sampai Tujuan ",
            text_13:"Ambil Di Counter ",
            text_14:"Data Penerima ",
            text_15:"Nama Penerima ",
            text_16:"Provinsi ",
            text_17:"Kota ",
            text_18:"Kecamatan ",
            text_19:"No Handphone ",
            text_20:"Alamat Penerima ",
            text_21:"Kode Pos ",
            text_22:"Pilih Lokasi Outlet Untuk Ambil Paket ",
            text_23:"Kembali ",
            text_24:"Lanjut ",
            text_25:"Informasi Paket ",
            text_26:"Jenis Paket ",
            text_27:"Paket ",
            text_28:"Dokumen ",
            text_29:"Ukuran ",
            text_30:"Isi Paket ",
            text_31:"Catatan",
            text_32:"Kembali",
            text_33:"Cek Tarif Pengiriman",
            text_34:"Biaya Kirim Paket",
            text_35:"Lanjutkan Pembayaran",
            text_36:"Kembali",
            text_37:"Biaya Paket (Reguler)"
          },

          checkPemesananNonMember:{
            txt_1:"Menu Cek Pesanan",
            txt_2:"Tiket Saya",
            txt_3:"Paket Saya",
            txt_4:"Pengecekan Transaksi Lebih Mudah Dengan Akun JRG",
            txt_5:"Masuk atau Daftar untuk mengakses detail pesanan melalui menu Pesanan Saya. Kami menyimpan semua detail transaksi, agar Anda mudah mengeceknya  pun, di mana pun!",
            txt_6:"Untuk mengetahui nomor pemesanan transaksi tiket anda, Silahkan untuk memeriksa informasi transaksi tiket yang masuk melalui email anda. Terimakasih",
            txt_6_1:"Untuk mengetahui nomor pemesanan transaksi Paket anda, Silahkan untuk memeriksa informasi transaksi Paket yang masuk melalui email anda. Terimakasih",
            txt_7:"No. Pesanan",
            txt_8:"Cek Pesanan",
            txt_9:"Kode Pemesanan",
            txt_9_1:"Nomor Resi",
            txt_10:"Pesanan Kadaluarsa",
            txt_11:"Aktif",
            txt_12:"Selesai",
            txt_13:"Belum Bayar",
            txt_14:"Penumpang",
            txt_14_1:"Ukuran Paket",
            txt_15:"orang",
            txt_16:"Penumpang",
            txt_17:"orang",
            txt_18:"Lihat Detail",
            txt_19:"Metode Pembayaran :",
            txt_20:"Informasi Penting :",
            txt_21:"Cara Pembayaran :",
            txt_22:"Total Pembayaran",

            txt_23: "Tiket Saya",
            txt_24: "Berangkat",
            txt_25: "Pulang",
            txt_26: "Tanggal Order",
            txt_27: "Details Penumpang",
            txt_28: "Total",
            txt_29: "Lihat Tket",
            txt_30: "Lihat Pembayaran",
            txt_31: "Paket Saya",
            txt_32: "Nomor Resi Pengiriman",
            txt_33: "Cek Nomor Resi",
            txt_34: "Asal",
            txt_35: "Telp",
            txt_36: "Tujuan",
            txt_37: "Telp",
            txt_38: "Jenis",
            txt_39: "Isi Paket",
            txt_40: "Catatan",
            txt_41: "Lihat Pembayaran",
            txt_42: "Lihat Resi",
            txt_43: "Pembayaran dana akan dilakukan di halaman DANA, Silahkan melakukan pembayaran hingga selesai.",
            txt_44: "Bayar Sekarang ",
            txt_45: "Pembayaran dengan OVO akan dilakukan menggunakan no telepon, Silahkan masukan no telepon ovo dan klik bayar, dan tunggu aplikasi ovo akan terbuka otomatis dan lakukan pembayaran.",
            txt_46: "Bayar Sekarang",
            txt_47: "Pembayaran Link Aja akan dilakukan di halaman resmi Link Aja, Silahkan melakukan pembayaran hingga selesai.",
            txt_48: "Bayar Sekarang",
            txt_49: "Pembayaran untuk ShopeePay menggunakan QR CODE, Silahkan scan QR-Code dibawah ini untuk melakukan pembayaran.",
            txt_50: "Pembayaran untuk menggunakan QRIS CODE, Silahkan scan QR-Code dibawah ini untuk melakukan pembayaran."
          },

          publishTicket: {
            txt_1: "Tiket Elektronik Tersedia",
            txt_2: "Informasi Tiket Keberangkatan",
            txt_3: "Perjalanan",
            txt_4: "ID Pesanan",
            txt_5: "Data Penumpang",
            txt_6: "Tidak",
            txt_7: "Nama Penumpang",
            txt_8: "Jenis Tiket",
            txt_9: "Nomor Kursi",
            txt_10: "Nomor Tiket",
            txt_11: "Dewasa",
            txt_12: "Tiket Kembali",
            txt_13: "Perjalanan",
            txt_14: "ID Pesanan",
            txt_15: "Data Penumpang",
            txt_16: "Tidak",
            txt_17: "Nama Penumpang",
            txt_18: "Jenis Tiket",
            txt_19: "Nomor Kursi",
            txt_20: "Nomor Tiket",
            txt_21: "Dewasa",
            txt_22: "Jika mengalami kendala mohon hubungi Customer service SMS/WhatsApp 0811 6816 900 atau email admin@jrgindonesia.co.id",
          },

          publishPacket: {
            txt_1: "No Seri Paket Sudah Terbit",
            txt_2: "E-Seri Paket ~ ",
            txt_3: "Nomor Seri Paket",
            txt_4: "Nomor seri Bisa digunakan dan langsung ditempelkan di paket",
            txt_5: "Jenis",
            txt_5_1: "Asal",
            txt_6: "Konter",
            txt_7: "Tujuan",
            txt_7_1: "Telp",
            txt_8: "Konter",
            txt_9: "Isi Paket",
            txt_10: "Catatan",
            txt_11: "Jika mengalami kendala mohon hubungi Customer service SMS/WhatsApp 0811 6816 900 atau email admin@jrgindonesia.co.id"
          },

          antarPaketDriver: {
            txt_1: "Manifest Paket",
            txt_2: "Data Belum Tersedia",
            txt_3: "No. Manifest",
            txt_4: "Lokasi Awal",
            txt_5: "Lokasi Sekarang",
            txt_6: "Tujuan Selanjutnya",
            txt_7: "Driver",
            txt_8: "Tanggal",
            txt_9: "Jumlah Manifest",
            txt_10: "Cari",
            txt_11: "No Resi",
            txt_12: "Penerima",
            txt_13: "Kota Penerima",
            txt_14: "Tipe",
            txt_15: "Status",
            txt_16: "Detail Paket",
            txt_17: "No Resi",
            txt_18: "Status",
            txt_19: "Nama Pengirim",
            txt_20: "Kode Pos",
            txt_21: "Nama Penerima",
            txt_22: "Kab",
            txt_23: "Provinsi",
            txt_24: "Informasi Paket",
            txt_25: "Isi Paket",
            txt_26: "Ukuran Paket",
            txt_27: "Catatan Paket",
            txt_28: "Lacak",
            txt_29: "Lacak Paket",
          },

          val:{
            v1: "Nama Pengirim Harus Di isi",
            v2:"Provinsi Pengirim Id Harus di isi",
            v3:"Provinsi Pengirim Id Harus di isi",
            v4:"Kota Pengirim Harus Di isi",
            v5:"Kota Pengirim Harus Di isi",
            v6:"Kecamatan Pengirim Harus Di isi",
            v7:"Alamat Pengirim Harus Di isi",
            v8:"Kode Pos Pengirim Harus Di isi",
            v9:"Counter Pengirim Harus Di isi",
            v10:"Counter Pengirim Harus Di isi",
            v11:"No Telepon pengirim Harus Di isi",
            v12:"No Telepon Pengirim Harus mulai dari angka 0 dan lebih dari 9 angka",
            v13:"Email Pengirim Harus Di isi",
            v14:"Email Pengirim Harus berformat benar",
            v15:"Counter Penerima Harus Di isi",
            v16:"Counter Penerima Harus Di isi",
            v17:"Nama Penerima Harus Di isi",
            v18:"Provinsi Penerima Harus Di isi",
            v19:"Provinsi Penerima Harus Di isi",
            v20:"Kota Penerima Harus Di isi",
            v21:"Kota Penerima Harus Di isi",
            v22:"Kecamatan Penerima Harus Di isi",
            v23:"Alamat Penerima Harus Di isi",
            v24: "Kode Pos Penerima Harus Di isi",
            v25:"No Telepon Penerima Harus Di isi",
            v26:"No Telepon Penerima Harus mulai dari angka 0 dan lebih dari 9 angka",

            v27:"Ukuran Paket Harus Di isi",
            v28:"Jenis Paket Harus Di isi",
            v29:"Isi Paket Harus Di isi",
          },

          valIP:{
            v1: "Nama Pemesanan Harus Di isi",
            v2:"Email Harus Di isi" ,
            v3: "Email Harus berformat benar" ,
            v4: "Nomor Telepon Harus Di isi",
            v5: "Nomor Identitas Pemesan Tidak Boleh Lebih Dari 16 Digit",
            v6: "Nomor Identitas Pemesanan harus diantara 7 - 16 Karakter",
            v7: "Nomor Telepon Tidak Benar" ,
            v8: "Panggilan Untuk Penumpang Ke " ,
            v8_1: " Harus Di isi",
            v9:  "Nama Penumpang Ke ",
            v9_1: " Harus Di isi",
            v10:"Nama Penumpang ke " ,
            v10_1:" Harus lebih dari 3 karakter ( Nama Lengkap )" ,
            v11: "Nomor Identitas Penumpang Ke ",
            v11_1: " Tidak Boleh Lebih Dari 16 Digit",
            v12_1:"  harus diantara 7 - 16 Karakter" ,
            v13:"Nomor Identitas Penumpang Ke " ,
            v13_1: " Tidak Boleh Lebih Dari 16 Digit",
          },

          swal:{
            v1:"Lengkapi data email !",
            v2:"Email Format Tidak Benar !",
            v3:"Lengkapi data No Telepon !",
            v4:"Isi No Telepon Harus Nomor",
            v5:"Lengkapi data sebutan !",
            v6:"Lengkapi data nama lengkap !",
            v7:"Lengkapi data Identitas !",
            v8:"Lengkapi data Nomor identitas !",
            v9:"Lengkapi kode OTP !",

            t1:"Kami Akan Segera Kembali",
            txt1:"Server sedang diperbaiki untuk terus memberikan pengalaman terbaik. Silahkan coba lagi nanti",

            t2:"Waktu Habis",
            txt2:"Silahkan Login Kembali !",

            t3:"Berhasil",
            txt3:"Berhasil mengubah data Email, Silahkan Melakukan login kembali dengan menggunakan alamat email yang baru",

            t4:"Berhasil",
            txt4:"Berhasil mengubah data No Telepon, Silahkan Melakukan login kembali dengan menggunakan alamat no telepon yang baru",

            t5:"Peringatan",
            txt5:"Kode OTP Salah",

            t6:"Maaf",
            txt6:"Password Tidak Sama",
            txt6_1:"Password Harus Di Isi",
            txt6_2:"Password Berhasil Di Ubah",

            informasi:"Informasi",
            peringatan:"Peringatan",
            no_telepon_belum_benar:"No Telepon Belum Benar",
            ada_kesalahan:"Ada kesalahan"
          },
          

        },
        en: {

          locale:"en",
          locale_lang:"en_US",

          swal: {
           v1:"Complete email data!",
            v2:"Invalid Format Email!",
            v3:"Complete Phone Number data!",
            v4:"Fill in Phone Number Must be Number",
            v5:"Complete mention data!",
            v6:"Complete full name data!",
            v7:"Complete Identity data!",
            v8:"Complete ID Number data!",
            v9:"Complete OTP code!",

            t1:"We Will Be Back Soon",
            txt1:"Server is being repaired to continue to provide the best experience. Please try again later",

            t2:"Time Out",
            txt2:"Please Login Again!",

            t3:"Success",
            txt3:"Successfully changing Email data, Please login again using the new email address",

            t4:"Success",
            txt4:"Successfully changing Phone Number data, Please login again using the new phone number address",

            t5:"Warning",
            txt5:"Invalid OTP Code",

            t6:"Sorry",
            txt6:"Unmatched Password",
            txt6_1:"Password Required",
            txt6_2:"Password Changed Successfully",

            information:"Information",
            warning:"Warning",
            no_telepon_belum_true:"Phone Number Not Correct",
            there_error:"There was an error"
          },

          val:{
            v1: "Sender Name Required",
            v2:"Province of Sender Id Required",
            v3:"Province of Sender Id Required",
            v4:"Sending City Required",
            v5:"Sending City Required",
            v6:"Submitting District Required",
            v7:"Sender Address Required",
            v8:"Sender's Zip Code is Required",
            v9:"Sending Counter Required",
            v10:"Sending Counter Required",
            v11:"The sender's phone number must be filled in",
            v12:"Sender's Phone Number Must start from 0 and more than 9 digits",
            v13:"Sender's Email Required",
            v14:"Sender's Email Must be in correct format",
            v15:"Recipient Counter Required",
            v16:"Recipient Counter Required",
            v17:"Recipient Name Required",
            v18:"Recipient Province Required",
            v19:"Recipient Province Required",
            v20:"Recipient City Required",
            v21:"Recipient City Required",
            v22:"Recipient District Required",
            v23:"Recipient Address Required",
            v24: "Recipient Postal Code Required",
            v25:"Recipient's Phone Number Must Be Filled",
            v26:"Recipient's Phone Number Must start from 0 and more than 9 digits",

            v27:"Package Size Required",
             v28:"Package Type Required",
             v29:"Package Contents Must Be Filled",
          },

          footer:{
            text_1:"Service",
            text_2:"Bus And Shuttle Tickets",
            text_3:"Send Package",
            text_4:"About JRG",
            text_5:"Customer Service",
          },

          sendPacket:{
            text_1:"SEND PACKAGE / DOCUMENT",
            text_2:"Sender Data",
            text_3:"Sender Name",
            text_4:"Province",
            text_5:"City",
            text_6:"District",
            text_7:"Mobile Number",
            text_8:"Sender Address",
            text_9:"Zip Code",
            text_10:"Choose Counter Location for Delivery of Packages",
            text_11:"Continue",
            text_12:"Delivery To Destination",
            text_13:"Take At Counter",
            text_14:"Recipient Data",
            text_15:"Recipient Name",
            text_16:"Province ",
            text_17:"City",
            text_18:"District",
            text_19:"Mobile Number",
            text_20:"Recipient Address",
            text_21:"Zip Code",
            text_22:"Select Counter Location To Pick Up Packages",
            text_23:"Back",
            text_24:"Continue",
            text_25:"Package Information",
            text_26:"Package Type",
            text_27:"Package",
            text_28:"Document",
            text_29:"Size",
            text_30:"Package Contents",
            text_31:"Note",
            text_32:"Back",
            text_33:"Check Shipping Rates",
            text_34:"Package Delivery Fee",
            text_35:"Continue Payment",
            text_36:"Back",
            text_37:"Package Fee (Regular)"
          },

          message: {
            top_titlediv1: 'Ticket Bus And Shuttle',
            top_form_dari: 'From',
            top_form_ke: 'To',
            top_form_berangkat: 'Go Date',
            top_form_pulang: 'Home Date',
            top_form_penumpang: 'Passenger',
            top_form_deskripsi: 'Log in or register to get the benefits',
            top_form_cari: 'Search',
            usia_des:'Age Over 3 Years',

            top_form_placeholder_dari: 'Home City',
            top_form_placeholder_ke: 'Destination city',
            top_form_placeholder_dewasa: 'Adult',

            top_options_seat: [
              { name: '1 Adult', value: 1 },
              { name: '2 Adult', value: 2 },
              { name: '3 Adult', value: 3 }
            ],
          },

          valIP:{
            v1: "Order Name Required",
            v2:"Email Required",
            v3: "Email Must be properly formatted",
            v4: "Phone Number Required",
            v5: "Customer Identity Number Can't Be More Than 16 Digits",
            v6: "Order ID number must be between 7 - 16 Characters",
            v7: "Invalid Phone Number",
            v8: "Call For Passenger To " ,
            v8_1: "Required",
            v9: "Passenger Name To",
            v9_1: "Required",
            v10:"Passenger Name to " ,
            v10_1:"Must be more than 3 characters(Full Name)" ,
            v11: "Passenger Identity Number To ",
            v11_1: "No More Than 16 Digits",
            v12: "Passenger Identity Number To " ,
            v12_1:"must be between 7 - 16 Characters" ,
            v13:"Passenger ID Number To " ,
            v13_1: "No More Than 16 Digits",
          },

          publishPacket: {
            txt_1: "Package Serial Number Already Issued",
            txt_2: "E-Series Package ~",
            txt_3: "Package Serial Number",
            txt_4: "Serial number Can be used and directly affixed to the package",
            txt_5: "Type",
            txt_5_1: "Origin",
            txt_6: "Counter",
            txt_7: "Destination",
            txt_7_1: "Phone",
            txt_8: "Counter",
            txt_9: "Package Contents",
            txt_10: "Note",
            txt_11: "If you experience problems, please contact Customer service 0813 5678 8989 or email cs@jrgtravel.com"
          },

          contentUtama:{
            text_1 : "Why Use JRG Indonesia? ",
            text_2 : "Secure Transaction ? ",
            text_3 : "Online transactions are protected by SSL technology & e-tickets will be sent directly to your e-mail or mobile number",
            text_4 : "Can Choose Seat",
            text_5 : "Select bus and seat, place of departure, destination and click Payment",
            text_6 : "Various Payment Methods",
            text_7 : "Payments can be made via ATM transfer, internet banking, Alfamaret, debit and credit cards, Mandiri Clikpay BCA Mobile.",
            text_8 : "Bus Routes & Shuttle.",
            text_9 : "Payment Method.",
            text_10 : "Install the JRG application on your android smartphone",
            text_11 : "Get convenience when booking your placement ticket."
          },

          cekResi:{
            text_1:"Your shipment is safe, check the position",
            text_2:"Enter Serial Number and click track package button to see its position",
            text_3:"Check Package",
            text_4:"Serial Number Found Successful",
            text_5:"Sender Name",
            text_6:"Recipient Name",
            text_7:"Package Type",
            text_8:"Package Contents",
            text_9:"Package Size"
          },

         

          register:{
              text_1: "Become a JRG Indonesia member",
              text_2: "Register now, enjoy the following convenience and benefits",
              text_3: "Secure Transaction",
              text_4: "Enter the place of departure, destination, Passenger. Shuttle date and then click SEARCH",
              text_5: "Can Choose a Chair",
              text_6: "Select the bus and seat, place of departure, destination and click Payment",
              text_7: "Various Payment Methods",
              text_8: "Payment can be made via ATM transfer, internet mbanking, alfamaret, debit and credit cards, Mandiri clikpay bca mobile.",
              text_9: "Register Now",
              text_10: "Mobile Number",
              text_11: "Email",
              text_12: "Your data will be protected and will not be shared",
              text_13: "Register",
              text_14: "By registering I agree, the terms and conditions and privacy policy",
              text_15: "Or Register With",
              text_16: "Account Verification",
              text_17: "We have sent a verification code to",
              text_18: "Use the code for verification",
              text_19: "Verification Code",
              text_20: "Send Verification code?",
              text_21: "Send Again",
              text_22: "Submit",
              text_23: "Account Verification",
              text_24: "One more step to become a member of JRG Indonesia. Please fill in your full name and password to login.",
              text_25: "Full Name",
              text_26: "Password",
              text_27: "Retype Password",
              text_28: "Send me info about JRG Indonesia promotions and discounts",
              text_29: "Continue",
              text_30: "Additional Information",
              text_31: "Please complete the information below to receive a special offer just for you.",
              text_32: "Gender",
              text_33: "Date of Birth",
              text_34: "Save & Finish",
              text_35: "Thank you",
              text_36: "You are already a JRG Member",
              text_37: "Please enter your account on the main page to enjoy the convenience of ordering tickets and get the benefits of being a JRG Indonesia member",
              text_38: "Go to Main Page",
          },

          terimaPaket:{
            text_1:"Manifest Package",
            text_2:"Data Not Available",
            text_3:"No. Manifest",
            text_4:"Courier",
            text_5:"Date",
            text_6:"Number of Manifests",
            text_7:"Enter Serial Number and Click Search Receipt Data Button",
            text_8:"Search",
            text_9:"No Receipt",
            text_10:"Recipient",
            text_11:"Recipient City",
            text_12:"Type",
            text_13:"Status",
            text_14:"Confirmation of Package Delivery",
            text_15:"No Receipt : ",
            text_16:"Package Delivery Details",
            text_17:"Sender Name",
            text_18:"Recipient Name",
            text_19:"Track",
            text_20:"Package Information",
            text_21:"Package Contents",
            text_22:"Ukrainian Package",
            text_23:"Package Note",
            text_23_1:"Cost Status",
            text_23_2:"Package Fee",
            text_24:"Received By",
            text_25:"Proof of Delivery",
            text_26:"Photo",
            text_27:"TTD",
            text_28:"Capture Photo",
            text_29:"Repeat Photo",
            text_30:"Repeat TTD",
            text_31:"Save",
            text_32:"Reason",
            text_33:"Save",
            text_34:"Track Package",
          },

          cekOngkir:{
            text_1:"Check your Postage",
            text_2:"Enter Serial Number and click track package button to see its position",
            text_3:"From",
            text_4:"To",
            text_5:"Type Send",
            text_6:"Size",
            text_7:"Size",
            text_8:"Check Postage",
            text_9:"Package Delivery Fee",
            text_10:"Packages are picked up at JRG Counters",
            text_11:"Packages are delivered to the destination address",
          },

          app_header:{
            menu_1:'Packet',
            menu_1_1:'Send Packet',
            menu_1_2:'Tract Paket',
            menu_1_3:'Check Rates ',
            menu_1_4:'Confirm Packet',
            menu_1_5:'Packet Driver',
            menu_2:'Check Order',

            btn_masuk:'Login',
            btn_daftar:'Register',

            menu_member:'Menu Member',
            halaman_member:'Member Page',
            btn_keluar:'Logout',

            txt_login_sekarang:'Login Now',
            txt_lupa_kata_sandi:'Forget Password ?',
            txt_sandi_salah:'Wrong Password',
            txt_masuk:'Login',
            txt_atau:'Or Login With',

            txt_login_pc_1:'Phone Number / Email',
            txt_login_pc_2:'Password',

            txt_tlt_lupa_sandi:'Forget Password',
            txt_deskripsi_lupa_sandi:'Enter your email address or mobile number and we will send instructions to change your password.',
            txt_btn_lupa_sandi:'Verification',

            txt_verifikasi:'Verification ACcount',
            txt_verifikasi_1:'A verification code has been sent to',
            txt_verifikasi_2:'Enter the code that you have received to verify and change the new password',
            txt_verifikasi_3:'Send',
            txt_verifikasi_4:'Send Verification code ?',
            txt_verifikasi_5:'Send Again',

            txt_kata_sandi_baru:'New password',
            txt_kata_sandi_baru_1:'Create your new password to access your JRG Indonesia account with the account : ',
            txt_kata_sandi_baru_2:'After the password has been changed, please log back in with the new password on all your devices',
            txt_kata_sandi_baru_3:'Save',
            txt_kata_sandi_baru_4:'New Password',
            txt_kata_sandi_baru_5:'Repeat new password',
          },

         

          search_bus:{
            txt_penumpang_dewasa:"Adult Passenger",
            txt_ubah_pencarian:"Change Search",
            txt_tiket_bus_dan_travel:"Ticket Bus And Shuttle",
            txt_filter:"Filter",
            txt_waktu_berangkat:"Departure Time",

            txt_pagi:"Morning",
            txt_sore:"Afternoon",
            txt_malam:"Night",
            txt_armada:"Fleet",

            txt_keberangkatan_pilihan:"Your Choice of Departure Shuttle :",
            txt_travel:"Shuttle",

            txt_kursi:"Chairs",
            txt_tersedia:"Available",
            txt_cancel:"Cancel",
            txt_pilih_jadwal:"Choose a Schedule",

            txt_pilih:"Choose",
            txt_habis:"Run Out",

            txt_jadwal_tidak_tersedia:"SCHEDULE NOT AVAILABLE",
            txt_jadwal_tidak_tersedia_des:"The trip you want is not available at this time. Try another trip",

            txt_pil_pdb:"Choose, Book and Go! ",
            txt_pil_pdb_des1:"Booking bus & shuttle tickets is as easy as shopping online. from searching for a schedule, choosing a seat to making payments while relaxing at home, no need to bother lining up! ",
            txt_pil_pdb_des2:"Buy bus & shuttle tickets through the JRG Indonesia application",

          },

          antarPaketDriver: {
            txt_1: "Package Manifest",
            txt_2: "Data Not Available",
            txt_3: "No.Manifest",
            txt_4: "Initial Location",
            txt_5: "Current Location",
            txt_6: "Next Destination",
            txt_7: "Drivers",
            txt_8: "Date",
            txt_9: "Number of Manifests",
            txt_10: "Search",
            txt_11: "No Receipt",
            txt_12: "Recipient",
            txt_13: "Receiving City",
            txt_14: "Type",
            txt_15: "Status",
            txt_16: "Package Details",
            txt_17: "No Receipt",
            txt_18: "Status",
            txt_19: "Sender Name",
            txt_20: "Zip Code",
            txt_21: "Recipient Name",
            txt_22: "Kab",
            txt_23: "Province",
            txt_24: "Package Information",
            txt_25: "Package Contents",
            txt_26: "Package Size",
            txt_27: "Package Notes",
            txt_28: "Track",
            txt_29: "Track Packages"
          },


          inputPassenger:{
            txt_daftar_pesanan:"Order List",
            txt_isi_data_penumpang:"Fill in Passenger Data and Review Your Order",
            txt_daftar_pemesan_1:"List of Customers (For E-Tickets)",

            txt_pilih_kursi_berangkat:"Select Departure Seat",
            txt_pilih_kursi_pulang:"Select Go Home",

            txt_data_1:"Use Existing Data?",
            txt_data_2:"Call",
            txt_data_3:"Full name",
            txt_data_4:"Identity Type",
            txt_data_5:"Identity Number (according to KTP / passport / other)",
            txt_data_6:"For ages under 17 years old, select your date of birth in the Identity Type column",
            txt_data_7:"No phone",
            txt_data_8:"Telephone Code",
            txt_data_9:"Fill in the telephone number that can be contacted",
            txt_data_10:"Fill in the active email address correctly",
            txt_data_11:"List of Passengers",
            txt_data_12:"Same with the customer",
            
            txt_data_13:"Adult",
            txt_data_14:"Call",
            txt_data_15:"Full Name",
            txt_data_16:"Identity Type",
            txt_data_17:"Identity Number (according to KTP / passport / other)",
            txt_data_18:"For ages under 17 years old, select your date of birth in the Identity Type column",
            txt_data_19:"Sorry, there are some things that need to be fixed",
            txt_data_20:"Save & Select Seats",
            txt_data_20_1:"Cancel",
            txt_data_21:"Seat Number:",
            txt_data_22:"Change",
            txt_data_23:"Time You Live:",
            txt_data_24:"Empty",
            txt_data_25:"Filled",
            txt_data_26:"Be chosen",
            txt_data_27:"Seat Number:",
            txt_data_28:"Change",
            txt_data_29:"Save & Continue",
            txt_data_30:"Total Pay",
          },

          finsihOrder:{
            txt_review_pesanan:"Order Review",
            txt_1:"Order Details",
            txt_2:"Departure Ticket",
            txt_3:"Shuttle",
            txt_4:"Return Ticket",
            txt_5:"Shuttle",
            txt_6:"List of Passengers",
            txt_7:"Type & No. Identity",
            txt_8:"Chairs depart",
            txt_9:"Home Chair",
            txt_10:"Back",
            txt_11:"Verify & Pay",
            txt_12:"Is your Order suitable?",
            txt_13:"You will not be allowed to change the order details after entering the payment process. Would you like to continue?",
            txt_14:"Check again",
            txt_15:"Already appropriate",
            txt_16:"BOOKING ID",
            txt_17:"Copy",
            txt_18:"Details Pay",
            txt_19:"Total Pay",
          },

          paymentMethod:{
            txt_1:"Payment method",
            txt_2:"Transfer Virtual Account",
            txt_3:"Retail outlets",
            txt_4:"Payment Method Not Selected",
            txt_5:"Choose a payment method that makes it easier for you",
            txt_6:"BOOKING ID",
            txt_7:"Copy",
            txt_8:"Bus & Shuttle details",
            txt_9:"List of Passengers",
          },

          paymentMethodTva:{
            txt_1:"Please Transfer To",
            txt_2:"VIRTUAL Account",
            txt_3:"Virtual Account Number",
            txt_4:"Copy",
            txt_5:"Total payment",
            txt_6:"Important Information:",
            txt_7:"Payment method :",
            txt_8:"Via Transfer",
            txt_9:"Change Payment Methods",
            txt_10:"Done",
          },
          
          paymentMethodRetail:{
            txt_1:"Payment Code",
            txt_2:"Copy",
            txt_3:"Total payment",
            txt_4:"Important Information:",
            txt_5:"Payment method :",
            txt_6:"Via Transfer",
            txt_7:"Change Payment Methods",
            txt_8:"Done",
            txt_9:"Verification COD",
          },

          checkPemesananNonMember:{
            txt_1: "Order Check Menu",
            txt_2: "My Tickets",
            txt_3: "My Package",
            txt_4: "It's easier to check orders on JRG Indonesia",
            txt_5: "Login or Register to access order details via the My Orders menu. We store all transaction details, so you can easily check them anywhere, anywhere!",
            txt_6: "To find out your transaction order number, please check the transaction information entered via your email. Thank you",
            txt_7: "Order No.",
            txt_8: "Check Order",
            txt_9: "Order Code",
            txt_10: "Order Expired",
            txt_11: "Active",
            txt_12: "Done",
            txt_13: "Not Paid",
            txt_14: "Passenger",
            txt_14_1:"Package Size",
            txt_15: "people",
            txt_16: "Passenger",
            txt_17: "people",
            txt_18: "View Details",
            txt_19: "Payment Method:",
            txt_20: "Important Information:",
            txt_21: "Payment Method:",
            txt_22: "Total Payment",

            txt_23: "My Ticket",
            txt_24: "Depart",
            txt_25: "Home",
            txt_26: "Order Date",
            txt_27: "Passenger Details",
            txt_28: "Total",
            txt_29: "View Tket",
            txt_30: "View Payment",
            txt_31: "My Plans",
            txt_32: "Delivery Receipt Number",
            txt_33: "Check Receipt Number",
            txt_34: "Origin",
            txt_35: "Phone",
            txt_36: "Destination",
            txt_37: "Call",
            txt_38: "Type",
            txt_39: "Package Contents",
            txt_40: "Note",
            txt_41: "View Payment",
            txt_42: "View Receipt",
            txt_43: "Payment of funds will be made on the DANA page, Please make the payment to completion.",
            txt_44: "Pay Now",
            txt_45: "Payments with OVO will be made using your phone number. Please enter your ovo phone number and click pay, and wait for the ovo application to open automatically and make payment.",
            txt_46: "Pay Now",
            txt_47: "Link Aja payments will be made on the Link Aja official page. Please make the payment until it's finished.",
            txt_48: "Pay Now",
            txt_49: "Payment for ShopeePay using QR CODE, Please scan the QR-Code below to make payment.",
            txt_50: "Payment to use QRIS CODE, Please scan the QR-Code below to make payment."
          },

          publishTicket:{
            txt_1: "Electronic Tickets Available",
            txt_2: "Information Departure Ticket",
            txt_3: "Shuttle",
            txt_4: "Order ID",
            txt_5: "Passenger Data",
            txt_6: "No",
            txt_7: "Passenger Name",
            txt_8: "Ticket Type",
            txt_9: "Seat Number",
            txt_10: "Ticket Number",
            txt_11: "Adult",
            txt_12: "Return Ticket",
            txt_13: "Shuttle",
            txt_14: "Order ID",
            txt_15: "Passenger Data",
            txt_16: "No",
            txt_17: "Passenger Name",
            txt_18: "Ticket Type",
            txt_19: "Seat Number",
            txt_20: "Ticket Number",
            txt_21: "Adult",
            txt_22: "If you experience problems, please contact Customer service 0813 5678 8989 or email cs@jrgtravel.com",
          },

          profile_accountme: {
            text_1: "Full Name",
            text_2: "Change Login Password",
            text_3: "No Identity",
            text_4: "Mobile Number",
            text_5: "Email",
            text_6: "Linked Accounts",
            text_7: "Merge Account",
            text_8: "Merge Accounts",
            text_9: "Change Profile",
            text_10: "Save",
            text_11: "Send OTP",

            text_12:"Change Phone Number",
            text_13:"To change the phone number data, an otp code is required to a new phone number.",
            text_14:"Change",
            text_15:"To change the email data, you need an otp code to a new email.",
            text_16:"Submit.",
            text_17:"Save.",
            text_18:"Change Password.",
            text_19:"Please enter a new password and repeat the password to change your password.",
          },

          profile_listpassenger: {
            text_1: "Fill in passenger details now, just select passengers later. With Passenger List, you no longer need to fill in the same passenger details every time you book.",
            text_2: "Add Passenger",
            text_3: "Passenger List",
            text_4: "Fill in the passenger details now, just select the passenger later. With the Passenger List, you no longer need to fill in the same passenger details every time you order.",
            text_5: "Passenger Details",
            text_6: "You can add up to 4 passengers",
            text_7: "Delete",
            text_8: "Edit",
            text_9: "Adult",
            text_10: "Call",
            text_11: "(Required)",
            text_12: "First Name",
            text_13: "(Required)",
            text_14: "(Required)",
            text_15: "Identity Type",
            text_16: "Identity Number (according to KTP / passport / other)",
            text_17: "For ages under 17 years old, select the date of birth in the Identity Type column",
            text_18: "Sorry, there are some things that need to be fixed",
            text_19: "Cancel",
          },

          profile_myorder: {
            text_1: "My Tickets",
            text_2: "My Package",
            text_3: "Not Paid",
            text_4: "Active",
            text_5: "Done",
            text_6: "Expired",
            text_7: "Order Code",
            text_8: "Order Expired",
            text_9: "Active",
            text_10: "Done",
            text_11: "Not Paid",
            text_12: "(Departing)",
            text_13: "depart:",
            text_14: "(Home)",
            text_15: "depart:",
            text_16: "View Details",
            text_17: "Payment Method:",
            text_18: "Please Transfer To",
            text_19: "Total Payment",
            text_20: "Payment Method:",

            text_21: "Asal",
            text_22: "Telp",
            text_23: "Tujuan",
            text_24: "Telp",
            text_25: "Jenis",
            text_26: "Isi Paket",
            text_27: "Catatan",
            text_28: "Pembayaran dana akan dilakukan di halaman DANA, Silahkan melakukan pembayaran hingga selesai.",
            text_29: "Bayar Sekarang",
            text_30: "Pembayaran dengan OVO akan dilakukan menggunakan no telepon, Silahkan masukan no telepon ovo dan klik bayar, dan tunggu aplikasi ovo akan terbuka otomatis dan lakukan pembayaran.",
            text_31: "Pembayaran Link Aja akan dilakukan di halaman resmi Link Aja, Silahkan melakukan pembayaran hingga selesai.",
            text_32: "Bayar Sekarang",
            text_33: "Pembayaran untuk ShopeePay menggunakan QR CODE, Silahkan scan QR-Code dibawah ini untuk melakukan pembayaran.",
            text_34: "Pembayaran untuk menggunakan QRIS CODE, Silahkan scan QR-Code dibawah ini untuk melakukan pembayaran.",
            text_35: "Informasi Penting",


          },

          profile: {
            text_1: "My Orders",
            text_2: "Passenger List",
            text_3: "My Account",
            text_4: "Log Out of Account",
          },

        }
    };
